/* eslint-disable react-hooks/exhaustive-deps */
/* Hook für die Rückgabe der Dimensions eines bestimmten Nodes (über ref)  */

import { useState, useCallback, useLayoutEffect } from 'react'

interface DimensionObject {
  width: number
  height: number
  top: number
  left: number
  x: number
  y: number
  right: number
  bottom: number
}

type UseDimensionsHook = [
  (node: HTMLElement) => void,
  {} | DimensionObject,
  HTMLElement | null
]

interface UseDimensionsArgs {
  liveMeasure?: boolean
}

function getDimensionObject(node: HTMLElement): DimensionObject {
  const rect = node.getBoundingClientRect()

  return {
    width: rect.width,
    height: rect.height,
    top: rect.x || rect.top,
    left: rect.y || rect.left,
    x: rect.x || rect.left,
    y: rect.y || rect.top,
    right: rect.right,
    bottom: rect.bottom
  }
}

function useOwnDimensions({
  liveMeasure = true
}: UseDimensionsArgs = {}): UseDimensionsHook {
  const [dimensions, setDimensions] = useState({})
  const [node, setNode] = useState<HTMLElement | null>(null)

  const ref = useCallback((node) => {
    setNode(node)
  }, [])

  useLayoutEffect(() => {
    if (node) {
      const measure = () =>
        window.requestAnimationFrame(() =>
          setDimensions(getDimensionObject(node))
        )
      measure()

      if (liveMeasure) {
        window.addEventListener('resize', measure)

        return () => {
          window.removeEventListener('resize', measure)
        }
      }
    }
  }, [node])

  return [ref, dimensions as DimensionObject, node]
}

export default useOwnDimensions
