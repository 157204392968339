import React from 'react'
import BaseInput from '@components/elements/BaseInput'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
const Label = styled.p`
  color: black;
  margin-left: 5px;
`
const Container = styled.div`
  cursor: pointer;
  display: flex;
  flex: 1 1 33%;
  align-items: center;
  padding-left: 5px;
  margin-left: -5px;
`

const SingleChoice = ({
  inputName,
  options,
  value,
  onChange,
  placeholder
}: {
  inputName: string
  options: string[]
  value: boolean
  onChange: (inputName: string, options: any[]) => void
  placeholder?: string
}) => {
  const { t } = useTranslation()
  return (
    <Container onClick={() => onChange(inputName, options)}>
      <BaseInput
        type="radio"
        onChange={(e) => onChange(inputName, options)}
        checked={value}
      />
      <Label>{t(`${placeholder}`)}</Label>
    </Container>
  )
}

export default SingleChoice
