import { MachineCard } from '@components/cards/MachineCard'
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Grid
} from '@material-ui/core'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { Project } from '../../../../interfaces/project'

const StyledAccordion = styled(Accordion)`
  // Remove grey line above Accordion
  &:before {
    display: none;
  }
`

const StyledGridItem = styled(Grid)`
  // Empty grid items should not occupy space
  &:empty {
    display: none;
  }
`

const EmptySoldCard = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  border-radius: 10px;
  box-sizing: border-box;
  border: dashed 2px;
`
const EmptyCard = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  border-radius: 10px;
  box-sizing: border-box;
  border: dashed 2px;
`

export const SoldMachinesAccordion = ({
  machineProjects,
  idsOfHiddenProjects,
  setDeleteDialog,
  goToDescriptionImages,
  machineCardOptionsId,
  setMachineCardOptionsId
}: {
  machineProjects: Project[]
  idsOfHiddenProjects: string[]
  setDeleteDialog: (pId?: string | null) => void
  goToDescriptionImages: (project: Project) => void
  machineCardOptionsId?: string
  setMachineCardOptionsId: (id: string) => void
}) => {
  const { t } = useTranslation()
  const [soldMachinesExpanded, setSoldMachinesExpanded] = useState(true)

  const showEmptyCards = machineProjects.length % 3 !== 0
  const showTwoEmptyCards = machineProjects.length % 3 === 1

  return (
    <StyledAccordion
      style={{ boxShadow: 'none', background: 'none' }}
      expanded={soldMachinesExpanded}
      onChange={() => setSoldMachinesExpanded((prevState) => !prevState)}>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <h3 style={{ fontSize: 25 }}>{t('home.sold')}</h3>
      </AccordionSummary>
      <AccordionDetails>
        <Grid container spacing={3}>
          {machineProjects.map(
            (mProject) =>
              !idsOfHiddenProjects.includes(mProject.id!) && (
                <StyledGridItem
                  item
                  xs={12}
                  sm={6}
                  md={4}
                  style={{ height: 300 }}
                  key={mProject.id}>
                  <MachineCard
                    project={mProject}
                    onDelete={() => setDeleteDialog(mProject.id)}
                    options={[
                      {
                        title: t('home.goToTheOverview'),
                        onClick: () => goToDescriptionImages(mProject)
                      }
                    ]}
                    machineCardOptionsId={machineCardOptionsId}
                    setMachineCardOptionsId={setMachineCardOptionsId}
                  />
                </StyledGridItem>
              )
          )}
          {showEmptyCards && (
            <StyledGridItem item xs={12} sm={6} md={4} style={{ height: 300 }}>
              <EmptyCard />
            </StyledGridItem>
          )}
          {showEmptyCards && showTwoEmptyCards && (
            <StyledGridItem item xs={12} sm={6} md={4} style={{ height: 300 }}>
              <EmptyCard />
            </StyledGridItem>
          )}
          {machineProjects.length < 1 && (
            <Grid item xs={12} sm={6} md={4} style={{ height: 300 }}>
              <EmptySoldCard>
                <p
                  style={{
                    fontWeight: 500,
                    fontSize: 18,
                    textAlign: 'center'
                  }}>
                  {t('home.hereCouldBeYourMachine')}
                </p>
              </EmptySoldCard>
            </Grid>
          )}
        </Grid>
      </AccordionDetails>
    </StyledAccordion>
  )
}
