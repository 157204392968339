import { Project } from '@interfaces/project'
import { createSelector } from 'reselect'

export const selectFirebaseData = (s: any) => s?.firestore?.data

export const selectUserProjects = createSelector(
  selectFirebaseData,
  (firebaseData): Project[] =>
    (firebaseData?.companyProjects &&
      Object.entries(firebaseData?.companyProjects)
        ?.filter(([key, value]) => !!value)
        ?.map(([key, value]: [string, any]) => ({
          ...value,
          id: key
        }))) ||
    []
)
export const selectCompanySpectrum = createSelector(
  selectUserProjects,
  (projects) => projects[0] || null
)
