import { setName } from '@redux/project'
import { selectCurrentProject } from '@selectors/projectSelectors'
import React, { useState, useRef, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

const NameDisplay = styled.input`
  background: #fff;
  min-width: 225px;
  height: 45px;
  border-radius: 23px;
  box-sizing: border-box;
  padding: 0 20px;
  color: black;
  font-weight: 800;
  font-size: 30px;
  outline: none;
  border-style: solid;
  z-index: 1;
  :focus {
    box-shadow: 0 0 3px 1px ${({ theme }) => theme.primary};
  }
`

const BorderAnimation = styled.div`
  --color-primary: ${({ theme }) => theme.primary};
  position: absolute;
  transform: scale(1.01, 1.1);
  min-width: 225px;
  height: 45px;
  border-radius: 26px;
  background-image: linear-gradient(
    to right,
    rgba(0, 0, 0, 0) 37%,
    var(--color-primary) 40%,
    rgba(0, 0, 0, 0) 43%
  );
  background-size: 300%;
  animation: move 6s infinite;
  @keyframes move {
    0% {
      background-position: right;
    }
    100% {
      background-position: left;
    }
  }
`

const MeasureDiv = styled.div`
  display: inline-block;
  white-space: pre;
  min-width: 225px;
  box-sizing: border-box;
  padding-left: 20px;
  font-weight: 800;
  font-size: 30px;
  position: absolute;
  height: 0;
  overflow: hidden;
`
const NameContainer = styled.div`
  display: flex;
  align-items: center;
  position: relative;
`

const ProjectName: React.FC<{ placeholder?: string; className?: string }> = ({
  placeholder,
  className
}) => {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const { name } = useSelector(selectCurrentProject)
  const defaultName = placeholder ?? t('project.basics.defaultProjectName')
  const [nameDisplayWidth, setNameDisplayWidth] = useState(405)
  const [playBorderAnim, setPlayBorderAnim] = useState(name?.length < 1)
  const input = useRef<HTMLInputElement | null>(null)
  const measureRef = useRef<HTMLDivElement | null>(null)

  function getMeasureDivWidth(node: HTMLDivElement) {
    const { width } = node.getBoundingClientRect()
    return width
  }

  const handleChange = (e: any) => {
    dispatch(setName(e.target.value))
  }

  // Ignore Enter-Key for NameDisplay & unfocus
  const handleKeyPress = (e: any) => {
    if (e.which === 13) {
      e.preventDefault()
      input?.current?.blur()
    }
  }

  useEffect(() => {
    // @ts-ignore
    if (measureRef) setNameDisplayWidth(getMeasureDivWidth(measureRef.current))
  }, [name, measureRef])

  const handleBorderAnimation = (bool: boolean) => {
    if (name?.length < 1) setPlayBorderAnim(bool)
    else setPlayBorderAnim(false)
  }

  return (
    <NameContainer className={className}>
      <NameDisplay
        ref={input}
        id="input"
        onChange={handleChange}
        value={name}
        placeholder={defaultName}
        onKeyPress={handleKeyPress}
        onFocus={() => handleBorderAnimation(false)}
        onBlur={() => handleBorderAnimation(true)}
        style={{ width: nameDisplayWidth + 30 }}
      />
      {playBorderAnim && (
        <BorderAnimation style={{ width: nameDisplayWidth + 30 }} />
      )}
      <MeasureDiv ref={measureRef}>
        {name?.length > 0 ? name : defaultName}
      </MeasureDiv>
    </NameContainer>
  )
}

export default ProjectName
