export default {
  primary: '#62FCFC',
  primaryDark: '#009999',
  error: 'red',
  text: 'white',
  secondary: '#494949',
  background: '#7C7C7C',
  border: 'rgba(73, 73, 73, 0.5)',
  surface: '#C1C1C1',
  placeholder: 'rgb(166,166,166)',
  disabled: '#7C7C7C',
  sidebarWidth: '70px',
  headerHeight: '70px',
  card: 'rgba(212,212,212)',
  subcard: 'rgb(243,243,243)',
  popoverBackground: '#dddddd',
  textDark: 'black',
  lightGray: '#aaaaaa'
}
