import Breadcrumbs, {
  BreadcrumbInterface
} from '@components/elements/Breadcrumbs'
import CustomHelmet from '@components/elements/CustomHelmet'
import { Dashboard } from '@components/elements/Dashboard'
import { resetProject, setCurrentStep } from '@redux/project'
import ProgressBar from '@screens/addProject/elements/ProgressBar'
import ProjectForm from '@screens/addProject/ProjectForm'
import {
  selectCurrentProject,
  selectCurrentStep
} from '@selectors/projectSelectors'
import { selectSidebarIsOpen } from '@selectors/sidebarSelectors'
import { selectCompanySpectrum } from '@selectors/userProjectSelectors'
import { useIsPartner, useIsUsedMachinerySupplier } from '@services/customHooks'
import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { Redirect } from 'react-router-dom'
import styled from 'styled-components'

const StatusbarContainer = styled.div`
  display: flex;
  align-items: flex-end;
  height: 30px;
`

const AddProject = ({ editmode }: { editmode?: boolean }) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const inset = useSelector(selectSidebarIsOpen)
  const currentStep = useSelector(selectCurrentStep)
  const { name, id } = useSelector(selectCurrentProject)
  const isPartner = useIsPartner()
  const isUsedMachinerySupplier = useIsUsedMachinerySupplier()
  const spectrum = useSelector(selectCompanySpectrum)
  const crumbs: BreadcrumbInterface[] = [{ link: '/', text: 'Dashboard' }]
  if (currentStep !== 2)
    crumbs.push({
      onClick: () => {
        dispatch(setCurrentStep(0))
      },
      active: currentStep === 0,
      text: t('buttons.overview')
    })
  if (currentStep !== 0)
    crumbs.push({
      active: true,
      text: name
    })
  useEffect(() => {
    if (!editmode && id) dispatch(resetProject())
  }, [dispatch, editmode, id])
  if (!editmode && isPartner && !isUsedMachinerySupplier && spectrum)
    return <Redirect to="/" />
  if (editmode && !id && name) return <Redirect to="/new" />
  if (editmode && !id) return <Redirect to="/" />

  return (
    <Dashboard inset={inset}>
      <CustomHelmet title={t('metadata.newProject')} />
      <Breadcrumbs crumbs={crumbs} />

      <StatusbarContainer>
        <h1>
          {isPartner
            ? t('project.basics.rangeOfServices')
            : name?.length < 1
            ? t('project.basics.newProject')
            : name}
        </h1>
        <ProgressBar />
      </StatusbarContainer>
      <ProjectForm editmode={editmode} />
    </Dashboard>
  )
}

export default AddProject
