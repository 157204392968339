import { fromPrefix, QuestionType, toPrefix } from '@interfaces/project'
import Icon from '@material-ui/core/Icon'
import { removeProjectDataPriority, setNestedProjectData } from '@redux/project'
import { useIsPartner } from '@services/customHooks'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { useFirebase } from 'react-redux-firebase'
import styled from 'styled-components'
import Boolean from './questions/Boolean'
import Date from './questions/Date'
import File from './questions/File'
import Float from './questions/Float'
import LongString from './questions/LongString'
import RadioButtons from './questions/RadioButtons'
import Select from './questions/Select'
import SingleChoice from './questions/SingleChoice'
import String from './questions/String'

interface StyleProps {
  big?: boolean
  open?: boolean
}
const Container = styled.div<StyleProps>`
  display: flex;
  width: 100%;
  flex-direction: column;
  background-color: ${(props) => props.theme.subcard};
  padding: 0 15px;
  box-sizing: border-box;
  margin: 10px 0;
  border-radius: 6px;
  ${({ big }) =>
    big &&
    `
    padding: 10px;
    border-radius: 8px;
  `}
`
const TopBar = styled.div<StyleProps>`
  display: flex;
  height: 45px;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
  border-bottom: ${(props) => (props.open ? '1px solid #666' : undefined)};
  ${({ big, open }) =>
    !big &&
    open &&
    `
    margin-bottom: 5px;
  `}
  ${({ big, open }) =>
    big &&
    open &&
    `
    padding-bottom: 10px;
    height: 43px;
    margin-bottom: 10px;
  `}
`

const TitleContainer = styled.div`
  display: flex;
  align-items: center;

  cursor: pointer;
  :hover {
    p {
      background-color: ${({ theme }) => theme.primary};
    }
    span {
      color: ${({ theme }) => theme.primary};
    }
  }
`
const Title = styled.p<StyleProps>`
  background-color: ${(props) => props.theme.secondary};
  box-sizing: border-box;
  min-height: 18px;
  font-size: 13px;
  font-weight: 600;
  padding: 8px 14px 6px 9px;
  border-radius: 15px;
  ${({ big }) =>
    big &&
    `
    font-size: 18px;
    font-weight: 600;
    background: black;
    border-radius: 20px;
    padding: 8px 20px 6px 15px;

  `}
`
const PenIcon = styled(Icon)<StyleProps>`
  color: ${(props) => props.theme.secondary};
  font-size: ${({ big }) => (big ? '23px' : '17px')} !important;
  margin-left: 10px;
`
const IconContainer = styled.div`
  display: flex;
  height: 100%;
  align-items: center;
  cursor: pointer;
  span {
    :hover {
      color: ${({ theme }) => theme.primaryDark};
    }
  }
`
const RightIcons = styled(Icon)<StyleProps>`
  font-size: ${({ big }) => (big ? '20px' : '15px')} !important;
  color: ${(props) => props.theme.border};
  margin-left: 3px;
`
const InsetContainer = styled.div<InsetInterface>`
  padding: 10px 0 10px 0;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  overflow: hidden;
  &:first-of-type {
    padding-top: 0px;
  }
  &:last-of-type {
    padding-bottom: 0px;
  }
`
interface InsetInterface {
  open: boolean
}
const InsetTitle = styled.div<InsetInterface>`
  color: black;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: ${({ open }) => open && '1px solid #666'};

  font-weight: 700;
  font-size: 14px;
  margin-bottom: ${({ open }) => open && '10px'};
  cursor: pointer;

  :hover {
    color: ${({ theme }) => theme.primaryDark};
  }
`
const MultiPrioDescription = styled.div`
  color: black;
  font-size: 12px;
`
const InputsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  padding: 10px 0 10px;
`

const PdfIcon = styled(Icon)`
  color: black;
  font-size: 25px !important;
  margin-left: 5px;
  :hover {
    color: ${({ theme }) => theme.primaryDark};
  }
`
const ToggleIcon = styled(Icon)<InsetInterface>`
  color: black;
  transform: rotate(${(props) => (props.open ? '-180deg' : '0deg')});
  transition: transform ease-in-out 300ms;
  font-size: 20px !important;
  :hover {
    color: ${({ theme }) => theme.primaryDark};
  }
`
const InsetInputsItem = ({
  input,
  renderInput
}: {
  input: any
  renderInput: any
}) => {
  const { t } = useTranslation()
  const firebase = useFirebase()
  const [open, setOpen] = useState(false)
  const openPdf = async (link: string) => {
    const downloadUrl = await firebase
      .storage()
      .refFromURL(`gs://packpart-project.appspot.com/pdfs/${link}`)
      .getDownloadURL()
    window.open(downloadUrl, '_blank')
  }
  return (
    <InsetContainer open={open}>
      <InsetTitle open={open} onClick={() => setOpen((o) => !o)}>
        {t(`project.configurator.fieldsWithSubfields.${input.name}`)}
        <IconContainer>
          <ToggleIcon open={open}>expand_more</ToggleIcon>
          {input.pdfFile && (
            <PdfIcon
              onClick={(e) => {
                e.stopPropagation()
                openPdf(input.pdfFile)
              }}>
              picture_as_pdf
            </PdfIcon>
          )}
        </IconContainer>
      </InsetTitle>
      {open && input.inputs.map((i: any) => renderInput(i, input.name))}
    </InsetContainer>
  )
}
const ConfigurationItem = ({
  title,
  data,
  inputs,
  position,
  field,
  multiplePriorities,
  index,
  alwaysOpen = false,
  big,
  onExpand,
  showDelete
}: {
  title: string
  data: any
  inputs?: any
  field: string
  position: string
  multiplePriorities?: boolean
  index?: number
  alwaysOpen?: boolean
  big?: boolean
  onExpand?: () => void
  showDelete?: boolean
}) => {
  const dispatch = useDispatch()
  const [open, setOpen] = useState(true)
  const { t } = useTranslation()
  const setData = (name: string, value: any) => {
    dispatch(
      setNestedProjectData({
        position,
        field,
        name,
        value,
        multiplePriorities,
        index
      })
    )
  }
  const isPartner = useIsPartner()

  const renderInput = (input: any, subInputPath?: string) => {
    if (input?.inputs)
      return <InsetInputsItem input={input} renderInput={renderInput} />
    // Default Value
    const value = subInputPath
      ? data?.[subInputPath]?.[input.name]
      : data?.[input.name]
    // From Value for Manufacturers
    const fromValue = subInputPath
      ? data?.[subInputPath]?.[`${fromPrefix}${input.name}`]
      : data?.[`${fromPrefix}${input.name}`]
    // To Value for Manufacturers
    const toValue = subInputPath
      ? data?.[subInputPath]?.[`${toPrefix}${input.name}`]
      : data?.[`${toPrefix}${input.name}`]

    // Default OnChange
    const onChange = (v: any, customPrefix: string = '') =>
      setData(
        subInputPath
          ? `${subInputPath}.${customPrefix}${input.name}`
          : `${customPrefix}${input.name}`,
        v
      )
    const onChangeSingleChoice = (
      selectedOption: string,
      options: any[],
      customPrefix: string = ''
    ) => {
      options.forEach((option) => {
        setData(
          subInputPath
            ? `${subInputPath}.${customPrefix}${option}`
            : `${customPrefix}${option}`,
          option === selectedOption
        )
      })
    }
    if (input.type === QuestionType.Float) {
      if (isPartner)
        return (
          <>
            <Float
              value={fromValue}
              unit={`${t(fromPrefix)} ${t(
                `project.configurator.fields.${field}.${input.unit}`
              )}`}
              onChange={(v) => onChange(v, fromPrefix)}
            />
            <Float
              value={toValue}
              unit={`${t(toPrefix)} ${t(
                `project.configurator.fields.${field}.${input.unit}`
              )}`}
              onChange={(v) => onChange(v, toPrefix)}
            />
          </>
        )
      return (
        <Float
          value={value}
          unit={t(`project.configurator.fields.${field}.${input.unit}`)}
          onChange={onChange}
        />
      )
    }
    if (input.type === QuestionType.String)
      return (
        <String
          value={value}
          onChange={onChange}
          unit={
            input?.unit &&
            t(`project.configurator.fields.${field}.${input.unit}`)
          }
          placeholder={t(`project.configurator.fields.${field}.${input.name}`)}
        />
      )
    if (input.type === QuestionType.LongString)
      return (
        <LongString
          value={value}
          onChange={onChange}
          unit={
            input?.unit &&
            t(`project.configurator.fields.${field}.${input.unit}`)
          }
          placeholder={t(`project.configurator.fields.${field}.${input.name}`)}
        />
      )
    if (input.type === QuestionType.Boolean)
      return (
        <Boolean
          value={value}
          onChange={onChange}
          placeholder={t(`project.configurator.fields.${field}.${input.name}`)}
        />
      )
    if (input.type === QuestionType.YesNo)
      return (
        <RadioButtons
          options={['yes', 'no']}
          value={value}
          onChange={onChange}
          placeholder={`project.configurator.fields.${field}.${input.name}`}
        />
      )
    if (input.type === QuestionType.SingleChoice)
      return (
        <SingleChoice
          inputName={input.name}
          options={input.options}
          value={value}
          onChange={onChangeSingleChoice}
          placeholder={`project.configurator.fields.${field}.${input.name}`}
        />
      )
    if (input.type === QuestionType.SingleChoiceSelect)
      return (
        <Select
          options={input.options}
          value={value}
          onChange={onChange}
          placeholder={`project.configurator.fields.${field}.${input.name}`}
        />
      )
    if (input.type === QuestionType.Date)
      return (
        <Date
          value={value}
          onChange={onChange}
          placeholder={`project.configurator.fields.${field}.${input.name}`}
        />
      )
    if (input.type === QuestionType.File)
      return (
        <File
          value={value}
          onChange={onChange}
          placeholder={t(`project.configurator.fields.${field}.${input.name}`)}
        />
      )
    return <div></div>
  }

  return (
    <Container big={big}>
      {!alwaysOpen && (
        <TopBar open={open} big={big}>
          <TitleContainer onClick={() => setOpen((o) => !o)}>
            <Title big={big}>{title}</Title>
            <PenIcon big={big}>edit</PenIcon>
          </TitleContainer>
          <IconContainer>
            {multiplePriorities && !big && (
              <RightIcons onClick={onExpand}>zoom_out_map</RightIcons>
            )}
            {multiplePriorities && showDelete && (
              <RightIcons
                big={big}
                onClick={() =>
                  dispatch(
                    removeProjectDataPriority({
                      field,
                      position,
                      index: index as number
                    })
                  )
                }>
                delete
              </RightIcons>
            )}
          </IconContainer>
        </TopBar>
      )}
      {/* open && <TopbarUnderline /> */}
      {multiplePriorities && open && !isPartner && (
        <MultiPrioDescription>
          {t('project.basics.priorityOrder1')}
          <br />
          {t('project.basics.priorityOrder2')}
        </MultiPrioDescription>
      )}
      {(open || alwaysOpen) && (
        <InputsContainer>
          {inputs?.map((input: any) => renderInput(input))}
        </InputsContainer>
      )}
    </Container>
  )
}

export default ConfigurationItem
