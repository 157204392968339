import ProfilePicture from '@components/elements/ProfilePicture'
import Icon from '@material-ui/core/Icon'
import ArrowIcon from '@material-ui/icons/ArrowBackIos'
import { resetProject, setCurrentStep, setProject } from '@redux/project'
import { setShowCompleteProfileModal } from '@redux/profile'
import { setIsOpen } from '@redux/sidebar'
import { selectSidebarIsOpen } from '@selectors/sidebarSelectors'
import { selectCompanySpectrum } from '@selectors/userProjectSelectors'
import { selectAreProfileValuesComplete } from '@selectors/profileSelectors'
import { useIsPartner, useIsUsedMachinerySupplier } from '@services/customHooks'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useFirebase } from 'react-redux-firebase'
import { Link, useHistory } from 'react-router-dom'
import styled from 'styled-components'
import { persistor } from '../../index'

interface SidebarProps {
  active?: boolean
}
const sidebarHeight = '60%'
const SidebarContainer = styled.div<SidebarProps>`
  min-height: 450px;
  z-index: 100;
  display: flex;
  height: ${sidebarHeight};
  width: ${(props) => props.theme.sidebarWidth};
  position: absolute;
  left: ${(props) => (props.active ? '0px' : `-${props.theme.sidebarWidth}`)};
  top: calc((100vh - ${sidebarHeight}) / 2);
  background-color: ${(props) => props.theme.secondary};
  align-items: center;
  justify-content: center;
  flex-direction: column;
  border-radius: 5px;
  transition: left ease-in-out 500ms;
  padding: 30px 0 40px 0;
  box-sizing: border-box;
`
const floatingIconSize = '38px'
const FloatingIconContainer = styled.div<SidebarProps>`
  position: absolute;
  top: calc(-${floatingIconSize} / 3);

  right: ${({ active }) =>
    active
      ? `calc(-${floatingIconSize} / 2)`
      : `calc(-${floatingIconSize} + 10px)`};
  width: ${floatingIconSize};
  height: ${floatingIconSize};
  border-radius: calc(${floatingIconSize} / 2);
  background-color: ${(props) =>
    props.active ? props.theme.surface : props.theme.primary};
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: all ease-in-out 300ms;
  :hover {
    right: ${({ active }) =>
      active ? `calc(-${floatingIconSize} / 2)` : `calc(-${floatingIconSize})`};
    background-color: ${({ theme }) => theme.primary};
  }
`

const ToggleIcon = styled(ArrowIcon).withConfig({
  shouldForwardProp: (prop) => !['active'].includes(prop)
})<SidebarProps>`
  color: ${(props) => props.theme.secondary};
  margin-left: ${(props) => (props.active ? '8px' : '-8px')};
  transform: rotate(${(props) => (props.active ? '0deg' : '-180deg')});
  transition: all ease-in-out 500ms;
`

const Logout = styled.p`
  font-size: 10px;
  cursor: pointer;
  :hover {
    text-decoration: underline;
    color: ${({ theme }) => theme.primary};
  }
`

const ButtonContainer = styled.div`
  display: flex;
  flex: 1;
  padding: 30px 0 0 0;
  box-sizing: border-box;
  flex-direction: column;
`

const StyledLink = styled(Link)`
  position: relative;
`
const StyledIcon = styled(Icon)`
  color: ${(props) => props.theme.surface};
  font-size: 35px !important;
  margin-bottom: 30px;
  cursor: pointer;
  :hover {
    color: ${({ theme }) => theme.primary};
  }
`
const IncompleteIcon = styled(Icon)`
  position: absolute;
  font-size: 15px !important;
  right: 0;
  top: 0;
  background: orange;
  border-radius: 50%;
  color: black;
`

const Header = () => {
  const { t } = useTranslation()
  const firebase = useFirebase()
  const dispatch = useDispatch()
  const isOpen = useSelector(selectSidebarIsOpen)
  const isProfileCompleted = useSelector(selectAreProfileValuesComplete) ?? true
  const history = useHistory()
  const isPartner = useIsPartner()
  const isUsedMachinerySupplier = useIsUsedMachinerySupplier()
  const companySpectrum = useSelector(selectCompanySpectrum)

  const logout = async () => {
    history.push('/')
    dispatch(resetProject())
    dispatch(setCurrentStep(-2))
    await firebase.logout()
    persistor.purge()
    dispatch(setShowCompleteProfileModal(true))
  }

  return (
    <SidebarContainer active={isOpen}>
      <FloatingIconContainer
        active={isOpen}
        onClick={() => dispatch(setIsOpen(!isOpen))}>
        <ToggleIcon active={isOpen} />
      </FloatingIconContainer>
      <ProfilePicture size={'60px'} />

      <ButtonContainer>
        <Link to="/">
          <StyledIcon title="Dashboard" className="material-icons-round">
            dashboard
          </StyledIcon>
        </Link>
        {isPartner && !isUsedMachinerySupplier && (
          <>
            <Link
              to="/edit"
              onClick={() => {
                dispatch(setCurrentStep(0))
                dispatch(resetProject())
                dispatch(setProject(companySpectrum))
              }}>
              <StyledIcon
                title="Leistungsspektrum"
                className="material-icons-round">
                description
              </StyledIcon>
            </Link>
            <Link to="/partnerSearch">
              <StyledIcon title="Partnersuche" className="material-icons-round">
                groups
              </StyledIcon>
            </Link>
          </>
        )}
        <StyledLink to="/profile">
          <StyledIcon title="Profil" className="material-icons-round">
            settings
          </StyledIcon>
          {!isProfileCompleted && (
            <IncompleteIcon>priority_high</IncompleteIcon>
          )}
        </StyledLink>
      </ButtonContainer>
      <Logout onClick={logout}>{t('buttons.logout')}</Logout>
    </SidebarContainer>
  )
}

export default Header
