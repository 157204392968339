import { defaultProjectValues, offeringValues } from '@interfaces/project'
import cloneDeep from 'lodash/cloneDeep'

/*
 *************
 * Searching *
 *************
 */
const usedMachinerySearching = cloneDeep(defaultProjectValues)
// sortAndFeed
// -> not optional
/* unset(usedMachinerySearching, 'sort.sortAndFeed.fields.outputRate')
set(
  usedMachinerySearching,
  'sort.sortAndFeed.fields.outputRateWageProducer',
  outputRateWageProducer
) */

/*
 ************
 * Offering *
 ************
 */
const usedMachineryOffering = cloneDeep(offeringValues)
// sortAndFeed
// -> not optional
/* unset(usedMachineryOffering, 'sort.sortAndFeed.fields.outputRate')
set(
  usedMachineryOffering,
  'sort.sortAndFeed.fields.outputRateWageProducer',
  outputRateWageProducer
) */

export { usedMachineryOffering, usedMachinerySearching }
