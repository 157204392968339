import { setProjectBranches, setProjectType } from '@redux/project'
import { selectCompanyBranches } from '@selectors/companySelectors'
import { selectCompanyID, selectProfileType } from '@selectors/profileSelectors'
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  useFirebase,
  useFirestore,
  useFirestoreConnect
} from 'react-redux-firebase'

const DataSubscriber = () => {
  const companyID = useSelector(selectCompanyID)
  const type = useSelector(selectProfileType)
  const branches = useSelector(selectCompanyBranches)
  const dispatch = useDispatch()
  useFirestoreConnect([
    { collection: 'companies', doc: companyID },
    {
      collection: 'companies',
      doc: companyID,
      storeAs: 'companyProjects',
      subcollections: [{ collection: 'projects' }]
    }
  ])

  useEffect(() => {
    dispatch(setProjectBranches(branches))
    dispatch(setProjectType(type))
  }, [dispatch, type, branches])

  return <div />
}

export default DataSubscriber
