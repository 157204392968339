import styled from 'styled-components'

const ConfiguratorTitle = styled.p`
  font-size: 23px;
  color: black;
  font-weight: 800;
  margin-bottom: 20px;
`

export default ConfiguratorTitle
