import Button from '@components/elements/Button'
import ContentCenterer from '@components/elements/ContentCenterer'
import { AvailableCategories, DisplayedProjectType } from '@interfaces/project'
import { Icon } from '@material-ui/core'
import {
  setCurrentStep,
  toggleCategory,
  setProjectTypeAndValues
} from '@redux/project'
import ProjectDescription from '@screens/addProject/elements/ProjectDescription'
import RadioButtons from '@screens/addProject/elements/questions/RadioButtons'
import ProjectName from '@screens/addProject/elements/ProjectName'
import {
  selectCurrentProject,
  selectProjectType,
  selectDisplayedType
} from '@selectors/projectSelectors'
import { useIsPartner } from '@services/customHooks'
import { motion } from 'framer-motion'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components'

interface CategoryCardProps {
  isActive: boolean
}
const CategoryCard = styled(motion.button)<CategoryCardProps>`
  width: 209px;
  height: 209px;
  padding: 40px;
  box-sizing: border-box;
  border-radius: 27px;
  opacity: ${(props) => (props.isActive ? 1 : 0.35)};
  background-color: ${(props) =>
    props.isActive ? props.theme.primary : '#FFF'};
  display: flex;
  position: relative;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-size: 20px;
  font-weight: 700;
  text-transform: uppercase;
  color: black;
  transition: background-color ease-in-out 200ms;
  justify-self: center;
  cursor: pointer;
  transition: opacity ease-in-out 300ms;

  &:hover {
    opacity: 1;
  }
`
const CardsContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(209px, 1fr));
  grid-gap: 20px;
  box-sizing: border-box;
  margin: 50px 26px;
`
const Title = styled.div`
  color: black;
  font-weight: 700;
  font-size: 30px;
  margin-left: 26px;
`
const Description = styled.p`
  color: #333;
  font-size: 20px;
  margin: 30px 0 0 26px;
`
const StyledRadioButtons = styled(RadioButtons)<{
  isDisabled?: boolean
}>`
  grid-template-columns: repeat(2, max-content);
  grid-gap: 0 20px;
  margin: 10px 0 0 15px;

  // override cursor for children
  div,
  input[type='radio']::after {
    ${({ isDisabled }) => isDisabled && 'cursor: default;'}
  }
  // override radio color
  input[type='radio']::after {
    ${({ isDisabled }) =>
      isDisabled &&
      `
    color: #757575;
  `}
  }
`

const CheckIconContainer = styled.div`
  position: absolute;
  top: -6px;
  right: -6px;
  background-color: white;
  width: 28px;
  height: 28px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 14px;
  box-shadow: 0px 0px 14px 0px rgba(0, 0, 0, 0.45);
`
const CheckIcon = styled(Icon)`
  font-size: 35px !important;
  font-weight: 700;
  color: ${({ theme }) => theme.primary};
`

export const Basics = ({ editmode }: { editmode?: boolean }) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const project = useSelector(selectCurrentProject)
  const isPartner = useIsPartner()
  const displayedType = useSelector(
    editmode ? selectProjectType : selectDisplayedType
  ) as DisplayedProjectType
  const radioOptions: DisplayedProjectType[] = [
    'searching',
    'searchingWageProducer',
    'searchingUsedMachinerySupplier'
  ]
  const [radioValue, setRadioValue] = useState<DisplayedProjectType>(
    displayedType ?? 'searching'
  )

  const handleRadioButtonChange = (value: String) => {
    if (!editmode) {
      setRadioValue(value as DisplayedProjectType)
      dispatch(setProjectTypeAndValues(value as DisplayedProjectType))
    }
  }

  const buttonDisabled =
    project?.categories?.length === 0 ||
    (!isPartner && project?.name?.length < 1)
  return (
    <>
      {isPartner ? (
        <Title>{t('project.configurator.general.rangeOfServices')}</Title>
      ) : (
        <ProjectName />
      )}
      {!isPartner && <ProjectDescription />}
      {!isPartner && (
        <>
          <Description>{t('project.basics.chooseProjectType')}</Description>
          <StyledRadioButtons
            options={radioOptions}
            isDisabled={editmode}
            value={radioValue}
            onChange={handleRadioButtonChange}
            placeholder={'project.basics.radioOptions'}
          />
        </>
      )}

      <Description>
        {t(`project.basics.${isPartner ? 'producerTitle' : 'title'}`)}
      </Description>
      <CardsContainer>
        {AvailableCategories.map((category, index) => (
          <CategoryCard
            key={index}
            whileHover={{ scale: 1.03 }}
            whileTap={{ scale: 0.98 }}
            onClick={() => dispatch(toggleCategory(category))}
            isActive={project.categories.includes(category)}>
            {project.categories.includes(category) && (
              <CheckIconContainer>
                <CheckIcon>check_circle</CheckIcon>
              </CheckIconContainer>
            )}
            {t(`project.categories.${category}Basics`)}
          </CategoryCard>
        ))}
      </CardsContainer>

      {!buttonDisabled && (
        <ContentCenterer>
          <Button
            onClick={() => dispatch(setCurrentStep(1))}
            disabled={buttonDisabled}>
            {t('buttons.continue')}
          </Button>
        </ContentCenterer>
      )}
    </>
  )
}
