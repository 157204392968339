/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { ErrorMessage, useField } from 'formik'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useFirebase } from 'react-redux-firebase'
import styled from 'styled-components'
import { Icon } from '@material-ui/core'

interface ImageWithErrorInterface {
  width?: string
  marginLeft?: boolean
}
export const ImageWithErrorContainer = styled.div<ImageWithErrorInterface>`
  margin-bottom: 25px;
  box-sizing: border-box;
  width: ${(props) => props.width || '110px'};
  min-width: 110px;
  margin-left: ${(props) => (props.marginLeft ? '20px' : 0)};
`
interface ImageFieldInterface {
  empty?: boolean
}
const ImageField = styled.div<ImageFieldInterface>`
  background-color: ${({ theme }) => theme.card};
  border-radius: 5px;
  height: 110px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: black;
  box-sizing: border-box;
  border: 1px dotted black;
  flex-wrap: wrap;
  overflow: hidden;
  position: relative;
  cursor: ${({ empty }) => (empty ? 'pointer' : 'auto')};
  :hover {
    transform: scale(0.985);
    background-color: ${({ theme, empty }) =>
      empty ? theme.primary : theme.card};
  }
`
export const ImagesInputContainer = styled.div`
  display: flex;
  margin-top: 50px;
`
const ImageDescription = styled.p`
  font-size: 13px;
  line-height: 15px;
  margin-top: 3px;
`
const UploadIcon = styled(Icon)`
  color: black;
`
const ClearIcon = styled(Icon)`
  color: black;
  position: absolute;
  top: 5px;
  right: 5px;
  cursor: pointer;
  :hover {
    transform: scale(1.04);
    color: ${({ theme }) => theme.primary};
  }
`

const Preview = styled.img`
  height: 100px;
  width: 100%;
  object-fit: contain;
`

const PreviewImage = ({ src }: { src: string }) => {
  const [source, setSource] = useState('')
  const firebase = useFirebase()
  useEffect(() => {
    const convert = async () => {
      try {
        if (typeof src === 'string' && src.startsWith('gs://')) {
          const ref = await firebase.storage().refFromURL(src)
          const url = await ref.getDownloadURL()
          setSource(url)
        } else {
          const converted = URL.createObjectURL(src)
          setSource(converted)
        }
      } catch (e) {}
    }
    convert()
  }, [src])
  if (!source) return null
  return <Preview src={source} />
}

export const ImageWithError = (props: any) => {
  const { t } = useTranslation()
  const [_field, _meta, helpers] = useField(props)
  return (
    <ImageWithErrorContainer width={props.width} marginLeft={props.marginLeft}>
      <label style={{ width: '100%' }}>
        {!props.value && (
          <input
            onChange={(event: any) => {
              helpers.setValue(event.currentTarget.files[0])
            }}
            id={props.name}
            name={props.name}
            type="file"
            accept="image/*"
            style={{ display: 'none' }}
          />
        )}
        <ImageField empty={!props.value}>
          {props.value ? (
            <>
              <ClearIcon onClick={() => helpers.setValue(null)}>
                clear
              </ClearIcon>
              <PreviewImage src={props.value} />
            </>
          ) : (
            <>
              <UploadIcon>backup</UploadIcon>
              {t(`register.form.placeholders.${props.name}`)}
            </>
          )}
        </ImageField>
      </label>
      {props?.description && (
        <ImageDescription>{props.description}</ImageDescription>
      )}{' '}
      <ErrorMessage name={props.name} />
    </ImageWithErrorContainer>
  )
}
