import {
  AvailableCategories,
  Categories,
  Project,
  ProjectCategory,
  ProjectOvercategory,
  FieldWithInputs,
  Match,
  Company
} from '@interfaces/project'
import { createSelector } from 'reselect'
import { selectCompanyID } from './profileSelectors'
import { isSubcategoryDone } from '@services/helpers'
import { ProfileTypes } from '@interfaces/user'
import partition from 'lodash/partition'
import { selectUserProjects } from './userProjectSelectors'

export const selectCurrentProject = (s: any): Project => s.project
export const selectCurrentStep = createSelector(
  selectCurrentProject,
  (project) => project.currentStep
)
export const selectShowUnfinishedFields = createSelector(
  selectCurrentProject,
  (project) => project.showUnfinishedFields
)
export const selectProjectType = createSelector(
  selectCurrentProject,
  (project) => project.type
)
export const selectDisplayedType = createSelector(
  selectCurrentProject,
  (project) => project.displayedType
)
export const selectProjectID = createSelector(
  selectCurrentProject,
  (project) => project?.id
)
export const selectProjectDescriptionImages = createSelector(
  selectCurrentProject,
  (project) => project?.descriptionImages
)
export const selectFinalProjectData = createSelector(
  selectCurrentProject,
  selectCompanyID,
  (project, companyID) => {
    const out: {
      [key: string]: any
    } = {
      name: project.name,
      categories: project.categories,
      description: project.description
    }

    project.categories.forEach((cat: Categories) => {
      const data = project[cat]
      /* We could filter out the fields and optionalfields from here but then would need to fill them back in when fetching, maybe todo later */
      /*       let cleaned = omit(data, ['fields', 'optionalFields'])
      if (!cleaned.data) {
        // it has nested fields, clean them out too
        cleaned = mapValues(cleaned, (d) =>
          omit(d, ['fields', 'optionalFields'])
        )
      }
      out[cat] = cleaned */
      out[cat] = data
    }) // filter only used categorys
    out.companyID = companyID
    out.type = project?.type
    return out
  }
)
export const selectActiveCategories = createSelector(
  selectCurrentProject,
  (project) =>
    project.categories.slice().sort(function (a: Categories, b: Categories) {
      return AvailableCategories.indexOf(a) - AvailableCategories.indexOf(b)
    })
)
export const selectOpenCategory = createSelector(
  selectCurrentProject,
  (project) => project.openCategory
)
export const selectIsProjectExisting = createSelector(
  selectActiveCategories,
  (activeCategories) => activeCategories?.length > 0
)
export const selectRelevantCategories = createSelector(
  selectCurrentProject,
  (project) => {
    const { categories } = project
    const relevantCategories: ProjectCategory[] = []
    categories.forEach((category: Categories) => {
      if (!project[category]?.fields)
        Object.values(project[category] as ProjectCategory).forEach(
          (subcategory) => relevantCategories.push(subcategory)
        )
      else relevantCategories.push(project[category] as ProjectCategory)
    })
    return relevantCategories
  }
)
export const selectIsEveryRequiredItemDone = createSelector(
  selectRelevantCategories,
  (relevantCategories) => {
    if (relevantCategories.length < 1) return false
    return relevantCategories.every((category) => {
      return Object.entries(category.fields).every((field) => {
        const fieldName = field[0]
        const fieldValue: FieldWithInputs = field[1]
        return isSubcategoryDone(fieldValue, category?.data[fieldName])
      })
    })
  }
)
export const selectUndoneCategories = createSelector(
  selectCurrentProject,
  selectActiveCategories,
  (project, activeCategories) => {
    const undoneCategories: Categories[] = []
    activeCategories.forEach((activeCat) => {
      const category = project[activeCat] as
        | ProjectCategory
        | ProjectOvercategory

      const categoriesToCheck: ProjectCategory[] = []
      if (category.data) categoriesToCheck.push(category as ProjectCategory)
      else
        Object.values(category as ProjectOvercategory).forEach((v) =>
          categoriesToCheck.push(v)
        )

      const isDone = categoriesToCheck.every((category) => {
        return Object.entries(category.fields).every((field) => {
          const fieldName = field[0]
          const fieldValue: FieldWithInputs = field[1]
          return isSubcategoryDone(fieldValue, category?.data[fieldName])
        })
      })
      if (!isDone) undoneCategories.push(activeCat)
    })
    return undoneCategories
  }
)
export const selectProgress = createSelector(
  selectCurrentProject,
  selectRelevantCategories,
  (project, relevantCategories) => {
    const { currentStep, name, type } = project
    let percentage = 0
    if (name.length >= 1) percentage += 5
    if (relevantCategories.length >= 1) percentage += 5
    // Add 5% extra if type = offering because name stays empty
    if (type === ProfileTypes.Offering && relevantCategories.length >= 1)
      percentage += 5
    if (currentStep === 2) percentage += 1
    let totalFields = 0
    let completedFields = 0
    relevantCategories.forEach((category) => {
      totalFields += Object.keys(category.fields).length
      completedFields += Object.entries(category.fields).filter((field) => {
        const fieldName = field[0]
        const fieldValue: FieldWithInputs = field[1]
        return isSubcategoryDone(fieldValue, category?.data[fieldName])
      }).length
    })
    if (totalFields > 0) percentage += (completedFields / totalFields) * 90
    return Math.round(percentage)
  }
)

export const selectProjectSort = createSelector(
  selectCurrentProject,
  (project) => project.sort as ProjectOvercategory
)
export const selectProjectFill = createSelector(
  selectCurrentProject,
  (project) => project.fill as ProjectCategory
)
export const selectProjectSeal = createSelector(
  selectCurrentProject,
  (project) => project.seal as ProjectCategory
)
export const selectProjectLabel = createSelector(
  selectCurrentProject,
  (project) => project.label as ProjectCategory
)
export const selectProjectPackaging = createSelector(
  selectCurrentProject,
  (project) => project.packaging as ProjectCategory
)

export const selectMatchesByID =
  (id: string | undefined) =>
  (s: {
    firestore: {
      data: { [x: string]: { [x: string]: Match } }
    }
  }) => {
    if (!id) return []
    if (!s?.firestore?.data?.[`project/${id}/matches`]) return []
    const matches = s.firestore.data[`project/${id}/matches`]
    const matchValues = Object.entries(matches)
      .map(([key, value]) => ({
        ...value,
        id: key,
        companyId: value.path.split('/')[1]
      }))
      .filter((m) => !m.isSold)
    return matchValues
  }
export const selectMatchForPartnerProject = (
  customerProjectID: string,
  partnerCompanyId: string
) =>
  createSelector(selectMatchesByID(customerProjectID), (matches) => {
    return matches.find((m) => m?.partnerCompanyID === partnerCompanyId) || null
  })

export const selectMatchForMachineProject = (
  customerProjectId: string,
  partnerProjectId: string,
  partnerCompanyId: string
) =>
  createSelector(selectMatchesByID(customerProjectId), (matches) => {
    return (
      matches.find(
        (m) =>
          m?.partnerCompanyID === partnerCompanyId && m?.id === partnerProjectId
      ) || null
    )
  })

export const selectMachineMatchesById =
  (company: Company, partnerCompanyId?: string) =>
  (s: {
    firestore: {
      data: { [x: string]: { [x: string]: Match } }
    }
  }) => {
    const matchesGroup = s?.firestore?.data?.matchesGroup
    if (!matchesGroup) return []
    const allMatches = Object.values(matchesGroup).filter((match) => {
      return match.partnerCompanyID === partnerCompanyId
    })
    const matches = allMatches.filter(
      (match) =>
        !company?.rejected?.some(
          (rejected) =>
            rejected.company === match.customerCompanyID &&
            rejected.project === match.customerProjectID
        )
    )
    return matches
  }

const selectPartitionedMachineMatches = (company: Company, companyId: string) =>
  createSelector(
    selectUserProjects,
    selectMachineMatchesById(company, companyId),
    (mProjects, matches) => {
      // Add machine name as property to all matches
      const matchesWithMachineName = matches.map((match) => {
        const projectForMatch = mProjects.find(
          (p) => p.id === match.partnerProjectID
        )
        return { ...match, machineName: projectForMatch?.name }
      })
      // split matches in new & confirmed matches
      const partitionedMatches = partition(
        matchesWithMachineName,
        (match) =>
          company?.unlocked?.some(
            (unlocked) =>
              unlocked.company === match.customerCompanyID &&
              unlocked.project === match.customerProjectID
          ) || false
      )
      return partitionedMatches
    }
  )

export const selectNewMachineMatches = (company: Company, companyId: string) =>
  createSelector(
    selectPartitionedMachineMatches(company, companyId),
    (partitionedMatches) => {
      return partitionedMatches[1]
    }
  )

export const selectConfirmedMachineMatches = (
  company: Company,
  companyId: string
) =>
  createSelector(
    selectPartitionedMachineMatches(company, companyId),
    (partitionedMatches) => {
      return partitionedMatches[0]
    }
  )
