import styled from 'styled-components'
import React from 'react'
import variables from '@themes/variables'

const CornerBottom = styled.div`
  position: absolute;
  right: 0;
  width: 20px;
  height: 20px;
  bottom: -20px;
`
export const OverhangBottom = () => (
  <CornerBottom>
    <svg width="20" height="20">
      <mask id="maskTop">
        <rect x="0" y="0" width="20" height="20" fill="white" />
        <circle cx="0" cy="20" r="20" fill="black" />
      </mask>
      <rect
        x="0"
        y="0"
        width="20"
        height="20"
        fill={variables.card}
        mask="url(#maskTop)"
      />
    </svg>
  </CornerBottom>
)

const CornerTop = styled.div`
  position: absolute;
  right: 0;
  width: 20px;
  height: 20px;
  top: -20px;
`
export const OverhangTop = () => (
  <CornerTop>
    <svg width="20" height="20">
      <mask id="maskBottom">
        <rect x="0" y="0" width="20" height="20" fill="white" />
        <circle cx="0" cy="0" r="20" fill="black" />
      </mask>
      <rect
        x="0"
        y="0"
        width="20"
        height="20"
        fill={variables.card}
        mask="url(#maskBottom)"
      />
    </svg>
  </CornerTop>
)
