/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from 'react'
import styled from 'styled-components'
import Icon from '@material-ui/core/Icon'
import { useTranslation } from 'react-i18next'
import CustomPopover from '@components/elements/CustomPopover'
import PopoverContent from '@screens/addProject/elements/PopoverContent'
import useOwnDimensions from '@services/useOwnDimensions'
import useWindowDimensions from '@services/useWindowDimensions'
import DashedContainer from '@components/elements/DashedContainer'

interface OptionalSubcategoryProps {
  onAdd: (field: string) => void
  onDelete: () => void
  data: { title: string; field: string; position: string }[]
}

const Container = styled(DashedContainer)`
  display: flex;
  border-radius: 7px;
  width: 100%;
  min-width: 230px;
  background-color: rgba(255, 255, 255, 0.5);
  margin-bottom: 15px;
  box-sizing: border-box;
  padding-right: 10px;
  height: 46px;
  cursor: pointer;
`
const AddWrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  align-items: center;
`

interface AddIconInterface {
  active?: boolean
}

const AddCircleIcon = styled(Icon)<AddIconInterface>`
  font-size: 20px !important;
  background: ${({ theme, active }) => (active ? theme.primary : 'white')};
  border-radius: 11px;
  color: ${(props) => props.theme.border};
  margin-right: 15px;
  margin-left: 15px;
  ${Container}:hover & {
    background: ${({ theme }) => theme.primary};
  }
`

const CategoryTitle = styled.h1`
  font-size: 17px;
  font-weight: 600;
  box-sizing: border-box;
  align-items: center;
  color: ${(props) => props.theme.border};
`

const DeleteIcon = styled(Icon)`
  margin-left: 5px;
  font-size: 24px !important;
  color: ${(props) => props.theme.border};
  align-self: center;
`

export const OptionalSubcategory = ({
  onAdd,
  onDelete,
  data
}: OptionalSubcategoryProps) => {
  const { t } = useTranslation()
  // @ts-ignore
  const [containerRef, { width }] = useOwnDimensions()
  const { windowHeight } = useWindowDimensions()
  const [isPopoverVisible, setPopoverVisibility] = useState(false)

  const handleOnAdd = () => {
    setPopoverVisibility(true)
  }

  const handlePopoverClose = () => {
    setPopoverVisibility(false)
  }

  const handleOnAddItem = (title: string) => {
    const field = data.find((d) => d.title === title)
    if (field) onAdd(field.field as string)
    setPopoverVisibility(false)
  }
  return (
    <Container
      // @ts-ignore
      ref={containerRef}>
      <CustomPopover
        handlePopoverClose={handlePopoverClose}
        isVisible={isPopoverVisible}
        position="top"
        width={width}
        height={windowHeight / 2}
        awayFromAnchorMargin={15}
        content={
          <PopoverContent
            content={data.map((c) => c.title)}
            handleOnAddItem={(title: string) => handleOnAddItem(title)}
          />
        }>
        <AddWrapper onClick={handleOnAdd}>
          <AddCircleIcon>addCircle</AddCircleIcon>
          <CategoryTitle>
            {t('project.newProject.addOptionalFields')}
          </CategoryTitle>
        </AddWrapper>
        <DeleteIcon onClick={onDelete}>delete</DeleteIcon>
      </CustomPopover>
    </Container>
  )
}
