import React from 'react'
import styled from 'styled-components'

interface ErrorMessageProps {
  margin?: string
  children: string | null
}

const ErrorContainer = styled.p<ErrorMessageProps>`
  color: ${(props) => props.theme.error};
  font-size: 14px;
  letter-spacing: 1px;
  margin: ${({ margin }) => margin || null};
`

const ErrorMessage = ({ children, margin }: ErrorMessageProps) => {
  if (!children) return null
  if (children && typeof children !== 'string') return null
  if (children.length === 0) return null
  return <ErrorContainer margin={margin}>{children}</ErrorContainer>
}
export default ErrorMessage
