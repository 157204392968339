/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-vars */
import Button from '@components/elements/Button'
import { ProfileTypes } from '@interfaces/user'
import BranchesSelector from '@screens/login/BranchesSelector'
import Countrys from '@screens/login/Countrys'
import { getRegisterSchema } from '@services/helpers'
import { ErrorMessage, Field, Formik } from 'formik'
import React from 'react'
import { useTranslation } from 'react-i18next'
import {
  StyledForm,
  LeftForm,
  RightForm,
  StyledErrorMessage,
  FieldWithErrorContainer,
  FieldWithError,
  ButtonContainer,
  Progress,
  ImageWithError,
  ImagesInputContainer
} from '@components/forms/elements'

const SelectFieldStyle = {
  backgroundColor: 'white',
  borderRadius: '5px',
  height: '40px',
  borderWidth: 0,
  padding: '10px 0 10px 10px',
  width: '100%',
  color: 'black',
  boxSizing: 'border-box',
  cursor: 'pointer',
  border: 'none'
}
const SelectFieldStyleIncomplete = {
  ...SelectFieldStyle,
  border: '1px solid orange'
}
const AddressFieldStyle = {
  backgroundColor: 'white',
  borderRadius: '5px',
  height: '150px',
  borderWidth: 0,
  padding: '10px 0 10px 10px',
  width: '100%',
  color: 'black',
  boxSizing: 'border-box',
  border: 'none'
}
const AddressFieldStyleIncomplete = {
  ...AddressFieldStyle,
  border: '1px solid orange'
}

interface IProfileForm {
  initialValues: any
  onSubmit: (values: any) => void
  error: string
  profileType: ProfileTypes | null
  loading?: boolean
}

const ProfileForm = ({
  initialValues,
  onSubmit,
  error,
  loading = false,
  profileType
}: IProfileForm) => {
  const { t } = useTranslation()
  const SignupSchema = getRegisterSchema(t, profileType, true)
  return (
    <Formik
      initialValues={initialValues}
      validationSchema={SignupSchema}
      onSubmit={onSubmit}>
      {({ values, touched, errors, isValid }) => (
        <StyledForm $rightPadding={true}>
          {/* left side */}
          <LeftForm>
            <FieldWithError
              name="firstName"
              isTouched={touched.firstName}
              errorMessage={errors.firstName}
            />
            <FieldWithError
              name="lastName"
              isTouched={touched.lastName}
              errorMessage={errors.lastName}
            />
            <FieldWithError name="position" />
            <FieldWithError
              name="phone"
              type="phone"
              isTouched={touched.phone}
              errorMessage={errors.phone}
              isIncomplete={values.phone.length < 1}
            />
            <ImageWithError
              name="profileImage"
              value={values.profileImage}
              description={t('register.form.placeholders.quadratic')}
            />
          </LeftForm>

          {/* right side */}
          <RightForm>
            <FieldWithError
              name="company"
              isTouched={touched.company}
              errorMessage={errors.company}
              isIncomplete={values.company.length < 1}
            />
            <FieldWithErrorContainer
              hasError={touched.address && errors?.address !== undefined}>
              <Field
                name="address"
                as="textarea"
                style={
                  values?.address?.length < 1
                    ? AddressFieldStyleIncomplete
                    : AddressFieldStyle
                }
                placeholder={t('register.form.placeholders.address')}
              />
              <StyledErrorMessage component="div" name={'address'} />
            </FieldWithErrorContainer>
            <FieldWithErrorContainer
              hasError={touched.branches && errors?.branches !== undefined}>
              <Field
                name="branches"
                as={BranchesSelector}
                isTouched={touched.branches}
                errorMessage={errors.branches}
              />
              <StyledErrorMessage component="div" name={'branches'} />
            </FieldWithErrorContainer>
            <Field
              as="select"
              name="country"
              style={
                values.country?.length < 2
                  ? SelectFieldStyleIncomplete
                  : SelectFieldStyle
              }>
              <Countrys />
            </Field>
            <StyledErrorMessage component="div" name={'country'} />{' '}
            <ImagesInputContainer>
              <ImageWithError
                name="logo"
                value={values.logo}
                description={t('register.form.placeholders.quadratic')}
              />
              {(profileType === ProfileTypes.Offering ||
                profileType === ProfileTypes.WageProducer ||
                profileType === ProfileTypes.UsedMachinerySupplier) && (
                <ImageWithError
                  name="imagePicture"
                  value={values.imagePicture}
                  width="100%"
                  marginLeft
                  description={t('register.form.placeholders.3by2')}
                />
              )}
            </ImagesInputContainer>
            {(profileType === ProfileTypes.Offering ||
              profileType === ProfileTypes.WageProducer ||
              profileType === ProfileTypes.UsedMachinerySupplier) && (
              <FieldWithErrorContainer>
                <Field
                  name="description"
                  as="textarea"
                  style={AddressFieldStyle}
                  placeholder={t('register.form.placeholders.description')}
                />
                <ErrorMessage name={'description'} />
              </FieldWithErrorContainer>
            )}
          </RightForm>

          {/* button */}
          <ButtonContainer>
            <Button type="submit" primary condensed>
              {loading && <Progress />}
              {t('buttons.save')}
            </Button>
            {error}
          </ButtonContainer>
        </StyledForm>
      )}
    </Formik>
  )
}

export default ProfileForm
