/* eslint-disable react/style-prop-object */
import React from 'react'
import styled from 'styled-components'

const impressumHtml = `
<p>
  <strong>Angaben gem&auml;&szlig; &sect; 5 TMG</strong>
</p>
<p>&nbsp;</p>
<p>
  <br />
  <strong>PackPart GmbH</strong>
  <br />
  Nu&szlig;baumweg 19
  <br />
  74538&nbsp;Rosengarten
</p>
<p>Vertreten durch Gesch&auml;ftsf&uuml;hrer&nbsp;Christian Baumann</p>
<p>
  Telefon:&nbsp;
  <span style="color: #33e1e1">
    <a style="color: #33e1e1" href="tel:+4917657730239"> +49.176.57730239 </a>
  </span>
  <br />
  E-Mail:&nbsp;
  <span style="color: #33e1e1">
    <a style="color: #33e1e1" href="mailto:sales@packpart.eu">
      sales@packpart.eu
    </a>
  </span>
</p>
<p>
  Registernummer-Nr.:&nbsp;HRB 772241
  <br />
  Registergericht: Stuttgart
</p>
<p>&nbsp;</p>
<p>
  <strong>Umsatzsteuer-ID</strong>
  <br />
  Umsatzsteuer-Identifikationsnummer gem&auml;&szlig; &sect;27 a
  Umsatzsteuergesetz: DE327995828
  <br />
  Steuernummern:&nbsp;84060/65088
</p>
<p>
  <br />
  <strong>
    Verantwortlich f&uuml;r den Inhalt nach &sect; 55 Abs. 2 RStV:
  </strong>
</p>
<p>
  PackPart GmbH
  <br />
  Christian Baumann
  <br />
  Nu&szlig;baumweg 19
  <br />
  74538&nbsp;Rosengarten
</p>
<p>&nbsp;</p>
<p>&nbsp;</p>
<p>
  <strong>Konzeption &amp; Gestaltung</strong>
</p>
<p>
  understood: informationsdesign.
  <br />
  Anton-Huber-Stra&szlig;e 20
  <br />
  73430 Aalen
  <br />
  <span style="color: #33e1e1">
    <a style="color: #33e1e1" href="mailto:infodesign@understood.biz">
      infodesign@understood.biz
    </a>
  </span>
  <br />
  <span style="color: #33e1e1">
    <a style="color: #33e1e1" href="http://www.understood.biz/">
      www.understood.biz
    </a>
  </span>
</p>
<p>&nbsp;</p>
<p>&nbsp;</p>
<p>
  <strong>Programmierung und Umsetzung</strong>
</p>
<p>
  Peakconcepts GmbH
  <br />
  Anton-Huber-Stra&szlig;e 20
  <br />
  73430 Aalen
  <br />
  <span style="color: #33e1e1">
    <a style="color: #33e1e1" href="mailto:info@peakconcepts.de">
      info@peakconcepts.de
    </a>
  </span>
  <br />
  <span style="color: #33e1e1">
    <a style="color: #33e1e1" href="https://www.peakconcepts.de">
    https://www.peakconcepts.de
    </a>
  </span>
</p>
<p>&nbsp;</p>
<p>&nbsp;</p>
<p>
  <strong>Streitschlichtung</strong>
</p>
<p>
  Die Europ&auml;ische Kommission stellt eine Plattform zur
  Online-Streitbeilegung (OS) bereit:&nbsp;
  <span style="color: #33e1e1">
    <a
      style="color: #33e1e1"
      href="https://ec.europa.eu/consumers/odr"
      target="_blank"
      rel="noopener noreferrer"
    >
      https://ec.europa.eu/consumers/odr
    </a>
  </span>
  . Unsere E-Mail-Adresse finden Sie oben im Impressum.
</p>
<p>
  Wir sind nicht bereit oder verpflichtet, an Streitbeilegungsverfahren vor
  einer Verbraucherschlichtungsstelle teilzunehmen.
</p>
<p>&nbsp;</p>
<p>
  <strong>Haftung f&uuml;r Inhalte</strong>
</p>
<p>
  Als Diensteanbieter sind wir gem&auml;&szlig; &sect; 7 Abs.1 TMG f&uuml;r
  eigene Inhalte auf diesen Seiten nach den allgemeinen Gesetzen verantwortlich.
  Nach &sect;&sect; 8 bis 10 TMG sind wir als Diensteanbieter jedoch nicht
  verpflichtet, &uuml;bermittelte oder gespeicherte fremde Informationen zu
  &uuml;berwachen oder nach Umst&auml;nden zu forschen, die auf eine
  rechtswidrige T&auml;tigkeit hinweisen.
</p>
<p>
  Verpflichtungen zur Entfernung oder Sperrung der Nutzung von Informationen
  nach den allgemeinen Gesetzen bleiben hiervon unber&uuml;hrt. Eine
  diesbez&uuml;gliche Haftung ist jedoch erst ab dem Zeitpunkt der Kenntnis
  einer konkreten Rechtsverletzung m&ouml;glich. Bei Bekanntwerden von
  entsprechenden Rechtsverletzungen werden wir diese Inhalte umgehend entfernen.
</p>
<p>&nbsp;</p>
<p>
  <strong>Haftung f&uuml;r Links</strong>
</p>
<p>
  Unser Angebot enth&auml;lt Links zu externen Websites Dritter, auf deren
  Inhalte wir keinen Einfluss haben. Deshalb k&ouml;nnen wir f&uuml;r diese
  fremden Inhalte auch keine Gew&auml;hr &uuml;bernehmen. F&uuml;r die Inhalte
  der verlinkten Seiten ist stets der jeweilige Anbieter oder Betreiber der
  Seiten verantwortlich. Die verlinkten Seiten wurden zum Zeitpunkt der
  Verlinkung auf m&ouml;gliche Rechtsverst&ouml;&szlig;e &uuml;berpr&uuml;ft.
  Rechtswidrige Inhalte waren zum Zeitpunkt der Verlinkung nicht erkennbar.
</p>
<p>
  Eine permanente inhaltliche Kontrolle der verlinkten Seiten ist jedoch ohne
  konkrete Anhaltspunkte einer Rechtsverletzung nicht zumutbar. Bei
  Bekanntwerden von Rechtsverletzungen werden wir derartige Links umgehend
  entfernen.
</p>
<p>&nbsp;</p>
<p>
  <strong>Urheberrecht</strong>
</p>
<p>
  Die durch die Seitenbetreiber erstellten Inhalte und Werke auf diesen Seiten
  unterliegen dem deutschen Urheberrecht. Die Vervielf&auml;ltigung,
  Bearbeitung, Verbreitung und jede Art der Verwertung au&szlig;erhalb der
  Grenzen des Urheberrechtes bed&uuml;rfen der schriftlichen Zustimmung des
  jeweiligen Autors bzw. Erstellers. Downloads und Kopien dieser Seite sind nur
  f&uuml;r den privaten, nicht kommerziellen Gebrauch gestattet.
</p>
<p>
  Soweit die Inhalte auf dieser Seite nicht vom Betreiber erstellt wurden,
  werden die Urheberrechte Dritter beachtet. Insbesondere werden Inhalte Dritter
  als solche gekennzeichnet. Sollten Sie trotzdem auf eine
  Urheberrechtsverletzung aufmerksam werden, bitten wir um einen entsprechenden
  Hinweis. Bei Bekanntwerden von Rechtsverletzungen werden wir derartige Inhalte
  umgehend entfernen.
</p>

`
const Container = styled.div`
  padding: 30px 50px;
  overflow-y: scroll;
  strong {
    font-weight: bold;
    font-size: 22px;
    padding-bottom: 20px;
    line-height: 50px;
  }
`
const Impressum = () => {
  return (
    <Container>
      <div dangerouslySetInnerHTML={{ __html: impressumHtml }} />
    </Container>
  )
}

export default Impressum
