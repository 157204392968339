import { action, on, payload, reducer, empty } from 'ts-action'
/* Interfaces */

export interface FeedbackState {
  message: string | null
  type: 'error' | 'warning' | 'info' | 'success'
  visible?: boolean
}

/* Redux */
export const setFeedback = action('setFeedback', payload<FeedbackState>())
export const hideFeedback = action('hideFeedback', empty())

const initialState: FeedbackState = {
  message: null,
  type: 'success',
  visible: false
}

export const feedbackReducer = reducer(
  initialState,
  on(setFeedback, (state, { payload }) => ({ ...payload, visible: true })),
  on(hideFeedback, (state) => ({ ...state, visible: false }))
)
