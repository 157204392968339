import CustomHelmet from '@components/elements/CustomHelmet'
import { Dashboard } from '@components/elements/Dashboard'
import Dialog from '@components/elements/Dialog'
import { defaultProjectValues, Project } from '@interfaces/project'
import { wageProducerSearching } from '@interfaces/wageProducerProject'
import { setProject } from '@redux/project'
import { setShowCompleteProfileModal } from '@redux/profile'
import {
  selectCompanyID,
  selectAreProfileValuesComplete,
  selectShowCompleteProfileModal
} from '@selectors/profileSelectors'
import { selectSidebarIsOpen } from '@selectors/sidebarSelectors'
import { selectUserProjects } from '@selectors/userProjectSelectors'
import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useFirebase } from 'react-redux-firebase'
import { useHistory } from 'react-router-dom'
import styled from 'styled-components'
import FirstProjectLongCard from './FirstProjectLongCard'
import ProjectLongCard from './ProjectLongCard'
import cloneDeep from 'lodash/cloneDeep'
import partition from 'lodash/partition'
import { usedMachinerySearching } from '../../interfaces/usedMachineryProject'
import { Grid, GridSize } from '@material-ui/core'

const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin: 20px 40px 0 0;
  padding-right: 30px;
  box-sizing: border-box;
  overflow-y: auto;
  &::-webkit-scrollbar-track {
    border-radius: 0px;
  }
  &::-webkit-scrollbar-thumb {
    border-radius: 0px;
  }
`
const ProjectCategoryTitle = styled.h2`
  font-size: 25px;
  color: white;
`
const TitleUnderline = styled.div`
  margin-top: 5px;
  height: 4px;
  width: 60px;
  background-color: ${(props) => props.theme.primary};
`
const ContainerForProjectCards = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 20px;
`
const CardWrapper = styled.div`
  margin-left: 5px;
`

const Home = () => {
  const { t } = useTranslation()
  const history = useHistory()
  const dispatch = useDispatch()
  const firebase = useFirebase()

  const companyID = useSelector(selectCompanyID)
  const isProfileCompleted = useSelector(selectAreProfileValuesComplete)
  const showCompleteProfileModal = useSelector(selectShowCompleteProfileModal)
  const [deleteDialog, setDeleteDialog] = useState<null | string>()
  const inset = useSelector(selectSidebarIsOpen)
  const userProjects = useSelector(selectUserProjects)
  const [wageProducerProjects, setWageProducerProjects] = useState<Project[]>(
    []
  )
  const [searchingProjects, setSearchingProjects] = useState<Project[]>([])
  const [usedMachineryProjects, setUsedMachineryProjects] = useState<Project[]>(
    []
  )
  const closeDeleteDialog = () => setDeleteDialog(null)
  const editItem = (item: Project) => {
    const defaultValues =
      item.type === 'searchingWageProducer'
        ? wageProducerSearching
        : item.type === 'searchingUsedMachinerySupplier'
        ? usedMachinerySearching
        : defaultProjectValues
    // Do not forget spread merge goes only one level deep
    const merged = { ...defaultValues, ...item }
    dispatch(setProject(merged))
    history.push('/edit')
  }
  const deleteProject = () => {
    setDeleteDialog(null)
    firebase
      .firestore()
      .collection('companies')
      .doc(companyID)
      .collection('projects')
      .doc(deleteDialog as string)
      .delete()
  }

  const closeCompleteProfileModal = () =>
    dispatch(setShowCompleteProfileModal(false))

  useEffect(() => {
    if (isProfileCompleted === true) closeCompleteProfileModal()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isProfileCompleted])

  useEffect(() => {
    const projects = cloneDeep(userProjects)
    const [partitionedSearching, remainingProjects] = partition(projects, {
      type: 'searching'
    })
    const [partitionedWageProducer, partitionedUsedMachinery] = partition(
      remainingProjects,
      {
        type: 'searchingWageProducer'
      }
    )
    setSearchingProjects(partitionedSearching)
    setWageProducerProjects(partitionedWageProducer)
    setUsedMachineryProjects(partitionedUsedMachinery)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userProjects])

  const [gridPropMd, setGridPropMd] = useState<GridSize>(6)
  const [gridPropLg, setGridPropLg] = useState<GridSize>(4)

  useEffect(() => {
    // count amount of categories shown
    let amountToShow = 0
    if (searchingProjects.length > 0) amountToShow += 1
    if (wageProducerProjects.length > 0) amountToShow += 1
    if (usedMachineryProjects.length > 0) amountToShow += 1

    // if only one category then it should have full width
    setGridPropMd(amountToShow > 1 ? 6 : 12)

    if (amountToShow === 1) setGridPropLg(12)
    else if (amountToShow === 2) setGridPropLg(6)
    else setGridPropLg(4)
  }, [searchingProjects, wageProducerProjects, usedMachineryProjects])

  return (
    <Dashboard inset={inset}>
      <CustomHelmet title={t('metadata.home')} />
      <h1>DASHBOARD</h1>
      <Container>
        <FirstProjectLongCard />
        <Grid container style={{ marginTop: 20 }} spacing={4}>
          {searchingProjects.length > 0 && (
            <Grid item xs={12} md={gridPropMd} lg={gridPropLg}>
              <ProjectCategoryTitle>
                {t('home.ProjectCategorySearching')}
              </ProjectCategoryTitle>
              <TitleUnderline />
              <ContainerForProjectCards>
                {searchingProjects.map((item) => (
                  <CardWrapper>
                    <ProjectLongCard
                      key={item.id}
                      id={item.id as string}
                      title={item.name}
                      description={
                        item.description || t('projectCard.descriptionFallback')
                      }
                      onEdit={() => editItem(item)}
                      onDelete={() => setDeleteDialog(item.id)}
                    />
                  </CardWrapper>
                ))}
              </ContainerForProjectCards>
            </Grid>
          )}
          {wageProducerProjects.length > 0 && (
            <Grid item xs={12} md={gridPropMd} lg={gridPropLg}>
              <ProjectCategoryTitle>
                {t('home.ProjectCategorySearchingWageProducer')}
              </ProjectCategoryTitle>
              <TitleUnderline />
              <ContainerForProjectCards>
                {wageProducerProjects.map((item) => (
                  <CardWrapper>
                    <ProjectLongCard
                      key={item.id}
                      id={item.id as string}
                      title={item.name}
                      description={
                        item.description || t('projectCard.descriptionFallback')
                      }
                      onEdit={() => editItem(item)}
                      onDelete={() => setDeleteDialog(item.id)}
                    />
                  </CardWrapper>
                ))}
              </ContainerForProjectCards>
            </Grid>
          )}
          {usedMachineryProjects.length > 0 && (
            <Grid item xs={12} md={gridPropMd} lg={gridPropLg}>
              <ProjectCategoryTitle>
                {t('home.ProjectCategorySearchingUsedMachinerySupplier')}
              </ProjectCategoryTitle>
              <TitleUnderline />
              <ContainerForProjectCards>
                {usedMachineryProjects.map((item) => (
                  <CardWrapper>
                    <ProjectLongCard
                      isUsedMachineryCard={true}
                      key={item.id}
                      id={item.id as string}
                      title={item.name}
                      description={
                        item.description || t('projectCard.descriptionFallback')
                      }
                      onEdit={() => editItem(item)}
                      onDelete={() => setDeleteDialog(item.id)}
                    />
                  </CardWrapper>
                ))}
              </ContainerForProjectCards>
            </Grid>
          )}
        </Grid>
      </Container>

      <Dialog
        onClose={closeDeleteDialog}
        open={!!deleteDialog}
        title={t('messages.deleteProject')}
        description={t('messages.deleteProjectDescription')}
        buttons={[
          {
            title: t('buttons.cancel'),
            onClick: closeDeleteDialog,
            primary: false
          },
          {
            title: t('buttons.delete'),
            onClick: deleteProject,
            primary: true
          }
        ]}
      />
      <Dialog
        onClose={() => {}}
        open={showCompleteProfileModal}
        title={t('messages.incompleteProfile')}
        description={t('messages.incompleteProfileDescription')}
        buttons={[
          {
            title: t('general.later'),
            onClick: closeCompleteProfileModal,
            primary: false
          },
          {
            title: t('general.yes'),
            linkTo: '/profile',
            primary: true,
            onClick: closeCompleteProfileModal
          }
        ]}
      />
    </Dashboard>
  )
}

export default Home
