import BaseInput from '@components/elements/BaseInput'
import React from 'react'
import styled, { css } from 'styled-components'

const Container = styled.div`
  display: flex;
  flex: 1 1 100%;
  position: relative;
`

interface FloatInputInterface {
  unitNotEmpty: boolean
}

const FloatInput = styled(BaseInput)<FloatInputInterface>`
  display: flex;
  flex: 1;
  ${({ unitNotEmpty }) =>
    unitNotEmpty &&
    css`
      :focus + label {
        color: #111;
        transform: translate(0, -3rem);
      }
      :focus,
      :not(:placeholder-shown) {
        margin-top: 22px;
      }
      :not(:placeholder-shown) + label {
        color: #111;
        transform: translate(0, -3rem);
      }
    `}
  transition: margin-top 0.2s ease-out;
`

const UnitLabel = styled.label`
  color: grey;
  transform: translate(0.25rem, -1.5rem);
  transition: all 0.2s ease-out;
  padding-left: 5px;
  font-size: 13px;
  position: absolute;
  bottom: -12px;
`

const Float = ({
  value,
  unit,
  onChange
}: {
  value: number | undefined
  unit: string
  onChange: (v: number) => void
}) => {
  return (
    <Container>
      <FloatInput
        type="number"
        id={unit}
        value={value}
        onChange={(e) => onChange(parseFloat(e.target.value))}
        placeholder=" "
        unitNotEmpty={unit.length > 0}
      />
      <UnitLabel htmlFor={unit}>{unit}</UnitLabel>
    </Container>
  )
}

export default Float
