import { action, on, payload, reducer } from 'ts-action'
/* Interfaces */

export interface SidebarState {
  isOpen: boolean
}

/* Redux */
export const setIsOpen = action('setIsOpen', payload<boolean>())

const initialState: SidebarState = {
  isOpen: false
}

export const sidebarReducer = reducer(
  initialState,
  on(setIsOpen, (state, { payload }) => ({
    ...state,
    isOpen: payload
  }))
)
