import React from 'react'
import { useTranslation } from 'react-i18next'
import CustomHelmet from '@components/elements/CustomHelmet'
import ConfiguratorTitle from '../microElements/ConfiguratorTitle'
import { useSelector, useDispatch } from 'react-redux'
import { selectProjectPackaging } from '@selectors/projectSelectors'
import { OptionalSubcategory } from '../elements/OptionalSubcategory'
import ConfigurationSubcategory from '../elements/ConfigurationSubcategory'
import GridSystem from '../elements/GridSystem'
import { removeOptionalCategories, addOptionalCategory } from '@redux/project'
import { useIsPartner } from '@services/customHooks'

const Packaging = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const { fields, data, optionalFields } = useSelector(selectProjectPackaging)
  const optionalCategoryData = Object.entries(optionalFields).map(
    ([field]) => ({
      title: t(`project.configurator.subcategories.${field}`),
      field,
      position: 'packaging'
    })
  )
  const isPartner = useIsPartner()

  return (
    <div>
      <CustomHelmet title={t('metadata.newProjectPackaging')} />
      <ConfiguratorTitle>
        {t(
          `project.categories.${
            isPartner ? 'depositRangeOfServicePackaging' : 'packaging'
          }`
        )}
      </ConfiguratorTitle>

      <GridSystem
        data={fields}
        optionalCategoryData={optionalCategoryData}
        renderSubcategory={(field, index) => (
          <ConfigurationSubcategory
            key={index}
            title={`${fields[field].initialNotOptional ? '* ' : ''}${t(
              `project.configurator.subcategories.${field}`
            )}`}
            subcategoryData={data[field]}
            subcategory={fields[field]}
            field={field}
            position={'packaging'}
          />
        )}
        renderOptionalSubcategory={(opData) => (
          <OptionalSubcategory
            onAdd={(field: string) => {
              dispatch(
                addOptionalCategory({
                  field,
                  position: 'packaging'
                })
              )
            }}
            onDelete={() =>
              dispatch(removeOptionalCategories({ position: 'packaging' }))
            }
            data={opData}
          />
        )}
      />
    </div>
  )
}

export default Packaging
