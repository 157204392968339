/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react'
import { useFirebase } from 'react-redux-firebase'
import { getUrlFirebase } from '@services/helpers'

export const CustomImage = ({
  url,
  onClick,
  className
}: {
  url: string
  onClick: () => {}
  className?: string
}) => {
  const [urlFromFirebase, setUrlFromFirebase] = useState(undefined)
  const firebase = useFirebase()

  useEffect(() => {
    const fetch = async () => {
      setUrlFromFirebase(await getUrlFirebase(firebase, url))
    }
    fetch()
  }, [url])

  return urlFromFirebase ? (
    <img className={className} src={urlFromFirebase} alt="" onClick={onClick} />
  ) : null
}
