/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable react-hooks/exhaustive-deps */
import { ProfileTypes } from '@interfaces/user'
import { Icon } from '@material-ui/core'
import { useIsPartnerInRegister } from '@services/customHooks'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import styled from 'styled-components'

const DisclaimerContainer = styled.div<{ centerContent?: boolean }>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  margin-top: 10px;
  padding: ${({ centerContent }) => (centerContent ? '0' : '0 100px')};
  align-items: ${({ centerContent }) => (centerContent ? 'center' : 'initial')};
  a,
  span.material-icons {
    color: ${({ theme }) => theme.primary};
    cursor: pointer;
  }
  a {
    margin-left: 1px;
  }
  span {
    margin-left: 5px;
  }
`
const DisclaimerInnerContainer = styled.div`
  display: flex;
  align-items: center;
`
const DisclaimerTitle = styled.div`
  width: 100%;
  text-align: center;
  margin: 15px 0 10px;
`

interface IRegisterDisclaimer {
  profileType: ProfileTypes | null
  setIsSubmitEnabled: React.Dispatch<React.SetStateAction<boolean>>
}

const RegisterDisclaimer = ({
  profileType,
  setIsSubmitEnabled
}: IRegisterDisclaimer) => {
  const { t } = useTranslation()
  const isPartner = useIsPartnerInRegister()
  const [disclaimerAccepted, setDisclaimerAccepted] = useState(false)
  const [comActivityAccepted, setComActivityAccepted] = useState(false)

  useEffect(() => {
    if (isPartner) setIsSubmitEnabled(disclaimerAccepted && comActivityAccepted)
    else setIsSubmitEnabled(disclaimerAccepted)
  }, [disclaimerAccepted, comActivityAccepted])

  if (isPartner)
    return (
      <DisclaimerContainer>
        <DisclaimerInnerContainer>
          <Icon onClick={() => setDisclaimerAccepted((a) => !a)}>
            {disclaimerAccepted ? 'check_box' : 'check_box_outline_blank'}
          </Icon>
          <span>
            {t('privacyPolicyPretext')}
            <Link to="/datenschutz">{t('privacyPolicy')}</Link>
            {t('termsOfUsePretext')}
            <a target="_blank" href={`documents/AGB_EGB.pdf`}>
              {`${t('agbEgb')}.`}
            </a>
          </span>
        </DisclaimerInnerContainer>
        <DisclaimerTitle>{t('commercialActivity')}</DisclaimerTitle>
        <DisclaimerInnerContainer>
          <Icon onClick={() => setComActivityAccepted((a) => !a)}>
            {comActivityAccepted ? 'check_box' : 'check_box_outline_blank'}
          </Icon>
          <span>{t('commercialActivityDescription')}</span>
        </DisclaimerInnerContainer>
      </DisclaimerContainer>
    )

  /* profileType === ProfileTypes.Searching */
  return (
    <DisclaimerContainer centerContent={true}>
      <DisclaimerInnerContainer>
        <Icon onClick={() => setDisclaimerAccepted((a) => !a)}>
          {disclaimerAccepted ? 'check_box' : 'check_box_outline_blank'}
        </Icon>
        <span>
          {t('privacyPolicyPretext')}
          <a target="_blank" href="/datenschutz">
            {t('privacyPolicy')}
          </a>
          {t('termsOfUsePretext')}
          <a target="_blank" href="documents/Nutzungsbedingungen.pdf">
            {`${t('termsOfUse')}.`}
          </a>
        </span>
      </DisclaimerInnerContainer>
    </DisclaimerContainer>
  )
}

export default RegisterDisclaimer
