/* eslint-disable react-hooks/exhaustive-deps */
import CustomPopover from '@components/elements/CustomPopover'
import { FieldWithInputs } from '@interfaces/project'
import Icon from '@material-ui/core/Icon'
import {
  addProjectDataPriority,
  prefillDataForField,
  removeOptionalCategory,
  setShowUnfinishedFields
} from '@redux/project'
import useWindowDimensions from '@services/useWindowDimensions'
import { motion } from 'framer-motion'
import React, { useContext, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { selectShowUnfinishedFields } from '@selectors/projectSelectors'
import styled, { ThemeContext } from 'styled-components'
import ConfigurationAddItem from './ConfigurationAddItem'
import ConfigurationItem from './ConfigurationItem'
import { isSubcategoryDone } from '@services/helpers'
import { useIsPartner } from '@services/customHooks'
import { useFirebase } from 'react-redux-firebase'

interface SubcategoryInterface {
  open?: boolean
  done?: boolean
  hasRedBorder?: boolean
}

const Container = styled(motion.div)<SubcategoryInterface>`
  border-radius: 7px;
  width: 100%;

  background-color: ${(props) => props.theme.surface};
  margin-bottom: 15px;
  box-sizing: border-box;
  max-height: ${(props) => (props.open ? undefined : '46px')};
`

const CategoryTitle = styled(motion.div)<SubcategoryInterface>`
  display: flex;
  flex: 1;
  height: 46px;
  background-color: ${(props) =>
    props.done ? 'rgba(255, 255, 255, 0.5)' : props.theme.subcard};
  border-radius: 7px;
  border-bottom-left-radius: ${(props) => (props.open ? '-0' : '7px')};
  color: ${(props) => (props.done ? 'rgba(0, 0, 0, 0.65)' : 'black')};
  font-size: 17px;
  font-weight: 600;
  box-sizing: border-box;
  justify-content: space-between;
  align-items: center;
  padding: 0 0 0 10px;
  word-break: break-all;
  cursor: pointer;
  border: ${({ hasRedBorder }) => (hasRedBorder ? '1px solid red' : 'none')};
`
const ToggleIcon = styled(Icon)<SubcategoryInterface>`
  color: ${(props) => props.theme.border};
  transform: rotate(${(props) => (props.open ? '-180deg' : '0deg')});
  transition: transform ease-in-out 300ms;
  font-size: 30px !important;
`
const ToggleIconContainer = styled(motion.div)<SubcategoryInterface>`
  height: 100%;
  width: 46px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${(props) => props.theme.surface};
  border-bottom-right-radius: ${(props) => (props.open ? '0' : '7px')};
  border-top-right-radius: 7px;
  :hover {
    span {
      color: ${({ theme }) => theme.primary};
    }
  }
`
const ExpandIcon = styled(Icon)`
  color: ${(props) => props.theme.border};
  font-size: 25px !important;
  margin-right: 5px;
  cursor: pointer;
  :hover {
    color: ${({ theme }) => theme.primary};
  }
`
const OpenedLock = styled(Icon)`
  color: ${(props) => props.theme.border};
  font-size: 25px !important;
  margin-right: 5px;
  margin-bottom: 3px;
`

const PopoverContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  height: 100%;
  border-radius: 0 0 15px 15px;
  background: ${({ theme }) => theme.popoverBackground};
  overflow-y: auto;
  padding: 0 30px 35px;
  box-sizing: border-box;
`
const PopoverTitle = styled.div`
  font-size: 22px;
  font-weight: bold;
  color: ${(props) => props.theme.secondary};
  margin-bottom: 40px;
`
const PopoverAcceptButton = styled(Icon)`
  align-self: center;
  background: #fff;
  padding: 13px;
  font-size: 30px !important;
  border-radius: 15px;
  color: ${(props) => props.theme.secondary};
  box-shadow: 0px 3px 7px 0px rgba(0, 0, 0, 0.25);
  cursor: pointer;
  opacity: 0.5;
  :hover {
    background-color: ${({ theme }) => theme.primary} !important;
    transform: scale(1.04);
    opacity: 1;
  }
`
const IconsRow = styled(motion.div)`
  display: flex;
  height: 100%;
  align-items: center;
`
const DoneIconsContainer = styled(motion.div)`
  display: flex;
  height: 100%;
  min-width: 97px;
  box-sizing: border-box;
  background-color: ${(props) => props.theme.primary};
  padding: 0 15px;
  align-items: center;
  justify-content: space-between;
  border-top-right-radius: 7px;
  border-bottom-right-radius: 7px;
`
const DoneIcons = styled(Icon)`
  color: #494949;
  font-size: 23px !important;
`
const DeleteIcon = styled(Icon)`
  color: #494949;
  font-size: 25px !important;
  margin-right: 5px;
  margin-bottom: 3px;
  cursor: pointer;
  :hover {
    color: ${({ theme }) => theme.primary} !important;
    transform: scale(1.04);
  }
`
const InfoIcon = styled(Icon)`
  color: #494949;
  font-size: 22px !important;
  margin-right: 5px;
  margin-bottom: 3px;
  cursor: pointer;
  :hover {
    color: ${({ theme }) => theme.primary} !important;
    transform: scale(1.04);
  }
`
const Content = styled(motion.div)`
  box-sizing: border-box;
  padding-bottom: 20px;
  overflow-y: auto;
  max-height: 450px;
  padding: 10px 13px 10px 10px;

  &::-webkit-scrollbar-track {
    border-top-right-radius: 0px;
    border-bottom-right-radius: 7px;
  }
  &::-webkit-scrollbar-thumb {
    border-top-right-radius: 0px;
    border-bottom-right-radius: 7px;
  }
`

interface ConfigurationSubcategoryInterface {
  title: string
  subcategory: FieldWithInputs
  subcategoryData: any
  done?: boolean
  field: string
  position: string
  big?: boolean
  onExpand?: () => void
}
const Items = (props: ConfigurationSubcategoryInterface) => {
  const { t } = useTranslation()
  const isPartner = useIsPartner()
  const { subcategory, subcategoryData, field, position, big, onExpand } = props
  const dispatch = useDispatch()
  const propsForItem = {
    position,
    field,
    inputs: subcategory.inputs,
    onExpand
  }
  useEffect(() => {
    if (subcategory.multiplePriorities && !subcategoryData) {
      dispatch(addProjectDataPriority({ position, field }))
    }
  }, [subcategoryData, subcategory.multiplePriorities])

  if (subcategory.multiplePriorities) {
    if (!subcategoryData) return <div />

    return (
      <>
        {Object.keys(subcategoryData).map(
          (subDataKey: string, index: number) => (
            <ConfigurationItem
              title={`${index + 1}. ${t(
                `project.configurator.general.${
                  isPartner ? 'rangeOfServices' : 'priority'
                }`
              )}`}
              multiplePriorities
              index={index}
              data={subcategoryData[index]}
              big={big}
              showDelete={subcategoryData.length !== 1}
              {...propsForItem}
            />
          )
        )}
        <ConfigurationAddItem
          big={big}
          onClick={() => dispatch(addProjectDataPriority({ position, field }))}
        />
      </>
    )
  }
  if (subcategory.inputs)
    return (
      <ConfigurationItem
        title={t(`project.configurator.subcategories.fields.${field}`)}
        data={subcategoryData}
        {...propsForItem}
        inputs={subcategory.inputs}
        alwaysOpen
      />
    )
  return null
}

const ConfigurationSubcategory = (props: ConfigurationSubcategoryInterface) => {
  const { title, subcategory, subcategoryData, field, position } = props
  const titleRef = useRef<HTMLDivElement | null>(null)
  const { windowHeight, windowWidth } = useWindowDimensions()
  const [open, setOpen] = useState(false)
  const [isPopoverVisible, setPopoverVisibility] = useState(false)
  const themeContext = useContext(ThemeContext)
  const dispatch = useDispatch()
  const done = isSubcategoryDone(subcategory, subcategoryData)
  const firebase = useFirebase()
  const showUnfinishedFields = useSelector(selectShowUnfinishedFields)
  const [hasRedBorder, setHasRedBorder] = useState(false)

  useEffect(() => {
    if (showUnfinishedFields && !done) {
      setOpen(false)
      setHasRedBorder(true)
    } else setHasRedBorder(false)
  }, [showUnfinishedFields, done])

  const openPdf = async (link: string) => {
    const downloadUrl = await firebase
      .storage()
      .refFromURL(`gs://packpart-project.appspot.com/pdfs/${link}`)
      .getDownloadURL()
    window.open(downloadUrl, '_blank')
  }
  const handleOpenToggle = () => {
    if (titleRef?.current) {
      titleRef.current.scrollIntoView({
        behavior: 'smooth',
        block: 'start'
      })
    }
    dispatch(setShowUnfinishedFields(false))
    setOpen((o) => !o)
  }

  const handlePopoverClose = () => {
    setPopoverVisibility(false)
  }

  const handleExpand = () => {
    setPopoverVisibility(true)
  }

  const getDynamicPopoverPosition = (): string => {
    const distance = titleRef?.current?.getBoundingClientRect()
    // two thirds of the anchor elements width are on the right half of the screen
    if (distance && distance.left + distance.width / 3 > windowWidth / 2)
      return 'left'
    return 'right'
  }
  const removeOptional = (e: any) => {
    e.stopPropagation()

    dispatch(
      removeOptionalCategory({
        field,
        position
      })
    )
  }
  useEffect(() => {
    if (subcategory.prefill && !subcategoryData) {
      dispatch(prefillDataForField({ position, field }))
    }
  }, [subcategoryData, subcategory.prefill])

  return (
    <Container done={done} open={open}>
      <CustomPopover
        handlePopoverClose={handlePopoverClose}
        isVisible={isPopoverVisible}
        position={getDynamicPopoverPosition()}
        backgroundColor={themeContext.popoverBackground}
        width={windowWidth / 2.5}
        height={windowHeight / 1.5}
        awayFromAnchorMargin={-27}
        content={
          <PopoverContentContainer>
            <div>
              <PopoverTitle>{title}</PopoverTitle>
              <Items {...props} big />
            </div>
            <PopoverAcceptButton onClick={handlePopoverClose}>
              done
            </PopoverAcceptButton>
          </PopoverContentContainer>
        }>
        <CategoryTitle
          hasRedBorder={hasRedBorder}
          done={done}
          open={open}
          ref={titleRef}
          onClick={handleOpenToggle}>
          {title}
          {done && !open ? (
            <DoneIconsContainer>
              <DoneIcons style={{ cursor: 'pointer' }}>lock</DoneIcons>

              <DoneIcons>checkmark</DoneIcons>
            </DoneIconsContainer>
          ) : (
            <IconsRow>
              {open && subcategory.multiplePriorities && (
                <motion.div>
                  <ExpandIcon
                    onClick={(e: any) => {
                      e.stopPropagation()
                      handleExpand()
                    }}>
                    zoom_out_map
                  </ExpandIcon>
                </motion.div>
              )}

              {done && <OpenedLock>lock_open</OpenedLock>}
              {subcategory.glossarLink && (
                <InfoIcon
                  onClick={(e: any) => {
                    e.stopPropagation()
                    window.open(subcategory.glossarLink, '_blank')
                  }}>
                  info
                </InfoIcon>
              )}
              {subcategory.pdfFile && (
                <InfoIcon
                  onClick={(e: any) => {
                    e.stopPropagation()
                    openPdf(subcategory.pdfFile as string)
                  }}>
                  picture_as_pdf
                </InfoIcon>
              )}
              {!subcategory?.initialNotOptional && (
                <DeleteIcon onClick={removeOptional}>delete</DeleteIcon>
              )}
              <ToggleIconContainer open={open}>
                <ToggleIcon open={open}>expand_more</ToggleIcon>
              </ToggleIconContainer>
            </IconsRow>
          )}
        </CategoryTitle>
      </CustomPopover>

      <Content>{open && <Items {...props} onExpand={handleExpand} />}</Content>
    </Container>
  )
}

export default ConfigurationSubcategory
