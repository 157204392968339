/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

const SelectInput = styled.select`
  display: flex;
  width: 100%;
  box-sizing: border-box;
  color: black;
  height: 25px;
  border-radius: 12.5px;
  padding: 0 7px;
  margin: 5px 0;
  outline: none;
  border-width: 0px;
`
const Select = ({
  options,
  value,
  onChange,
  placeholder
}: {
  options: string[]
  value: string
  onChange: (v: string) => void
  placeholder?: string
}) => {
  const { t } = useTranslation()
  useEffect(() => {
    if (!value) onChange(options[0])
  }, [value])
  return (
    <fieldset>
      <SelectInput
        value={value}
        onChange={(e) => {
          onChange(e.target.value)
        }}>
        {options.map((option) => (
          <option value={option}>{t(`${placeholder}.${option}`)}</option>
        ))}
      </SelectInput>
    </fieldset>
  )
}

export default Select
