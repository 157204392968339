import React from 'react'
import { useTranslation } from 'react-i18next'

const Countrys = () => {
  const { t } = useTranslation()

  return (
    <>
      <option value="x">{t('countries.chooseCountry')}</option>
      <option value="DE">{t('countries.germany')}</option>
      <option value="AT">{t('countries.austria')}</option>
      <option value="CH">{t('countries.switzerland')}</option>
      <option value="-">------------------</option>
      <option value="AF">{t('countries.afghanistan')}</option>
      <option value="EG">{t('countries.egypt')}</option>
      <option value="AX">{t('countries.aland')}</option>
      <option value="AL">{t('countries.albania')}</option>
      <option value="DZ">{t('countries.algeria')}</option>
      <option value="AS">{t('countries.americanSamoa')}</option>
      <option value="VI">{t('countries.americanVirginIslands')}</option>
      <option value="AD">{t('countries.andorra')}</option>
      <option value="AO">{t('countries.angola')}</option>
      <option value="AI">{t('countries.anguilla')}</option>
      <option value="AQ">{t('countries.antarctic')}</option>
      <option value="AG">{t('countries.antiguaAndBarbuda')}</option>
      <option value="GQ">{t('countries.aquatorialGuinea')}</option>
      <option value="AR">{t('countries.argentina')}</option>
      <option value="AM">{t('countries.armenia')}</option>
      <option value="AW">{t('countries.aruba')}</option>
      <option value="AC">{t('countries.ascension')}</option>
      <option value="AZ">{t('countries.azerbaijan')}</option>
      <option value="ET">{t('countries.ethiopia')}</option>
      <option value="AU">{t('countries.australia')}</option>
      <option value="BS">{t('countries.bahamas')}</option>
      <option value="BH">{t('countries.bahrain')}</option>
      <option value="BD">{t('countries.bangladesh')}</option>
      <option value="BB">{t('countries.barbados')}</option>
      <option value="BE">{t('countries.belgium')}</option>
      <option value="BZ">{t('countries.belize')}</option>
      <option value="BJ">{t('countries.benin')}</option>
      <option value="BM">{t('countries.bermuda')}</option>
      <option value="BT">{t('countries.bhutan')}</option>
      <option value="BO">{t('countries.bolivia')}</option>
      <option value="BA">{t('countries.bosniaAndHerzegovina')}</option>
      <option value="BW">{t('countries.botswana')}</option>
      <option value="BV">{t('countries.bouvetIsland')}</option>
      <option value="BR">{t('countries.brazil')}</option>
      <option value="BN">{t('countries.brunei')}</option>
      <option value="BG">{t('countries.bulgaria')}</option>
      <option value="BF">{t('countries.burkinaFaso')}</option>
      <option value="BI">{t('countries.burundi')}</option>
      <option value="CL">{t('countries.chile')}</option>
      <option value="CN">{t('countries.china')}</option>
      <option value="CK">{t('countries.cookIslands')}</option>
      <option value="CR">{t('countries.costaRica')}</option>
      <option value="CI">{t('countries.coteDivoire')}</option>
      <option value="DK">{t('countries.denmark')}</option>
      <option value="DG">{t('countries.diegoGarcia')}</option>
      <option value="DM">{t('countries.dominica')}</option>
      <option value="DO">{t('countries.dominicanRepublic')}</option>
      <option value="DJ">{t('countries.djibouti')}</option>
      <option value="EC">{t('countries.ecuador')}</option>
      <option value="SV">{t('countries.elSalvador')}</option>
      <option value="ER">{t('countries.eritrea')}</option>
      <option value="EE">{t('countries.estonia')}</option>
      <option value="EU">{t('countries.europeanUnion')}</option>
      <option value="FK">{t('countries.falklandIslands')}</option>
      <option value="FO">{t('countries.faroeIslands')}</option>
      <option value="FJ">{t('countries.fiji')}</option>
      <option value="FI">{t('countries.finland')}</option>
      <option value="FR">{t('countries.france')}</option>
      <option value="GF">{t('countries.frenchGuiana')}</option>
      <option value="PF">{t('countries.frenchPolynesia')}</option>
      <option value="GA">{t('countries.gabon')}</option>
      <option value="GM">{t('countries.gambia')}</option>
      <option value="GE">{t('countries.georgia')}</option>
      <option value="GH">{t('countries.ghana')}</option>
      <option value="GI">{t('countries.gibraltar')}</option>
      <option value="GD">{t('countries.grenada')}</option>
      <option value="GR">{t('countries.greece')}</option>
      <option value="GL">{t('countries.greenland')}</option>
      <option value="GB">{t('countries.greatBritain')}</option>
      <option value="CP">{t('countries.guadeloupe')}</option>
      <option value="GU">{t('countries.guam')}</option>
      <option value="GT">{t('countries.guatemala')}</option>
      <option value="GG">{t('countries.guernsey')}</option>
      <option value="GN">{t('countries.guinea')}</option>
      <option value="GW">{t('countries.guineaBissau')}</option>
      <option value="GY">{t('countries.guyana')}</option>
      <option value="HT">{t('countries.haiti')}</option>
      <option value="HM">{t('countries.heardAndMcdonaldIslands')}</option>
      <option value="HN">{t('countries.honduras')}</option>
      <option value="HK">{t('countries.hongKong')}</option>
      <option value="IN">{t('countries.india')}</option>
      <option value="ID">{t('countries.indonesia')}</option>
      <option value="IQ">{t('countries.iraq')}</option>
      <option value="IR">{t('countries.iran')}</option>
      <option value="IE">{t('countries.ireland')}</option>
      <option value="IS">{t('countries.iceland')}</option>
      <option value="IL">{t('countries.israel')}</option>
      <option value="IT">{t('countries.italy')}</option>
      <option value="JM">{t('countries.jamaica')}</option>
      <option value="JP">{t('countries.japan')}</option>
      <option value="YE">{t('countries.yemen')}</option>
      <option value="JE">{t('countries.jersey')}</option>
      <option value="JO">{t('countries.jordan')}</option>
      <option value="KY">{t('countries.caymanIslands')}</option>
      <option value="KH">{t('countries.cambodia')}</option>
      <option value="CM">{t('countries.cameroon')}</option>
      <option value="CA">{t('countries.canada')}</option>
      <option value="IC">{t('countries.canaryIslands')}</option>
      <option value="CV">{t('countries.capeVerde')}</option>
      <option value="KZ">{t('countries.kazakhstan')}</option>
      <option value="QA">{t('countries.qatar')}</option>
      <option value="KE">{t('countries.kenya')}</option>
      <option value="KG">{t('countries.kyrgyzstan')}</option>
      <option value="KI">{t('countries.kiribati')}</option>
      <option value="CC">{t('countries.coconutIslands')}</option>
      <option value="CO">{t('countries.colombia')}</option>
      <option value="KM">{t('countries.comoros')}</option>
      <option value="CG">{t('countries.congo')}</option>
      <option value="HR">{t('countries.croatia')}</option>
      <option value="CU">{t('countries.cuba')}</option>
      <option value="KW">{t('countries.kuwait')}</option>
      <option value="LA">{t('countries.laos')}</option>
      <option value="LS">{t('countries.lesotho')}</option>
      <option value="LV">{t('countries.latvia')}</option>
      <option value="LB">{t('countries.lebanon')}</option>
      <option value="LR">{t('countries.liberia')}</option>
      <option value="LY">{t('countries.libya')}</option>
      <option value="LI">{t('countries.liechtenstein')}</option>
      <option value="LT">{t('countries.lithuania')}</option>
      <option value="LU">{t('countries.luxembourg')}</option>
      <option value="MO">{t('countries.macau')}</option>
      <option value="MG">{t('countries.madagascar')}</option>
      <option value="MW">{t('countries.malawi')}</option>
      <option value="MY">{t('countries.malaysia')}</option>
      <option value="MV">{t('countries.maldives')}</option>
      <option value="ML">{t('countries.mali')}</option>
      <option value="MT">{t('countries.malta')}</option>
      <option value="MA">{t('countries.morocco')}</option>
      <option value="MH">{t('countries.marshallIslands')}</option>
      <option value="MQ">{t('countries.martinique')}</option>
      <option value="MR">{t('countries.mauritania')}</option>
      <option value="MU">{t('countries.mauritius')}</option>
      <option value="YT">{t('countries.mayotte')}</option>
      <option value="MK">{t('countries.macedonia')}</option>
      <option value="MX">{t('countries.mexico')}</option>
      <option value="FM">{t('countries.micronesia')}</option>
      <option value="MD">{t('countries.moldova')}</option>
      <option value="MC">{t('countries.monaco')}</option>
      <option value="MN">{t('countries.mongolia')}</option>
      <option value="MS">{t('countries.montserrat')}</option>
      <option value="MZ">{t('countries.mozambique')}</option>
      <option value="MM">{t('countries.myanmar')}</option>
      <option value="NA">{t('countries.namibia')}</option>
      <option value="NR">{t('countries.nauru')}</option>
      <option value="NP">{t('countries.nepal')}</option>
      <option value="NC">{t('countries.newCaledonia')}</option>
      <option value="NZ">{t('countries.newZealand')}</option>
      <option value="NT">{t('countries.neutralZone')}</option>
      <option value="NI">{t('countries.nicaragua')}</option>
      <option value="NL">{t('countries.netherlands')}</option>
      <option value="AN">{t('countries.netherlandsAntilles')}</option>
      <option value="NE">{t('countries.niger')}</option>
      <option value="NG">{t('countries.nigeria')}</option>
      <option value="NU">{t('countries.niue')}</option>
      <option value="KP">{t('countries.northKorea')}</option>
      <option value="MP">{t('countries.northernMarianaIslands')}</option>
      <option value="NF">{t('countries.norfolkIsland')}</option>
      <option value="NO">{t('countries.norway')}</option>
      <option value="OM">{t('countries.oman')}</option>
      <option value="AT">{t('countries.austria')}</option>
      <option value="PK">{t('countries.pakistan')}</option>
      <option value="PS">{t('countries.palestine')}</option>
      <option value="PW">{t('countries.palau')}</option>
      <option value="PA">{t('countries.panama')}</option>
      <option value="PG">{t('countries.papuaNewGuinea')}</option>
      <option value="PY">{t('countries.paraguay')}</option>
      <option value="PE">{t('countries.peru')}</option>
      <option value="PH">{t('countries.philippines')}</option>
      <option value="PN">{t('countries.pitcairnIslands')}</option>
      <option value="PL">{t('countries.poland')}</option>
      <option value="PT">{t('countries.portugal')}</option>
      <option value="PR">{t('countries.puertoRico')}</option>
      <option value="RE">{t('countries.reunion')}</option>
      <option value="RW">{t('countries.rwanda')}</option>
      <option value="RO">{t('countries.romania')}</option>
      <option value="RU">{t('countries.russianFederation')}</option>
      <option value="SB">{t('countries.solomonIslands')}</option>
      <option value="ZM">{t('countries.zambia')}</option>
      <option value="WS">{t('countries.samoa')}</option>
      <option value="SM">{t('countries.sanMarino')}</option>
      <option value="ST">{t('countries.saoTomeAndPrincipe')}</option>
      <option value="SA">{t('countries.saudiArabia')}</option>
      <option value="SE">{t('countries.sweden')}</option>
      <option value="SN">{t('countries.senegal')}</option>
      <option value="CS">{t('countries.serbiaAndMontenegro')}</option>
      <option value="SC">{t('countries.seychelles')}</option>
      <option value="SL">{t('countries.sierraLeone')}</option>
      <option value="ZW">{t('countries.zimbabwe')}</option>
      <option value="SG">{t('countries.singapore')}</option>
      <option value="SK">{t('countries.slovakia')}</option>
      <option value="SI">{t('countries.slovenia')}</option>
      <option value="SO">{t('countries.somalia')}</option>
      <option value="ES">{t('countries.spain')}</option>
      <option value="LK">{t('countries.sriLanka')}</option>
      <option value="SH">{t('countries.stHelena')}</option>
      <option value="KN">{t('countries.stKittsAndNevis')}</option>
      <option value="LC">{t('countries.stLucia')}</option>
      <option value="PM">{t('countries.stPierreAndMiquelon')}</option>
      <option value="VC">{t('countries.stVincentGrenadines')}</option>
      <option value="ZA">{t('countries.southAfrica')}</option>
      <option value="SD">{t('countries.sudan')}</option>
      <option value="KR">{t('countries.southKorea')}</option>
      <option value="SR">{t('countries.suriname')}</option>
      <option value="SJ">{t('countries.svalbardAndJanMayen')}</option>
      <option value="SZ">{t('countries.swaziland')}</option>
      <option value="SY">{t('countries.syria')}</option>
      <option value="TJ">{t('countries.tajikistan')}</option>
      <option value="TW">{t('countries.taiwan')}</option>
      <option value="TZ">{t('countries.tanzania')}</option>
      <option value="TH">{t('countries.thailand')}</option>
      <option value="TL">{t('countries.timorLeste')}</option>
      <option value="TG">{t('countries.togo')}</option>
      <option value="TK">{t('countries.tokelau')}</option>
      <option value="TO">{t('countries.tonga')}</option>
      <option value="TT">{t('countries.trinidadAndTobago')}</option>
      <option value="TA">{t('countries.tristanDaCunha')}</option>
      <option value="TD">{t('countries.chad')}</option>
      <option value="CZ">{t('countries.czechRepublic')}</option>
      <option value="TN">{t('countries.tunisia')}</option>
      <option value="TR">{t('countries.turkey')}</option>
      <option value="TM">{t('countries.turkmenistan')}</option>
      <option value="TC">{t('countries.turksAndCaicosIslands')}</option>
      <option value="TV">{t('countries.tuvalu')}</option>
      <option value="UG">{t('countries.uganda')}</option>
      <option value="UA">{t('countries.ukraine')}</option>
      <option value="HU">{t('countries.hungary')}</option>
      <option value="UY">{t('countries.uruguay')}</option>
      <option value="UZ">{t('countries.uzbekistan')}</option>
      <option value="VU">{t('countries.vanuatu')}</option>
      <option value="VA">{t('countries.vaticanCity')}</option>
      <option value="VE">{t('countries.venezuela')}</option>
      <option value="AE">{t('countries.unitedArabEmirates')}</option>
      <option value="US">{t('countries.unitedStatesOfAmerica')}</option>
      <option value="VN">{t('countries.vietnam')}</option>
      <option value="WF">{t('countries.wallisAndFutuna')}</option>
      <option value="CX">{t('countries.christmasIsland')}</option>
      <option value="BY">{t('countries.belarus')}</option>
      <option value="EH">{t('countries.westernSahara')}</option>
      <option value="CF">{t('countries.centralAfricanRepublic')}</option>
      <option value="CY">{t('countries.cyprus')}</option>
    </>
  )
}

export default Countrys
