import React from 'react'
import Snackbar from '@material-ui/core/Snackbar'
import MuiAlert, { AlertProps } from '@material-ui/lab/Alert'
import { hideFeedback } from '@redux/feedback'
import { selectFeedback } from '@selectors/feedbackSelectors'
import { useSelector, useDispatch } from 'react-redux'

function Alert(props: AlertProps) {
  return <MuiAlert elevation={6} variant="filled" {...props} />
}
const Feedback = () => {
  const feedback = useSelector(selectFeedback)
  const dispatch = useDispatch()
  const handleFeedbackClose = () => {
    dispatch(hideFeedback())
  }
  return (
    <Snackbar
      open={feedback.visible}
      autoHideDuration={4000}
      onClose={handleFeedbackClose}>
      <Alert onClose={handleFeedbackClose} severity={feedback.type}>
        {feedback.message}
      </Alert>
    </Snackbar>
  )
}

export default Feedback
