import DashedContainer from '@components/elements/DashedContainer'
import Icon from '@material-ui/core/Icon'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import styled from 'styled-components'

const Container = styled(DashedContainer)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 120px;
  border-radius: 10px;
  box-sizing: border-box;
  margin-bottom: 20px;
  /* Prevents cutted borders on the edges */
  border: dashed 2px;
  background-image: none;
  :hover {
    background-image: var(--gradient1), var(--gradient1), var(--gradient2),
      var(--gradient2);
    border: none;
  }
`

const StyledLink = styled(Link)`
  text-decoration: none;
`

const AddCircleIcon = styled(Icon)`
  margin-right: 1px;
  margin-top: 5px;
  font-size: 50px !important;
  background: white;
  border-radius: 25px;
  color: ${(props) => props.theme.surface};
  ${Container}:hover & {
    color: ${({ theme }) => theme.secondary};
    background: ${({ theme }) => theme.primary};
  }
`

const Subtitle = styled.h1`
  margin-top: 15px;
  font-size: 18px;
  font-weight: 500;
`

const FirstProjectLongCard = () => {
  const { t } = useTranslation()
  return (
    <StyledLink to="/new">
      <Container>
        <AddCircleIcon>addCircle</AddCircleIcon>
        <Subtitle>{t('partnerOverview.addRangeOfServices')}</Subtitle>
      </Container>
    </StyledLink>
  )
}

export default FirstProjectLongCard
