import React from 'react'
import styled from 'styled-components'
import Icon from '@material-ui/core/Icon'
import { useSelector, useDispatch } from 'react-redux'
import { selectRegisterType } from '@selectors/registerSelectors'
import { setRegisterType, setCurrentRegisterStep } from '@redux/register'
import { useTranslation } from 'react-i18next'
import Button from '@components/elements/Button'
import ContentCenterer from '@components/elements/ContentCenterer'
import { ProfileTypes } from '@interfaces/user'

const ToggleContainer = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: space-around;
  box-sizing: border-box;
  padding: 50px;
  @media (max-width: 850px) {
    flex-direction: column;
    padding: 20px 10px;
  }
`
interface ToggleInterface {
  active?: boolean
  inactive?: boolean
}
const Toggle = styled.div<ToggleInterface>`
  display: flex;
  flex-direction: column;
  align-items: center;
  opacity: ${(props) => (props.inactive ? 0.5 : 1)};
  transition: all ease-in-out 300ms;
  width: 150px;
  @media (max-width: 850px) {
    margin: 10px 0;
  }
  cursor: pointer;
`
const ToggleIconContainer = styled.div<ToggleInterface>`
  height: 150px;
  width: 150px;
  border-radius: 15px;
  background-color: ${(props) =>
    props.active ? props.theme.primary : 'white'};
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all ease-in-out 300ms;
  @media (max-width: 600px) {
    height: 75px;
    width: 75px;
  }
`
const ToggleDescription = styled.p`
  font-size: 22px;
  color: black;
  font-weight: 700;
  text-align: center;
  margin-top: 20px;
  @media (max-width: 600px) {
    font-size: 16px;
  }
`

const ToggleIcon = styled(Icon).withConfig({
  shouldForwardProp: (prop) => !['active'].includes(prop)
})<ToggleInterface>`
  font-size: 60px !important;
  color: ${(props) => (props.active ? 'white' : 'black')};
  transition: all ease-in-out 300ms;
`
const Toggles = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const type = useSelector(selectRegisterType)
  return (
    <>
      <ToggleContainer>
        <Toggle
          onClick={() => dispatch(setRegisterType(ProfileTypes.Searching))}
          inactive={type && type !== ProfileTypes.Searching}>
          <ToggleIconContainer active={type === ProfileTypes.Searching}>
            <ToggleIcon active={type === ProfileTypes.Searching}>
              description
            </ToggleIcon>
          </ToggleIconContainer>
          <ToggleDescription>{t('register.searching')}</ToggleDescription>
        </Toggle>
        <Toggle
          onClick={() => dispatch(setRegisterType(ProfileTypes.Offering))}
          inactive={type && type !== ProfileTypes.Offering}>
          <ToggleIconContainer active={type === ProfileTypes.Offering}>
            <ToggleIcon active={type === ProfileTypes.Offering}>
              engineering
            </ToggleIcon>
          </ToggleIconContainer>
          <ToggleDescription>{t('register.offering')}</ToggleDescription>
        </Toggle>
        <Toggle
          onClick={() => dispatch(setRegisterType(ProfileTypes.WageProducer))}
          inactive={type && type !== ProfileTypes.WageProducer}>
          <ToggleIconContainer active={type === ProfileTypes.WageProducer}>
            <ToggleIcon active={type === ProfileTypes.WageProducer}>
              buildRounded
            </ToggleIcon>
          </ToggleIconContainer>
          <ToggleDescription>{t('register.wageProducer')}</ToggleDescription>
        </Toggle>
        {/* <Toggle
          onClick={() =>
            dispatch(setRegisterType(ProfileTypes.UsedMachinerySupplier))
          }
          inactive={type && type !== ProfileTypes.UsedMachinerySupplier}>
          <ToggleIconContainer
            active={type === ProfileTypes.UsedMachinerySupplier}>
            <ToggleIcon active={type === ProfileTypes.UsedMachinerySupplier}>
              cached
            </ToggleIcon>
          </ToggleIconContainer>
          <ToggleDescription>
            {t('register.usedMachinerySupplier')}
          </ToggleDescription>
        </Toggle> */}
      </ToggleContainer>
      {type && (
        <ContentCenterer>
          <Button
            variant="contained"
            condensed
            onClick={() => dispatch(setCurrentRegisterStep(1))}>
            {t('buttons.next')}
          </Button>
        </ContentCenterer>
      )}
    </>
  )
}

export default Toggles
