import { Categories } from '@interfaces/project'
import { getCategoryIndex, indexToLetter } from '@services/helpers'
import React from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

interface CategoryPreviewProps {
  categories: Array<Categories>
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`

const CategoryDot = styled.h1`
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 10px;
  min-height: 20px;
  min-width: 20px;
  background: ${(props) => props.theme.primary};
  border-radius: 10px;
  color: black;
  margin: 10px 15px 10px 0;
`
const Title = styled.h1`
  font-size: 16px;
  font-weight: 800;
  color: ${(props) => props.theme.secondary};
  overflow: hidden;
  text-overflow: ellipsis;
`

const CategoryPreview: Function = ({ categories }: CategoryPreviewProps) => {
  const { t } = useTranslation()
  const sortedCategories = [...categories].sort(
    (first: Categories, second: Categories) =>
      getCategoryIndex(first) < getCategoryIndex(second) ? -1 : 1
  )

  return sortedCategories.map((sortedCategory: Categories) => (
    <Wrapper>
      <CategoryDot>
        {indexToLetter(getCategoryIndex(sortedCategory))}
      </CategoryDot>
      <Title>{t(`project.categories.${sortedCategory}`)}</Title>
    </Wrapper>
  ))
}

export default CategoryPreview
