import { setDescription } from '@redux/project'
import { selectCurrentProject } from '@selectors/projectSelectors'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components'

const DescriptionDisplay = styled.textarea`
  background: #fff;
  width: 70%;
  height: 110px;
  margin-top: 30px;
  border-radius: 27px;
  box-sizing: border-box;
  padding: 5px 20px;
  color: black;
  font-weight: 600;
  font-size: 25px;
  outline: none;
  border-style: solid;
  overflow: hidden;
  z-index: 1;
  :focus {
    box-shadow: 0 0 3px 1px ${({ theme }) => theme.primary};
  }
`

const ProjectDescription = ({ placeholder }: { placeholder?: string }) => {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const { description } = useSelector(selectCurrentProject)
  const defaultDescription =
    placeholder ?? t('project.basics.defaultProjectDescription')

  const handleChange = (e: any) => {
    dispatch(setDescription(e.target.value))
  }

  return (
    <DescriptionDisplay
      id="input"
      onChange={handleChange}
      value={description}
      placeholder={defaultDescription}
    />
  )
}

export default ProjectDescription
