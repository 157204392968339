import Icon from '@material-ui/core/Icon'
import { addOptionalCategory, removeOptionalCategories } from '@redux/project'
import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { selectShowUnfinishedFields } from '@selectors/projectSelectors'
import styled from 'styled-components'
import ConfigurationSubcategory from './ConfigurationSubcategory'
import GridSystem from './GridSystem'
import { OptionalSubcategory } from './OptionalSubcategory'
import { ProjectCategory } from '@interfaces/project'
import { isSubcategoryDone } from '@services/helpers'

interface CategoryInterface {
  open?: boolean
  isDone?: boolean
  hasRedBorder?: boolean
}

const ToggleIcon = styled(Icon)<CategoryInterface>`
  color: black;
  transform: rotate(${(props) => (props.open ? '-180deg' : '0deg')});
  transition: transform ease-in-out 300ms;
  font-size: 32px !important;
  :hover {
    color: ${({ theme }) => theme.primary} !important;
  }
`
const CategoryTitle = styled.div<CategoryInterface>`
  display: flex;
  font-size: 18px;
  font-weight: 600;
  box-sizing: border-box;
  min-height: 46px;
  padding-bottom: 2px;
  margin-bottom: 15px;
  justify-content: space-between;
  align-items: center;
  color: ${({ hasRedBorder }) => (hasRedBorder ? 'red' : 'black')};
  border-bottom: 1px solid
    ${({ theme, hasRedBorder }) => (hasRedBorder ? 'red' : theme.border)};
  background-color: ${(props) => props.theme.card};
  cursor: pointer;
  ${ToggleIcon} {
    color: ${({ hasRedBorder }) => (hasRedBorder ? 'red' : 'black')} !important;
  }
  :hover {
    ${ToggleIcon} {
      color: ${({ theme }) => theme.primary} !important;
    }
  }
  ${({ isDone, theme }) =>
    isDone &&
    `
    background-color: ${theme.subcard};
    margin: 0 -7px 10px -7px;
    padding: 0 0 0 7px;
    border-radius: 7px;
  `};
`
const DoneIconsContainer = styled.div`
  display: flex;
  height: 100%;
  height: 46px;
  width: 96px;
  box-sizing: border-box;
  background-color: ${(props) => props.theme.primary};
  padding: 0 15px;
  align-items: center;
  justify-content: space-between;
  border-top-right-radius: 7px;
  border-bottom-right-radius: 7px;
`
const DoneIcons = styled(Icon)`
  color: #494949 !important;
  font-size: 23px !important;
  cursor: pointer;
`
const InfoIcon = styled(Icon)`
  color: #494949;
  font-size: 22px !important;
  margin-right: 5px;

  cursor: pointer;
  :hover {
    color: ${({ theme }) => theme.primary} !important;
  }
`
const IconsContainer = styled.div`
  display: flex;
  height: 100%;
  align-items: center;
`
const ConfigurationCategory = ({
  title,
  category,
  position,
  defaultOpen
}: {
  title: string
  category: any
  position: string
  defaultOpen?: boolean
}) => {
  const [open, setOpen] = useState(defaultOpen ?? false)
  const showUnfinishedFields = useSelector(selectShowUnfinishedFields)
  const [hasRedBorder, setHasRedBorder] = useState(false)
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const { fields, data, optionalFields, glossarLink }: ProjectCategory =
    category

  const optionalCategoryData = Object.entries(optionalFields).map(
    ([field]) => ({
      title: t(`project.configurator.subcategories.${field}`),
      field,
      position
    })
  )

  const isDone = Object.keys(fields).every((field) => {
    const subcategoryData = data[field]
    const subcategory = fields[field]
    return isSubcategoryDone(subcategory, subcategoryData)
  })

  useEffect(() => {
    if (showUnfinishedFields && !isDone) setHasRedBorder(true)
    else setHasRedBorder(false)
  }, [showUnfinishedFields, isDone])

  return (
    <>
      <CategoryTitle
        open={open}
        hasRedBorder={hasRedBorder}
        onClick={() => setOpen((o) => !o)}
        isDone={isDone}>
        {title}

        {isDone ? (
          <DoneIconsContainer>
            <DoneIcons>lock</DoneIcons>

            <DoneIcons>checkmark</DoneIcons>
          </DoneIconsContainer>
        ) : (
          <IconsContainer>
            {glossarLink && (
              <InfoIcon
                onClick={(e) => {
                  e.stopPropagation()
                  window.open(glossarLink, '_blank')
                }}>
                info
              </InfoIcon>
            )}
            <ToggleIcon open={open}>expand_more</ToggleIcon>
          </IconsContainer>
        )}
      </CategoryTitle>
      {open && (
        <GridSystem
          data={fields}
          optionalCategoryData={optionalCategoryData}
          renderSubcategory={(field, index) => (
            <ConfigurationSubcategory
              key={index}
              title={`${fields[field].initialNotOptional ? '* ' : ''}${t(
                `project.configurator.subcategories.${field}`
              )}`}
              subcategoryData={data[field]}
              subcategory={fields[field]}
              field={field}
              position={position}
            />
          )}
          renderOptionalSubcategory={(opData) => (
            <OptionalSubcategory
              onAdd={(field: string) => {
                dispatch(
                  addOptionalCategory({
                    field,
                    position
                  })
                )
              }}
              onDelete={() => dispatch(removeOptionalCategories({ position }))}
              data={opData}
            />
          )}
        />
      )}
    </>
  )
}

export default ConfigurationCategory
