import styled from 'styled-components'
interface DashboardProps {
  inset?: boolean
}
export const Dashboard = styled.div<DashboardProps>`
  display: flex;
  flex-direction: column;
  flex: 1;
  padding-left: ${(props) => (props.inset ? '150px' : '80px')};
  padding-top: 115px;
  padding-bottom: 60px;
  transition: padding-left ease-in-out 500ms;
  box-sizing: border-box;
  max-width: inherit;
  overflow-x: hidden;
  @media (max-width: 800px) {
    padding-left: 20px;
  }
  @media (max-width: 1200px) {
    padding-bottom: 20px;
    padding-top: 40px;
  }
`
