import Container from '@components/elements/Container'
import DataSubscriber from '@components/elements/DataSubscriber'
import Feedback from '@components/elements/Feedback'
import Loader from '@components/elements/Loader'
import Footer from '@components/menu/Footer'
import Header from '@components/menu/Header'
import Sidebar from '@components/menu/Sidebar'
import { ProfileTypes } from '@interfaces/user'
import AddProject from '@screens/addProject'
import { Done } from '@screens/addProject/Done'
import Home from '@screens/home'
import Impressum from '@screens/impressum'
import Login from '@screens/login'
import { PartnerOverview } from '@screens/MatchedOverview/MatchedPartnerOverview'
import PartnerDetails from '@screens/MatchedOverview/PartnerDetails'
import PartnerDashboard from '@screens/dashboards/partnerDashboard'
import UsedMachinerySupplierDashboard from '@screens/dashboards/usedMachinerySupplierDashboard'
import Profile from '@screens/profile'
import { selectCompanyType } from '@selectors/companySelectors'
import { FirebaseAuth } from '@selectors/firebaseAuth'
import { selectCompanyID } from '@selectors/profileSelectors'
import { selectRegisterType } from '@selectors/registerSelectors'
import React from 'react'
import { useSelector } from 'react-redux'
import { isEmpty } from 'react-redux-firebase'
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect
} from 'react-router-dom'
import RenderCompanySpectrum from '@components/elements/RenderCompanySpectrum'
import PartnerSearch from '@screens/partnerSearch'
import { useIsUsedMachinerySupplier } from '@services/customHooks'
import { UsedMachinerySupplierDone } from '@screens/addProject/usedMachinerySupplier/UsedMachinerySupplierDone'
import { UsedMachineryOverview } from '@screens/MatchedOverview/MatchedUsedMachineryOverview'
import UsedMachineryIframe from './components/elements/UsedMachineryIframe'
import UsedMachinerySupplierDetailsForIframe from './screens/usedMachinerySupplierDetailsForIframe/UsedMachinerySupplierDetailsForIframe'

const App = () => {
  const authenticated = useSelector(FirebaseAuth)
  const companyID = useSelector(selectCompanyID)
  const companyType = useSelector(selectCompanyType)
  const registerType = useSelector(selectRegisterType)
  const isUsedMachinerySupplier = useIsUsedMachinerySupplier()

  const renderHome = () => {
    switch (companyType) {
      case ProfileTypes.Searching:
        return <Home />
      case ProfileTypes.Offering:
      case ProfileTypes.WageProducer:
        return <PartnerDashboard />
      case ProfileTypes.UsedMachinerySupplier:
        return <UsedMachinerySupplierDashboard />
      default:
        return <Loader />
    }
  }

  return (
    <Router>
      <Switch>
        <Route path="/showOnlyDetailsForOurProject/:companyID/:projectID" exact>
          <Header hideItems />
          <RenderCompanySpectrum />
        </Route>
        <Route path="/showAllUsedMachineryiFrame" exact>
          <Container style={{ overflowY: 'scroll' }}>
            <UsedMachineryIframe />
          </Container>
        </Route>
        <Route
          path={['/showUsedMachineryPartnerDetails/:partnerID/:projectID']}>
          <Container>
            <UsedMachinerySupplierDetailsForIframe />
          </Container>
        </Route>
        <Route path="/">
          <Container direction={'column'} justifyContent="flex-start">
            <Switch>
              <Route path="/impressum">
                <Header hideItems />

                <Container autoHeight>
                  <Impressum />
                </Container>
                <Footer />
              </Route>

              <Route
                path="/datenschutz"
                component={() => {
                  if (
                    registerType === ProfileTypes.WageProducer ||
                    companyType === ProfileTypes.WageProducer
                  ) {
                    window.location.replace(
                      '/documents/Datenschutz_Lohnhersteller.pdf'
                    )

                    return null
                  }
                  window.location.replace(
                    'https://packpart.eu/datenschutzerklaerung/'
                  )
                  return null
                }}
              />
              <Route path="/">
                {isEmpty(authenticated) ? (
                  <>
                    <Login />
                    <Footer />
                  </>
                ) : (
                  <>
                    <Header />
                    <Sidebar />

                    {companyID && <DataSubscriber />}
                    <Container autoHeight>
                      <Switch>
                        <Route path="/new">
                          <AddProject />
                        </Route>
                        <Route path="/edit">
                          <AddProject editmode />
                        </Route>
                        <Route path="/done/:projectID">
                          {isUsedMachinerySupplier ? (
                            <UsedMachinerySupplierDone />
                          ) : (
                            <Done />
                          )}
                        </Route>
                        <Route path="/profile">
                          <Profile />
                        </Route>
                        <Route
                          path={[
                            '/partner/:partnerID/:projectID',
                            '/partner/:partnerID'
                          ]}
                          component={PartnerDetails}
                        />
                        <Route
                          path="/partnerOverview/:projectID"
                          component={PartnerOverview}
                        />
                        <Route
                          path="/usedMachineryOverview/:projectID"
                          component={UsedMachineryOverview}
                        />
                        <Route
                          path="/partnerSearch"
                          component={PartnerSearch}
                        />
                        <Route
                          path={[
                            '/dashboard/:companyID/:projectID',
                            '/dashboard'
                          ]}>
                          {renderHome()}
                        </Route>
                        <Route path="/">
                          <Redirect to="/dashboard" />
                        </Route>
                      </Switch>
                    </Container>
                    <Footer />
                  </>
                )}
              </Route>
              <Feedback />
            </Switch>
          </Container>
        </Route>
      </Switch>
    </Router>
  )
}

export default App
