import React from 'react'
import Icon from '@material-ui/core/Icon'
import styled from 'styled-components'

interface BellJarProps {
  notificationCount: number
  onNotificationPress?: () => void
}

interface SizeProps {
  iconSize?: number
  counterSize?: number
}

const NotifcationWrapper = styled.div`
  cursor: pointer;
  position: relative;
`

const NotifcationIcon = styled(Icon).withConfig({
  shouldForwardProp: (prop) => !['iconSize'].includes(prop)
})<SizeProps>`
  color: ${(props) => props.theme.surface};
  ${NotifcationWrapper}:hover & {
    transform: scale(1.04);
    color: ${({ theme }) => theme.primary};
  }
  font-size: ${({ iconSize }) => iconSize || 24}px !important;
`

const NotifcationCounterIcon = styled.div<SizeProps>`
  background-color: ${(props) => props.theme.primary};
  width: ${({ counterSize }) => counterSize || 14}px;
  height: ${({ counterSize }) => counterSize || 14}px;
  border-radius: 7px;
  font-weight: 600;
  position: absolute;
  font-size: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: black;
  bottom: -2px;
  left: -2px;
  right: 20px;
  ${NotifcationWrapper}:hover & {
    transform: scale(1.04);
  }
`

const BellJar = ({
  notificationCount,
  onNotificationPress,
  iconSize,
  counterSize
}: BellJarProps & SizeProps) => {
  return (
    <NotifcationWrapper>
      <NotifcationIcon iconSize={iconSize} onClick={onNotificationPress}>
        notifications
      </NotifcationIcon>
      <NotifcationCounterIcon counterSize={counterSize}>
        {notificationCount}
      </NotifcationCounterIcon>
    </NotifcationWrapper>
  )
}

export default BellJar
