import React, { useState } from 'react'
import styled from 'styled-components'
import Icon from '@material-ui/core/Icon'

interface PopoverContentProps {
  content: Array<string>
  handleOnAddItem: (title: string) => void
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  padding: 0 20px 15px;
  width: 60vw;
  overflow-y: auto;
`

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 10px;
  margin-top: 5px;
  padding-left: 5px;
  border-width: 1px;
  border-style: solid;
  border-radius: 7px;
  background: white;
  cursor: pointer;
  :hover {
    box-shadow: 0 0 3px 1px ${({ theme }) => theme.primary};
    transform: scale(1.01);
  }
`

interface AddIconInterface {
  active?: boolean
}
const AddIcon = styled(Icon)<AddIconInterface>`
  font-size: 33px !important;
  border-top-right-radius: 6px;
  border-bottom-right-radius: 6px;
  color: ${({ theme, active }) => (active ? 'black' : theme.border)};
  background: ${({ theme, active }) =>
    active ? theme.primary : theme.surface};
`

const CategoryTitle = styled.h1`
  flex: 1;
  align-self: center;
  font-size: 18px;
  font-weight: 600;
  box-sizing: border-box;
  color: rgba(73, 73, 73, 0.65);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-right: 10px;
`

const Content: Function = ({
  content,
  handleOnAddItem
}: PopoverContentProps) => {
  const [hoveredIconIndex, setHoveredIconIndex] = useState(-1)

  return content.map((title, index) => {
    return (
      <Wrapper
        key={index}
        onClick={() => handleOnAddItem(title)}
        onMouseEnter={() => setHoveredIconIndex(index)}
        onMouseLeave={() => setHoveredIconIndex(-1)}>
        <CategoryTitle>{title}</CategoryTitle>
        <AddIcon active={index === hoveredIconIndex}>addCircle</AddIcon>
      </Wrapper>
    )
  })
}

const PopoverContent = ({ content, handleOnAddItem }: PopoverContentProps) => {
  return (
    <Container>
      <Content content={content} handleOnAddItem={handleOnAddItem} />
    </Container>
  )
}

export default PopoverContent
