/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useFirebase } from 'react-redux-firebase'
import styled from 'styled-components'
import { Icon } from '@material-ui/core'
import { useSelector } from 'react-redux'
import { selectCompanyID, selectLanguage } from '@selectors/profileSelectors'

const Card = styled.div`
  display: flex;
  flex-direction: column;
  height: 300px;
  background: ${(props) => props.theme.surface};
  border-radius: 7px;
  position: relative;
  overflow-y: auto;
`

const CardTitleContainer = styled.div`
  display: flex;
  align-items: center;
  margin-top: 25px;
  padding-left: 25px;
  padding-right: 15px;
  flex-wrap: wrap;
`

const ProjectTitle = styled.p`
  display: flex;
  flex: 1;
  font-size: 20px;
  font-weight: 800;
  color: ${(props) => props.theme.secondary};
`
const IconContainer = styled.div`
  display: flex;
  align-items: center;
`
const LabelIcon = styled(Icon)`
  font-size: 35px !important;
  font-weight: 700;
  margin-right: 20px;
  color: ${({ theme }) => theme.primary};
`
const InfoIcon = styled(Icon)`
  display: flex;
  justify-content: center;
  align-content: content;
  font-size: 30px !important;
  font-weight: 700;
  margin-right: 20px;
  color: ${({ theme }) => theme.secondary};
  cursor: pointer;
  user-select: none;
  :hover {
    transform: scale(1.04);
    background-color: ${({ theme }) => theme.primary};
  }
`

const MachineTitle = styled.div`
  margin: 10px 25px;
  font-size: 18px;
  font-weight: 600;
  color: ${(props) => props.theme.secondary};
  i {
    font-style: italic;
  }
`

const ProjectDescription = styled.div`
  display: flex;
  margin-top: 30px;
  margin-left: 25px;
  padding-right: 120px;
  font-size: 20px;
  font-weight: 600;
  line-height: 27px;
  color: ${(props) => props.theme.secondary};
  height: 100px;
  ::-webkit-scrollbar-track {
    border-radius: 0;
  }
  ::-webkit-scrollbar-thumb {
    border-radius: 0;
  }
`
interface MoreButtonInterface {
  locked?: boolean
}
const MoreButton = styled.div<MoreButtonInterface>`
  display: flex;
  flex-direction: column;
  margin-left: 18px;
  margin-top: 40px;
  height: 45px;
  width: 175px;
  border-radius: 10px;
  justify-content: center;
  background: ${({ theme, locked }) =>
    locked ? theme.disabled : theme.primary};
  color: ${(props) => props.theme.secondary};
  cursor: ${({ locked }) => (locked ? 'not-allower' : 'pointer')};
  text-decoration: none;
  :hover {
    transform: ${({ locked }) => (locked ? 'scale(1)' : 'scale(1.04)')};
  }
`
const MoreButtonTitle = styled.p`
  margin-left: 30px;
  color: ${(props) => props.theme.secondary};
`

const MoreButtonUnderline = styled.div`
  height: 1px;
  width: 66%;
  align-self: center;
  background: ${(props) => props.theme.secondary};
  margin-top: 3px;
`

export const ProjectCards = ({
  projectPath,
  searchValue,
  onClick,
  type,
  className,
  machineName
}: {
  projectPath: string
  searchValue: string
  onClick: () => void
  type: 'new' | 'confirmed'
  className?: string
  machineName?: string
}) => {
  const { t } = useTranslation()

  const firebase = useFirebase()
  const myCompanyID = useSelector(selectCompanyID)
  const currentLanguage = useSelector(selectLanguage)

  const [project, setProject] = useState<any>()
  const [deleted, setDeleted] = useState(false)
  const [, companyID, , projectID] = projectPath?.split('/')
  const getProjectPDF = () => {
    const url = `${process.env.REACT_APP_FIREBASE_FUNCTIONS_BASE}/getProjectPDF?companyID=${companyID}&projectID=${projectID}&language=${currentLanguage}`
    window.open(url, '_blank')
  }
  const getData = async () => {
    try {
      const data = await (
        await firebase.firestore().doc(projectPath).get()
      ).data()
      setProject(data)
      if (!data || Object.keys(data).length === 0) {
        setDeleted(true)
        if (type === 'confirmed') {
          await firebase
            .firestore()
            .doc(`companies/${myCompanyID}`)
            .update({
              unlocked: firebase.firestore().FieldValue.arrayRemove({
                company: companyID,
                project: projectID
              })
            })
        }
      }
    } catch (e) {
      console.log(e)
    }
  }
  useEffect(() => {
    getData()
  }, [projectPath])
  const checkLowerCaseMatch = (val: string) => {
    if (!searchValue || searchValue.length === 0) return true
    if (!val) return false
    return val.toLowerCase().includes(searchValue.toLowerCase().trim())
  }
  if (
    searchValue &&
    !checkLowerCaseMatch(project?.name) &&
    !checkLowerCaseMatch(project?.description)
  )
    return null
  if (deleted) return null
  return (
    <Card key={projectID} onClick={onClick} className={className}>
      <CardTitleContainer>
        <ProjectTitle>{project?.name?.toUpperCase()}</ProjectTitle>
        <IconContainer>
          <InfoIcon onClick={getProjectPDF}>picture_as_pdf</InfoIcon>

          <LabelIcon>
            {type === 'new' ? 'new_releases' : 'check_circle'}
          </LabelIcon>
        </IconContainer>
      </CardTitleContainer>
      {machineName && (
        <MachineTitle>
          {t('home.requestFor')}
          <i>{machineName}</i>
        </MachineTitle>
      )}
      <ProjectDescription>
        {project?.description || t('companyCard.noDescription')}
      </ProjectDescription>
      {project && (
        <MoreButton onClick={onClick} className="pC_MoreButton">
          <MoreButtonTitle>{t('general.projectView')}</MoreButtonTitle>
          <MoreButtonUnderline />
        </MoreButton>
      )}
    </Card>
  )
}
