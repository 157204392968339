import BaseInput from '@components/elements/BaseInput'
import React from 'react'
import styled from 'styled-components'

const Container = styled.div`
  display: flex;
  flex: 1 1 100%;
  position: relative;
`

const StringInput = styled(BaseInput)`
  display: flex;
  flex: 1;
  :focus + label {
    color: #111;
    transform: translate(0, -3rem);
  }
  :focus,
  :not(:placeholder-shown) {
    margin-top: 22px;
  }
  :not(:placeholder-shown) + label {
    color: #111;
    transform: translate(0, -3rem);
  }
  transition: margin-top 0.2s ease-out;
`
const UnitLabel = styled.label`
  color: grey;
  transform: translate(0.25rem, -1.5rem);
  transition: all 0.2s ease-out;
  padding-left: 5px;
  font-size: 13px;
  position: absolute;
  bottom: -12px;
`
const String = ({
  value,
  onChange,
  unit,
  placeholder
}: {
  value: number | undefined
  unit?: string
  onChange: (v: string) => void
  placeholder?: string
}) => {
  const unitOrPlaceholder = unit || placeholder
  return (
    <Container>
      <StringInput
        value={value}
        id={unitOrPlaceholder}
        onChange={(e) => onChange(e.target.value)}
      />
      <UnitLabel htmlFor={unitOrPlaceholder}>{unitOrPlaceholder}</UnitLabel>
    </Container>
  )
}

export default String
