import Button from '@components/elements/Button'
import CustomHelmet from '@components/elements/CustomHelmet'
import { Dashboard } from '@components/elements/Dashboard'
import ProfileForm from '@components/forms/ProfileForm'
import { setFeedback } from '@redux/feedback'
import { selectCompanyType } from '@selectors/companySelectors'
import { selectUID } from '@selectors/firebaseAuth'
import {
  selectCompanyID,
  selectProfileFormValues,
  selectProfileFormValuesLoaded,
  selectAreProfileValuesComplete
} from '@selectors/profileSelectors'
import { selectSidebarIsOpen } from '@selectors/sidebarSelectors'
import { resizeAndUploadImage } from '@services/storage'
import { diff } from 'deep-object-diff'
import pick from 'lodash/pick'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useFirebase } from 'react-redux-firebase'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import { useIsPartner } from '@services/customHooks'
import { selectCompanySpectrum } from '@selectors/userProjectSelectors'

const HeadlineContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-right: 30px;
  box-sizing: border-box;
`

const TitleContainer = styled.div`
  display: flex;
  flex-direction: row;
`
const IncompleteTitle = styled.div`
  margin-left: 20px;
  align-self: center;
  color: orange;
`

const Container = styled.div`
  display: flex;
  height: calc(100% - 50px);
  width: calc(100% - 30px);
  padding-left: 20px;
  margin: 20px 30px 0 0;
  box-sizing: border-box;
  background-color: ${(props) => props.theme.surface};
  border-radius: 10px;
  align-self: center;
`
const Profile = () => {
  const inset = useSelector(selectSidebarIsOpen)
  const firebase = useFirebase()
  const [error, setError] = useState('')
  const [loading, setLoading] = useState(false)
  const profileValues = useSelector(selectProfileFormValues)
  const formValuesLoaded = useSelector(selectProfileFormValuesLoaded)
  const isComplete = useSelector(selectAreProfileValuesComplete) ?? true
  const type = useSelector(selectCompanyType)
  const uid = useSelector(selectUID)
  const companyID = useSelector(selectCompanyID)
  const dispatch = useDispatch()
  const isPartner = useIsPartner()
  const spectrum = useSelector(selectCompanySpectrum)
  const { t } = useTranslation()
  return (
    <Dashboard inset={inset}>
      <CustomHelmet title={t('metadata.profile')} />
      <HeadlineContainer>
        <TitleContainer>
          <h1>{t('profile.title').toUpperCase()}</h1>
          {!isComplete && (
            <IncompleteTitle>{`(${t('general.incomplete')})`}</IncompleteTitle>
          )}
        </TitleContainer>
        {isPartner && spectrum && (
          <Link to={`partner/${companyID}/${spectrum.id}`}>
            <Button small>{t('buttons.showOwnProfile')}</Button>
          </Link>
        )}
      </HeadlineContainer>

      <Container>
        {formValuesLoaded && (
          <ProfileForm
            profileType={type}
            loading={loading}
            error={error}
            initialValues={profileValues}
            onSubmit={async (values) => {
              try {
                setLoading(true)
                let changedValues: any = diff(profileValues as object, values)
                const checkIfFile = (property: string) => {
                  if (values[property]?.name) {
                    changedValues = {
                      ...changedValues,
                      [property]: values[property]
                    }
                  }
                }
                checkIfFile('profileImage')
                checkIfFile('imagePicture')
                checkIfFile('logo')
                if (values.branches !== profileValues?.branches) {
                  changedValues.branches = values.branches
                }
                if (changedValues?.profileImage) {
                  const profileImage = await resizeAndUploadImage({
                    path: `profile-pictures/${uid}`,
                    file: changedValues.profileImage,
                    firebase,
                    maxSize: 1000
                  })
                  changedValues.profileImage = profileImage
                }
                if (changedValues?.logo) {
                  const logo = await resizeAndUploadImage({
                    path: `logos/${uid}`,
                    file: changedValues.logo,
                    firebase,
                    maxSize: 600
                  })
                  changedValues.logo = logo
                }
                if (changedValues?.imagePicture) {
                  const imagePicture = await resizeAndUploadImage({
                    path: `image-pictures/${uid}`,
                    file: changedValues.imagePicture,
                    firebase
                  })
                  changedValues.imagePicture = imagePicture
                }
                const profileUpdates = pick(changedValues, [
                  'company',
                  'firstName',
                  'lastName',
                  'position',
                  'phone',
                  'profileImage'
                ])
                const companyUpdates = pick(changedValues, [
                  'address',
                  'branches',
                  'country',
                  'imagePicture',
                  'logo',
                  'description'
                ]) as { [key: string]: any }
                // company-prop is name-prop in companies
                // check if null or undefined
                if (changedValues.company != null)
                  companyUpdates.name = changedValues.company
                await firebase
                  .firestore()
                  .collection('companies')
                  .doc(companyID)
                  .update(companyUpdates)
                await firebase
                  .firestore()
                  .collection('users')
                  .doc(uid)
                  .update(profileUpdates)
                dispatch(
                  setFeedback({
                    message: t('feedback.success'),
                    type: 'success'
                  })
                )
              } catch (e) {
                console.log(e, e.message)
                if (e.message) setError(e.message)
                dispatch(setFeedback({ message: e.message, type: 'error' }))
              } finally {
                setLoading(false)
              }
            }}
          />
        )}
      </Container>
    </Dashboard>
  )
}

export default Profile
