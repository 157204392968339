import { action, on, payload, reducer } from 'ts-action'

export interface ProfileState {
  showCompleteProfileModal: boolean
}
const initialState: ProfileState = {
  showCompleteProfileModal: true
}

export const setShowCompleteProfileModal = action(
  'setShowCompleteProfileModal',
  payload<boolean>()
)

export const profileReducer = reducer(
  initialState,
  on(setShowCompleteProfileModal, (state, { payload }) => ({
    ...state,
    showCompleteProfileModal: payload
  }))
)
