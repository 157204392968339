/* eslint-disable react-hooks/exhaustive-deps */
import { selectIsPremium } from '@selectors/companySelectors'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { selectMatchForPartnerProject } from '@selectors/projectSelectors'
import { useFirebase } from 'react-redux-firebase'
import { useHistory, useParams } from 'react-router-dom'
import styled from 'styled-components'
import { Icon } from '@material-ui/core'
import { selectCompanyID } from '@selectors/profileSelectors'
import { Company } from '../../interfaces/project'

const Card = styled.div`
  display: flex;
  flex-direction: column;
  height: 290px;
  padding-bottom: 10px;
  background: ${(props) => props.theme.surface};
  border-radius: 7px;
`

const CardTitleContainer = styled.div`
  display: flex;
  align-items: center;
  margin-top: 25px;
  padding-left: 25px;
  padding-right: 15px;
`

const CompanyTitle = styled.p`
  display: flex;
  flex: 1;
  font-size: 20px;
  font-weight: 800;
  color: ${(props) => props.theme.secondary};
`

const InfoIcon = styled.div`
  display: flex;
  height: 20px;
  width: 23px;
  justify-content: center;
  align-content: content;
  background: ${({ theme }) => theme.secondary};
  border-radius: 4px;
  margin: 4px 5px 5px 5px;
  padding-top: 2px;
  font-size: 20px;
  font-weight: 700;
  color: ${({ theme }) => theme.surface};
  cursor: pointer;
  user-select: none;
  :hover {
    transform: scale(1.04);
    color: ${({ theme }) => theme.secondary};
    background-color: ${({ theme }) => theme.primary};
  }
`

/* const MailIcon = styled(Icon)`
  color: ${(props) => props.theme.secondary};
  font-size: 32px !important;
  cursor: pointer;
  padding-top: 2px;
  margin-left: 10px;
  :hover {
    transform: scale(1.04);
    color: ${({ theme }) => theme.primary};
  } 
` */

const CompanyDescription = styled.div`
  display: flex;
  margin-top: 30px;
  margin-left: 25px;
  padding-right: 120px;
  font-size: 20px;
  font-weight: 600;
  line-height: 27px;
  color: ${(props) => props.theme.secondary};
  height: 110px;
  overflow-y: auto;
  ::-webkit-scrollbar-track {
    border-radius: 0;
  }
  ::-webkit-scrollbar-thumb {
    border-radius: 0;
  }
`
interface MoreButtonInterface {
  locked?: boolean
}
const ButtonRow = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 10px 0;
  justify-content: space-between;
  padding-left: 18px;
  padding-right: 18px;
  margin-top: 40px;
`
const MoreButton = styled.div<MoreButtonInterface>`
  display: flex;
  flex-direction: column;
  height: 45px;
  width: 175px;
  border-radius: 10px;
  justify-content: center;
  background: ${({ theme, locked }) =>
    locked ? theme.disabled : theme.primary};
  color: ${(props) => props.theme.secondary};
  cursor: pointer;
  text-decoration: none;
  :hover {
    transform: ${({ locked }) => (locked ? 'scale(1)' : 'scale(1.04)')};
  }
`
const RequestButton = styled.div<MoreButtonInterface>`
  display: flex;
  align-items: center;
  justify-items: center;
  height: 45px;
  width: 175px;
  padding-left: 8px;
  padding-right: 8px;
  border-radius: 11px;
  justify-content: center;
  background: ${({ theme, locked }) =>
    locked ? theme.disabled : theme.primary};
  color: ${(props) => props.theme.secondary};
  cursor: pointer;
  text-decoration: none;
  :hover {
    transform: ${({ locked }) => (locked ? 'scale(1)' : 'scale(1.04)')};
  }
`
const AlreadyRequestedButton = styled.div<MoreButtonInterface>`
  display: flex;
  align-items: center;
  justify-items: center;
  height: 45px;
  width: 175px;
  padding-left: 8px;
  padding-right: 8px;
  border-radius: 11px;
  justify-content: center;
  background: ${({ theme, locked }) =>
    locked ? theme.disabled : theme.primary};
  color: ${(props) => props.theme.secondary};
  text-decoration: none;
`
const CheckIcon = styled(Icon)`
  color: ${(props) => props.theme.secondary};
  font-size: 30px !important;
  margin: 0 10px;
`

const MoreButtonTitle = styled.p`
  margin-left: 30px;
  color: ${(props) => props.theme.secondary};
`

const MoreButtonUnderline = styled.div`
  height: 1px;
  width: 66%;
  align-self: center;
  background: ${(props) => props.theme.secondary};
  margin-top: 3px;
`

export const CompanyCard = ({
  partnerCompanyId,
  searchValue
}: {
  partnerCompanyId: string
  searchValue: string
}) => {
  const { t } = useTranslation()
  const history = useHistory()
  const { projectID } = useParams<{ projectID: string }>()
  const firebase = useFirebase()
  const [company, setCompany] = useState<Company | undefined>()
  const isPremium = useSelector(selectIsPremium)
  const myCompanyID = useSelector(selectCompanyID)
  const match = useSelector(
    selectMatchForPartnerProject(projectID, partnerCompanyId)
  )
  const getData = async () => {
    const data = (await (
      await firebase
        .firestore()
        .collection('companies')
        .doc(partnerCompanyId)
        .get()
    ).data()) as Company | undefined
    setCompany(data)
  }
  useEffect(() => {
    getData()
  }, [partnerCompanyId])
  const checkLowerCaseMatch = (val: string | undefined) => {
    if (!searchValue || searchValue.length === 0) return true
    if (!val) return false
    return val.toLowerCase().includes(searchValue.toLowerCase().trim())
  }

  if (
    searchValue &&
    !checkLowerCaseMatch(company?.name) &&
    !checkLowerCaseMatch(company?.description)
  )
    return null

  const goToPartner = () => {
    if (!isPremium) return
    if (projectID) history.push(`/partner/${partnerCompanyId}/${projectID}`)
    else history.push(`/partner/${partnerCompanyId}`)
  }
  const requestAsPartner = async () => {
    if (match?.requested) return
    try {
      if (match) {
        const doc = firebase
          .firestore()
          .doc(
            `companies/${myCompanyID}/projects/${projectID}/matches/${match?.id}`
          )
        await doc.update({ requested: true })
      }
    } catch (e) {
      console.log(e)
    }
  }
  const companyName = () => {
    if (company?.hiddenProfile) return 'PackPart GmbH'
    if (isPremium) return company?.name?.toUpperCase()
    if (company?.name) {
      const strLength = company.name.length
      const amountToHide = Math.floor(strLength * 0.75)
      return (
        company.name.toUpperCase().substring(0, strLength - amountToHide) +
        '*'.repeat(amountToHide)
      )
    }
    return ''
  }
  const description = () => {
    if (company?.hiddenProfile) return t('companyCard.noDescription')
    if (!isPremium) return t('companyCard.descriptionLocked')
    if (!company?.description) return t('companyCard.noDescription')
    return company?.description
  }
  return (
    <Card key={partnerCompanyId}>
      <CardTitleContainer>
        <CompanyTitle>{companyName()}</CompanyTitle>

        <InfoIcon onClick={goToPartner}>i</InfoIcon>
      </CardTitleContainer>
      <CompanyDescription>{description()}</CompanyDescription>
      <ButtonRow>
        <MoreButton onClick={goToPartner} locked={!isPremium}>
          <MoreButtonTitle>{t('general.profileView')}</MoreButtonTitle>
          <MoreButtonUnderline />
        </MoreButton>
        {match?.requested ? (
          <AlreadyRequestedButton>
            <CheckIcon>check_mark</CheckIcon>
            {t('buttons.partnerAlreadyRequested')}
          </AlreadyRequestedButton>
        ) : (
          <RequestButton onClick={requestAsPartner}>
            {t('buttons.partnerRequest')}
          </RequestButton>
        )}
      </ButtonRow>
    </Card>
  )
}
