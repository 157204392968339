/* eslint-disable react-hooks/exhaustive-deps */
import Button from '@components/elements/Button'
import Dialog from '@components/elements/Dialog'
import {
  resetProject,
  setCurrentStep,
  setShowUnfinishedFields
} from '@redux/project'
import { selectCompanyID } from '@selectors/profileSelectors'
import {
  selectCurrentStep,
  selectFinalProjectData,
  selectIsEveryRequiredItemDone,
  selectIsProjectExisting,
  selectProjectID
} from '@selectors/projectSelectors'
import { useIsPartner, useIsUsedMachinerySupplier } from '@services/customHooks'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useFirebase } from 'react-redux-firebase'
import { useHistory } from 'react-router-dom'
import styled from 'styled-components'
import { Basics } from './Basics'
import { UsedMachinerySupplierBasics } from './usedMachinerySupplier/UsedMachinerySupplierBasics'
import { Details } from './Details'
import { Done } from './Done'
import { UsedMachinerySupplierDone } from './usedMachinerySupplier/UsedMachinerySupplierDone'
import { selectCompanySpectrum } from '../../selectors/userProjectSelectors'

const ProjectContainer = styled.div`
  position: relative;
  width: calc(100% - 40px);
  min-width: 600px;
  margin-top: 50px;
  background-color: ${(props) => props.theme.surface};
  border-radius: 18px;
  box-sizing: border-box;
  padding: 40px 25px;
  @media (max-width: 1200px) {
    width: calc(100% - 40px);
    margin-right: 40px;
    margin-top: 25px;
  }
  @media (max-width: 800px) {
    width: calc(100% - 20px);
    margin-right: 20px;
  }
`

const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  padding: 65px 0 40px 0;
`

const ProjectContent = ({ editmode }: { editmode?: boolean }) => {
  const currentStep = useSelector(selectCurrentStep)
  const isUsedMachinerySupplier = useIsUsedMachinerySupplier()
  const dispatch = useDispatch()
  // PLS NO JUDGE
  if (currentStep === -2) {
    dispatch(resetProject())
    return null
  }
  if (currentStep === 0) {
    if (isUsedMachinerySupplier) {
      return <UsedMachinerySupplierBasics editmode={editmode} />
    } else {
      return <Basics editmode={editmode} />
    }
  }
  if (currentStep === 1) return <Details />
  // This is UNUSED! Look in App.tsx
  if (currentStep === 2) {
    if (isUsedMachinerySupplier) {
      return <UsedMachinerySupplierDone />
    } else {
      return <Done />
    }
  }

  return <div onClick={() => dispatch(setCurrentStep(0))}>error</div>
}

const ProjectForm = ({ editmode }: { editmode?: boolean }) => {
  const firebase = useFirebase()
  const companyID = useSelector(selectCompanyID)
  const isProjectExisting = useSelector(selectIsProjectExisting)
  const project = useSelector(selectFinalProjectData)
  const projectID = useSelector(selectProjectID)
  const allFieldsDone = useSelector(selectIsEveryRequiredItemDone)
  const [buttonError, setButtonError] = useState(false)
  const [resetDialogOpen, setResetDialogOpen] = useState(false)
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const history = useHistory()
  const currentStep = useSelector(selectCurrentStep)
  const isPartner = useIsPartner()
  const isUsedMachinerySupplier = useIsUsedMachinerySupplier()
  const spectrum = useSelector(selectCompanySpectrum)

  useEffect(() => {
    if (isProjectExisting && !editmode) {
      setResetDialogOpen(true)
    }
  }, [])

  useEffect(() => {
    if (buttonError) {
      const buttonErrorTimeout = setTimeout(() => setButtonError(false), 600)
      return () => {
        clearTimeout(buttonErrorTimeout)
      }
    }
  }, [buttonError])

  const handleClose = () => {
    setResetDialogOpen(false)
  }
  const saveProjectAndContinue = async () => {
    if (allFieldsDone) {
      if (
        editmode ||
        (isPartner && !isUsedMachinerySupplier && spectrum && projectID)
      ) {
        firebase
          .firestore()
          .collection('companies')
          .doc(companyID)
          .collection('projects')
          .doc(projectID)
          .set({ ...project, last_matched: Date.now() })
        history.push(isPartner ? '/' : `/done/${projectID}`)
      } else {
        const added = await firebase
          .firestore()
          .collection('companies')
          .doc(companyID)
          .collection('projects')
          .add({ ...project, last_matched: Date.now() })
        history.push(
          isPartner && !isUsedMachinerySupplier ? '/' : `/done/${added.id}`
        )
      }
      if (isPartner && !isUsedMachinerySupplier) dispatch(resetProject())
    } else {
      setButtonError(true)
      dispatch(setShowUnfinishedFields(true))
    }
  }
  return (
    <ProjectContainer>
      <ProjectContent editmode={editmode} />

      <Dialog
        onClose={handleClose}
        open={resetDialogOpen}
        title={t(
          isPartner ? 'project.dialog.titleOffering' : 'project.dialog.title'
        )}
        description={t(
          isPartner
            ? 'project.dialog.descriptionOffering'
            : 'project.dialog.description'
        )}
        buttons={[
          {
            title: t(
              isPartner
                ? 'project.dialog.startNewOffering'
                : 'project.dialog.startNew'
            ),
            onClick: () => {
              dispatch(resetProject())
              handleClose()
            },
            primary: false
          },
          {
            title: t('project.dialog.continue'),
            onClick: handleClose,
            primary: true
          }
        ]}
      />
      {currentStep === 1 && (
        <ButtonContainer>
          <Button onClick={saveProjectAndContinue} error={buttonError}>
            {t('buttons.saveAndContinue')}
          </Button>
        </ButtonContainer>
      )}
    </ProjectContainer>
  )
}
export default ProjectForm
