import React from 'react'
import { useTranslation } from 'react-i18next'

import styled from 'styled-components'
import { Icon } from '@material-ui/core'
interface SearchbarProps {
  searchValue: string
  setSearchValue: (val: string) => void
  className?: string
}

const SearchbarContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 50px;
  min-width: 420px;
  background: ${(props) => props.theme.surface};
  border-radius: 7px;
  padding: 0 20px;
  box-sizing: border-box;
  margin-right: 35px;
`
const SearchTitle = styled.h1`
  color: ${(props) => props.theme.secondary};
  font-weight: 600;
  font-size: 19px;
`

const SearchInputWrapper = styled.div`
  display: flex;
  position: relative;
  height: 100%;
  max-width: 340px;
  width: 100%;
  align-items: center;
  margin-left: 15px;
  margin-right: 50px;
`

const SearchInput = styled.input`
  color: ${(props) => props.theme.secondary};
  display: flex;
  flex: 1;
  height: 30px;
  background: ${(props) => props.theme.surface};
  border-radius: 15px;
  border-color: ${(props) => props.theme.secondary};
  border-width: 2px;
  padding-left: 14px;
  padding-right: 34px;
  box-sizing: border-box;
  :focus {
    outline: none;
  }
`

const SearchIcon = styled(Icon)`
  position: absolute;
  right: 10px;
  color: ${(props) => props.theme.secondary};
  font-size: 23px !important;
  font-weight: 800 !important;
  cursor: pointer;
  :hover {
    transform: scale(1.04);
    color: ${({ theme }) => theme.primary};
  }
`

export const Searchbar = ({
  searchValue,
  setSearchValue,
  className
}: SearchbarProps) => {
  const { t } = useTranslation()

  return (
    <SearchbarContainer className={className}>
      <SearchTitle>{t('general.search')}</SearchTitle>
      <SearchInputWrapper>
        <SearchInput
          type="text"
          value={searchValue}
          onChange={(event) => setSearchValue(event.target.value)}
        />
        <SearchIcon>search</SearchIcon>
      </SearchInputWrapper>
    </SearchbarContainer>
  )
}
