import { CardGrid } from '@components/elements/CompanyCardGrid'
import CustomHelmet from '@components/elements/CustomHelmet'
import { Dashboard } from '@components/elements/Dashboard'
import { Searchbar } from '@components/elements/Searchbar'
import { selectCompaniesArray } from '@selectors/companySelectors'
import { selectCompanyID, selectProfileType } from '@selectors/profileSelectors'
import { selectSidebarIsOpen } from '@selectors/sidebarSelectors'
import { useIsPartner } from '@services/customHooks'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { useFirestoreConnect } from 'react-redux-firebase'
import { Redirect } from 'react-router-dom'
import styled from 'styled-components'
import { CompanyCards } from './Card'
const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  margin: 20px 40px 0 0;
  box-sizing: border-box;
  overflow-y: auto;
  &::-webkit-scrollbar-track {
    border-radius: 0px;
  }
  &::-webkit-scrollbar-thumb {
    border-radius: 0px;
  }
`

// padding instead of margin for correct displaying of newlyAdded-Icon
const StyledCardGrid = styled(CardGrid)`
  margin-top: 0;
  padding-top: 20px;
`

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  box-sizing: border-box;
  padding-right: 30px;
`

const CardsTitle = styled.div`
  font-size: 26px;
  color: white;
  font-weight: 600;
  margin: 80px 0 0 15px;
`

const PartnerSearch = () => {
  const { t } = useTranslation()

  const inset = useSelector(selectSidebarIsOpen)
  const [searchValue, setSearchValue] = useState<string>('')

  const myCompanyID = useSelector(selectCompanyID)
  const companies = useSelector(selectCompaniesArray)
  const isPartner = useIsPartner()
  const myProfileType = useSelector(selectProfileType)
  useFirestoreConnect([
    {
      collection: 'companies',
      where: [['type', 'in', [myProfileType]]]
    }
  ])

  if (!isPartner) return <Redirect to="/" />
  return (
    <Dashboard inset={inset}>
      <CustomHelmet title={t('metadata.home')} />
      <h1>DASHBOARD</h1>
      <Container>
        <ContentContainer>
          <>
            <Searchbar
              searchValue={searchValue}
              setSearchValue={setSearchValue}
            />
            <CardsTitle>{t('partnerOverview.title')}</CardsTitle>

            <StyledCardGrid>
              {companies
                .filter(
                  (c) =>
                    c.id !== myCompanyID &&
                    c.name?.length > 0 &&
                    c.type === myProfileType &&
                    !c.hiddenProfile
                )
                .map((company) => (
                  <CompanyCards
                    key={company.id}
                    searchValue={searchValue}
                    company={company}
                  />
                ))}
            </StyledCardGrid>
          </>
        </ContentContainer>
      </Container>
    </Dashboard>
  )
}

export default PartnerSearch
