import Container from '@components/elements/Container'
import CustomHelmet from '@components/elements/CustomHelmet'
import { MenuItem, Select } from '@material-ui/core'
import { LoginBox } from '@screens/login/LoginBox'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import i18next from 'i18next'

const InnerContainer = styled.div`
  display: flex;
  flex: 1;
  position: relative;
  @media (max-width: 850px) {
    flex-direction: column;
  }
  overflow: auto;
`

const DescriptionBox = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex: 1 1 50%;
  text-align: left;
  align-items: center;
  padding: 40px 80px;
  box-sizing: border-box;
`
const Description = styled.p`
  font-size: 40px;
  font-weight: 800;
  line-height: 60px;
  @media (max-width: 850px) {
    font-size: 25px;
    font-weight: 800;
    line-height: 40px;
  }
`

const DescriptionActive = styled.span`
  color: ${(props) => props.theme.primary};
`
const DescriptionInnerContainer = styled.div``

const DividerLine = styled.div`
  width: 1px;
  background-color: white;
  height: 80px;
  align-self: flex-start;
  margin: 15px 0 0 20px;
`

const WhiteLogo = styled.img`
  object-fit: contain;
  width: 300px;
  margin-left: -29px;
`
const LanguageSelect = styled(Select)`
  position: absolute;
  top: 0;
  right: 8px;
`
const Login = () => {
  const { t } = useTranslation()
  const [language, setLanguage] = useState('de')
  useEffect(() => {
    i18next.changeLanguage(language)
  }, [language])

  return (
    <Container>
      <CustomHelmet title={t('metadata.login')} />
      <InnerContainer>
        <LanguageSelect
          value={language}
          onChange={(event: any) => setLanguage(event.target.value as string)}>
          <MenuItem value={'de'}>{t('languages.de')}</MenuItem>
          <MenuItem value={'en'}>{t('languages.en')}</MenuItem>
        </LanguageSelect>
        <DescriptionBox>
          <DescriptionInnerContainer>
            <Description>
              {t('login.description.1')}{' '}
              <DescriptionActive>{t('login.description.2')}</DescriptionActive>
            </Description>
            <Description>
              {t('login.description.3')}{' '}
              <DescriptionActive>{t('login.description.4')}</DescriptionActive>
            </Description>
            <DividerLine />
            <WhiteLogo src={require('../../assets/logo-white.png').default} />
          </DescriptionInnerContainer>
        </DescriptionBox>
        <LoginBox />
      </InnerContainer>
    </Container>
  )
}

export default Login
