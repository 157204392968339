import { CardGrid } from '@components/elements/CompanyCardGrid'
import CustomHelmet from '@components/elements/CustomHelmet'
import { Dashboard } from '@components/elements/Dashboard'
import { EmptyCard } from '@components/elements/EmptyCard'
import Modal from '@components/elements/Modal'
import Dialog from '@components/elements/Dialog'
import { setShowCompleteProfileModal } from '@redux/profile'
import { ProjectCards } from '@components/elements/ProjectCards'
import { Searchbar } from '@components/elements/Searchbar'
import { selectCompany } from '@selectors/companySelectors'
import {
  selectCompanyID,
  selectAreProfileValuesComplete,
  selectShowCompleteProfileModal
} from '@selectors/profileSelectors'
import { selectMatchesByID } from '@selectors/projectSelectors'
import { selectSidebarIsOpen } from '@selectors/sidebarSelectors'
import { selectCompanySpectrum } from '@selectors/userProjectSelectors'
import { useIsPartner } from '@services/customHooks'
import partition from 'lodash/partition'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector, useDispatch } from 'react-redux'
import { useFirestoreConnect } from 'react-redux-firebase'
import { Redirect, useHistory, useParams } from 'react-router-dom'
import styled from 'styled-components'
import useMedia from 'use-media'
import AddSpectrumCard from './AddSpectrumCard'
import ProjectInfo from './ProjectInfo'
const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  margin: 20px 40px 0 0;
  box-sizing: border-box;
  overflow-y: auto;
  &::-webkit-scrollbar-track {
    border-radius: 0px;
  }
  &::-webkit-scrollbar-thumb {
    border-radius: 0px;
  }
`

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  box-sizing: border-box;
  padding-right: 30px;
`
const NoMatches = styled.div`
  font-size: 24px;
  line-height: 30px;
  font-weight: 500;
`
const CardsTitle = styled.div`
  font-size: 26px;
  color: white;
  font-weight: 600;
  margin: 80px 0 0 15px;
`
const NoCards = styled.div`
  color: white;
  margin-left: 15px;
  margin-top: 20px;
`

const PartnerDashboard = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const history = useHistory()
  const inset = useSelector(selectSidebarIsOpen)
  const [searchValue, setSearchValue] = useState<string>('')
  const companySpectrum = useSelector(selectCompanySpectrum)
  const myCompanyID = useSelector(selectCompanyID)
  const company = useSelector(selectCompany)
  const spectrumID = companySpectrum?.id || 'undefined'
  const matchesUnfiltered = useSelector(selectMatchesByID(companySpectrum?.id))
  const matches = matchesUnfiltered.filter(
    (m) =>
      !company?.rejected?.some(
        (u: { company: string; project: string }) =>
          u.company === m.path.split('/')[1] && u.project === m.id
      )
  )
  const [projectDetails, setProjectDetails] = useState<null | string>(null)
  const { projectID, companyID } = useParams<{
    projectID: string
    companyID: string
  }>()
  const showFirstEmpty = useMedia({ minWidth: '750px' })

  const showSecondEmpty = useMedia({ minWidth: '960px' })
  const isPartner = useIsPartner()
  useFirestoreConnect([
    {
      collection: 'companies',
      doc: myCompanyID,
      storeAs: `project/${spectrumID}/matches`,
      subcollections: [
        {
          collection: 'projects',
          doc: spectrumID,
          subcollections: [{ collection: 'matches' }]
        }
      ]
    }
  ])

  const [confirmedMatches, newMatches] = partition(
    matches,
    (m) =>
      company?.unlocked?.some(
        (u: { company: string; project: string }) =>
          u.company === m.path.split('/')[1] && u.project === m.id
      ) || false
  )

  useEffect(() => {
    if (projectID && companyID && !projectDetails) {
      setProjectDetails(`companies/${companyID}/projects/${projectID}`)
    }
    if (!projectID || !companyID) setProjectDetails(null)
  }, [projectID, companyID, projectDetails])

  const openMatch = (path: string) => {
    const [, cID, , pID] = path.split('/')
    history.push(`/dashboard/${cID}/${pID}`)
  }

  const isProfileCompleted = useSelector(selectAreProfileValuesComplete)
  const showCompleteProfileModal = useSelector(selectShowCompleteProfileModal)
  const closeCompleteProfileModal = () =>
    dispatch(setShowCompleteProfileModal(false))

  useEffect(() => {
    if (isProfileCompleted === true) closeCompleteProfileModal()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isProfileCompleted])

  if (!isPartner) return <Redirect to="/" />
  return (
    <Dashboard inset={inset}>
      <CustomHelmet title={t('metadata.home')} />
      <h1>DASHBOARD</h1>
      <Container>
        <ContentContainer>
          {!companySpectrum ? (
            <>
              <AddSpectrumCard />
              <NoMatches>
                {t('partnerOverview.addRangeOfServicesNote')}
              </NoMatches>
            </>
          ) : (
            <>
              {matches?.length > 0 ? (
                <>
                  <Searchbar
                    searchValue={searchValue}
                    setSearchValue={setSearchValue}
                  />
                  <CardsTitle>
                    {t('partnerOverview.currentRequests')}
                  </CardsTitle>
                  {newMatches.length !== 0 ? (
                    <CardGrid>
                      {newMatches.map((match) => (
                        <ProjectCards
                          searchValue={searchValue}
                          projectPath={match.path}
                          type="new"
                          onClick={() => openMatch(match.path)}
                        />
                      ))}
                      {(newMatches.length % 3 === 1 ||
                        newMatches.length % 3 === 2) &&
                        showFirstEmpty && <EmptyCard />}
                      {newMatches.length % 3 === 1 && showSecondEmpty && (
                        <EmptyCard />
                      )}
                    </CardGrid>
                  ) : (
                    <NoCards>{t('partnerOverview.noCurrentRequests')}</NoCards>
                  )}
                  <CardsTitle>
                    {t('partnerOverview.confirmedRequests')}
                  </CardsTitle>
                  {confirmedMatches.length !== 0 ? (
                    <CardGrid>
                      {confirmedMatches.map((match) => (
                        <ProjectCards
                          searchValue={searchValue}
                          type="confirmed"
                          projectPath={match.path}
                          onClick={() => openMatch(match.path)}
                        />
                      ))}

                      {(confirmedMatches.length % 3 === 1 ||
                        confirmedMatches.length % 3 === 2) &&
                        showFirstEmpty && <EmptyCard />}
                      {confirmedMatches.length % 3 === 1 && showSecondEmpty && (
                        <EmptyCard />
                      )}
                    </CardGrid>
                  ) : (
                    <NoCards>
                      {t('partnerOverview.noConfirmedProjects')}
                    </NoCards>
                  )}
                </>
              ) : (
                <>
                  <NoMatches>
                    {t('partnerOverview.noSuitableProjects')}
                  </NoMatches>
                  <NoMatches>
                    {t('partnerOverview.noSuitableProjectsNote')}
                  </NoMatches>
                </>
              )}
            </>
          )}
        </ContentContainer>
      </Container>

      <Modal
        size="auto"
        height="auto"
        open={!!projectDetails}
        onClose={() => history.push('/dashboard')}>
        <ProjectInfo
          path={projectDetails}
          onClose={() => history.push('/dashboard')}
        />
      </Modal>
      <Dialog
        onClose={() => {}}
        open={showCompleteProfileModal}
        title={t('messages.incompleteProfile')}
        description={t('messages.incompleteProfileDescription')}
        buttons={[
          {
            title: t('general.later'),
            onClick: closeCompleteProfileModal,
            primary: false
          },
          {
            title: t('general.yes'),
            linkTo: '/profile',
            primary: true,
            onClick: closeCompleteProfileModal
          }
        ]}
      />
    </Dashboard>
  )
}

export default PartnerDashboard
