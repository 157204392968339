/* eslint-disable react-hooks/exhaustive-deps */
import { CompanyCard } from '@components/elements/CompanyCard'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { MatchedOverview } from './MatchedOverview'

export const PartnerOverview = () => {
  const { t } = useTranslation()

  return (
    <MatchedOverview
      helmetTitle={t('metadata.partnerOverview')}
      title={t('project.overview.yourProjectPartners')}
      renderCard={(match, searchValue) => {
        const partnerCompanyID =
          match.partnerCompanyID || match.path.split('/')[1]
        return (
          <CompanyCard
            partnerCompanyId={partnerCompanyID}
            key={partnerCompanyID}
            searchValue={searchValue}
          />
        )
      }}
    />
  )
}
