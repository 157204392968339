import React from 'react'
import Button from '@components/elements/Button'
import Modal from '@components/elements/Modal'
import { Link } from 'react-router-dom'
import styled from 'styled-components'

interface DialogInterface {
  onClose: () => void
  title: string
  description?: string
  open: boolean
  buttons: {
    onClick?: () => void
    title: string
    primary: boolean
    linkTo?: string
  }[]
}

const Content = styled.div`
  flex: 1;
  flex-direction: column;
  border-radius: 10px;
  background-color: ${(props) => props.theme.secondary};
  box-sizing: border-box;
  padding: 20px 20px;
  position: relative;
`
const Title = styled.p`
  font-size: 25px;
  font-weight: 600;
  margin-bottom: 20px;
`
const Description = styled.p`
  font-size: 18px;
  margin-bottom: 25px;
`

const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-right: -20px;
  box-sizing: border-box;

  div {
    margin-right: 20px;
    box-sizing: border-box;
  }
`
const Dialog = ({
  onClose,
  open,
  buttons,
  title,
  description
}: DialogInterface) => {
  return (
    <Modal size="auto" height="auto" open={open} onClose={onClose}>
      <Content>
        <Title>{title}</Title>
        {description && <Description>{description}</Description>}
        <ButtonContainer>
          {buttons.map(({ onClick, title, primary, linkTo }) =>
            linkTo ? (
              <Link to={linkTo} key={title}>
                <Button onClick={onClick} primary={primary} small condensed>
                  {title}
                </Button>
              </Link>
            ) : (
              <Button
                onClick={onClick}
                primary={primary}
                small
                condensed
                key={title}>
                {title}
              </Button>
            )
          )}
        </ButtonContainer>
      </Content>
    </Modal>
  )
}

export default Dialog
