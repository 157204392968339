import React from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import Icon from '@material-ui/core/Icon'
import { useIsPartner } from '@services/customHooks'
import DashedContainer from '@components/elements/DashedContainer'

interface SizeProp {
  big?: boolean
  active?: boolean
}

const Container = styled(DashedContainer)<SizeProp>`
  display: flex;
  width: 100%;
  height: 43px;
  background-color: ${(props) => props.theme.card};
  padding: 0 5px 0 12px;
  box-sizing: border-box;
  margin: 10px 0;
  border-radius: 6px;
  cursor: pointer;
  ${({ big }) =>
    big &&
    `
    padding: 23.5px 10px;
    border-radius: 8px;
  `}
`

const TitleContainer = styled.div`
  display: flex;
  align-items: center;
`
const Title = styled.p<SizeProp>`
  box-sizing: border-box;
  font-weight: 600;
  margin-top: 2px;
  color: ${(props) => props.theme.disabled};
  font-size: ${({ big }) => (big ? '18px' : '13px')};
`
const AddIcon = styled(Icon)<SizeProp>`
  color: white;
  border-radius: 100px;
  font-size: 14px !important;
  margin-right: 10px;
  ${({ big }) =>
    big &&
    `
    font-size: 24px !important;
    margin-right: 10px;
  `}
  ${Container}:hover & {
    color: ${({ theme }) => theme.primary};
  }
`
const ConfigurationAddItem = ({
  onClick,
  big
}: {
  onClick: () => void
  big?: boolean
}) => {
  const { t } = useTranslation()
  const isPartner = useIsPartner()

  return (
    <Container onClick={onClick} big={big}>
      <TitleContainer>
        <AddIcon big={big}>add_circle</AddIcon>
        <Title big={big}>
          {t(
            `project.configurator.general.${
              isPartner ? 'addRangeOfServices' : 'addPriority'
            }`
          )}
        </Title>
      </TitleContainer>
    </Container>
  )
}

export default ConfigurationAddItem
