import React from 'react'
import styled from 'styled-components'
import useMedia from 'use-media'

const Column = styled.div`
  display: flex;
  flex-direction: column;
  width: calc(50% - 10px);
  @media (max-width: 999px) {
    width: 100%;
  }
`
const Content = styled.div`
  box-sizing: border-box;
  padding-bottom: 20px;
  display: flex;
  width: 100%;
  justify-content: space-between;
`
const GridSystem = ({
  data,
  optionalCategoryData,
  renderOptionalSubcategory,
  renderSubcategory
}: {
  data: { [key: string]: any }
  optionalCategoryData: any[]
  renderOptionalSubcategory: (data: any[]) => void
  renderSubcategory: (data: string, index: number) => void
}) => {
  const isSmall = useMedia({ maxWidth: '1000px' })
  if (isSmall)
    return (
      <Content>
        <Column>
          {Object.keys(data).map((field, index) =>
            renderSubcategory(field, index)
          )}
          {optionalCategoryData.length > 0 &&
            renderOptionalSubcategory(optionalCategoryData)}
        </Column>{' '}
      </Content>
    )
  return (
    <Content>
      <Column>
        {Object.keys(data).map(
          (field, index) => index % 2 === 0 && renderSubcategory(field, index)
        )}
        {optionalCategoryData.length > 0 &&
          Object.keys(data).length % 2 === 0 &&
          renderOptionalSubcategory(optionalCategoryData)}
      </Column>
      <Column>
        {Object.keys(data).map(
          (field, index) => index % 2 === 1 && renderSubcategory(field, index)
        )}
        {optionalCategoryData.length > 0 &&
          Object.keys(data).length % 2 === 1 &&
          renderOptionalSubcategory(optionalCategoryData)}
      </Column>
    </Content>
  )
}

export default GridSystem
