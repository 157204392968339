import React from 'react'
import BaseInput from '@components/elements/BaseInput'
import styled from 'styled-components'
import moment from 'moment'
const DateInput = styled(BaseInput)`
  display: flex;
  align-items: center;
  flex: 1 1 90%;
`

const Date = ({
  value,
  onChange,
  placeholder
}: {
  value: string | undefined
  onChange: (v: string) => void
  placeholder?: string
}) => {
  return (
    <DateInput
      type="date"
      value={value}
      onChange={(e) => {
        console.log(e.target.value)
        onChange(e.target.value)
      }}
      placeholder={placeholder}
      min={moment().format('yyyy-MM-DD')}
    />
  )
}

export default Date
