import { Form, ErrorMessage } from 'formik'
import styled from 'styled-components'
import { CircularProgress } from '@material-ui/core'

export const StyledForm = styled(Form)<{ $rightPadding?: boolean }>`
  display: flex;
  flex: 1;
  flex-wrap: wrap;
  padding: ${(props) => (props.$rightPadding ? '30px 40px 30px 0' : '30px 0')};
  overflow-y: auto;
  @media (max-width: 850px) {
    flex-direction: column;
    flex-wrap: nowrap;
  }
`
export const LeftForm = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1 1 50%;
  box-sizing: border-box;
  padding-right: 20px;
  @media (max-width: 850px) {
    flex: 1 1 100%;
    padding: 0 10px;
  }
`
export const RightForm = styled(LeftForm)`
  padding-right: 0px;
  padding-left: 20px;
`

export const StyledErrorMessage = styled(ErrorMessage)`
  margin-top: 5px;
  color: red;
`

export const ButtonContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 40px 0 20px;
  box-sizing: border-box;
`
export const ButtonNote = styled.div`
  width: 50%;
  text-align: center;
  margin-top: 10px;
`

export const Progress = styled(CircularProgress)`
  width: 20px !important;
  height: 20px !important;
  color: black;
  margin-right: 10px;
`
