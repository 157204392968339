/* eslint-disable react-hooks/exhaustive-deps */
import { Company } from '@interfaces/company'
import { Icon } from '@material-ui/core'
import { selectCompanyID } from '@selectors/profileSelectors'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { useFirebase } from 'react-redux-firebase'
import { useHistory } from 'react-router-dom'
import styled from 'styled-components'

const Card = styled.div`
  position: relative;
  padding: 20px 0 10px 0;
  display: flex;
  flex-direction: column;
  background: ${(props) => props.theme.surface};
  border-radius: 7px;
`

const CardTitleContainer = styled.div`
  display: flex;
  align-items: center;
  padding: 0 20px;
`

const CompanyTitle = styled.p`
  display: flex;
  flex: 1;
  font-size: 20px;
  font-weight: 800;
  color: ${(props) => props.theme.secondary};
`

const InfoIcon = styled(Icon)`
  display: flex;
  justify-content: center;
  align-content: content;
  font-size: 30px !important;
  font-weight: 700;
  margin-right: 0px;
  color: ${({ theme }) => theme.secondary};
  cursor: pointer;
  user-select: none;
  :hover {
    transform: scale(1.04);
    background-color: ${({ theme }) => theme.primary};
  }
`

const NewlyAddedIcon = styled(Icon)`
  position: absolute;
  top: -10px;
  right: -10px;
  font-size: 35px !important;
  color: ${({ theme }) => theme.primary};
  animation: heartbeat 3s infinite;

  @keyframes heartbeat {
    0% {
      transform: scale(0.9);
    }

    50% {
      transform: scale(1);
    }

    100% {
      transform: scale(0.9);
    }
  }
`
const NewlyAddedIconBackground = styled.div`
  position: absolute;
  top: 0px;
  right: 1px;
  width: 10px;
  height: 18px;
  background: black;
`

const CompanyDescription = styled.div`
  display: flex;
  margin-top: 30px;
  padding: 0 20px;
  font-size: 20px;
  font-weight: 600;
  line-height: 27px;
  color: ${(props) => props.theme.secondary};
  height: 110px;
  overflow-y: auto;
  ::-webkit-scrollbar-track {
    border-radius: 0;
  }
  ::-webkit-scrollbar-thumb {
    border-radius: 0;
  }
`
const MoreButton = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 20px;
  height: 35px;
  flex: 0 1 calc(50% - 30px);
  min-width: 140px;
  border-radius: 10px;
  background: ${({ theme }) => theme.primary};
  color: ${(props) => props.theme.secondary};
  cursor: pointer;
  text-decoration: none;
  padding: 5px 10px;
  align-items: center;
  justify-content: center;
  :hover {
    transform: scale(1.04);
  }
`
const MoreButtonTitle = styled.p`
  color: ${(props) => props.theme.secondary};
  text-align: center;
`

const ButtonRow = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 0 20px;
  flex: 1;
`
export const CompanyCards = ({
  company,
  searchValue
}: {
  company: Company
  searchValue: string
}) => {
  const { t } = useTranslation()
  const history = useHistory()
  const firebase = useFirebase()
  const [projectID, setProjectID] = useState()
  const ownID = useSelector(selectCompanyID)
  const checkLowerCaseMatch = (val: string) => {
    if (!searchValue || searchValue.length === 0) return true
    if (!val) return false
    return val.toLowerCase().includes(searchValue.toLowerCase().trim())
  }
  const twoWeeksAgo = new Date().setDate(new Date().getDate() - 14)
  const showNewlyAdded = company.created_at > twoWeeksAgo

  const getProjectID = async () => {
    const projects = await firebase
      .firestore()
      .collection(`companies/${company.id}/projects`)
      .get()
    let projectID
    projects.forEach((p) => (projectID = p.id))
    if (projectID) setProjectID(projectID)
  }
  const goToPartner = async () => {
    history.push(`/partner/${company.id}/${projectID}`)
  }
  const getProjectPDF = async () => {
    const url = `${process.env.REACT_APP_FIREBASE_FUNCTIONS_BASE}/getProjectPDF?companyID=${company.id}&projectID=${projectID}`
    window.open(url, '_blank')
  }
  const contactPartner = async () => {
    try {
      const requestPartnerForPartner = (firebase as any)
        .app()
        .functions('europe-west3')
        .httpsCallable('requestPartnerForPartner')
      const { data: email } = await requestPartnerForPartner({
        ownID,
        requestedID: company.id
      })
      window.open(`mailto:${email}`, '_self')
    } catch (e) {
      console.log(e)
    }
  }
  useEffect(() => {
    getProjectID()
  }, [])
  if (!projectID) return null
  if (
    searchValue &&
    !checkLowerCaseMatch(company?.name) &&
    !checkLowerCaseMatch(company?.description)
  )
    return null
  return (
    <Card key={company.id}>
      <CardTitleContainer>
        <CompanyTitle>{company?.name?.toUpperCase()}</CompanyTitle>

        <InfoIcon onClick={getProjectPDF}>picture_as_pdf</InfoIcon>

        {/*  <a onClick={onEmailPress}>
          <MailIcon>mail</MailIcon>
        </a> */}
      </CardTitleContainer>
      {showNewlyAdded && <NewlyAddedIconBackground />}
      {showNewlyAdded && <NewlyAddedIcon>new_releases</NewlyAddedIcon>}
      <CompanyDescription>
        {company?.description || t('companyCard.noDescription')}
      </CompanyDescription>
      <ButtonRow>
        <MoreButton onClick={goToPartner}>
          <MoreButtonTitle>
            {t('buttons.acceptRangeOfServices')}
          </MoreButtonTitle>
        </MoreButton>
        <MoreButton onClick={contactPartner}>
          <MoreButtonTitle>{t('buttons.contact')}</MoreButtonTitle>
        </MoreButton>
      </ButtonRow>
    </Card>
  )
}
