import React from 'react'
import { Helmet } from 'react-helmet'

interface HelmetProps {
  title: string
}

const CustomHelmet = ({ title }: HelmetProps) => {
  return (
    <Helmet>
      <meta charSet="utf-8" />
      <title>{title}</title>
    </Helmet>
  )
}

export default CustomHelmet
