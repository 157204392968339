/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react'
import styled from 'styled-components'
import DashedContainer from './DashedContainer'

const Card = styled(DashedContainer)`
  display: flex;
  border-radius: 7px;
  height: 300px;
`

export const EmptyCard = () => {
  return <Card />
}
