/* eslint-disable react-hooks/exhaustive-deps */
import { MachineCard } from '@components/cards/MachineCard'
import Icon from '@material-ui/core/Icon'
import { selectIsPremium } from '@selectors/companySelectors'
import { selectCompanyID } from '@selectors/profileSelectors'
import { Match } from 'packpart-interfaces/lib'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { useFirebase } from 'react-redux-firebase'
import { useHistory, useParams } from 'react-router-dom'
import styled from 'styled-components'
import { MatchedOverview } from './MatchedOverview'

const CardContainer = styled.div`
  height: 280px;
`
const RequestOptionContainer = styled.div`
  display: flex;
  align-items: center;
  color: inherit;
`
const OptionCheckMark = styled(Icon)`
  padding: 0px 5px 5px;
  font-size: 30px !important;
  color: ${({ theme }) => theme.primary};
`

export const UsedMachineryOverview = () => {
  const { t } = useTranslation()
  const firebase = useFirebase()
  const isPremium = useSelector(selectIsPremium)
  const history = useHistory()
  const myCompanyID = useSelector(selectCompanyID)
  const { projectID } = useParams<{ projectID: string }>()

  const goToMachineSpectrum = (match: Match) => {
    if (!isPremium) return
    if (projectID)
      history.push(
        `/partner/${match.partnerCompanyID}/${match.partnerProjectID}`,
        {
          showMachineDetails: true,
          clientProjectId: projectID
        }
      )
  }
  const requestMachine = async (match: Match) => {
    if (match.requested) return
    try {
      if (match) {
        const doc = firebase
          .firestore()
          .doc(
            `companies/${myCompanyID}/projects/${projectID}/matches/${match.id}`
          )
        await doc.update({ requested: true })
      }
    } catch (e) {
      console.log(e)
    }
  }

  const requestOption = (match: Match) => {
    return match.requested
      ? {
          title: (
            <RequestOptionContainer>
              {t('partnerOverview.partnerRequested')}
              <OptionCheckMark>done</OptionCheckMark>
            </RequestOptionContainer>
          ),
          onClick: () => null,
          disabled: true
        }
      : {
          title: t('project.overview.requestMachine'),
          onClick: () => requestMachine(match)
        }
  }

  const [machineCardOptionsId, setMachineCardOptionsId] = useState('')

  return (
    <MatchedOverview
      helmetTitle={t('metadata.machineOverview')}
      title={t('project.overview.yourUsedMachinery')}
      renderCard={(match, searchValue) => (
        <CardContainer>
          <MachineCard
            projectMatchPath={match.path}
            optionsIconName="info"
            options={[
              {
                title: t('project.overview.showServiceSpectrum'),
                onClick: () => goToMachineSpectrum(match)
              },
              requestOption(match)
            ]}
            machineCardOptionsId={machineCardOptionsId}
            setMachineCardOptionsId={setMachineCardOptionsId}
            searchValue={searchValue}
          />
        </CardContainer>
      )}
    />
  )
}
