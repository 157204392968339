import React from 'react'
import styled from 'styled-components'
import TextareaAutosize from '@material-ui/core/TextareaAutosize'

const TextareaInput = styled(TextareaAutosize)`
  display: inline-block;
  box-sizing: border-box;
  margin: 10px 0 5px 0;
  padding: 5px 5px 5px 10px;
  width: 100%;
  color: black;
  border: none;
`
const LongString = ({
  value,
  onChange,
  unit,
  placeholder
}: {
  value: string | undefined
  onChange: (v: string) => void
  unit?: string
  placeholder?: string
}) => {
  const unitOrPlaceholder = unit || placeholder

  return (
    <TextareaInput
      placeholder={unitOrPlaceholder}
      value={value}
      onChange={(e) => {
        onChange(e.target.value)
      }}
    />
  )
}

export default LongString
