/* eslint-disable react-hooks/exhaustive-deps */
import CompanySpectrum from '@components/elements/CompanySpectrum'
import CustomHelmet from '@components/elements/CustomHelmet'
import { Dashboard } from '@components/elements/Dashboard'
import { Company, User } from '@interfaces/user'
import CircularProgress from '@material-ui/core/CircularProgress'
import { ImageGallery } from '@screens/addProject/usedMachinerySupplier/ImageGallery'
import { PriceAndWarranty } from '@screens/addProject/usedMachinerySupplier/PriceAndWarranty'
import { selectSidebarIsOpen } from '@selectors/sidebarSelectors'
import { getUrlFirebase } from '@services/helpers'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { useFirebase } from 'react-redux-firebase'
import { useParams } from 'react-router-dom'
import styled from 'styled-components'
import CategoryPreview from '../MatchedOverview/elements/CategoriesPreview'
import Button from '@components/elements/Button'

const RootContainer = styled.div`
  display: flex;
  flex: 1;
  height: 200px;
  padding-right: 40px;
  overflow: auto;
`
const Spinner = styled(CircularProgress)`
  align-self: center;
  color: ${(props) => props.theme.primary};
`

const CompanyTitle = styled.h1`
  font-weight: 800;
`
const TitleLineContainer = styled.div`
  display: flex;
  justify-content: space-between;
  padding-right: 40px;
  align-items: center;
  margin-bottom: 30px;
`
const LeftContainer = styled.div`
  display: flex;
  height: 100%;
  min-width: 270px;
  flex-direction: column;
  flex: 2.25;
  border-radius: 10px;
  box-sizing: border-box;
  background: ${(props) => props.theme.surface};
  overflow-y: auto;
`
const LeftImage = styled.img`
  display: flex;
  box-sizing: border-box;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  object-fit: contain;
  width: 100%;
  max-height: 65%;
`
const LeftContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  padding: 30px;
`
const LeftTitle = styled.div`
  display: flex;
  font-size: 21px;
  font-weight: bold;
  color: #2d2d2d;
`

const SmallCategoryContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(190px, 1fr));
  grid-gap: 5px;
  margin-top: 20px;
  margin-bottom: 20px;
`

const LeftDescription = styled.div`
  display: block;
  font-size: 16px;
  line-height: 21px;
  font-weight: 600;
  white-space: pre-line;
  color: #3d3d3d;
`

const StyledPriceAndWarranty = styled(PriceAndWarranty)`
  padding-bottom: 20px;
`

const MachineDescription = styled(LeftDescription)`
  display: flex;
  height: 115px;
  overflow-y: auto;
`

const MiddleContainer = styled.div`
  display: flex;
  flex: 2.25;
  min-width: 270px;
  box-sizing: border-box;
  margin-right: 15px;
  margin-left: 15px;
  padding-right: 5px;
  overflow-y: scroll;
  ::-webkit-scrollbar-track {
    border-radius: 10px;
  }
  ::-webkit-scrollbar-thumb {
    border-radius: 10px;
  }
`

const RightContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  padding-top: 20px;
  border-radius: 10px;
  box-sizing: border-box;
  background: ${(props) => props.theme.surface};
  overflow-y: auto;
  min-width: 230px;
`

const ContactTitle = styled.div`
  display: flex;
  font-size: 20px;
  font-weight: bold;
  color: #2d2d2d;
  margin: 0 15px;
  white-space: nowrap;
  align-self: center;
`

const ContactImage = styled.img`
  height: 180px;
  width: 180px;
  object-fit: cover;
  border-radius: 50%;
  margin-top: 25px;
  align-self: center;
`

const ContactRowContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 20px 15px 10px 15px;
  justify-content: space-between;
`
const ContactName = styled.h1`
  display: flex;
  flex: 1;
  font-size: 20px;
  font-weight: bold;
  color: #2d2d2d;
`

const ContactDescription = styled.div`
  display: block;
  font-size: 16px;
  line-height: 21px;
  font-weight: 600;
  color: #3d3d3d;
  white-space: pre-line;
  margin: 0 25px 15px 15px;
`

const ContactButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 10px;
`
const ContactButton = styled(Button)``

const UsedMachinerySupplierDetailsForIframe = () => {
  const { partnerID, projectID: partnerProjectId } = useParams<{
    partnerID: string
    projectID: string
  }>()

  const [supplierDetails, setSupplierDetails] = useState<{
    partner?: Company
    partnerOwner?: User
  }>({})

  const partner = supplierDetails?.partner
  const partnerOwner = supplierDetails?.partnerOwner
  const { imagePicture } = partner || {}
  const inset = useSelector(selectSidebarIsOpen)

  const { profileImage } = partnerOwner || {}
  const { t } = useTranslation()
  const firebase = useFirebase()
  const [profilePicUrl, setProfilePicUrl] = useState<string | null>(null)
  const [corpPicUrl, setCorpPicUrl] = useState<string | boolean | null>(null)
  const [spectrum, setSpectrum] = useState<any>()

  useEffect(() => {
    const fetch = async () => {
      if (!corpPicUrl && imagePicture && partner) {
        const urlOrFalse = await getUrlFirebase(firebase, partner.imagePicture)
        setCorpPicUrl(urlOrFalse)
      }
      if (!profilePicUrl && profileImage && partnerOwner) {
        const urlOrFalse = await getUrlFirebase(
          firebase,
          partnerOwner.profileImage as string
        )
        setProfilePicUrl(urlOrFalse)
      }
    }
    fetch()
  }, [partner, partnerOwner])

  const getUsedMachinerySupplierDetails = (firebase as any)
    .app()
    .functions('europe-west3')
    .httpsCallable('getUsedMachinerySupplierDetails')

  useEffect(() => {
    const fetch = async () => {
      // load machine project
      const { partner, partnerOwner } = (
        await getUsedMachinerySupplierDetails({
          companyID: partnerID
        })
      ).data
      const doc = await firebase
        .firestore()
        .doc(`companies/${partnerID}/projects/${partnerProjectId}`)
      const machineProject = (await doc.get()).data()
      setSpectrum(machineProject || null)
      setSupplierDetails({ partner, partnerOwner })
    }
    if (partnerID) fetch()
  }, [partnerID])

  const renderImage = (
    urlData: string | undefined,
    urlFirebase: string | boolean | null,
    isContactImage?: boolean
  ) => {
    if (urlData && urlFirebase !== false)
      return urlFirebase ? (
        isContactImage ? (
          <ContactImage src={urlFirebase as string} />
        ) : (
          <LeftImage src={urlFirebase as string} />
        )
      ) : (
        <Spinner style={{ marginTop: 20 }} />
      )
    return null
  }

  const title = spectrum?.name

  const contactUsURL = 'https://packpart.eu/#kontakt'

  if (!partner || !partnerOwner || !spectrum) return <Spinner />
  return (
    <Dashboard inset={inset}>
      <CustomHelmet
        title={t('metadata.partnerDetails', {
          partnerName: title
        })}
      />

      <TitleLineContainer>
        <CompanyTitle>{title} </CompanyTitle>
      </TitleLineContainer>
      <RootContainer>
        <LeftContainer>
          <>
            <ImageGallery
              fixedHeight="250px"
              imageItems={spectrum.descriptionImages}
              setImageItems={() => null}
              showButtons={false}
              showImageName={false}
            />
            <LeftContentContainer>
              <LeftTitle>
                {t('project.companyPresentation.rangeOfServices')}
              </LeftTitle>
              <SmallCategoryContainer>
                {spectrum?.categories && (
                  <CategoryPreview categories={spectrum?.categories} />
                )}
              </SmallCategoryContainer>
              <StyledPriceAndWarranty
                price={spectrum.price}
                setPrice={() => null}
                warranty={spectrum.warranty}
                setWarranty={() => null}
                disabled={true}
              />
              <MachineDescription>{spectrum.description}</MachineDescription>
            </LeftContentContainer>
          </>
        </LeftContainer>

        <MiddleContainer>
          {spectrum ? (
            <CompanySpectrum spectrum={spectrum} />
          ) : (
            <>{t('partnerOverview.noRangeOfServices')}</>
          )}
        </MiddleContainer>

        <RightContainer>
          <ContactTitle>
            {t('project.companyPresentation.contactTitle')}
          </ContactTitle>
          {renderImage(profileImage, profilePicUrl, true)}
          <ContactRowContainer>
            <ContactName>{`${partnerOwner?.firstName} ${partnerOwner?.lastName}`}</ContactName>
          </ContactRowContainer>
          <ContactDescription>{partner?.address}</ContactDescription>
          <ContactButtonContainer>
            <ContactButton
              variant="contained"
              condensed
              onClick={() => window.open(contactUsURL, '_blank')}>
              {t('buttons.requestNow')}
            </ContactButton>
          </ContactButtonContainer>
        </RightContainer>
      </RootContainer>
    </Dashboard>
  )
}

export default UsedMachinerySupplierDetailsForIframe
