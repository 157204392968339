import React from 'react'
import BaseInput from '@components/elements/BaseInput'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
const Label = styled.p`
  color: black;
  margin-left: 5px;
`
const Container = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
  padding-left: 5px;
  margin-left: -5px;
`

const GridContainer = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
`
const RadioButtons = ({
  options,
  value,
  onChange,
  placeholder,
  className
}: {
  options: string[]
  value: string
  onChange: (v: string) => void
  placeholder?: string
  className?: string
}) => {
  const { t } = useTranslation()
  return (
    <GridContainer className={className}>
      {options.map((option) => (
        <Container onClick={() => onChange(option)}>
          <BaseInput
            type="radio"
            onChange={(e) => {
              onChange(e.target.value)
            }}
            checked={value === option}
            value={option}
          />
          <Label>{t(`${placeholder}.${option}`)}</Label>
        </Container>
      ))}
    </GridContainer>
  )
}

export default RadioButtons
