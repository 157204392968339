import i18next from 'i18next'
import moment from 'moment'
import { initReactI18next } from 'react-i18next'
import de from './locales/de-DE.json'
import en from './locales/en-US.json'
import 'moment/locale/de'

const namespace = 'translations'
const resources = {
  de: { [namespace]: de },
  en: { [namespace]: en }
}
moment.locale('de')

i18next.use(initReactI18next).init({
  fallbackLng: 'de',
  defaultNS: namespace,
  load: 'all',
  lowerCaseLng: true,
  interpolation: {
    escapeValue: false
  },
  debug: false,
  saveMissing: false,
  resources
})
export default i18next
