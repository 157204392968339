import {
  Categories,
  Project,
  defaultProjectValues,
  AvailableCategoriesWithDataArray,
  ProjectTypes,
  CustomGalleryItem,
  ProfileTypes
} from '@interfaces/project'
import clone from 'lodash/clone'
import cloneDeep from 'lodash/cloneDeep'
import merge from 'lodash/merge'
import get from 'lodash/get'
import set from 'lodash/set'
import omit from 'lodash/omit'
import { action, empty, on, payload, reducer } from 'ts-action'
import {
  AddProjectDataPriorityInterface,
  NesterProjectPayload,
  RemoveProjectDataPriorityInterface,
  AddOptionalCategory,
  RemoveOptionalCategories,
  RemoveOptionalCategory,
  PrefillDataForFieldInterface
} from './payloadInterfaces'
import { getDefaultProjectValues } from '@services/helpers'
import { Branches } from '@interfaces/branches'
import pick from 'lodash/pick'
// @ts-ignore
import eachDeep from 'deepdash/eachDeep' // or separate standalone methods
import { isPartner } from '@services/customHooks'

/* Redux */
export const resetProject = action('resetProject', empty())

export const setProject = action('setProject', payload<Project>())
export const setProjectTypeAndValues = action(
  'setProjectTypeAndValues',
  payload<ProjectTypes>()
)
export const setCurrentStep = action('setCurrentStep', payload<number>())
export const setShowUnfinishedFields = action(
  'setShowUnfinishedFields',
  payload<boolean>()
)
export const increaseCurrentStep = action('increaseCurrentStep', empty())
export const setName = action('setName', payload<string>())
export const setDescription = action('setDescription', payload<string>())
export const setProjectID = action('setProjectID', payload<string>())

export const setProjectType = action('setProjectType', payload<ProjectTypes>())
export const setProjectBranches = action(
  'setProjectBranches',
  payload<Branches[]>()
)
export const setProjectDescriptionImages = action(
  'setProjectDescriptionImages',
  payload<CustomGalleryItem[]>()
)
export const setProjectPrice = action('setProjectPrice', payload<string>())
export const setProjectWarranty = action(
  'setProjectWarranty',
  payload<boolean>()
)

export const setOpenCategory = action('setOpenCategory', payload<Categories>())
export const toggleCategory = action('toggleCategory', payload<Categories>())
export const addOptionalCategory = action(
  'addOptionalCategory',
  payload<AddOptionalCategory>()
)
export const removeOptionalCategory = action(
  'removeOptionalCategory',
  payload<RemoveOptionalCategory>()
)
export const removeOptionalCategories = action(
  'removeOptionalCategories',
  payload<RemoveOptionalCategories>()
)
export const setNestedProjectData = action(
  'setNestedProjectData',
  payload<NesterProjectPayload>()
)
export const addProjectDataPriority = action(
  'addProjectDataPriority',
  payload<AddProjectDataPriorityInterface>()
)
export const removeProjectDataPriority = action(
  'removeProjectDataPriority',
  payload<RemoveProjectDataPriorityInterface>()
)
export const prefillDataForField = action(
  'prefillDataForField',
  payload<PrefillDataForFieldInterface>()
)
export const projectReducer = reducer(
  { ...defaultProjectValues, currentStep: -2 },
  on(resetProject, (state) => {
    const newType = isPartner(state?.type as ProfileTypes)
      ? state?.type
      : ProfileTypes.Searching
    const initial = getDefaultProjectValues(newType, state.branches)
    return {
      ...cloneDeep(initial),
      type: newType,
      displayedType: 'searching',
      branches: state?.branches
    }
  }),
  on(setProject, (state, { payload }) => {
    /* For now merge the project with the defaultValues because it changes so much */
    const customProject = pick(payload, [
      'categories',
      'companyID',
      'currentStep',
      'description',
      'descriptionImages',
      'price',
      'warranty',
      'isSold',
      'id',
      'last_matched',
      'name',
      'openCategory',
      'type',
      'fill.data',
      'label.data',
      'packaging.data',
      'seal.data',
      'sort.sortAndFeed.data',
      'sort.stockpile.data'
    ])
    const omittedDefault = omit(
      getDefaultProjectValues(payload?.type || state.type, state.branches),
      ['name', 'description', 'categories', 'id']
    )

    const merged = merge(customProject, omittedDefault)
    /* Move optional fields back to fields */
    const datafields: string[] = []
    eachDeep(merged, (_value: any, key: any, _parent: any, ctx: any) => {
      if (ctx?.parent?.key === 'data') datafields.push(key as string)
    })
    let cloned = clone(merged)
    const multiPrioIsFalseNow: any[] = []
    const multiPrioIsTrueNow: any[] = []
    if (datafields.length > 0) {
      eachDeep(merged, (value: any, key: any, _parent: any, ctx: any) => {
        if (datafields.includes(key) && ctx?.parent?.key === 'optionalFields') {
          const optionalField = get(cloned, ctx.path)
          set(
            cloned,
            ctx.path.replace('optionalFields', 'fields'),
            optionalField
          )
          cloned = omit(cloned, ctx.path) as Project
        }
        if (key === 'multiplePriorities' && value === true) {
          if (datafields.includes(ctx.parent.key)) {
            multiPrioIsTrueNow.push(ctx.parent.key)
          }
        }
        if (key === 'multiplePriorities' && !value) {
          if (datafields.includes(ctx.parent.key)) {
            multiPrioIsFalseNow.push(ctx.parent.key)
          }
        }
      })
    }
    /* If MultiPrio changed convert data from or to array */
    eachDeep(cloned, (value: any, key: any, _parent: any, ctx: any) => {
      if (
        ctx?.parent?.key === 'data' &&
        multiPrioIsFalseNow.includes(key) &&
        Array.isArray(value)
      ) {
        const currentValue = get(cloned, ctx.path)
        set(cloned, ctx.path, currentValue[0])
      }
      if (
        ctx?.parent?.key === 'data' &&
        multiPrioIsTrueNow.includes(key) &&
        !Array.isArray(value)
      ) {
        const currentValue = get(cloned, ctx.path)
        set(cloned, ctx.path, [currentValue])
      }
    })
    // check this in case of bugs
    // Do not forget spread merge goes only one level deep
    // maybe merge with customProject props which are not in cloned instead of merging with state
    return {
      ...state,
      ...cloned
    }
  }),
  on(setProjectTypeAndValues, (state, { payload }) => {
    const initial = getDefaultProjectValues(payload, state.branches)
    return {
      ...cloneDeep(initial),
      type: payload,
      displayedType: payload,
      branches: state?.branches,
      categories: state?.categories,
      name: state?.name,
      description: state?.description
    }
  }),
  on(setCurrentStep, (state, { payload }) => ({
    ...state,
    currentStep: payload
  })),
  on(setShowUnfinishedFields, (state, { payload }) => ({
    ...state,
    showUnfinishedFields: payload
  })),
  on(setName, (state, { payload }) => ({
    ...state,
    name: payload
  })),
  on(setDescription, (state, { payload }) => ({
    ...state,
    description: payload
  })),
  on(setProjectID, (state, { payload }) => ({
    ...state,
    id: payload
  })),
  on(setProjectType, (state, { payload }) => ({
    ...state,
    type: payload
  })),
  on(setProjectBranches, (state, { payload }) => ({
    ...state,
    branches: payload
  })),
  on(setProjectDescriptionImages, (state, { payload }) => ({
    ...state,
    descriptionImages: payload
  })),
  on(setProjectPrice, (state, { payload }) => ({
    ...state,
    price: payload
  })),
  on(setProjectWarranty, (state, { payload }) => ({
    ...state,
    warranty: payload
  })),
  on(setOpenCategory, (state, { payload }) => ({
    ...state,
    openCategory: payload
  })),
  on(toggleCategory, (state, { payload }) => {
    const cloned = clone(state.categories)
    const index = cloned.findIndex(
      (clonedCategory: string) => clonedCategory === payload
    )
    if (index === -1) cloned.push(payload)
    else cloned.splice(index, 1)
    return {
      ...state,
      categories: cloned
    }
  }),
  on(increaseCurrentStep, (state) => ({
    ...state,
    currentStep: state.currentStep + 1
  })),
  on(setNestedProjectData, (state, { payload }) => {
    const cloned = cloneDeep(state)
    const { multiplePriorities, position, field, index, value, name } = payload

    const path = multiplePriorities
      ? `${position}.data.${field}[${index}].${name}`
      : `${position}.data.${field}.${name}`
    set(cloned, path, value)
    return cloned
  }),
  on(addProjectDataPriority, (state, { payload }) => {
    const { position, field } = payload
    const cloned = cloneDeep(state)
    const path = `${position}.data.${field}`
    const dataArray = get(cloned, path)
    const newIndex: number = dataArray?.length || 0
    set(cloned, `${path}[${newIndex}]`, {})

    return cloned
  }),
  on(removeProjectDataPriority, (state, { payload }) => {
    const { position, field, index } = payload
    const cloned = clone(state)
    const path = `${position}.data.${field}`
    let dataArray = get(cloned, path)
    dataArray.splice(index, 1)
    if (dataArray.length === 0) dataArray = null
    set(cloned, path, dataArray)

    return cloned
  }),
  on(addOptionalCategory, (state, { payload }) => {
    const { field } = payload
    let cloned = cloneDeep(state)
    AvailableCategoriesWithDataArray.forEach((cat) => {
      const found = get(cloned, `${cat}.optionalFields.${field}`)
      if (found) {
        cloned = omit(cloned, `${cat}.optionalFields.${field}`) as Project
        set(cloned, `${cat}.fields.${field}`, found)
      }
    })
    return cloned
  }),
  on(removeOptionalCategory, (state, { payload }) => {
    const { position, field } = payload
    const cloned = clone(state)
    const oldPath = `${position}.fields.${field}`
    const oldData = `${position}.data.${field}`
    const newPath = `${position}.optionalFields.${field}`
    const optionalField = get(cloned, oldPath)
    set(cloned, newPath, optionalField)
    const omitted = omit(cloned, [oldPath, oldData]) as Project
    return omitted
  }),
  on(removeOptionalCategories, (state, { payload }) => {
    const { position } = payload
    const cloned = clone(state)
    set(cloned, `${position}.optionalFields`, {})
    return cloned
  }),
  on(prefillDataForField, (state, { payload }) => {
    const prefillDataFields = [
      'sort.sortAndFeed.data',
      'sort.stockpile.data',
      'fill.data',
      'seal.data',
      'label.data',
      'packaging.data'
    ]
    const { position, field } = payload
    const cloned = cloneDeep(state)
    const path = `${position}.data.${field}`
    const dataToPrefillPath = prefillDataFields.find((prefillPosition) => {
      const data = get(cloned, `${prefillPosition}.${field}`)
      if (data) return true
      return false
    })

    if (dataToPrefillPath) {
      const dataToPrefill = get(cloned, `${dataToPrefillPath}.${field}`)
      set(cloned, `${path}`, cloneDeep(dataToPrefill))
      return cloned
    }
    return state
  })
)
