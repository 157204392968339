/* eslint-disable react-hooks/exhaustive-deps */
import { selectProfileImage } from '@selectors/profileSelectors'
import React, { memo, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useFirebase } from 'react-redux-firebase'
import styled from 'styled-components'

interface ImageInterface {
  size: string
}
const Image = styled.img<ImageInterface>`
  height: ${(props) => props.size};
  width: ${(props) => props.size};
  border-radius: calc(${(props) => props.size} / 2);
  object-fit: cover;
`
const ProfilePicture = memo(({ size = '40px' }: { size?: string }) => {
  const firebase = useFirebase()
  const path = useSelector(selectProfileImage)
  const [imagePath, setImagePath] = useState('')

  useEffect(() => {
    const getImage = async () => {
      const ref = await firebase.storage().refFromURL(path)
      const url = await ref.getDownloadURL()
      setImagePath(url)
    }
    if (path) getImage()
  }, [path])
  if (!path) return null
  return (
    <Image
      size={size}
      src={imagePath || require('../../assets/image-placeholder.png').default}
    />
  )
})

export default ProfilePicture
