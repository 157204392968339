import CustomHelmet from '@components/elements/CustomHelmet'
import { Dashboard } from '@components/elements/Dashboard'
import Dialog from '@components/elements/Dialog'
import { Searchbar } from '@components/elements/Searchbar'
import { defaultProjectValues } from '@interfaces/project'
import { setShowCompleteProfileModal } from '@redux/profile'
import { setProject } from '@redux/project'
import {
  selectAreProfileValuesComplete,
  selectCompanyID,
  selectShowCompleteProfileModal
} from '@selectors/profileSelectors'
import { selectSidebarIsOpen } from '@selectors/sidebarSelectors'
import { selectUserProjects } from '@selectors/userProjectSelectors'
import { useIsPartner } from '@services/customHooks'
import firebase from 'firebase'
import { Project } from 'packpart-interfaces/lib'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useFirestoreConnect } from 'react-redux-firebase'
import { Redirect, useHistory, useParams } from 'react-router-dom'
import styled from 'styled-components'
import { RequestsAccordion } from './accordions/RequestsAccordion'
import { MyMachinesAccordion } from './accordions/MyMachinesAccordion'
import { SoldMachinesAccordion } from './accordions/SoldMachinesAccordion'
import Modal from '@components/elements/Modal'
import ProjectInfo from '../partnerDashboard/ProjectInfo'
import partition from 'lodash/partition'

const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  margin: 20px 40px 0 0;
  box-sizing: border-box;
  overflow-y: auto;
  &::-webkit-scrollbar-track {
    border-radius: 0px;
  }
  &::-webkit-scrollbar-thumb {
    border-radius: 0px;
  }
`

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  box-sizing: border-box;
  padding-right: 30px;
`

const StyledSearchbar = styled(Searchbar)`
  margin-bottom: 10px;
  margin-right: 0px;
`

const UsedMachinerySupplierDashboard = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const history = useHistory()
  const companyID = useSelector(selectCompanyID)
  const inset = useSelector(selectSidebarIsOpen)
  const isPartner = useIsPartner()
  const isProfileCompleted = useSelector(selectAreProfileValuesComplete)
  const showCompleteProfileModal = useSelector(selectShowCompleteProfileModal)
  const closeCompleteProfileModal = () =>
    dispatch(setShowCompleteProfileModal(false))

  // load matches from fb & add to redux
  useFirestoreConnect([
    {
      collectionGroup: 'matches',
      where: [
        ['partnerCompanyID', '==', companyID],
        ['side', '==', 'partner']
      ],
      storeAs: 'matchesGroup'
    }
  ])

  useEffect(() => {
    if (isProfileCompleted === true) closeCompleteProfileModal()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isProfileCompleted])

  const machineProjects = useSelector(selectUserProjects)

  const [openMachineProjects, soldMachineProjects] = partition(
    machineProjects,
    (project: Project) => !project?.isSold
  )

  const [searchValue, setSearchValue] = useState<string>('')
  const [idsOfHiddenProjects, setIdsOfHiddenProjects] = useState([''])

  useEffect(() => {
    if (!searchValue) setIdsOfHiddenProjects([''])
    else {
      const projectsToHide = machineProjects.filter((p) => {
        const name = p.name.toLocaleLowerCase().trim()
        const search = searchValue.toLocaleLowerCase().trim()
        return !name.includes(search)
      })
      setIdsOfHiddenProjects(projectsToHide.map((p) => `${p.id}`))
    }
  }, [machineProjects, searchValue])

  const goToServiceSpectrum = (project: Project) => {
    // Do not forget spread merge goes only one level deep
    const merged = {
      ...defaultProjectValues,
      ...project
    }
    dispatch(setProject(merged))
    history.push('/edit')
  }

  const goToDescriptionImages = (project: Project) => {
    // Do not forget spread merge goes only one level deep
    const merged = {
      ...defaultProjectValues,
      ...project
    }
    dispatch(setProject(merged))
    history.push(`/done/${project.id}`)
  }

  const [deleteDialog, setDeleteDialog] = useState<null | string>()
  const deleteProject = () => {
    setDeleteDialog(null)
    firebase
      .firestore()
      .collection('companies')
      .doc(companyID)
      .collection('projects')
      .doc(deleteDialog as string)
      .delete()
  }

  const [machineCardOptionsId, setMachineCardOptionsId] = useState('')

  // open project details modal
  // ids get pushed to history in RequestsAccordion
  const [projectDetails, setProjectDetails] = useState<null | string>(null)
  const { projectID: customerProjectID, companyID: customerCompanyID } =
    useParams<{
      projectID: string
      companyID: string
    }>()
  useEffect(() => {
    if (customerProjectID && customerCompanyID && !projectDetails) {
      setProjectDetails(
        `companies/${customerCompanyID}/projects/${customerProjectID}`
      )
    }
    if (!customerProjectID || !customerCompanyID) setProjectDetails(null)
  }, [customerProjectID, customerCompanyID, projectDetails])

  if (!isPartner) return <Redirect to="/" />
  return (
    <Dashboard inset={inset}>
      <CustomHelmet title={t('metadata.home')} />
      <h1>DASHBOARD</h1>
      <Container>
        <ContentContainer>
          <StyledSearchbar
            searchValue={searchValue}
            setSearchValue={setSearchValue}
          />
          <RequestsAccordion searchValue={searchValue} />
          <MyMachinesAccordion
            machineProjects={openMachineProjects}
            idsOfHiddenProjects={idsOfHiddenProjects}
            setDeleteDialog={setDeleteDialog}
            goToServiceSpectrum={goToServiceSpectrum}
            goToDescriptionImages={goToDescriptionImages}
            machineCardOptionsId={machineCardOptionsId}
            setMachineCardOptionsId={setMachineCardOptionsId}
          />
          <RequestsAccordion searchValue={searchValue} showConfirmed={true} />
          <SoldMachinesAccordion
            machineProjects={soldMachineProjects}
            idsOfHiddenProjects={idsOfHiddenProjects}
            setDeleteDialog={setDeleteDialog}
            goToDescriptionImages={goToDescriptionImages}
            machineCardOptionsId={machineCardOptionsId}
            setMachineCardOptionsId={setMachineCardOptionsId}
          />
        </ContentContainer>
      </Container>

      <Dialog
        onClose={() => setDeleteDialog(null)}
        open={!!deleteDialog}
        title={t('messages.deleteProject')}
        description={t('messages.deleteProjectDescription')}
        buttons={[
          {
            title: t('buttons.cancel'),
            onClick: () => setDeleteDialog(null),
            primary: false
          },
          {
            title: t('buttons.delete'),
            onClick: deleteProject,
            primary: true
          }
        ]}
      />
      <Dialog
        onClose={() => {}}
        open={showCompleteProfileModal}
        title={t('messages.incompleteProfile')}
        description={t('messages.incompleteProfileDescription')}
        buttons={[
          {
            title: t('general.later'),
            onClick: closeCompleteProfileModal,
            primary: false
          },
          {
            title: t('general.yes'),
            linkTo: '/profile',
            primary: true,
            onClick: closeCompleteProfileModal
          }
        ]}
      />
      <Modal
        size="auto"
        height="auto"
        open={!!projectDetails}
        onClose={() => history.push('/dashboard')}>
        <ProjectInfo
          path={projectDetails}
          onClose={() => history.push('/dashboard')}
        />
      </Modal>
    </Dashboard>
  )
}

export default UsedMachinerySupplierDashboard
