// eslint-disable-next-line import/no-named-default
import { default as ImportedButton } from '@components/elements/Button'
import CompanySpectrum from '@components/elements/CompanySpectrum'
import { Dashboard } from '@components/elements/Dashboard'
import { Company, User } from '@interfaces/user'
import CircularProgress from '@material-ui/core/CircularProgress'
import {
  resetProject,
  setDescription,
  setProjectDescriptionImages,
  setProjectPrice,
  setProjectWarranty
} from '@redux/project'
import CategoryPreview from '@screens/MatchedOverview/elements/CategoriesPreview'
import { selectCompany } from '@selectors/companySelectors'
import { selectProfile } from '@selectors/profileSelectors'
import { selectCurrentProject } from '@selectors/projectSelectors'
import { selectSidebarIsOpen } from '@selectors/sidebarSelectors'
import { getUrlFirebase } from '@services/helpers'
import { useImageUpload } from '@services/storage'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useFirebase } from 'react-redux-firebase'
import { useHistory, useParams } from 'react-router-dom'
import styled from 'styled-components'
import { ImageGallery } from './ImageGallery'
import { CustomGalleryItem } from 'packpart-interfaces/lib'
import { PriceAndWarranty } from './PriceAndWarranty'

const ProjectContainer = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  width: calc(100% - 40px);
  min-width: 600px;
  margin-top: 50px;
  background-color: ${(props) => props.theme.surface};
  border-radius: 18px;
  box-sizing: border-box;
  padding: 40px 25px;
  @media (max-width: 1200px) {
    width: calc(100% - 40px);
    margin-right: 40px;
    margin-top: 25px;
  }
  @media (max-width: 800px) {
    width: calc(100% - 20px);
    margin-right: 20px;
  }
`

const RootContainer = styled.div`
  display: flex;
  flex: 1;
  height: 200px;
  padding-right: 40px;
  overflow: auto;
`
const Spinner = styled(CircularProgress)`
  align-self: center;
  color: ${(props) => props.theme.primary};
`

const CompanyTitle = styled.h1`
  font-weight: 800;
`
const TitleLineContainer = styled.div`
  display: flex;
  justify-content: space-between;
  padding-right: 40px;
  align-items: center;
  margin-bottom: 30px;
`
const LeftContainer = styled.div`
  display: flex;
  height: 100%;
  min-width: 270px;
  flex-direction: column;
  flex: 2.25;
  border-radius: 10px;
  box-sizing: border-box;
  background: ${(props) => props.theme.surface};
  overflow-y: auto;
  margin: 0 20px;
`
const LeftTitle = styled.div`
  display: flex;
  font-size: 21px;
  font-weight: bold;
  color: #2d2d2d;
  margin-top: 30px;
`

const SmallCategoryContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(190px, 1fr));
  grid-gap: 5px;
  margin-top: 10px;
  margin-bottom: 10px;
`

const Description = styled.textarea`
  background: #fff;
  width: 100%;
  min-height: 240px;
  margin-top: 10px;
  border-radius: 10px;
  box-sizing: border-box;
  padding: 5px 20px;
  color: black;
  font-weight: 600;
  font-size: 25px;
  outline: none;
  border-style: solid;
  overflow: hidden;
  z-index: 1;
  :focus {
    box-shadow: 0 0 3px 1px ${({ theme }) => theme.primary};
  }
  resize: vertical;
`

const MiddleContainer = styled.div`
  display: flex;
  flex: 2.25;
  min-width: 270px;
  box-sizing: border-box;
  margin-right: 15px;
  margin-left: 15px;
  padding-right: 5px;
  overflow-y: scroll;
  ::-webkit-scrollbar-track {
    border-radius: 10px;
  }
  ::-webkit-scrollbar-thumb {
    border-radius: 10px;
  }
`

const RightContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  border-radius: 10px;
  box-sizing: border-box;
  background: ${(props) => props.theme.surface};
  overflow-y: auto;
  min-width: 230px;
`

const ContactTitle = styled.div`
  display: flex;
  font-size: 20px;
  font-weight: bold;
  color: #2d2d2d;
  margin: 0 15px;
  white-space: nowrap;
  align-self: center;
`

const ContactImage = styled.img`
  height: 180px;
  width: 180px;
  object-fit: cover;
  border-radius: 50%;
  margin-top: 25px;
  align-self: center;
`

const ContactRowContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 20px 15px 10px 15px;
  justify-content: space-between;
`
const ContactName = styled.h1`
  display: flex;
  flex: 1;
  font-size: 20px;
  font-weight: bold;
  color: #2d2d2d;
`

const ContactDescription = styled.div`
  display: block;
  font-size: 16px;
  line-height: 21px;
  font-weight: 600;
  color: #3d3d3d;
  white-space: pre-line;
  margin: 0 25px 15px 15px;
`

const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 30px;
`
const Button = styled(ImportedButton)`
  position: relative;
`
const ButtonSpinner = styled(CircularProgress)`
  align-self: center;
  color: ${(props) => props.theme.primary};
  position: absolute;
  right: -50px;
`

export const UsedMachinerySupplierDone = () => {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const firebase = useFirebase()
  const inset = useSelector(selectSidebarIsOpen)
  const history = useHistory<{ projectId: string }>()

  const project = useSelector(selectCurrentProject)
  // Not from redux!
  const { projectID: projectId } = useParams<{ projectID: string }>()
  const projectDescription = project?.description
  const handleDescriptionChange = (
    event: React.ChangeEvent<HTMLTextAreaElement>
  ) => {
    // Change through redux
    dispatch(setDescription(event.target.value))
  }

  const myCompany: Company = useSelector(selectCompany)
  const myProfile: User = useSelector(selectProfile)
  const profileImage = myProfile?.profileImage
  const [profileImageUrl, setProfileImageUrl] = useState<string | null>(null)
  const { uploadFromImageGallery } = useImageUpload()
  const uploadedImages = project?.descriptionImages
  const [imageItems, setImageItems] = useState<CustomGalleryItem[]>([])
  useEffect(() => {
    if (uploadedImages && imageItems.length < 1) {
      setImageItems(uploadedImages)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, uploadedImages)

  useEffect(() => {
    const fetch = async () => {
      if (profileImage && !profileImageUrl) {
        const fbUrl = await getUrlFirebase(firebase, profileImage)
        if (fbUrl) setProfileImageUrl(fbUrl)
      }
    }
    fetch()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const [showSpinner, setShowSpinner] = useState(false)
  const endConfigurator = async () => {
    if (isSold) {
      dispatch(resetProject())
      history.push('/')
      return undefined
    }

    setShowSpinner(true)
    // Upload Images & exchange local url with fb-url
    const descriptionImages = await uploadFromImageGallery({
      uploadedImages,
      imageItems,
      projectId
    })
    if (descriptionImages) {
      await firebase
        .firestore()
        .collection('companies')
        .doc(myProfile.companyID)
        .collection('projects')
        .doc(projectId)
        .set({
          ...project,
          price,
          warranty,
          description: projectDescription,
          descriptionImages
        })
      dispatch(setProjectDescriptionImages(descriptionImages))
      // set price & warranty in redux
      dispatch(setProjectPrice(price))
      dispatch(setProjectWarranty(warranty))
      dispatch(resetProject())
      history.push('/')
      setShowSpinner(false)
    }
  }

  const renderImage = () => {
    // No profile image uploaded
    if (!profileImage) return null
    return profileImageUrl ? (
      <ContactImage src={profileImageUrl} />
    ) : (
      <Spinner style={{ marginTop: 20 }} />
    )
  }

  const [price, setPrice] = useState('')
  const [warranty, setWarranty] = useState(false)
  useEffect(() => {
    if (project?.price) setPrice(project.price)
    if (project?.warranty !== undefined) setWarranty(project.warranty)
  }, [project])

  const isSold = project?.isSold

  return (
    <Dashboard inset={inset}>
      <ProjectContainer>
        <TitleLineContainer>
          <CompanyTitle>{'Gebrauchtmaschine ' + project.name} </CompanyTitle>
        </TitleLineContainer>
        <RootContainer>
          <LeftContainer>
            <ImageGallery
              fixedHeight="250px"
              imageItems={imageItems}
              setImageItems={setImageItems}
              showButtons={!isSold}
              showImageName={!isSold}
            />
            <LeftTitle>
              {t('project.companyPresentation.rangeOfServices')}
            </LeftTitle>
            <SmallCategoryContainer>
              {project?.categories && (
                <CategoryPreview categories={project?.categories} />
              )}
            </SmallCategoryContainer>
            <PriceAndWarranty
              price={price}
              setPrice={setPrice}
              warranty={warranty}
              setWarranty={setWarranty}
              disabled={isSold}
            />
            <Description
              id="input"
              disabled={isSold}
              onChange={handleDescriptionChange}
              value={projectDescription}
              placeholder={t('project.basics.machineProjectDescription')}
            />
          </LeftContainer>

          <MiddleContainer>
            {project ? (
              <CompanySpectrum spectrum={project} />
            ) : (
              <>{t('partnerOverview.noRangeOfServices')}</>
            )}
          </MiddleContainer>

          <RightContainer>
            <ContactTitle>
              {t('project.companyPresentation.contactTitle')}
            </ContactTitle>
            {renderImage()}
            <ContactRowContainer>
              <ContactName>{`${myProfile.firstName} ${myProfile.lastName}`}</ContactName>
            </ContactRowContainer>
            <ContactDescription>{myCompany.address}</ContactDescription>
          </RightContainer>
        </RootContainer>
        <ButtonContainer>
          <Button onClick={endConfigurator}>
            {isSold
              ? t('project.basics.backToDashboard')
              : t('project.basics.saveAndBackToDashboard')}
            {showSpinner && <ButtonSpinner />}
          </Button>
        </ButtonContainer>
      </ProjectContainer>
    </Dashboard>
  )
}
