import { Fade, Icon } from '@material-ui/core'
import { ImageGallery as UnstyledImageGallery } from '@screens/addProject/usedMachinerySupplier/ImageGallery'
import { Project } from 'packpart-interfaces/lib'
import React, { ReactNode, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useFirebase } from 'react-redux-firebase'
import styled from 'styled-components'

const CardContainer = styled.div`
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  padding-top: 20px;
  height: 100%;
  background: ${(props) => props.theme.surface};
  border-radius: 7px;
  position: relative;
`

const OptionsOverlay = styled.div`
  position: absolute;
  z-index: 5;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: ${(props) => props.theme.surface};
  // TODO: Use from parent MachineCard
  border-radius: 7px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`
const OptionsClose = styled.div`
  position: absolute;
  top: 20px;
  left: 20px;
  display: flex;
  align-items: center;
  font-size: 20px !important;
  font-weight: 700;
  :hover {
    color: ${({ theme }) => theme.primary};
    text-decoration: underline;
  }
  cursor: pointer;
  color: ${(props) => props.theme.secondary};
`

const OptionsCloseIcon = styled(Icon)`
  font-size: 25px !important;
  font-weight: 700;
  color: ${(props) => props.theme.secondary};
  ${OptionsClose}:hover & {
    color: ${({ theme }) => theme.primary};
  }
`
const OptionsItem = styled.div<{ disabled?: boolean }>`
  display: flex;
  padding: 16px;
  font-size: 20px;
  transition: all 0.1s ease-in-out;
  :hover {
    ${({ disabled }) =>
      !disabled &&
      `
    transform: scale(1.08);
text-decoration: underline;`}
  }
  cursor: pointer;
  color: ${(props) => props.theme.secondary};
`

const TitleContainer = styled.div`
  display: flex;
  align-items: center;
  padding: 0 20px;
`

const Title = styled.p`
  display: flex;
  flex: 1;
  font-size: 20px;
  font-weight: 800;
  color: ${(props) => props.theme.secondary};
  word-break: break-word;
`

const TitleIcon = styled(Icon)`
  display: flex;
  justify-content: center;
  align-content: content;
  font-size: 30px !important;
  font-weight: 700;
  color: ${({ theme }) => theme.secondary};
  cursor: pointer;
  user-select: none;
  :hover {
    transform: scale(1.04);
    color: ${({ theme }) => theme.primary};
  }
  padding-left: 10px;
`

const ImageGallery = styled(UnstyledImageGallery)`
  margin: 20px 20px 0px;
`

export const MachineCard = ({
  project,
  projectMatchPath,
  optionsIconName = 'edit',
  options,
  machineCardOptionsId,
  setMachineCardOptionsId,
  onDelete,
  searchValue,
  hideFullscreenButton = false,
  onImageClick
}: {
  project?: Project
  projectMatchPath?: string
  optionsIconName?: string | null
  onDelete?: () => void
  options?: {
    title: ReactNode
    onClick: () => void
    hide?: boolean
    disabled?: boolean
  }[]
  machineCardOptionsId?: string
  setMachineCardOptionsId?: (id: string) => void
  searchValue?: string
  hideFullscreenButton?: boolean
  onImageClick?: () => void
}) => {
  const firebase = useFirebase()
  const { t } = useTranslation()
  const [machineProject, setMachineProject] = useState<Project>()

  const getProject = async (projectPath: string) => {
    try {
      const project = (await firebase.firestore().doc(projectPath).get()).data()
      // set project id when it's not set already
      if (project && !project?.id) project.id = projectPath.split('/')[3]
      return project
    } catch (e) {
      let errorMessage = 'Error in MachineCard, getProject'
      if (e instanceof Error) {
        errorMessage = e.message
      }
      console.log('e', errorMessage)
    }
  }

  useEffect(() => {
    if (project) {
      setMachineProject(project)
    } else if (projectMatchPath) {
      getProject(projectMatchPath).then((p) => setMachineProject(p as Project))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [project, projectMatchPath])

  // Displaying of options can be handeled in parent, so that only one card simultaneously can display options
  // If no showOptionsForId-Prop & setShowOptionsForId-Prop given, multiple cards can display options
  const [displayOptions, setDisplayOptions] = useState(false)
  useEffect(() => {
    if (machineCardOptionsId) {
      setDisplayOptions(machineProject?.id === machineCardOptionsId)
    }
  }, [machineProject, machineCardOptionsId])
  const onClickOptions = (showOptionsNow: boolean) => {
    // handle options in parent
    if (setMachineCardOptionsId !== undefined) {
      if (showOptionsNow) {
        setMachineCardOptionsId(machineProject?.id as string)
      } else {
        setMachineCardOptionsId('')
        setDisplayOptions(false)
      }
    } else {
      setDisplayOptions(showOptionsNow)
    }
  }

  const projectDoesMatchSearchValue = () => {
    const name = machineProject?.name.toLocaleLowerCase().trim()
    const search = searchValue?.toLocaleLowerCase().trim()
    if (!name || !search) return true
    return name.includes(search)
  }

  if (!machineProject || !projectDoesMatchSearchValue()) return null
  return (
    <CardContainer>
      <TitleContainer>
        <Title>{machineProject.name}</Title>
        {optionsIconName && (
          <TitleIcon onClick={() => onClickOptions(true)}>
            {optionsIconName}
          </TitleIcon>
        )}
        {onDelete && <TitleIcon onClick={onDelete}>delete</TitleIcon>}
      </TitleContainer>
      <ImageGallery
        fixedHeight="185px"
        showButtons={false}
        showFullscreenButton={
          !!machineProject?.descriptionImages && !hideFullscreenButton
        }
        showIndex={false}
        imageItems={machineProject?.descriptionImages ?? []}
        setImageItems={() => null}
        showImageName={false}
        onClick={onImageClick}
      />

      {options && (
        <Fade in={displayOptions}>
          <OptionsOverlay>
            <OptionsClose onClick={() => onClickOptions(false)}>
              <OptionsCloseIcon>arrow_back</OptionsCloseIcon>
              {t('buttons.back')}
            </OptionsClose>
            {options.map(
              (option) =>
                !option.hide && (
                  <OptionsItem
                    onClick={option.onClick}
                    disabled={option.disabled}>
                    {option.title}
                  </OptionsItem>
                )
            )}
          </OptionsOverlay>
        </Fade>
      )}
    </CardContainer>
  )
}
