import cloneDeep from 'lodash/cloneDeep'
import set from 'lodash/set'
import remove from 'lodash/remove'
import get from 'lodash/get'
import {
  Project,
  QuestionType,
  RequiredType,
  Branches,
  Input
} from 'packpart-interfaces'

export const fromPrefix = '_from_'
export const toPrefix = '_to_'

export * from 'packpart-interfaces'
export const defaultProjectValues: Project = {
  name: '',
  description: '',
  categories: [],
  openCategory: null,
  currentStep: 0,
  showUnfinishedFields: false,
  sort: {
    sortAndFeed: {
      data: {},
      fields: {
        outputRate: {
          inputs: [
            {
              type: QuestionType.Float,
              required: true,
              unit: 'amountPerMinute',
              name: 'amountPerMinute'
            }
          ],
          requiredType: RequiredType.All,
          multiplePriorities: true,
          initialNotOptional: true,
          prefill: true,
          glossarLink: 'https://packpart.eu/verpackungsmaschinen-glossar#a004'
        },
        packagingMaterial: {
          initialNotOptional: true,
          requiredType: RequiredType.MinOne,
          multiplePriorities: true,
          prefill: true,
          inputs: [
            {
              type: QuestionType.Boolean,
              name: 'paper'
            },
            {
              type: QuestionType.Boolean,
              name: 'carton'
            },
            {
              name: 'flexiblePlastics',
              inputs: [
                {
                  name: 'foil',
                  type: QuestionType.Boolean
                },
                {
                  name: 'pouches',
                  type: QuestionType.Boolean
                },
                {
                  name: 'bags',
                  type: QuestionType.Boolean
                },
                {
                  name: 'sacs',
                  type: QuestionType.Boolean
                },
                {
                  name: 'bigBags',
                  type: QuestionType.Boolean
                },
                {
                  name: 'fluidBags',
                  type: QuestionType.Boolean
                },
                {
                  name: 'hoods',
                  type: QuestionType.Boolean
                },
                {
                  name: 'nets',
                  type: QuestionType.Boolean
                },
                {
                  name: 'compounds',
                  type: QuestionType.Boolean
                },
                {
                  name: 'flexiblePlasticsOther',
                  type: QuestionType.LongString
                }
              ],
              pdfFile: 'PackPart-A-Mat-Flex.pdf'
            },
            {
              name: 'formboundPlastics',
              inputs: [
                {
                  name: 'bottles',
                  type: QuestionType.Boolean
                },
                {
                  name: 'ampoules',
                  type: QuestionType.Boolean
                },
                {
                  name: 'flacons',
                  type: QuestionType.Boolean
                },
                {
                  name: 'blisters',
                  type: QuestionType.Boolean
                },
                {
                  name: 'tubes',
                  type: QuestionType.Boolean
                },
                {
                  name: 'bowls',
                  type: QuestionType.Boolean
                },
                {
                  name: 'cups',
                  type: QuestionType.Boolean
                },
                {
                  name: 'tins',
                  type: QuestionType.Boolean
                },
                {
                  name: 'canisters',
                  type: QuestionType.Boolean
                },
                {
                  name: 'buckets',
                  type: QuestionType.Boolean
                },
                {
                  name: 'crucibles',
                  type: QuestionType.Boolean
                },
                {
                  name: 'boxes',
                  type: QuestionType.Boolean
                },
                {
                  name: 'barrels',
                  type: QuestionType.Boolean
                },
                {
                  name: 'ibcs',
                  type: QuestionType.Boolean
                },
                {
                  name: 'trays',
                  type: QuestionType.Boolean
                },
                {
                  name: 'vials',
                  type: QuestionType.Boolean
                },
                {
                  name: 'bodyOfSyringe',
                  type: QuestionType.Boolean
                },
                {
                  name: 'formboundPlasticsOther',
                  type: QuestionType.LongString
                }
              ],
              pdfFile: 'PackPart-A-Mat-Form.pdf'
            },
            {
              name: 'metal',
              inputs: [
                {
                  name: 'foil',
                  type: QuestionType.Boolean
                },
                {
                  name: 'tins',
                  type: QuestionType.Boolean
                },
                {
                  name: 'bottles',
                  type: QuestionType.Boolean
                },
                {
                  name: 'shells',
                  type: QuestionType.Boolean
                },
                {
                  name: 'blisters',
                  type: QuestionType.Boolean
                },
                {
                  name: 'tubes',
                  type: QuestionType.Boolean
                },
                {
                  name: 'canisters',
                  type: QuestionType.Boolean
                },
                {
                  name: 'buckets',
                  type: QuestionType.Boolean
                },
                {
                  name: 'crucibles',
                  type: QuestionType.Boolean
                },
                {
                  name: 'metalOther',
                  type: QuestionType.LongString
                }
              ],
              pdfFile: 'PackPart-A-Mat-Met.pdf'
            },
            {
              name: 'glasOrCeramic',
              inputs: [
                {
                  name: 'ampoules',
                  type: QuestionType.Boolean
                },
                {
                  name: 'vials',
                  type: QuestionType.Boolean
                },
                {
                  name: 'bottles',
                  type: QuestionType.Boolean
                },
                {
                  name: 'flacons',
                  type: QuestionType.Boolean
                },
                {
                  name: 'wideNeckVessel',
                  type: QuestionType.Boolean
                },
                {
                  name: 'bodyOfSyringe',
                  type: QuestionType.Boolean
                },
                {
                  name: 'glasOrCeramicOther',
                  type: QuestionType.LongString
                }
              ],
              pdfFile: 'PackPart-A-Mat-GL+KE.pdf'
            },
            {
              name: 'other',
              type: QuestionType.LongString
            }
          ]
        },
        containerVolume: {
          inputs: [
            {
              type: QuestionType.Float,
              unit: 'empty',
              name: 'containerVolume'
            },
            {
              type: QuestionType.SingleChoiceSelect,
              name: 'unitType',
              options: ['ml', 'gram', 'pieces']
            }
          ],
          requiredType: RequiredType.All,
          multiplePriorities: true,
          initialNotOptional: true,
          prefill: true
        },
        closure: {
          inputs: [
            { type: QuestionType.LongString, name: 'comment' },
            { type: QuestionType.File, name: 'file' }
          ],
          requiredType: RequiredType.MinOne,
          multiplePriorities: true,
          initialNotOptional: true,
          onlyForCustomer: true,
          prefill: true
        },
        gmpRequirement: {
          inputs: [
            {
              type: QuestionType.YesNo,
              name: 'gmpRequirement'
            },
            {
              type: QuestionType.LongString,
              name: 'other'
            }
          ],
          requiredType: RequiredType.MinOne,
          onlyForBranches: [Branches.Cosmetics, Branches.Food, Branches.Pharma],
          initialNotOptional: true,
          prefill: true,
          glossarLink: 'https://packpart.eu/verpackungsmaschinen-glossar#g002'
        },
        fdaConform: {
          inputs: [
            {
              type: QuestionType.YesNo,
              name: 'fdaConform'
            },
            {
              type: QuestionType.LongString,
              name: 'other'
            }
          ],
          requiredType: RequiredType.MinOne,
          onlyForBranches: [Branches.Cosmetics, Branches.Food, Branches.Pharma],
          initialNotOptional: true,
          prefill: true,
          glossarLink: 'https://packpart.eu/verpackungsmaschinen-glossar#f001'
        },
        exProtection: {
          inputs: [
            {
              type: QuestionType.SingleChoice,
              name: 'IECEX',
              options: [
                'IECEX',
                'ATEX',
                'NEMA',
                'EACEX',
                'NEPSI',
                'CNEX',
                'false'
              ]
            },
            {
              type: QuestionType.SingleChoice,
              name: 'ATEX',
              options: [
                'IECEX',
                'ATEX',
                'NEMA',
                'EACEX',
                'NEPSI',
                'CNEX',
                'false'
              ]
            },
            {
              type: QuestionType.SingleChoice,
              name: 'NEMA',
              options: [
                'IECEX',
                'ATEX',
                'NEMA',
                'EACEX',
                'NEPSI',
                'CNEX',
                'false'
              ]
            },
            {
              type: QuestionType.SingleChoice,
              name: 'EACEX',
              options: [
                'IECEX',
                'ATEX',
                'NEMA',
                'EACEX',
                'NEPSI',
                'CNEX',
                'false'
              ]
            },
            {
              type: QuestionType.SingleChoice,
              name: 'NEPSI',
              options: [
                'IECEX',
                'ATEX',
                'NEMA',
                'EACEX',
                'NEPSI',
                'CNEX',
                'false'
              ]
            },
            {
              type: QuestionType.SingleChoice,
              name: 'CNEX',
              options: [
                'IECEX',
                'ATEX',
                'NEMA',
                'EACEX',
                'NEPSI',
                'CNEX',
                'false'
              ]
            },
            {
              type: QuestionType.SingleChoice,
              name: 'false',
              options: [
                'IECEX',
                'ATEX',
                'NEMA',
                'EACEX',
                'NEPSI',
                'CNEX',
                'false'
              ]
            },
            {
              type: QuestionType.LongString,
              name: 'other'
            }
          ],
          onlyForBranches: [Branches.Cosmetics, Branches.Chemistry],
          requiredType: RequiredType.MinOne,
          initialNotOptional: true,
          prefill: true,
          glossarLink: 'https://packpart.eu/verpackungsmaschinen-glossar#e004'
        },
        pharma: {
          inputs: [
            {
              type: QuestionType.Boolean,
              name: 'high'
            },
            {
              type: QuestionType.Boolean,
              name: 'gray'
            },
            {
              type: QuestionType.LongString,
              name: 'other'
            }
          ],
          onlyForBranches: [Branches.Pharma],
          requiredType: RequiredType.MinOne,
          initialNotOptional: true,
          prefill: true,
          glossarLink: 'https://packpart.eu/verpackungsmaschinen-glossar#p001'
        }
      },
      optionalFields: {
        cleanWhileFeeding: {
          inputs: [
            {
              type: QuestionType.YesNo,
              name: 'cleanWhileFeeding'
            }
          ],
          requiredType: RequiredType.All,
          glossarLink: 'https://packpart.eu/verpackungsmaschinen-glossar#a003',
          prefill: true
        },
        containerDiameter: {
          inputs: [
            {
              type: QuestionType.Float,
              unit: 'containerDiameter',
              name: 'containerDiameter'
            }
          ],
          requiredType: RequiredType.All,
          pdfFile: 'PackPart-A-Opt-2.pdf',
          multiplePriorities: true,
          prefill: true
        },
        containerHeight: {
          inputs: [
            {
              type: QuestionType.Float,
              unit: 'containerHeight',
              name: 'containerHeight'
            }
          ],
          requiredType: RequiredType.All,
          pdfFile: 'PackPart-A-Opt-3.pdf',
          multiplePriorities: true,
          prefill: true
        },
        containerWidth: {
          inputs: [
            {
              type: QuestionType.Float,
              unit: 'containerWidth',
              name: 'containerWidth'
            }
          ],
          requiredType: RequiredType.All,
          pdfFile: 'PackPart-A-Opt-5.pdf',
          multiplePriorities: true,
          prefill: true
        },
        containerLength: {
          inputs: [
            {
              type: QuestionType.Float,
              unit: 'containerLength',
              name: 'containerLength'
            }
          ],
          requiredType: RequiredType.All,
          pdfFile: 'PackPart-A-Opt-4.pdf',
          multiplePriorities: true,
          prefill: true
        },
        setupContainer: {
          inputs: [
            {
              type: QuestionType.YesNo,
              name: 'setupContainer'
            }
          ],
          requiredType: RequiredType.All,
          prefill: true
        },
        positionContainer: {
          inputs: [
            {
              type: QuestionType.YesNo,
              name: 'positionContainer'
            }
          ],
          requiredType: RequiredType.All,
          glossarLink: 'https://packpart.eu/verpackungsmaschinen-glossar#p002',
          prefill: true
        },
        setinContainer: {
          inputs: [
            {
              type: QuestionType.YesNo,
              name: 'setinContainer'
            }
          ],
          requiredType: RequiredType.All,
          glossarLink: 'https://packpart.eu/verpackungsmaschinen-glossar#e001',
          prefill: true
        },
        projectBudget: {
          inputs: [
            {
              type: QuestionType.Float,
              name: 'totalBudget',
              unit: 'totalBudget'
            },
            { type: QuestionType.Float, name: 'budgetForA', unit: 'budgetForA' }
          ],
          requiredType: RequiredType.All,
          onlyForCustomer: true,
          prefill: true
        },
        deliveryDate: {
          inputs: [
            {
              type: QuestionType.Date,
              name: 'deliveryDate'
            }
          ],
          requiredType: RequiredType.All,
          onlyForCustomer: true,
          prefill: true
        },
        conveyorBelt: {
          inputs: [
            {
              type: QuestionType.Float,
              name: 'length',
              unit: 'length'
            },
            {
              type: QuestionType.Float,
              name: 'width',
              unit: 'width'
            },
            {
              type: QuestionType.Boolean,
              name: 'vacuumBelt'
            },
            {
              type: QuestionType.Boolean,
              name: 'metalBelt'
            },
            {
              type: QuestionType.Boolean,
              name: 'plasticBelt'
            },
            {
              type: QuestionType.Boolean,
              name: 'automaticallyAdjustableBelt'
            },
            {
              type: QuestionType.LongString,
              name: 'other'
            }
          ],
          requiredType: RequiredType.MinOne,
          prefill: true
        },
        roboticSorter: {
          inputs: [
            {
              type: QuestionType.YesNo,
              name: 'roboticSorter'
            }
          ],
          requiredType: RequiredType.MinOne,
          prefill: true
        },
        depalletizing: {
          inputs: [
            {
              type: QuestionType.YesNo,
              name: 'depalletizing'
            }
          ],
          requiredType: RequiredType.MinOne,
          prefill: true
        },
        machineRunningDirection: {
          inputs: [
            {
              name: 'fromLeftToRight',
              options: ['fromLeftToRight', 'fromRightToLeft'],
              type: QuestionType.SingleChoice
            },
            {
              name: 'fromRightToLeft',
              options: ['fromLeftToRight', 'fromRightToLeft'],
              type: QuestionType.SingleChoice
            }
          ],
          requiredType: RequiredType.MinOne,
          prefill: true
        },
        elevator: {
          inputs: [
            {
              type: QuestionType.Float,
              name: 'fillVolume',
              unit: 'fillVolume'
            },
            {
              type: QuestionType.Float,
              name: 'outputAmount',
              unit: 'outputAmount',
              required: true
            }
          ],
          requiredType: RequiredType.Specific,
          prefill: true
        },
        vibrationHopper: {
          inputs: [
            {
              type: QuestionType.Float,
              name: 'fillVolume',
              unit: 'fillVolume'
            },
            {
              type: QuestionType.Float,
              name: 'outputAmount',
              unit: 'outputAmount',
              required: true
            }
          ],
          requiredType: RequiredType.Specific,
          prefill: true
        },
        beltdrivenHopper: {
          inputs: [
            {
              type: QuestionType.Float,
              name: 'fillVolume',
              unit: 'fillVolume'
            },
            {
              type: QuestionType.Float,
              name: 'outputAmount',
              unit: 'outputAmount',
              required: true
            }
          ],
          requiredType: RequiredType.Specific,
          prefill: true
        },
        dischargeTable: {
          inputs: [
            { type: QuestionType.Float, name: 'length', unit: 'length' },
            { type: QuestionType.Float, name: 'width', unit: 'width' }
          ],
          requiredType: RequiredType.All,
          prefill: true
        },
        elevatedConveyorSorter: {
          inputs: [
            {
              type: QuestionType.Float,
              name: 'fillVolume',
              unit: 'fillVolume'
            },
            {
              type: QuestionType.Float,
              name: 'outputAmount',
              unit: 'outputAmount',
              required: true
            }
          ],
          requiredType: RequiredType.Specific,
          prefill: true
        },
        turnTable: {
          inputs: [
            {
              name: 'diameter',
              unit: 'diameter',
              type: QuestionType.Float
            }
          ],
          requiredType: RequiredType.All,
          prefill: true
        },
        other: {
          inputs: [
            {
              name: 'other',
              type: QuestionType.LongString
            }
          ],
          requiredType: RequiredType.All
        }
      }
    },
    stockpile: {
      data: {},
      fields: {
        closureType: {
          inputs: [
            {
              name: 'rollOn',
              inputs: [
                {
                  name: 'pilferProof',
                  type: QuestionType.Boolean
                },
                {
                  name: 'mca',
                  type: QuestionType.Boolean
                },
                {
                  name: 'rollOn',
                  type: QuestionType.Boolean
                },
                {
                  name: 'rollOnOther',
                  type: QuestionType.LongString
                }
              ],
              pdfFile: 'PackPart-A-Ver-An.pdf'
            },
            {
              name: 'screw',
              inputs: [
                {
                  name: 'twistOff',
                  type: QuestionType.Boolean
                },
                {
                  name: 'screwLid',
                  type: QuestionType.Boolean
                },
                {
                  name: 'flipTop',
                  type: QuestionType.Boolean
                },
                {
                  name: 'pushPull',
                  type: QuestionType.Boolean
                },
                {
                  name: 'dispenser',
                  type: QuestionType.Boolean
                },
                {
                  name: 'sprayPump',
                  type: QuestionType.Boolean
                },
                {
                  name: 'trigger',
                  type: QuestionType.Boolean
                },
                {
                  name: 'lidOriginal',
                  type: QuestionType.Boolean
                },
                {
                  name: 'lidChildproof',
                  type: QuestionType.Boolean
                },
                {
                  name: 'screwOther',
                  type: QuestionType.LongString
                }
              ],
              pdfFile: 'PackPart-A-Ver-Ver.pdf'
            },
            {
              name: 'pressIn',
              inputs: [
                {
                  name: 'cork',
                  type: QuestionType.Boolean
                },
                {
                  name: 'handleCork',
                  type: QuestionType.Boolean
                },
                {
                  name: 'crownCaps',
                  type: QuestionType.Boolean
                },
                {
                  name: 'pushInPlugs',
                  type: QuestionType.Boolean
                },
                {
                  name: 'snapCover',
                  type: QuestionType.Boolean
                },
                {
                  name: 'bounceClosure',
                  type: QuestionType.Boolean
                },
                {
                  name: 'decorativeCap',
                  type: QuestionType.Boolean
                },
                {
                  name: 'pourer',
                  type: QuestionType.Boolean
                },
                {
                  name: 'dustCaps',
                  type: QuestionType.Boolean
                },
                {
                  name: 'fingerRest',
                  type: QuestionType.Boolean
                },
                {
                  name: 'pistonRod',
                  type: QuestionType.Boolean
                },
                {
                  name: 'pressInOther',
                  type: QuestionType.LongString
                }
              ],
              pdfFile: 'PackPart-A-Sort-Ver-Ein-Auf.pdf'
            },
            {
              name: 'sealing',
              inputs: [
                {
                  name: 'hfSealing',
                  type: QuestionType.Boolean
                },
                {
                  name: 'preCutSealing',
                  type: QuestionType.Boolean
                },
                {
                  name: 'sealingByRole',
                  type: QuestionType.Boolean
                },
                {
                  name: 'beading',
                  type: QuestionType.Boolean
                },
                {
                  name: 'plateSealing',
                  type: QuestionType.Boolean
                },
                {
                  name: 'thermalSealing',
                  type: QuestionType.Boolean
                },
                {
                  name: 'sealingOther',
                  type: QuestionType.LongString
                }
              ]
            },
            {
              name: 'other',
              type: QuestionType.LongString
            }
          ],
          initialNotOptional: true,
          requiredType: RequiredType.MinOne,
          multiplePriorities: true,
          prefill: true
        },
        sealSortingPosition: {
          multiplePriorities: true,
          inputs: [
            {
              type: QuestionType.Boolean,
              name: 'top'
            },
            {
              type: QuestionType.Boolean,
              name: 'bottom'
            },
            {
              type: QuestionType.Boolean,
              name: 'impressionTop'
            },
            {
              type: QuestionType.Boolean,
              name: 'impressionBottom'
            },
            {
              type: QuestionType.LongString,
              name: 'other'
            }
          ],
          initialNotOptional: true,
          requiredType: RequiredType.MinOne
        }
      },
      optionalFields: {
        elevator: {
          inputs: [
            {
              type: QuestionType.Float,
              name: 'fillVolume',
              unit: 'fillVolume'
            },
            {
              type: QuestionType.Float,
              name: 'outputAmount',
              unit: 'outputAmount',
              required: true
            }
          ],
          requiredType: RequiredType.Specific,
          prefill: true
        },
        sealDiameter: {
          inputs: [
            {
              type: QuestionType.Float,
              unit: 'sealDiameter',
              name: 'sealDiameter'
            }
          ],
          requiredType: RequiredType.All,
          pdfFile: 'PackPart-A-Opt-7.pdf',
          multiplePriorities: true,
          prefill: true
        },
        sealHeight: {
          inputs: [
            {
              type: QuestionType.Float,
              unit: 'sealHeight',
              name: 'sealHeight'
            }
          ],
          requiredType: RequiredType.All,
          pdfFile: 'PackPart-A-Opt-6.pdf',
          multiplePriorities: true,
          prefill: true
        },
        vibrationHopper: {
          inputs: [
            {
              type: QuestionType.Float,
              name: 'fillVolume',
              unit: 'fillVolume'
            },
            {
              type: QuestionType.Float,
              name: 'outputAmount',
              unit: 'outputAmount',
              required: true
            }
          ],
          requiredType: RequiredType.Specific,
          prefill: true
        },
        vibrationSorter: {
          inputs: [
            {
              type: QuestionType.Float,
              name: 'fillVolume',
              unit: 'fillVolume'
            },
            {
              type: QuestionType.Float,
              name: 'outputAmount',
              unit: 'outputAmount',
              required: true
            }
          ],
          requiredType: RequiredType.Specific,
          prefill: true
        },
        elevatedConveyorSorter: {
          inputs: [
            {
              type: QuestionType.Float,
              name: 'fillVolume',
              unit: 'fillVolume'
            },
            {
              type: QuestionType.Float,
              name: 'outputAmount',
              unit: 'outputAmount',
              required: true
            }
          ],
          requiredType: RequiredType.Specific,
          prefill: true
        },
        beltdrivenHopper: {
          inputs: [
            {
              type: QuestionType.Float,
              name: 'fillVolume',
              unit: 'fillVolume'
            },
            {
              type: QuestionType.Float,
              name: 'outputAmount',
              unit: 'outputAmount',
              required: true
            }
          ],
          requiredType: RequiredType.Specific,
          prefill: true
        },
        other: {
          inputs: [
            {
              name: 'other',
              type: QuestionType.LongString
            }
          ],
          requiredType: RequiredType.All
        }
      }
    }
  },
  fill: {
    data: {},
    fields: {
      fullOrHalfAutomatic: {
        inputs: [
          {
            type: QuestionType.Boolean,
            name: 'full'
          },
          {
            type: QuestionType.Boolean,
            name: 'semi'
          },
          {
            type: QuestionType.Boolean,
            name: 'manual'
          }
        ],
        requiredType: RequiredType.MinOne,
        initialNotOptional: true,
        glossarLink: 'https://packpart.eu/verpackungsmaschinen-glossar#v001',
        prefill: true
      },
      outputRate: {
        inputs: [
          {
            type: QuestionType.Float,
            unit: 'amountPerMinute',
            name: 'amountPerMinute'
          }
        ],
        requiredType: RequiredType.All,
        multiplePriorities: true,
        initialNotOptional: true,
        glossarLink: 'https://packpart.eu/verpackungsmaschinen-glossar#a004',
        prefill: true
      },
      packagingMaterial: {
        initialNotOptional: true,
        requiredType: RequiredType.MinOne,
        multiplePriorities: true,
        inputs: [
          {
            type: QuestionType.Boolean,
            name: 'paper'
          },
          {
            type: QuestionType.Boolean,
            name: 'carton'
          },
          {
            name: 'flexiblePlastics',
            inputs: [
              {
                name: 'foil',
                type: QuestionType.Boolean
              },
              {
                name: 'pouches',
                type: QuestionType.Boolean
              },
              {
                name: 'bags',
                type: QuestionType.Boolean
              },
              {
                name: 'sacs',
                type: QuestionType.Boolean
              },
              {
                name: 'bigBags',
                type: QuestionType.Boolean
              },
              {
                name: 'fluidBags',
                type: QuestionType.Boolean
              },
              {
                name: 'hoods',
                type: QuestionType.Boolean
              },
              {
                name: 'nets',
                type: QuestionType.Boolean
              },
              {
                name: 'compounds',
                type: QuestionType.Boolean
              },
              {
                name: 'flexiblePlasticsOther',
                type: QuestionType.LongString
              }
            ],
            pdfFile: 'PackPart-B-Mat-Flex.pdf'
          },
          {
            name: 'formboundPlastics',
            inputs: [
              {
                name: 'bottles',
                type: QuestionType.Boolean
              },
              {
                name: 'ampoules',
                type: QuestionType.Boolean
              },
              {
                name: 'flacons',
                type: QuestionType.Boolean
              },
              {
                name: 'blisters',
                type: QuestionType.Boolean
              },
              {
                name: 'tubes',
                type: QuestionType.Boolean
              },
              {
                name: 'bowls',
                type: QuestionType.Boolean
              },
              {
                name: 'cups',
                type: QuestionType.Boolean
              },
              {
                name: 'tins',
                type: QuestionType.Boolean
              },
              {
                name: 'canisters',
                type: QuestionType.Boolean
              },
              {
                name: 'buckets',
                type: QuestionType.Boolean
              },
              {
                name: 'crucibles',
                type: QuestionType.Boolean
              },
              {
                name: 'boxes',
                type: QuestionType.Boolean
              },
              {
                name: 'barrels',
                type: QuestionType.Boolean
              },
              {
                name: 'ibcs',
                type: QuestionType.Boolean
              },
              {
                name: 'trays',
                type: QuestionType.Boolean
              },
              {
                name: 'vials',
                type: QuestionType.Boolean
              },
              {
                name: 'bodyOfSyringe',
                type: QuestionType.Boolean
              },
              {
                name: 'formboundPlasticsOther',
                type: QuestionType.LongString
              }
            ],
            pdfFile: 'PackPart-B-Mat-Form.pdf'
          },
          {
            name: 'metal',
            inputs: [
              {
                name: 'foil',
                type: QuestionType.Boolean
              },
              {
                name: 'tins',
                type: QuestionType.Boolean
              },
              {
                name: 'bottles',
                type: QuestionType.Boolean
              },
              {
                name: 'shells',
                type: QuestionType.Boolean
              },
              {
                name: 'blisters',
                type: QuestionType.Boolean
              },
              {
                name: 'tubes',
                type: QuestionType.Boolean
              },
              {
                name: 'canisters',
                type: QuestionType.Boolean
              },
              {
                name: 'buckets',
                type: QuestionType.Boolean
              },
              {
                name: 'crucibles',
                type: QuestionType.Boolean
              },
              {
                name: 'metalOther',
                type: QuestionType.LongString
              }
            ],
            pdfFile: 'PackPart-B-Mat-Met.pdf'
          },
          {
            name: 'glasOrCeramic',
            inputs: [
              {
                name: 'ampoules',
                type: QuestionType.Boolean
              },
              {
                name: 'vials',
                type: QuestionType.Boolean
              },
              {
                name: 'bottles',
                type: QuestionType.Boolean
              },
              {
                name: 'flacons',
                type: QuestionType.Boolean
              },
              {
                name: 'wideNeckVessel',
                type: QuestionType.Boolean
              },
              {
                name: 'bodyOfSyringe',
                type: QuestionType.Boolean
              },
              {
                name: 'glasOrCeramicOther',
                type: QuestionType.LongString
              }
            ],
            pdfFile: 'PackPart-B-Mat-GL+KE.pdf'
          },
          {
            name: 'other',
            type: QuestionType.LongString
          }
        ],
        prefill: true
      },
      containerVolume: {
        inputs: [
          {
            type: QuestionType.Float,
            name: 'containerVolume',
            unit: 'empty'
          },
          {
            type: QuestionType.SingleChoiceSelect,
            name: 'unitType',
            options: ['ml', 'gram', 'pieces']
          }
        ],
        initialNotOptional: true,
        requiredType: RequiredType.All,
        multiplePriorities: true,
        prefill: true
      },
      closure: {
        inputs: [
          { type: QuestionType.LongString, name: 'comment' },
          { type: QuestionType.File, name: 'file' }
        ],
        requiredType: RequiredType.MinOne,
        multiplePriorities: true,
        initialNotOptional: true,
        onlyForCustomer: true
      },
      productViscosity: {
        inputs: [
          {
            name: 'consistency',
            requiredType: RequiredType.MinOne,
            inputs: [
              {
                type: QuestionType.SingleChoice,
                name: 'lowViscosity',
                options: [
                  'lowViscosity',
                  'mediumViscosity',
                  'highViscosity',
                  'pasty',
                  'powdery',
                  'solid'
                ]
              },
              {
                type: QuestionType.SingleChoice,
                name: 'mediumViscosity',
                options: [
                  'lowViscosity',
                  'mediumViscosity',
                  'highViscosity',
                  'pasty',
                  'powdery',
                  'solid'
                ]
              },
              {
                type: QuestionType.SingleChoice,
                name: 'highViscosity',
                options: [
                  'lowViscosity',
                  'mediumViscosity',
                  'highViscosity',
                  'pasty',
                  'powdery',
                  'solid'
                ]
              },
              {
                type: QuestionType.SingleChoice,
                name: 'pasty',
                options: [
                  'lowViscosity',
                  'mediumViscosity',
                  'highViscosity',
                  'pasty',
                  'powdery',
                  'solid'
                ]
              },
              {
                type: QuestionType.SingleChoice,
                name: 'powdery',
                options: [
                  'lowViscosity',
                  'mediumViscosity',
                  'highViscosity',
                  'pasty',
                  'powdery',
                  'solid'
                ]
              },
              {
                type: QuestionType.SingleChoice,
                name: 'solid',
                options: [
                  'lowViscosity',
                  'mediumViscosity',
                  'highViscosity',
                  'pasty',
                  'powdery',
                  'solid'
                ]
              },
              {
                type: QuestionType.LongString,
                name: 'other'
              }
            ]
          },
          {
            name: 'piecesInProduct',
            inputs: [{ name: 'piecesInProduct', type: QuestionType.YesNo }]
          },
          {
            name: 'highlyConcentratedProduct',
            inputs: [
              { name: 'highlyConcentratedProduct', type: QuestionType.YesNo }
            ],
            onlyForBranches: [Branches.Chemistry]
          },
          {
            name: 'productName',
            inputs: [{ name: 'productName', type: QuestionType.LongString }]
          },
          {
            name: 'other',
            type: QuestionType.LongString
          }
        ],
        requiredType: RequiredType.MinOne,
        multiplePriorities: true,
        initialNotOptional: true,
        glossarLink: 'https://packpart.eu/verpackungsmaschinen-glossar#p005'
      },
      gmpRequirement: {
        inputs: [
          {
            type: QuestionType.YesNo,
            name: 'gmpRequirement'
          },
          {
            type: QuestionType.LongString,
            name: 'other'
          }
        ],
        requiredType: RequiredType.MinOne,
        onlyForBranches: [Branches.Cosmetics, Branches.Food, Branches.Pharma],
        initialNotOptional: true,
        glossarLink: 'https://packpart.eu/verpackungsmaschinen-glossar#g002',
        prefill: true
      },
      fdaConform: {
        inputs: [
          {
            type: QuestionType.YesNo,
            name: 'fdaConform'
          },
          {
            type: QuestionType.LongString,
            name: 'other'
          }
        ],
        requiredType: RequiredType.MinOne,
        onlyForBranches: [Branches.Cosmetics, Branches.Food, Branches.Pharma],
        initialNotOptional: true,
        glossarLink: 'https://packpart.eu/verpackungsmaschinen-glossar#f001',
        prefill: true
      },
      exProtection: {
        inputs: [
          {
            type: QuestionType.SingleChoice,
            name: 'IECEX',
            options: [
              'IECEX',
              'ATEX',
              'NEMA',
              'EACEX',
              'NEPSI',
              'CNEX',
              'false'
            ]
          },
          {
            type: QuestionType.SingleChoice,
            name: 'ATEX',
            options: [
              'IECEX',
              'ATEX',
              'NEMA',
              'EACEX',
              'NEPSI',
              'CNEX',
              'false'
            ]
          },
          {
            type: QuestionType.SingleChoice,
            name: 'NEMA',
            options: [
              'IECEX',
              'ATEX',
              'NEMA',
              'EACEX',
              'NEPSI',
              'CNEX',
              'false'
            ]
          },
          {
            type: QuestionType.SingleChoice,
            name: 'EACEX',
            options: [
              'IECEX',
              'ATEX',
              'NEMA',
              'EACEX',
              'NEPSI',
              'CNEX',
              'false'
            ]
          },
          {
            type: QuestionType.SingleChoice,
            name: 'NEPSI',
            options: [
              'IECEX',
              'ATEX',
              'NEMA',
              'EACEX',
              'NEPSI',
              'CNEX',
              'false'
            ]
          },
          {
            type: QuestionType.SingleChoice,
            name: 'CNEX',
            options: [
              'IECEX',
              'ATEX',
              'NEMA',
              'EACEX',
              'NEPSI',
              'CNEX',
              'false'
            ]
          },
          {
            type: QuestionType.SingleChoice,
            name: 'false',
            options: [
              'IECEX',
              'ATEX',
              'NEMA',
              'EACEX',
              'NEPSI',
              'CNEX',
              'false'
            ]
          },
          {
            type: QuestionType.LongString,
            name: 'other'
          }
        ],
        onlyForBranches: [Branches.Cosmetics, Branches.Chemistry],
        requiredType: RequiredType.MinOne,
        initialNotOptional: true,
        glossarLink: 'https://packpart.eu/verpackungsmaschinen-glossar#e004',
        prefill: true
      },
      pharma: {
        inputs: [
          {
            type: QuestionType.Boolean,
            name: 'high'
          },
          {
            type: QuestionType.Boolean,
            name: 'gray'
          },
          {
            type: QuestionType.LongString,
            name: 'other'
          }
        ],
        onlyForBranches: [Branches.Pharma],
        requiredType: RequiredType.MinOne,
        initialNotOptional: true,
        prefill: true,
        glossarLink: 'https://packpart.eu/verpackungsmaschinen-glossar#p001'
      }
    },
    optionalFields: {
      containerDiameter: {
        inputs: [
          {
            type: QuestionType.Float,
            unit: 'containerDiameter',
            name: 'containerDiameter'
          }
        ],
        requiredType: RequiredType.All,
        pdfFile: 'PackPart-B-Opt-2.pdf',
        multiplePriorities: true,
        prefill: true
      },
      containerHeight: {
        inputs: [
          {
            type: QuestionType.Float,
            unit: 'containerHeight',
            name: 'containerHeight'
          }
        ],
        requiredType: RequiredType.All,
        pdfFile: 'PackPart-B-Opt-3.pdf',
        multiplePriorities: true,
        prefill: true
      },
      containerWidth: {
        inputs: [
          {
            type: QuestionType.Float,
            unit: 'containerWidth',
            name: 'containerWidth'
          }
        ],
        requiredType: RequiredType.All,
        pdfFile: 'PackPart-B-Opt-5.pdf',
        multiplePriorities: true,
        prefill: true
      },
      containerLength: {
        inputs: [
          {
            type: QuestionType.Float,
            unit: 'containerLength',
            name: 'containerLength'
          }
        ],
        requiredType: RequiredType.All,
        pdfFile: 'PackPart-B-Opt-4.pdf',
        multiplePriorities: true,
        prefill: true
      },
      sealDiameter: {
        inputs: [
          {
            type: QuestionType.Float,
            unit: 'sealDiameter',
            name: 'sealDiameter'
          }
        ],
        requiredType: RequiredType.All,
        pdfFile: 'PackPart-B-Opt-7.pdf',
        multiplePriorities: true,
        prefill: true
      },
      sealHeight: {
        inputs: [
          {
            type: QuestionType.Float,
            unit: 'sealHeight',
            name: 'sealHeight'
          }
        ],
        requiredType: RequiredType.All,
        pdfFile: 'PackPart-B-Opt-6.pdf',
        multiplePriorities: true,
        prefill: true
      },
      containerInnerDiameter: {
        inputs: [
          {
            type: QuestionType.Float,
            unit: 'containerInnerDiameter',
            name: 'containerInnerDiameter'
          }
        ],
        requiredType: RequiredType.All,
        pdfFile: 'PackPart-B-Opt-1.pdf',
        multiplePriorities: true,
        prefill: true
      },
      fillProductUnderTemp: {
        inputs: [
          {
            name: 'fillTemperature',
            unit: 'fillTemperature',
            type: QuestionType.Float,
            onlyForCustomer: true
          },
          {
            name: 'no',
            type: QuestionType.Boolean
          }
        ],
        requiredType: RequiredType.OnlyOne,
        glossarLink: 'https://packpart.eu/verpackungsmaschinen-glossar#p004',
        multiplePriorities: true,
        prefill: true
      },
      cipSipCleaning: {
        inputs: [
          {
            name: 'cipCleaning',
            type: QuestionType.SingleChoice,
            options: ['cipCleaning', 'sipCleaning', 'false']
          },
          {
            name: 'sipCleaning',
            type: QuestionType.SingleChoice,
            options: ['cipCleaning', 'sipCleaning', 'false']
          },
          {
            name: 'false',
            type: QuestionType.SingleChoice,
            options: ['cipCleaning', 'sipCleaning', 'false']
          }
        ],
        requiredType: RequiredType.MinOne,
        glossarLink: 'https://packpart.eu/verpackungsmaschinen-glossar#c001',
        prefill: true
      },
      fillingType: {
        inputs: [
          {
            name: 'underlevel',
            type: QuestionType.Boolean
          },
          {
            name: 'overlevel',
            type: QuestionType.Boolean
          },
          {
            name: 'bunghole',
            type: QuestionType.Boolean
          },
          {
            name: 'vacuumFiller',
            type: QuestionType.Boolean
          },
          {
            name: 'levelFiller',
            type: QuestionType.Boolean
          },
          {
            name: 'otherFillerType',
            type: QuestionType.LongString
          }
        ],
        multiplePriorities: true,
        requiredType: RequiredType.MinOne,
        glossarLink: 'https://packpart.eu/verpackungsmaschinen-glossar#a001',
        pdfFile: 'PackPart-B-Opt-Abfuell.pdf',
        prefill: true
      },
      dosingMethod: {
        inputs: [
          {
            name: 'inductiveFlowMeasurement',
            type: QuestionType.Boolean
          },
          {
            name: 'massFlowMeasurement',
            type: QuestionType.Boolean
          },
          {
            name: 'pistonDosing',
            type: QuestionType.Boolean
          },
          {
            name: 'peristalticPump',
            type: QuestionType.Boolean
          },
          {
            name: 'rotaryPistonDosing',
            type: QuestionType.Boolean
          },
          {
            name: 'weighingDosing',
            type: QuestionType.Boolean
          },
          {
            name: 'timeFilling',
            type: QuestionType.Boolean
          },
          {
            name: 'other',
            type: QuestionType.LongString
          }
        ],
        requiredType: RequiredType.MinOne,
        glossarLink: 'https://packpart.eu/verpackungsmaschinen-glossar#d001',
        prefill: true
      },
      machineRunningDirection: {
        inputs: [
          {
            name: 'fromLeftToRight',
            type: QuestionType.SingleChoice,
            options: ['fromLeftToRight', 'fromRightToLeft']
          },
          {
            name: 'fromRightToLeft',
            type: QuestionType.SingleChoice,
            options: ['fromLeftToRight', 'fromRightToLeft']
          }
        ],
        requiredType: RequiredType.MinOne,
        prefill: true
      },
      packingProcess: {
        inputs: [
          {
            name: 'horizontalPackingProcess',
            type: QuestionType.SingleChoice,
            options: ['horizontalPackingProcess', 'verticalPackingProcess']
          },
          {
            name: 'verticalPackingProcess',
            type: QuestionType.SingleChoice,
            options: ['horizontalPackingProcess', 'verticalPackingProcess']
          }
        ],
        requiredType: RequiredType.MinOne,
        prefill: true
      },
      blowFillSealSolution: {
        inputs: [
          {
            name: 'blowFillSealSolution',
            type: QuestionType.YesNo
          }
        ],
        requiredType: RequiredType.MinOne,
        prefill: true
      },
      projectBudget: {
        inputs: [
          {
            type: QuestionType.Float,
            name: 'totalBudget',
            unit: 'totalBudget'
          },
          { type: QuestionType.Float, name: 'budgetForB', unit: 'budgetForB' }
        ],
        requiredType: RequiredType.All,
        onlyForCustomer: true,
        prefill: true
      },
      deliveryDate: {
        inputs: [
          {
            type: QuestionType.Date,
            name: 'deliveryDate'
          }
        ],
        requiredType: RequiredType.All,
        onlyForCustomer: true,
        prefill: true
      },
      other: {
        inputs: [
          {
            type: QuestionType.LongString,
            name: 'other'
          }
        ],
        requiredType: RequiredType.All
      }
    }
  },
  seal: {
    data: {},
    fields: {
      fullOrHalfAutomatic: {
        inputs: [
          {
            type: QuestionType.Boolean,
            name: 'full'
          },
          {
            type: QuestionType.Boolean,
            name: 'semi'
          },
          {
            type: QuestionType.Boolean,
            name: 'manual'
          }
        ],
        requiredType: RequiredType.MinOne,
        initialNotOptional: true,
        glossarLink: 'https://packpart.eu/verpackungsmaschinen-glossar#v001',
        prefill: true
      },
      outputRate: {
        inputs: [
          {
            type: QuestionType.Float,
            unit: 'amountPerMinute',
            name: 'amountPerMinute'
          }
        ],
        requiredType: RequiredType.All,
        multiplePriorities: true,
        initialNotOptional: true,
        glossarLink: 'https://packpart.eu/verpackungsmaschinen-glossar#a004',
        prefill: true
      },
      packagingMaterial: {
        initialNotOptional: true,
        requiredType: RequiredType.MinOne,
        multiplePriorities: true,
        inputs: [
          {
            type: QuestionType.Boolean,
            name: 'paper'
          },
          {
            type: QuestionType.Boolean,
            name: 'carton'
          },
          {
            name: 'flexiblePlastics',
            inputs: [
              {
                name: 'foil',
                type: QuestionType.Boolean
              },
              {
                name: 'pouches',
                type: QuestionType.Boolean
              },
              {
                name: 'bags',
                type: QuestionType.Boolean
              },
              {
                name: 'sacs',
                type: QuestionType.Boolean
              },
              {
                name: 'bigBags',
                type: QuestionType.Boolean
              },
              {
                name: 'fluidBags',
                type: QuestionType.Boolean
              },
              {
                name: 'hoods',
                type: QuestionType.Boolean
              },
              {
                name: 'nets',
                type: QuestionType.Boolean
              },
              {
                name: 'compounds',
                type: QuestionType.Boolean
              },
              {
                name: 'flexiblePlasticsOther',
                type: QuestionType.LongString
              }
            ],
            pdfFile: 'PackPart-C-Mat-Flex.pdf'
          },
          {
            name: 'formboundPlastics',
            inputs: [
              {
                name: 'bottles',
                type: QuestionType.Boolean
              },
              {
                name: 'ampoules',
                type: QuestionType.Boolean
              },
              {
                name: 'flacons',
                type: QuestionType.Boolean
              },
              {
                name: 'blisters',
                type: QuestionType.Boolean
              },
              {
                name: 'tubes',
                type: QuestionType.Boolean
              },
              {
                name: 'bowls',
                type: QuestionType.Boolean
              },
              {
                name: 'cups',
                type: QuestionType.Boolean
              },
              {
                name: 'tins',
                type: QuestionType.Boolean
              },
              {
                name: 'canisters',
                type: QuestionType.Boolean
              },
              {
                name: 'buckets',
                type: QuestionType.Boolean
              },
              {
                name: 'crucibles',
                type: QuestionType.Boolean
              },
              {
                name: 'boxes',
                type: QuestionType.Boolean
              },
              {
                name: 'barrels',
                type: QuestionType.Boolean
              },
              {
                name: 'ibcs',
                type: QuestionType.Boolean
              },
              {
                name: 'trays',
                type: QuestionType.Boolean
              },
              {
                name: 'vials',
                type: QuestionType.Boolean
              },
              {
                name: 'bodyOfSyringe',
                type: QuestionType.Boolean
              },
              {
                name: 'formboundPlasticsOther',
                type: QuestionType.LongString
              }
            ],
            pdfFile: 'PackPart-C-Mat-Form.pdf'
          },
          {
            name: 'metal',
            inputs: [
              {
                name: 'foil',
                type: QuestionType.Boolean
              },
              {
                name: 'tins',
                type: QuestionType.Boolean
              },
              {
                name: 'bottles',
                type: QuestionType.Boolean
              },
              {
                name: 'shells',
                type: QuestionType.Boolean
              },
              {
                name: 'blisters',
                type: QuestionType.Boolean
              },
              {
                name: 'tubes',
                type: QuestionType.Boolean
              },
              {
                name: 'canisters',
                type: QuestionType.Boolean
              },
              {
                name: 'buckets',
                type: QuestionType.Boolean
              },
              {
                name: 'crucibles',
                type: QuestionType.Boolean
              },
              {
                name: 'metalOther',
                type: QuestionType.LongString
              }
            ],
            pdfFile: 'PackPart-C-Mat-Met.pdf'
          },
          {
            name: 'glasOrCeramic',
            inputs: [
              {
                name: 'ampoules',
                type: QuestionType.Boolean
              },
              {
                name: 'vials',
                type: QuestionType.Boolean
              },
              {
                name: 'bottles',
                type: QuestionType.Boolean
              },
              {
                name: 'flacons',
                type: QuestionType.Boolean
              },
              {
                name: 'wideNeckVessel',
                type: QuestionType.Boolean
              },
              {
                name: 'bodyOfSyringe',
                type: QuestionType.Boolean
              },
              {
                name: 'glasOrCeramicOther',
                type: QuestionType.LongString
              }
            ],
            pdfFile: 'PackPart-C-Mat-GL+KE.pdf'
          },
          {
            name: 'other',
            type: QuestionType.LongString
          }
        ],
        prefill: true
      },
      containerVolume: {
        inputs: [
          {
            type: QuestionType.Float,
            name: 'containerVolume',
            unit: 'empty'
          },
          {
            type: QuestionType.SingleChoiceSelect,
            name: 'unitType',
            options: ['ml', 'gram', 'pieces']
          }
        ],
        requiredType: RequiredType.All,
        multiplePriorities: true,
        initialNotOptional: true,
        prefill: true
      },
      closure: {
        inputs: [
          { type: QuestionType.LongString, name: 'comment' },
          { type: QuestionType.File, name: 'file' }
        ],
        requiredType: RequiredType.MinOne,
        multiplePriorities: true,
        initialNotOptional: true,
        onlyForCustomer: true
      },
      closureType: {
        prefill: true,
        initialNotOptional: true,
        requiredType: RequiredType.MinOne,
        multiplePriorities: true,
        inputs: [
          {
            name: 'rollOn',
            inputs: [
              {
                name: 'pilferProof',
                type: QuestionType.Boolean
              },
              {
                name: 'mca',
                type: QuestionType.Boolean
              },
              {
                name: 'rollOn',
                type: QuestionType.Boolean
              },
              {
                name: 'rollOnOther',
                type: QuestionType.LongString
              }
            ],
            pdfFile: 'PackPart-C-Ver-An.pdf'
          },
          {
            name: 'screw',
            inputs: [
              {
                name: 'twistOff',
                type: QuestionType.Boolean
              },
              {
                name: 'screwLid',
                type: QuestionType.Boolean
              },
              {
                name: 'flipTop',
                type: QuestionType.Boolean
              },
              {
                name: 'pushPull',
                type: QuestionType.Boolean
              },
              {
                name: 'dispenser',
                type: QuestionType.Boolean
              },
              {
                name: 'sprayPump',
                type: QuestionType.Boolean
              },
              {
                name: 'trigger',
                type: QuestionType.Boolean
              },
              {
                name: 'lidOriginal',
                type: QuestionType.Boolean
              },
              {
                name: 'lidChildproof',
                type: QuestionType.Boolean
              },
              {
                name: 'screwOther',
                type: QuestionType.LongString
              }
            ],
            pdfFile: 'PackPart-C-Opt-Ver-Ver.pdf'
          },
          {
            name: 'pressIn',
            inputs: [
              {
                name: 'cork',
                type: QuestionType.Boolean
              },
              {
                name: 'handleCork',
                type: QuestionType.Boolean
              },
              {
                name: 'crownCaps',
                type: QuestionType.Boolean
              },
              {
                name: 'pushInPlugs',
                type: QuestionType.Boolean
              },
              {
                name: 'snapCover',
                type: QuestionType.Boolean
              },
              {
                name: 'bounceClosure',
                type: QuestionType.Boolean
              },
              {
                name: 'decorativeCap',
                type: QuestionType.Boolean
              },
              {
                name: 'pourer',
                type: QuestionType.Boolean
              },
              {
                name: 'dustCaps',
                type: QuestionType.Boolean
              },
              {
                name: 'fingerRest',
                type: QuestionType.Boolean
              },
              {
                name: 'pistonRod',
                type: QuestionType.Boolean
              },
              {
                name: 'pressInOther',
                type: QuestionType.LongString
              }
            ],
            pdfFile: 'PackPart-C-Sort-Ver-Ein-Auf.pdf'
          },
          {
            name: 'sealing',
            inputs: [
              {
                name: 'hfSealing',
                type: QuestionType.Boolean
              },
              {
                name: 'preCutSealing',
                type: QuestionType.Boolean
              },
              {
                name: 'sealingByRole',
                type: QuestionType.Boolean
              },
              {
                name: 'beading',
                type: QuestionType.Boolean
              },
              {
                name: 'plateSealing',
                type: QuestionType.Boolean
              },
              {
                name: 'thermalSealing',
                type: QuestionType.Boolean
              },
              {
                name: 'sealingOther',
                type: QuestionType.LongString
              }
            ]
          },
          {
            name: 'other',
            type: QuestionType.LongString
          }
        ]
      },
      sealingMaterial: {
        initialNotOptional: true,
        requiredType: RequiredType.MinOne,
        multiplePriorities: true,
        inputs: [
          {
            name: 'plastics',
            inputs: [
              {
                name: 'peHdpe',
                type: QuestionType.Boolean
              },
              {
                name: 'pet',
                type: QuestionType.Boolean
              },
              {
                name: 'pp',
                type: QuestionType.Boolean
              },
              {
                name: 'other',
                type: QuestionType.LongString
              }
            ]
          },
          {
            name: 'cork',
            type: QuestionType.Boolean
          },
          {
            name: 'metal',
            type: QuestionType.Boolean
          },
          {
            name: 'glas',
            type: QuestionType.Boolean
          },
          {
            name: 'sheetMetalCover',
            type: QuestionType.Boolean
          },
          {
            name: 'other',
            type: QuestionType.LongString
          }
        ]
      },
      gmpRequirement: {
        inputs: [
          {
            type: QuestionType.YesNo,
            name: 'gmpRequirement'
          },
          {
            type: QuestionType.LongString,
            name: 'other'
          }
        ],
        requiredType: RequiredType.MinOne,
        onlyForBranches: [Branches.Cosmetics, Branches.Food, Branches.Pharma],
        initialNotOptional: true,
        glossarLink: 'https://packpart.eu/verpackungsmaschinen-glossar#g002',
        prefill: true
      },
      fdaConform: {
        inputs: [
          {
            type: QuestionType.YesNo,
            name: 'fdaConform'
          },
          {
            type: QuestionType.LongString,
            name: 'other'
          }
        ],
        requiredType: RequiredType.MinOne,
        onlyForBranches: [Branches.Cosmetics, Branches.Food, Branches.Pharma],
        initialNotOptional: true,
        glossarLink: 'https://packpart.eu/verpackungsmaschinen-glossar#f001',
        prefill: true
      },
      exProtection: {
        inputs: [
          {
            type: QuestionType.SingleChoice,
            name: 'IECEX',
            options: [
              'IECEX',
              'ATEX',
              'NEMA',
              'EACEX',
              'NEPSI',
              'CNEX',
              'false'
            ]
          },
          {
            type: QuestionType.SingleChoice,
            name: 'ATEX',
            options: [
              'IECEX',
              'ATEX',
              'NEMA',
              'EACEX',
              'NEPSI',
              'CNEX',
              'false'
            ]
          },
          {
            type: QuestionType.SingleChoice,
            name: 'NEMA',
            options: [
              'IECEX',
              'ATEX',
              'NEMA',
              'EACEX',
              'NEPSI',
              'CNEX',
              'false'
            ]
          },
          {
            type: QuestionType.SingleChoice,
            name: 'EACEX',
            options: [
              'IECEX',
              'ATEX',
              'NEMA',
              'EACEX',
              'NEPSI',
              'CNEX',
              'false'
            ]
          },
          {
            type: QuestionType.SingleChoice,
            name: 'NEPSI',
            options: [
              'IECEX',
              'ATEX',
              'NEMA',
              'EACEX',
              'NEPSI',
              'CNEX',
              'false'
            ]
          },
          {
            type: QuestionType.SingleChoice,
            name: 'CNEX',
            options: [
              'IECEX',
              'ATEX',
              'NEMA',
              'EACEX',
              'NEPSI',
              'CNEX',
              'false'
            ]
          },
          {
            type: QuestionType.SingleChoice,
            name: 'false',
            options: [
              'IECEX',
              'ATEX',
              'NEMA',
              'EACEX',
              'NEPSI',
              'CNEX',
              'false'
            ]
          },
          {
            type: QuestionType.LongString,
            name: 'other'
          }
        ],
        onlyForBranches: [Branches.Cosmetics, Branches.Chemistry],
        requiredType: RequiredType.MinOne,
        initialNotOptional: true,
        glossarLink: 'https://packpart.eu/verpackungsmaschinen-glossar#e004',
        prefill: true
      },
      pharma: {
        inputs: [
          {
            type: QuestionType.Boolean,
            name: 'high'
          },
          {
            type: QuestionType.Boolean,
            name: 'gray'
          },
          {
            type: QuestionType.LongString,
            name: 'other'
          }
        ],
        onlyForBranches: [Branches.Pharma],
        requiredType: RequiredType.MinOne,
        initialNotOptional: true,
        prefill: true,
        glossarLink: 'https://packpart.eu/verpackungsmaschinen-glossar#p001'
      }
    },
    optionalFields: {
      containerDiameter: {
        inputs: [
          {
            type: QuestionType.Float,
            unit: 'containerDiameter',
            name: 'containerDiameter'
          }
        ],
        requiredType: RequiredType.All,
        pdfFile: 'PackPart-C-Opt-2.pdf',
        multiplePriorities: true,
        prefill: true
      },
      containerHeight: {
        inputs: [
          {
            type: QuestionType.Float,
            unit: 'containerHeight',
            name: 'containerHeight'
          }
        ],
        requiredType: RequiredType.All,
        pdfFile: 'PackPart-C-Opt-3.pdf',
        multiplePriorities: true,
        prefill: true
      },
      containerWidth: {
        inputs: [
          {
            type: QuestionType.Float,
            unit: 'containerWidth',
            name: 'containerWidth'
          }
        ],
        requiredType: RequiredType.All,
        pdfFile: 'PackPart-C-Opt-5.pdf',
        multiplePriorities: true,
        prefill: true
      },
      containerLength: {
        inputs: [
          {
            type: QuestionType.Float,
            unit: 'containerLength',
            name: 'containerLength'
          }
        ],
        requiredType: RequiredType.All,
        pdfFile: 'PackPart-C-Opt-4.pdf',
        multiplePriorities: true,
        prefill: true
      },
      sealDiameter: {
        inputs: [
          {
            type: QuestionType.Float,
            unit: 'sealDiameter',
            name: 'sealDiameter'
          }
        ],
        requiredType: RequiredType.All,
        pdfFile: 'PackPart-C-Opt-7.pdf',
        multiplePriorities: true,
        prefill: true
      },
      sealHeight: {
        inputs: [
          {
            type: QuestionType.Float,
            unit: 'sealHeight',
            name: 'sealHeight'
          }
        ],
        requiredType: RequiredType.All,
        pdfFile: 'PackPart-C-Opt-6.pdf',
        multiplePriorities: true,
        prefill: true
      },
      exactPositionClosure: {
        inputs: [
          {
            type: QuestionType.YesNo,
            name: 'exactPositionClosure'
          }
        ],
        requiredType: RequiredType.All,
        glossarLink: 'https://packpart.eu/verpackungsmaschinen-glossar#g001',
        multiplePriorities: true,
        prefill: true
      },
      stuff: {
        inputs: [
          {
            name: 'separate',
            type: QuestionType.SingleChoice,
            options: ['separate', 'alreadyInClosure', 'false']
          },
          {
            name: 'alreadyInClosure',
            type: QuestionType.SingleChoice,
            options: ['separate', 'alreadyInClosure', 'false']
          },
          {
            name: 'false',
            type: QuestionType.SingleChoice,
            options: ['separate', 'alreadyInClosure', 'false']
          },
          {
            name: 'other',
            type: QuestionType.LongString
          }
        ],
        requiredType: RequiredType.MinOne,
        multiplePriorities: true,
        glossarLink: 'https://packpart.eu/verpackungsmaschinen-glossar#s002',
        prefill: true
      },
      projectBudget: {
        inputs: [
          {
            type: QuestionType.Float,
            name: 'totalBudget',
            unit: 'totalBudget'
          },
          { type: QuestionType.Float, name: 'budgetForC', unit: 'budgetForC' }
        ],
        requiredType: RequiredType.All,
        onlyForCustomer: true,
        prefill: true
      },
      deliveryDate: {
        inputs: [
          {
            type: QuestionType.Date,
            name: 'deliveryDate'
          }
        ],
        requiredType: RequiredType.All,
        onlyForCustomer: true,
        prefill: true
      },
      blowFillSealSolution: {
        inputs: [
          {
            name: 'blowFillSealSolution',
            type: QuestionType.YesNo
          }
        ],
        requiredType: RequiredType.MinOne,
        prefill: true
      },
      other: {
        inputs: [
          {
            name: 'other',
            type: QuestionType.LongString
          }
        ],
        requiredType: RequiredType.All
      }
    }
  },
  label: {
    data: {},
    fields: {
      fullOrHalfAutomatic: {
        inputs: [
          {
            type: QuestionType.Boolean,
            name: 'full'
          },
          {
            type: QuestionType.Boolean,
            name: 'semi'
          },
          {
            type: QuestionType.Boolean,
            name: 'manual'
          }
        ],
        requiredType: RequiredType.MinOne,
        initialNotOptional: true,
        glossarLink: 'https://packpart.eu/verpackungsmaschinen-glossar#v001',
        prefill: true
      },
      outputRate: {
        inputs: [
          {
            type: QuestionType.Float,
            unit: 'amountPerMinute',
            name: 'amountPerMinute'
          }
        ],
        requiredType: RequiredType.All,
        multiplePriorities: true,
        initialNotOptional: true,
        glossarLink: 'https://packpart.eu/verpackungsmaschinen-glossar#a004',
        prefill: true
      },
      packagingMaterial: {
        initialNotOptional: true,
        requiredType: RequiredType.MinOne,
        multiplePriorities: true,
        inputs: [
          {
            type: QuestionType.Boolean,
            name: 'paper'
          },
          {
            type: QuestionType.Boolean,
            name: 'carton'
          },
          {
            name: 'flexiblePlastics',
            inputs: [
              {
                name: 'foil',
                type: QuestionType.Boolean
              },
              {
                name: 'pouches',
                type: QuestionType.Boolean
              },
              {
                name: 'bags',
                type: QuestionType.Boolean
              },
              {
                name: 'sacs',
                type: QuestionType.Boolean
              },
              {
                name: 'bigBags',
                type: QuestionType.Boolean
              },
              {
                name: 'fluidBags',
                type: QuestionType.Boolean
              },
              {
                name: 'hoods',
                type: QuestionType.Boolean
              },
              {
                name: 'nets',
                type: QuestionType.Boolean
              },
              {
                name: 'compounds',
                type: QuestionType.Boolean
              },
              {
                name: 'flexiblePlasticsOther',
                type: QuestionType.LongString
              }
            ],
            pdfFile: 'PackPart-D-Mat-Flex.pdf'
          },
          {
            name: 'formboundPlastics',
            inputs: [
              {
                name: 'bottles',
                type: QuestionType.Boolean
              },
              {
                name: 'ampoules',
                type: QuestionType.Boolean
              },
              {
                name: 'flacons',
                type: QuestionType.Boolean
              },
              {
                name: 'blisters',
                type: QuestionType.Boolean
              },
              {
                name: 'tubes',
                type: QuestionType.Boolean
              },
              {
                name: 'bowls',
                type: QuestionType.Boolean
              },
              {
                name: 'cups',
                type: QuestionType.Boolean
              },
              {
                name: 'tins',
                type: QuestionType.Boolean
              },
              {
                name: 'canisters',
                type: QuestionType.Boolean
              },
              {
                name: 'buckets',
                type: QuestionType.Boolean
              },
              {
                name: 'crucibles',
                type: QuestionType.Boolean
              },
              {
                name: 'boxes',
                type: QuestionType.Boolean
              },
              {
                name: 'barrels',
                type: QuestionType.Boolean
              },
              {
                name: 'ibcs',
                type: QuestionType.Boolean
              },
              {
                name: 'trays',
                type: QuestionType.Boolean
              },
              {
                name: 'vials',
                type: QuestionType.Boolean
              },
              {
                name: 'bodyOfSyringe',
                type: QuestionType.Boolean
              },
              {
                name: 'formboundPlasticsOther',
                type: QuestionType.LongString
              }
            ],
            pdfFile: 'PackPart-D-Mat-Form.pdf'
          },
          {
            name: 'metal',
            inputs: [
              {
                name: 'foil',
                type: QuestionType.Boolean
              },
              {
                name: 'tins',
                type: QuestionType.Boolean
              },
              {
                name: 'bottles',
                type: QuestionType.Boolean
              },
              {
                name: 'shells',
                type: QuestionType.Boolean
              },
              {
                name: 'blisters',
                type: QuestionType.Boolean
              },
              {
                name: 'tubes',
                type: QuestionType.Boolean
              },
              {
                name: 'canisters',
                type: QuestionType.Boolean
              },
              {
                name: 'buckets',
                type: QuestionType.Boolean
              },
              {
                name: 'crucibles',
                type: QuestionType.Boolean
              },
              {
                name: 'metalOther',
                type: QuestionType.LongString
              }
            ],
            pdfFile: 'PackPart-D-Mat-Met.pdf'
          },
          {
            name: 'glasOrCeramic',
            inputs: [
              {
                name: 'ampoules',
                type: QuestionType.Boolean
              },
              {
                name: 'vials',
                type: QuestionType.Boolean
              },
              {
                name: 'bottles',
                type: QuestionType.Boolean
              },
              {
                name: 'flacons',
                type: QuestionType.Boolean
              },
              {
                name: 'wideNeckVessel',
                type: QuestionType.Boolean
              },
              {
                name: 'bodyOfSyringe',
                type: QuestionType.Boolean
              },
              {
                name: 'glasOrCeramicOther',
                type: QuestionType.LongString
              }
            ],
            pdfFile: 'PackPart-D-Mat-GL+KE.pdf'
          },
          {
            name: 'other',
            type: QuestionType.LongString
          }
        ],
        prefill: true
      },
      containerVolume: {
        inputs: [
          {
            type: QuestionType.Float,
            name: 'containerVolume',
            unit: 'empty'
          },
          {
            type: QuestionType.SingleChoiceSelect,
            name: 'unitType',
            options: ['ml', 'gram', 'pieces']
          }
        ],
        initialNotOptional: true,
        requiredType: RequiredType.All,
        multiplePriorities: true,
        prefill: true
      },
      closure: {
        inputs: [
          { type: QuestionType.LongString, name: 'comment' },
          { type: QuestionType.File, name: 'file' }
        ],
        requiredType: RequiredType.MinOne,
        multiplePriorities: true,
        initialNotOptional: true,
        onlyForCustomer: true
      },
      label: {
        initialNotOptional: true,
        requiredType: RequiredType.MinOne,
        multiplePriorities: true,
        inputs: [
          {
            name: 'whatShouldbeLabelled',
            type: QuestionType.LongString
          },
          {
            name: 'pages',
            type: QuestionType.Boolean
          },
          {
            name: 'frontpages',
            type: QuestionType.Boolean
          },
          {
            name: 'backpages',
            type: QuestionType.Boolean
          },
          {
            name: 'cornerFront',
            type: QuestionType.Boolean
          },
          {
            name: 'cornerBack',
            type: QuestionType.Boolean
          },
          {
            name: '2pages',
            type: QuestionType.Boolean
          },
          {
            name: '3pages',
            type: QuestionType.Boolean
          },
          {
            name: '4pages',
            type: QuestionType.Boolean
          },
          {
            name: 'allAround',
            type: QuestionType.Boolean
          },
          {
            name: 'allAroundWithPosition',
            type: QuestionType.Boolean
          },
          {
            name: 'capTop',
            type: QuestionType.Boolean
          },
          {
            name: 'capTopPageBottom',
            type: QuestionType.Boolean
          },
          {
            name: 'securityLabel',
            type: QuestionType.Boolean
          },
          {
            name: 'originalityClosure',
            type: QuestionType.Boolean
          },
          {
            name: 'contactless',
            type: QuestionType.Boolean
          },
          {
            name: 'other',
            type: QuestionType.LongString
          }
        ],
        glossarLink: 'https://packpart.eu/verpackungsmaschinen-glossar#e003',
        pdfFile: 'PackPart-D-Etti.pdf'
      },
      labelMaterial: {
        inputs: [
          {
            type: QuestionType.Boolean,
            name: 'paper'
          },
          {
            type: QuestionType.Boolean,
            name: 'plastics'
          },
          {
            type: QuestionType.Boolean,
            name: 'transparent'
          },
          {
            type: QuestionType.Boolean,
            name: 'booklet'
          },
          {
            type: QuestionType.LongString,
            name: 'other'
          }
        ],
        requiredType: RequiredType.MinOne,
        initialNotOptional: true,
        multiplePriorities: true
      },
      gmpRequirement: {
        inputs: [
          {
            type: QuestionType.YesNo,
            name: 'gmpRequirement'
          },
          {
            type: QuestionType.LongString,
            name: 'other'
          }
        ],
        requiredType: RequiredType.MinOne,
        onlyForBranches: [Branches.Cosmetics, Branches.Food, Branches.Pharma],
        initialNotOptional: true,
        glossarLink: 'https://packpart.eu/verpackungsmaschinen-glossar#g002',
        prefill: true
      },
      fdaConform: {
        inputs: [
          {
            type: QuestionType.YesNo,
            name: 'fdaConform'
          },
          {
            type: QuestionType.LongString,
            name: 'other'
          }
        ],
        requiredType: RequiredType.MinOne,
        onlyForBranches: [Branches.Cosmetics, Branches.Food, Branches.Pharma],
        initialNotOptional: true,
        glossarLink: 'https://packpart.eu/verpackungsmaschinen-glossar#f001',
        prefill: true
      },
      exProtection: {
        inputs: [
          {
            type: QuestionType.SingleChoice,
            name: 'IECEX',
            options: [
              'IECEX',
              'ATEX',
              'NEMA',
              'EACEX',
              'NEPSI',
              'CNEX',
              'false'
            ]
          },
          {
            type: QuestionType.SingleChoice,
            name: 'ATEX',
            options: [
              'IECEX',
              'ATEX',
              'NEMA',
              'EACEX',
              'NEPSI',
              'CNEX',
              'false'
            ]
          },
          {
            type: QuestionType.SingleChoice,
            name: 'NEMA',
            options: [
              'IECEX',
              'ATEX',
              'NEMA',
              'EACEX',
              'NEPSI',
              'CNEX',
              'false'
            ]
          },
          {
            type: QuestionType.SingleChoice,
            name: 'EACEX',
            options: [
              'IECEX',
              'ATEX',
              'NEMA',
              'EACEX',
              'NEPSI',
              'CNEX',
              'false'
            ]
          },
          {
            type: QuestionType.SingleChoice,
            name: 'NEPSI',
            options: [
              'IECEX',
              'ATEX',
              'NEMA',
              'EACEX',
              'NEPSI',
              'CNEX',
              'false'
            ]
          },
          {
            type: QuestionType.SingleChoice,
            name: 'CNEX',
            options: [
              'IECEX',
              'ATEX',
              'NEMA',
              'EACEX',
              'NEPSI',
              'CNEX',
              'false'
            ]
          },
          {
            type: QuestionType.SingleChoice,
            name: 'false',
            options: [
              'IECEX',
              'ATEX',
              'NEMA',
              'EACEX',
              'NEPSI',
              'CNEX',
              'false'
            ]
          },
          {
            type: QuestionType.LongString,
            name: 'other'
          }
        ],
        onlyForBranches: [Branches.Cosmetics, Branches.Chemistry],
        requiredType: RequiredType.MinOne,
        initialNotOptional: true,
        glossarLink: 'https://packpart.eu/verpackungsmaschinen-glossar#e004',
        prefill: true
      },
      pharma: {
        inputs: [
          {
            type: QuestionType.Boolean,
            name: 'high'
          },
          {
            type: QuestionType.Boolean,
            name: 'gray'
          },
          {
            type: QuestionType.LongString,
            name: 'other'
          }
        ],
        onlyForBranches: [Branches.Pharma],
        requiredType: RequiredType.MinOne,
        initialNotOptional: true,
        prefill: true,
        glossarLink: 'https://packpart.eu/verpackungsmaschinen-glossar#p001'
      }
    },
    optionalFields: {
      labelSize: {
        requiredType: RequiredType.All,
        multiplePriorities: true,
        inputs: [
          {
            type: QuestionType.Float,
            unit: 'length',
            name: 'length'
          },
          {
            type: QuestionType.Float,
            unit: 'width',
            name: 'width'
          }
        ],
        glossarLink: 'https://packpart.eu/verpackungsmaschinen-glossar#e002',
        pdfFile: 'PackPart-D-Opt-9.pdf',
        prefill: true
      },
      containerDiameter: {
        inputs: [
          {
            type: QuestionType.Float,
            unit: 'containerDiameter',
            name: 'containerDiameter'
          }
        ],
        requiredType: RequiredType.All,
        pdfFile: 'PackPart-D-Opt-2.pdf',
        multiplePriorities: true,
        prefill: true
      },
      containerHeight: {
        inputs: [
          {
            type: QuestionType.Float,
            unit: 'containerHeight',
            name: 'containerHeight'
          }
        ],
        requiredType: RequiredType.All,
        pdfFile: 'PackPart-D-Opt-3.pdf',
        multiplePriorities: true,
        prefill: true
      },
      containerWidth: {
        inputs: [
          {
            type: QuestionType.Float,
            unit: 'containerWidth',
            name: 'containerWidth'
          }
        ],
        requiredType: RequiredType.All,
        multiplePriorities: true,
        pdfFile: 'PackPart-D-Opt-5.pdf',
        prefill: true
      },
      containerLength: {
        inputs: [
          {
            type: QuestionType.Float,
            unit: 'containerLength',
            name: 'containerLength'
          }
        ],
        requiredType: RequiredType.All,
        pdfFile: 'PackPart-D-Opt-4.pdf',
        multiplePriorities: true,
        prefill: true
      },
      sealDiameter: {
        inputs: [
          {
            type: QuestionType.Float,
            unit: 'sealDiameter',
            name: 'sealDiameter'
          }
        ],
        requiredType: RequiredType.All,
        pdfFile: 'PackPart-D-Opt-7.pdf',
        multiplePriorities: true,
        prefill: true
      },
      sealHeight: {
        inputs: [
          {
            type: QuestionType.Float,
            unit: 'sealHeight',
            name: 'sealHeight'
          }
        ],
        requiredType: RequiredType.All,
        pdfFile: 'PackPart-D-Opt-6.pdf',
        multiplePriorities: true,
        prefill: true
      },
      labelRollSize: {
        inputs: [
          {
            type: QuestionType.Float,
            unit: 'diameter',
            name: 'diameter'
          }
        ],
        requiredType: RequiredType.All,
        multiplePriorities: true,
        glossarLink: 'https://packpart.eu/verpackungsmaschinen-glossar#g003',
        pdfFile: 'PackPart-D-Opt-8.pdf',
        prefill: true
      },
      roleHandlingInstructions: {
        inputs: [
          {
            name: 'textHeaderAheadExternal',
            type: QuestionType.Boolean
          },
          {
            name: 'textFooterAheadExternal',
            type: QuestionType.Boolean
          },
          {
            name: 'textWordEndAheadExternal',
            type: QuestionType.Boolean
          },
          {
            name: 'textWordStartAheadExternal',
            type: QuestionType.Boolean
          },
          {
            name: 'textHeaderAheadInternal',
            type: QuestionType.Boolean
          },
          {
            name: 'textFooterAheadInternal',
            type: QuestionType.Boolean
          },
          {
            name: 'textWordEndAheadInternal',
            type: QuestionType.Boolean
          },
          {
            name: 'textWordStartAheadInternal',
            type: QuestionType.Boolean
          },
          {
            name: 'other',
            type: QuestionType.LongString
          }
        ],
        requiredType: RequiredType.MinOne,
        glossarLink: 'https://packpart.eu/verpackungsmaschinen-glossar#a002',
        pdfFile: 'PackPart-D-Opt-Blatt-1.pdf',
        multiplePriorities: true,
        prefill: true
      },
      encodingOn: {
        inputs: [
          {
            type: QuestionType.Boolean,
            name: 'seal'
          },
          {
            type: QuestionType.Boolean,
            name: 'label'
          },
          {
            type: QuestionType.Boolean,
            name: 'packaging'
          },
          {
            type: QuestionType.LongString,
            name: 'other'
          }
        ],
        multiplePriorities: true,
        requiredType: RequiredType.MinOne,
        glossarLink: 'https://packpart.eu/verpackungsmaschinen-glossar#c002',
        prefill: true
      },
      encodingWith: {
        inputs: [
          {
            type: QuestionType.Boolean,
            name: 'injektVideojet'
          },
          {
            type: QuestionType.Boolean,
            name: 'hotStampingUnit'
          },
          {
            type: QuestionType.Boolean,
            name: 'luminescentColor'
          },
          {
            type: QuestionType.Boolean,
            name: 'barCode'
          },
          {
            type: QuestionType.String,
            name: 'other'
          }
        ],
        multiplePriorities: true,
        requiredType: RequiredType.MinOne,
        glossarLink: 'https://packpart.eu/verpackungsmaschinen-glossar#c003',
        prefill: true
      },
      projectBudget: {
        inputs: [
          {
            type: QuestionType.Float,
            name: 'totalBudget',
            unit: 'totalBudget'
          },
          { type: QuestionType.Float, name: 'budgetForD', unit: 'budgetForD' }
        ],
        requiredType: RequiredType.All,
        onlyForCustomer: true,
        prefill: true
      },
      deliveryDate: {
        inputs: [
          {
            type: QuestionType.Date,
            name: 'deliveryDate'
          }
        ],
        requiredType: RequiredType.All,
        onlyForCustomer: true,
        prefill: true
      },
      printBeforeLabeling: {
        inputs: [
          {
            type: QuestionType.Float,
            name: 'numberOfLines',
            unit: 'numberOfLines'
          },
          {
            type: QuestionType.Float,
            name: 'fontSize',
            unit: 'fontSize'
          },
          {
            type: QuestionType.Boolean,
            name: 'hotStampingPrinting'
          },
          {
            type: QuestionType.Boolean,
            name: 'ttPrinting'
          },
          {
            type: QuestionType.Boolean,
            name: 'inkjetPrinting'
          },
          {
            type: QuestionType.Boolean,
            name: 'no'
          },
          {
            type: QuestionType.LongString,
            name: 'other'
          }
        ],
        requiredType: RequiredType.MinOne,
        prefill: true
      },
      sleeve: {
        inputs: [
          {
            name: 'yes',
            type: QuestionType.SingleChoice,
            options: ['yes', 'no']
          },
          {
            name: 'no',
            type: QuestionType.SingleChoice,
            options: ['yes', 'no']
          }
        ],
        requiredType: RequiredType.MinOne,
        prefill: true
      },
      other: {
        inputs: [
          {
            name: 'other',
            type: QuestionType.LongString
          }
        ],
        requiredType: RequiredType.All
      }
    }
  },
  packaging: {
    data: {},
    fields: {
      fullOrHalfAutomatic: {
        inputs: [
          {
            type: QuestionType.Boolean,
            name: 'full'
          },
          {
            type: QuestionType.Boolean,
            name: 'semi'
          },
          {
            type: QuestionType.Boolean,
            name: 'manual'
          }
        ],
        requiredType: RequiredType.MinOne,
        initialNotOptional: true,
        glossarLink: 'https://packpart.eu/verpackungsmaschinen-glossar#v001',
        prefill: true
      },
      outputRate: {
        inputs: [
          {
            name: 'amountPerMinute',
            unit: 'amountPerMinute',
            type: QuestionType.Float
          }
        ],
        requiredType: RequiredType.All,
        initialNotOptional: true,
        multiplePriorities: true,
        glossarLink: 'https://packpart.eu/verpackungsmaschinen-glossar#a004',
        prefill: true
      },
      primaryPackaging: {
        initialNotOptional: true,
        requiredType: RequiredType.MinOne,
        multiplePriorities: true,
        inputs: [
          {
            type: QuestionType.Boolean,
            name: 'paper'
          },
          {
            type: QuestionType.Boolean,
            name: 'carton'
          },
          {
            name: 'flexiblePlastics',
            inputs: [
              {
                name: 'foil',
                type: QuestionType.Boolean
              },
              {
                name: 'pouches',
                type: QuestionType.Boolean
              },
              {
                name: 'bags',
                type: QuestionType.Boolean
              },
              {
                name: 'sacs',
                type: QuestionType.Boolean
              },
              {
                name: 'bigBags',
                type: QuestionType.Boolean
              },
              {
                name: 'fluidBags',
                type: QuestionType.Boolean
              },
              {
                name: 'hoods',
                type: QuestionType.Boolean
              },
              {
                name: 'nets',
                type: QuestionType.Boolean
              },
              {
                name: 'compounds',
                type: QuestionType.Boolean
              },
              {
                name: 'flexiblePlasticsOther',
                type: QuestionType.LongString
              }
            ],
            pdfFile: 'PackPart-E-Mat-Flex.pdf'
          },
          {
            name: 'formboundPlastics',
            inputs: [
              {
                name: 'bottles',
                type: QuestionType.Boolean
              },
              {
                name: 'ampoules',
                type: QuestionType.Boolean
              },
              {
                name: 'flacons',
                type: QuestionType.Boolean
              },
              {
                name: 'blisters',
                type: QuestionType.Boolean
              },
              {
                name: 'tubes',
                type: QuestionType.Boolean
              },
              {
                name: 'bowls',
                type: QuestionType.Boolean
              },
              {
                name: 'cups',
                type: QuestionType.Boolean
              },
              {
                name: 'tins',
                type: QuestionType.Boolean
              },
              {
                name: 'canisters',
                type: QuestionType.Boolean
              },
              {
                name: 'buckets',
                type: QuestionType.Boolean
              },
              {
                name: 'crucibles',
                type: QuestionType.Boolean
              },
              {
                name: 'boxes',
                type: QuestionType.Boolean
              },
              {
                name: 'barrels',
                type: QuestionType.Boolean
              },
              {
                name: 'ibcs',
                type: QuestionType.Boolean
              },
              {
                name: 'trays',
                type: QuestionType.Boolean
              },
              {
                name: 'vials',
                type: QuestionType.Boolean
              },
              {
                name: 'bodyOfSyringe',
                type: QuestionType.Boolean
              },
              {
                name: 'formboundPlasticsOther',
                type: QuestionType.LongString
              }
            ],
            pdfFile: 'PackPart-E-Mat-Form.pdf'
          },
          {
            name: 'metal',
            inputs: [
              {
                name: 'foil',
                type: QuestionType.Boolean
              },
              {
                name: 'tins',
                type: QuestionType.Boolean
              },
              {
                name: 'bottles',
                type: QuestionType.Boolean
              },
              {
                name: 'shells',
                type: QuestionType.Boolean
              },
              {
                name: 'blisters',
                type: QuestionType.Boolean
              },
              {
                name: 'tubes',
                type: QuestionType.Boolean
              },
              {
                name: 'canisters',
                type: QuestionType.Boolean
              },
              {
                name: 'buckets',
                type: QuestionType.Boolean
              },
              {
                name: 'crucibles',
                type: QuestionType.Boolean
              },
              {
                name: 'metalOther',
                type: QuestionType.LongString
              }
            ],
            pdfFile: 'PackPart-E-Mat-Met.pdf'
          },
          {
            name: 'glasOrCeramic',
            inputs: [
              {
                name: 'ampoules',
                type: QuestionType.Boolean
              },
              {
                name: 'vials',
                type: QuestionType.Boolean
              },
              {
                name: 'bottles',
                type: QuestionType.Boolean
              },
              {
                name: 'flacons',
                type: QuestionType.Boolean
              },
              {
                name: 'wideNeckVessel',
                type: QuestionType.Boolean
              },
              {
                name: 'bodyOfSyringe',
                type: QuestionType.Boolean
              },
              {
                name: 'glasOrCeramicOther',
                type: QuestionType.LongString
              }
            ],
            pdfFile: 'PackPart-E-Mat-GL+KE.pdf'
          },
          {
            name: 'other',
            type: QuestionType.LongString
          }
        ],
        glossarLink: 'https://packpart.eu/verpackungsmaschinen-glossar#p003'
      },
      groupOfPrimaryPackagingInSecondary: {
        inputs: [
          {
            name: 'upload',
            type: QuestionType.File
          },
          {
            name: 'description',
            type: QuestionType.LongString
          }
        ],
        onlyForCustomer: true,
        initialNotOptional: true,
        multiplePriorities: true,
        requiredType: RequiredType.MinOne
      },
      /* closure: {
        inputs: [
          { type: QuestionType.LongString, name: 'comment' },
          { type: QuestionType.File, name: 'file' }
        ],
        requiredType: RequiredType.MinOne,
        multiplePriorities: true,
        initialNotOptional: true,
        onlyForCustomer: true
      }, */
      packageSize: {
        inputs: [
          {
            name: 'length',
            unit: 'length',
            type: QuestionType.Float
          },
          {
            name: 'width',
            unit: 'width',
            type: QuestionType.Float
          },
          {
            name: 'height',
            unit: 'height',
            type: QuestionType.Float
          }
        ],
        initialNotOptional: true,
        multiplePriorities: true,
        requiredType: RequiredType.All
      },
      feed: {
        inputs: [
          {
            name: 'clocked',
            type: QuestionType.Boolean
          },
          {
            name: 'constant',
            type: QuestionType.Boolean
          },
          {
            name: 'multiLane',
            type: QuestionType.Boolean
          },
          {
            name: 'other',
            type: QuestionType.LongString
          }
        ],
        initialNotOptional: true,
        requiredType: RequiredType.MinOne
      },
      productDrawing: {
        inputs: [
          {
            name: 'upload',
            type: QuestionType.File
          },
          {
            name: 'description',
            type: QuestionType.LongString
          }
        ],
        onlyForCustomer: true,
        initialNotOptional: true,
        multiplePriorities: true,
        requiredType: RequiredType.MinOne
      },
      closeSecondaryPackaging: {
        inputs: [
          {
            name: 'glue',
            type: QuestionType.Boolean
          },
          {
            name: 'tape',
            type: QuestionType.Boolean
          },
          {
            name: 'clickclock',
            type: QuestionType.Boolean
          },
          {
            name: 'other',
            type: QuestionType.LongString
          }
        ],
        initialNotOptional: true,
        requiredType: RequiredType.MinOne
      },
      secondaryPackagingMaterial: {
        inputs: [
          {
            name: 'corrugatedCarton',
            inputs: [
              {
                name: 'foldingBoxes',
                type: QuestionType.Boolean
              },
              {
                name: 'onePieceCartons',
                type: QuestionType.Boolean
              },
              {
                name: 'multipartCartons',
                type: QuestionType.Boolean
              },
              {
                name: 'wraparoundCarton',
                type: QuestionType.Boolean
              },
              {
                name: 'shippingCarton',
                type: QuestionType.Boolean
              },
              {
                name: 'specialFormatCarton',
                type: QuestionType.Boolean
              },
              {
                name: 'bottlesShippingCarton',
                type: QuestionType.Boolean
              },
              {
                name: 'other',
                type: QuestionType.LongString
              }
            ]
          },
          {
            name: 'solidCarton',
            inputs: [
              {
                name: 'foldingBoxes',
                type: QuestionType.Boolean
              },
              {
                name: 'onePieceCartons',
                type: QuestionType.Boolean
              },
              {
                name: 'multipartCartons',
                type: QuestionType.Boolean
              },
              {
                name: 'wraparoundCarton',
                type: QuestionType.Boolean
              },
              {
                name: 'shippingCarton',
                type: QuestionType.Boolean
              },
              {
                name: 'specialFormatCarton',
                type: QuestionType.Boolean
              },
              {
                name: 'bottlesShippingCarton',
                type: QuestionType.Boolean
              },
              {
                name: 'other',
                type: QuestionType.LongString
              }
            ]
          },
          {
            name: 'foil',
            inputs: [
              {
                name: 'banderole',
                type: QuestionType.Boolean
              },
              {
                name: 'hikingRolle',
                type: QuestionType.Boolean
              },
              {
                name: 'foilFullInsert',
                type: QuestionType.Boolean
              },
              {
                name: 'stretchFoil',
                type: QuestionType.Boolean
              },
              {
                name: 'tubularBag',
                type: QuestionType.Boolean
              },
              {
                name: 'standupPouches',
                type: QuestionType.Boolean
              },
              {
                name: 'deepDrawnBowl',
                type: QuestionType.Boolean
              },
              {
                name: 'other',
                type: QuestionType.LongString
              }
            ]
          },
          {
            name: 'other',
            type: QuestionType.LongString
          }
        ],
        initialNotOptional: true,
        requiredType: RequiredType.MinOne
      },
      specialAlignmentOfPrimaryPackaginInSecondaryPackaging: {
        inputs: [
          {
            name: 'yes',
            type: QuestionType.SingleChoice,
            options: ['yes', 'no']
          },
          {
            name: 'no',
            type: QuestionType.SingleChoice,
            options: ['yes', 'no']
          }
        ],
        initialNotOptional: true,
        requiredType: RequiredType.MinOne
      }
    },
    optionalFields: {
      automationSolutions: {
        inputs: [
          {
            name: 'picking',
            type: QuestionType.Boolean
          },
          {
            name: 'sideloading',
            type: QuestionType.Boolean
          },
          {
            name: 'toploading',
            type: QuestionType.Boolean
          },
          {
            name: 'palletizing',
            type: QuestionType.Boolean
          },
          {
            name: 'mixPacking',
            type: QuestionType.Boolean
          },
          {
            name: 'liddingMachines',
            type: QuestionType.Boolean
          },
          {
            name: 'cartonSealer',
            type: QuestionType.Boolean
          },
          {
            name: 'wrapAround',
            type: QuestionType.Boolean
          },
          {
            name: 'other',
            type: QuestionType.LongString
          }
        ],
        requiredType: RequiredType.MinOne,
        glossarLink: 'https://packpart.eu/verpackungsmaschinen-glossar#a005',
        prefill: true
      },
      primaryPackagingSize: {
        inputs: [
          {
            type: QuestionType.Float,
            name: 'containerVolume',
            unit: 'empty'
          },
          {
            type: QuestionType.SingleChoiceSelect,
            name: 'unitType',
            options: ['ml', 'gram', 'pieces']
          }
        ],
        requiredType: RequiredType.All,
        multiplePriorities: true
        /* inputs: [
          {
            type: QuestionType.Float,
            name: 'amountInMl',
            unit: 'amountInMl'
          },
          {
            type: QuestionType.Float,
            name: 'amount',
            unit: 'amount'
          },
          {
            type: QuestionType.Float,
            name: 'weightInGram',
            unit: 'weightInGram'
          }
        ],
        requiredType: RequiredType.MinOne,
        prefill: true */
      },
      secondaryPackagingLock: {
        inputs: [
          {
            type: QuestionType.LongString,
            name: 'description'
          },
          {
            type: QuestionType.File,
            name: 'upload'
          }
        ],
        onlyForCustomer: true,
        requiredType: RequiredType.MinOne,
        prefill: true
      },
      /*   containerVolume: {
        inputs: [
          {
            type: QuestionType.Float,
            name: 'containerVolume',
            unit: 'empty'
          },
          {
            type: QuestionType.SingleChoiceSelect,
            name: 'unitType',
            options: ['ml', 'gram', 'pieces']
          }
        ],
        requiredType: RequiredType.All,
        multiplePriorities: true,
        prefill: true
      }, */
      tertiaryPackaging: {
        inputs: [
          {
            name: 'solidWoodPallets',
            type: QuestionType.Boolean
          },
          {
            name: 'euroPallet',
            type: QuestionType.Boolean
          },
          {
            name: 'plasticPallet',
            type: QuestionType.Boolean
          },
          {
            name: 'inkaPallet',
            type: QuestionType.Boolean
          },
          {
            name: 'corrugatedCardboardPallet',
            type: QuestionType.Boolean
          },
          {
            name: 'B4BLightPallet',
            type: QuestionType.Boolean
          },
          {
            name: 'quarterChipPallet',
            type: QuestionType.Boolean
          },
          {
            name: 'latticeBoxes',
            type: QuestionType.Boolean
          },
          {
            name: 'container',
            type: QuestionType.Boolean
          },
          {
            name: 'other',
            type: QuestionType.LongString
          }
        ],
        requiredType: RequiredType.MinOne,
        prefill: true
      },
      projectBudget: {
        inputs: [
          {
            type: QuestionType.Float,
            name: 'totalBudget',
            unit: 'totalBudget'
          }
        ],
        requiredType: RequiredType.All,
        onlyForCustomer: true,
        prefill: true
      },
      deliveryDate: {
        inputs: [
          {
            type: QuestionType.Date,
            name: 'deliveryDate'
          }
        ],
        requiredType: RequiredType.All,
        onlyForCustomer: true,
        prefill: true
      },
      other: {
        inputs: [
          {
            type: QuestionType.LongString,
            name: 'other'
          }
        ],
        requiredType: RequiredType.All,
        onlyForCustomer: true
      },
      additionalInfo: {
        inputs: [
          {
            name: 'additionalInfo',
            type: QuestionType.LongString
          }
        ],
        requiredType: RequiredType.All
      }
    }
  }
}

// Adapt defaultProjectValues for producer
const offeringValues = cloneDeep(defaultProjectValues)
// Set multiPrio to false if field has no min-max input
// Sort: Not Optional
set(
  offeringValues,
  'sort.sortAndFeed.fields.packagingMaterial.multiplePriorities',
  false
)
set(offeringValues, 'sort.sortAndFeed.fields.closure.multiplePriorities', false)
// Sort: Optional
// Fill: Not Optional
set(offeringValues, 'fill.fields.packagingMaterial.multiplePriorities', false)
set(offeringValues, 'fill.fields.closure.multiplePriorities', false)
set(offeringValues, 'fill.fields.productViscosity.multiplePriorities', false)
set(offeringValues, 'fill.fields.productViscosity.inputs[0].inputs', [
  {
    name: 'lowViscosity',
    type: QuestionType.Boolean
  },
  {
    name: 'mediumViscosity',
    type: QuestionType.Boolean
  },
  {
    name: 'highViscosity',
    type: QuestionType.Boolean
  },
  {
    name: 'pasty',
    type: QuestionType.Boolean
  },
  {
    name: 'powdery',
    type: QuestionType.Boolean
  },
  {
    name: 'solid',
    type: QuestionType.Boolean
  }
])
set(
  offeringValues,
  'fill.fields.productViscosity.inputs[0].requiredType',
  RequiredType.MinOne
)

const productViscosityInputs = get(
  offeringValues,
  'fill.fields.productViscosity.inputs'
)
remove(productViscosityInputs, (obj: Input) => obj.name === 'productName')
set(
  offeringValues,
  'fill.fields.productViscosity.inputs',
  productViscosityInputs
)

// Fill: Optional
set(offeringValues, 'fill.optionalFields.fillingType.multiplePriorities', false)
// Seal: Not Optional
set(offeringValues, 'seal.fields.packagingMaterial.multiplePriorities', false)
set(offeringValues, 'seal.fields.closure.multiplePriorities', false)
set(offeringValues, 'seal.fields.closureType.multiplePriorities', false)
set(offeringValues, 'seal.fields.sealingMaterial.multiplePriorities', false)
// Seal: Optional
// Label: Not Optional
set(offeringValues, 'label.fields.packagingMaterial.multiplePriorities', false)
set(offeringValues, 'label.fields.closure.multiplePriorities', false)
set(offeringValues, 'label.fields.label.multiplePriorities', false)
// Packaging: Not Optional
set(
  offeringValues,
  'packaging.fields.primaryPackaging.multiplePriorities',
  false
)
set(offeringValues, 'packaging.fields.closure', {
  inputs: [
    { type: QuestionType.LongString, name: 'comment' },
    { type: QuestionType.File, name: 'file' }
  ],
  requiredType: RequiredType.MinOne,
  multiplePriorities: false,
  initialNotOptional: true,
  onlyForCustomer: true,
  prefill: true
})
set(
  offeringValues,
  'seal.optionalFields.exactPositionClosure.multiplePriorities',
  false
)
set(offeringValues, 'label.optionalFields.encodingOn.multiplePriorities', false)
set(
  offeringValues,
  'label.optionalFields.encodingWith.multiplePriorities',
  false
)
set(
  offeringValues,
  'label.optionalFields.roleHandlingInstructions.multiplePriorities',
  false
)

// Modify fields
const exProtection = {
  inputs: [
    {
      type: QuestionType.Boolean,
      name: 'IECEX'
    },
    {
      type: QuestionType.Boolean,
      name: 'ATEX'
    },
    {
      type: QuestionType.Boolean,
      name: 'NEMA'
    },
    {
      type: QuestionType.Boolean,
      name: 'EACEX'
    },
    {
      type: QuestionType.Boolean,
      name: 'NEPSI'
    },
    {
      type: QuestionType.Boolean,
      name: 'CNEX'
    },
    {
      type: QuestionType.Boolean,
      name: 'false'
    },
    {
      type: QuestionType.LongString,
      name: 'other'
    }
  ],
  requiredType: RequiredType.MinOne,
  onlyForBranches: [Branches.Cosmetics, Branches.Chemistry],
  initialNotOptional: true,
  glossarLink: 'https://packpart.eu/verpackungsmaschinen-glossar#e004',
  prefill: true
}
// Sort: Not Optional
set(offeringValues, 'sort.sortAndFeed.fields.exProtection', exProtection)
// Sort: Optional
set(
  offeringValues,
  'sort.sortAndFeed.optionalFields.machineRunningDirection.inputs',
  [
    {
      name: 'fromLeftToRight',
      type: QuestionType.Boolean
    },
    {
      name: 'fromRightToLeft',
      type: QuestionType.Boolean
    }
  ]
)

// Fill: Not Optional
set(offeringValues, 'fill.fields.exProtection', exProtection)
// Fill: Optional
set(offeringValues, 'fill.optionalFields.packingProcess.inputs', [
  {
    name: 'horizontalPackingProcess',
    type: QuestionType.Boolean
  },
  {
    name: 'verticalPackingProcess',
    type: QuestionType.Boolean
  }
])

set(offeringValues, 'fill.optionalFields.machineRunningDirection.inputs', [
  {
    name: 'fromLeftToRight',
    type: QuestionType.Boolean
  },
  {
    name: 'fromRightToLeft',
    type: QuestionType.Boolean
  }
])
// Seal: Not Optional
set(offeringValues, 'seal.fields.exProtection', exProtection)
// Label: Not Optional
set(offeringValues, 'label.fields.exProtection', exProtection)
set(offeringValues, 'label.fields.label.inputs[0].unit', 'whatCanBeLabelled')

const stuff = {
  inputs: [
    {
      name: 'separate',
      type: QuestionType.Boolean
    },
    {
      name: 'alreadyInClosure',
      type: QuestionType.Boolean
    },
    {
      name: 'other',
      type: QuestionType.LongString
    }
  ],
  requiredType: RequiredType.MinOne,
  glossarLink: 'https://packpart.eu/verpackungsmaschinen-glossar#s002',
  prefill: true
}
set(offeringValues, 'seal.optionalFields.stuff', stuff)

const cipSipCleaning = {
  inputs: [
    {
      name: 'cipCleaning',
      type: QuestionType.Boolean
    },
    {
      name: 'sipCleaning',
      type: QuestionType.Boolean
    }
  ],
  requiredType: RequiredType.MinOne,
  prefill: true
}
set(offeringValues, 'fill.optionalFields.cipSipCleaning', cipSipCleaning)

const fillProductUnderTemp = {
  inputs: [
    {
      name: 'yes',
      type: QuestionType.SingleChoice,
      options: ['yes', 'no']
    },
    {
      name: 'no',
      type: QuestionType.SingleChoice,
      options: ['yes', 'no']
    }
  ],
  requiredType: RequiredType.MinOne,
  prefill: true
}
set(
  offeringValues,
  'fill.optionalFields.fillProductUnderTemp',
  fillProductUnderTemp
)

set(
  offeringValues,
  'packaging.fields.groupOfPrimaryPackagingInSecondary.multiplePriorities',
  false
)
export { offeringValues }
