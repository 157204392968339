/* eslint-disable import/no-named-default */
import React, { ChangeEvent, useEffect, useRef, useState } from 'react'
import {
  default as ReactImageGallery,
  ReactImageGalleryItem,
  ReactImageGalleryProps
} from 'react-image-gallery'
import { Button, Grid } from '@material-ui/core'
import { default as UnstyledAddIcon } from '@material-ui/icons/Add'
import { default as UnstyledDeleteIcon } from '@material-ui/icons/Delete'
import styled from 'styled-components'
import 'react-image-gallery/styles/css/image-gallery.css'
import uniqBy from 'lodash/uniqBy'
import { useTranslation } from 'react-i18next'
import { CustomGalleryItem } from 'packpart-interfaces/lib'

interface ImageGalleryContainerProps {
  isFullscreen: boolean
  fixedHeight?: string
  showImageName?: boolean
}

const ImageGalleryContainer = styled.div<ImageGalleryContainerProps>`
  display: flex;
  flex-direction: column;
  position: relative;
  overflow: hidden;
  // set fixed height
  .image-gallery-image {
    ${({ isFullscreen, fixedHeight }) =>
      !isFullscreen &&
      fixedHeight &&
      `
    height: ${fixedHeight} !important;
  `}
  }
  // set filename in left-upper corner
  .image-gallery-description {
    bottom: unset !important;
    display: ${({ showImageName }) => showImageName === false && 'none'};
  }
`

const AddButton = styled(Button)<{ component: string }>`
  text-transform: none;
  background-color: ${({ theme }) => theme.secondary};
  :hover {
    background-color: ${({ theme }) => theme.primary};
  }
`
const AddIcon = styled(UnstyledAddIcon)``
const DeleteButton = styled(Button)`
  text-transform: none;
  background-color: ${({ theme }) => theme.secondary};
  :hover {
    background-color: red;
  }
`
const DeleteIcon = styled(UnstyledDeleteIcon)``

export const ImageGallery = ({
  imageItems,
  setImageItems,
  showImageName = true,
  showButtons = true,
  fixedHeight,
  className,
  ...imageGalleryProps
}: {
  imageItems: CustomGalleryItem[]
  setImageItems: React.Dispatch<React.SetStateAction<CustomGalleryItem[]>>
  showImageName?: boolean
  showButtons?: boolean
  fixedHeight?: string
  className?: string
} & Omit<ReactImageGalleryProps, 'items'>) => {
  const { t } = useTranslation()
  const dummyImage = [
    {
      original: '/images/icons/icon-512x512.png',
      title: 'Image'
    }
  ]
  const ref = useRef<ReactImageGallery>(null)
  const [isFullscreen, setisFullscreen] = useState(false)
  const [startIndex, setStartIndex] = useState(0)
  const [items, setItems] = useState<ReactImageGalleryItem[]>([])

  useEffect(() => {
    const newItems: ReactImageGalleryItem[] = []
    imageItems?.forEach((imgItem) => {
      const { file, storageUrl, ...otherProps } = imgItem
      newItems.push({ ...otherProps })
    })
    setItems(newItems)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [imageItems])

  const handleAddImages = (event: ChangeEvent<HTMLInputElement>) => {
    const files = event.currentTarget.files
    if (files) {
      const imageFiles: CustomGalleryItem[] = []
      Array.from(files).forEach((file) =>
        imageFiles.push({
          original: URL.createObjectURL(file),
          description: file.name,
          file
        })
      )
      setImageItems((prevArray: CustomGalleryItem[]) =>
        uniqBy([...prevArray, ...imageFiles], 'description')
      )
    }
  }

  const handleDeleteImage = () => {
    const currentIndex = ref?.current?.getCurrentIndex()
    if (currentIndex !== undefined) {
      const hasHighestIndex = items.length - 1 === currentIndex
      const isLastImage = items.length === 1
      setImageItems((prevArray: CustomGalleryItem[]) => {
        const copy = [...prevArray]
        copy.splice(currentIndex, 1)
        return copy
      })
      // default startIndex is 0, this causes ugly sliding of images on delete
      setStartIndex(
        hasHighestIndex && !isLastImage ? currentIndex - 1 : currentIndex
      )
    }
  }

  return (
    <ImageGalleryContainer
      className={className}
      isFullscreen={isFullscreen}
      fixedHeight={fixedHeight}
      showImageName={showImageName}>
      <ReactImageGallery
        key={items.length}
        ref={ref}
        startIndex={startIndex}
        items={items.length < 1 ? dummyImage : items}
        onScreenChange={(isFullscreen) => setisFullscreen(isFullscreen)}
        showPlayButton={false}
        showIndex={true}
        {...imageGalleryProps}
      />
      {showButtons && (
        <Grid
          style={{ padding: '10px 0' }}
          justifyContent="space-between"
          container
          spacing={1}>
          <Grid item>
            <AddButton
              component="label"
              variant="contained"
              endIcon={<AddIcon />}>
              {t('buttons.addPicture')}
              <input
                hidden
                multiple
                onChange={handleAddImages}
                type="file"
                accept="image/*"
              />
            </AddButton>
          </Grid>
          <Grid item>
            <DeleteButton
              disabled={items.length < 1}
              variant="contained"
              endIcon={<DeleteIcon />}
              onClick={handleDeleteImage}>
              {t('buttons.deleteThisPicture')}
            </DeleteButton>
          </Grid>
        </Grid>
      )}
    </ImageGalleryContainer>
  )
}
