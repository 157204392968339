/* eslint-disable react-hooks/exhaustive-deps */
import Breadcrumbs from '@components/elements/Breadcrumbs'
import { CardGrid } from '@components/elements/CompanyCardGrid'
import CustomHelmet from '@components/elements/CustomHelmet'
import { Dashboard } from '@components/elements/Dashboard'
import { Searchbar as UnstyledSearchBar } from '@components/elements/Searchbar'
import { Match, ProfileTypes } from '@interfaces/user'
import CircularProgress from '@material-ui/core/CircularProgress'
import { selectCompanyType } from '@selectors/companySelectors'
import { selectCompanyID } from '@selectors/profileSelectors'
import { selectMatchesByID } from '@selectors/projectSelectors'
import { selectSidebarIsOpen } from '@selectors/sidebarSelectors'
import React, { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { useFirestoreConnect } from 'react-redux-firebase'
import { Redirect, useParams } from 'react-router-dom'
import styled from 'styled-components'

const RootContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  padding-right: 35px;
  overflow: hidden;
`
const SearchBar = styled(UnstyledSearchBar)`
  padding-top: 8px;
  padding-bottom: 8px;
`

const Title = styled.h1`
  margin-bottom: 20px;
`

interface ProgressCircleInterface {
  progress: number
}
const ProgressCircleContainer = styled.div<ProgressCircleInterface>`
  margin: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 25px;
  position: relative;
  .MuiCircularProgress-static {
    position: absolute;
    color: ${({ theme }) => theme.primary};
  }
`

export const MatchedOverview = ({
  helmetTitle,
  title,
  renderCard
}: {
  helmetTitle: string
  title: string
  renderCard: (match: Match, searchValue: string) => JSX.Element
}) => {
  const { t } = useTranslation()
  const { projectID } = useParams<{ projectID: string }>()
  const inset = useSelector(selectSidebarIsOpen)
  const [calcProgress, setCalcProgress] = useState(0)
  const calcProgressRef = useRef<any>(null)
  const [searchValue, setSearchValue] = useState<string>('')
  const companyID = useSelector(selectCompanyID)
  const type = useSelector(selectCompanyType)
  const matches = useSelector(selectMatchesByID(projectID))
  useFirestoreConnect([
    {
      collection: 'companies',
      doc: companyID,
      storeAs: `project/${projectID}/matches`,
      subcollections: [
        {
          collection: 'projects',
          doc: projectID,

          subcollections: [{ collection: 'matches' }]
        }
      ]
    }
  ])

  useEffect(() => {
    calcProgressRef.current = setInterval(() => {
      setCalcProgress((c) => c + 1)
    }, 100)
    return () => clearInterval(calcProgressRef.current)
  }, [])
  useEffect(() => {
    if (calcProgress >= 150 || matches?.length > 0)
      clearInterval(calcProgressRef.current)
    if (calcProgress >= 150 && matches?.length === 0) {
      console.log('No Matches, send Email')
    }
  }, [calcProgress, matches])

  if (calcProgress < 150 && matches?.length === 0)
    return (
      <Dashboard inset={inset}>
        {t('partnerOverview.searchPartner')}
        <ProgressCircleContainer progress={calcProgress / 150}>
          {((calcProgress / 150) * 100).toFixed(0)}%
          <CircularProgress
            variant="static"
            size={200}
            value={(calcProgress / 150) * 100}
          />
        </ProgressCircleContainer>
      </Dashboard>
    )

  if (!matches) return <>{t('partnerOverview.searchPartnerNote')}</>
  if (type === ProfileTypes.Offering) return <Redirect to="/" />
  return (
    <Dashboard inset={inset}>
      <CustomHelmet title={helmetTitle} />
      <Breadcrumbs
        crumbs={[
          { link: '/', text: 'Dashboard' },
          {
            active: true,
            text: title
          }
        ]}
      />
      <Title>{title.toUpperCase()}</Title>
      <RootContainer>
        {matches?.length > 0 && (
          <SearchBar
            searchValue={searchValue}
            setSearchValue={setSearchValue}
          />
        )}
        <CardGrid>
          {matches.map((match) => renderCard(match, searchValue))}
        </CardGrid>
        {!matches || (matches?.length === 0 && t('partnerOverview.noPartners'))}
      </RootContainer>
    </Dashboard>
  )
}
