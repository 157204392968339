import { ProjectCards } from '@components/elements/ProjectCards'
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Grid
} from '@material-ui/core'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import { selectCompany } from '@selectors/companySelectors'
import { selectCompanyID } from '@selectors/profileSelectors'
import {
  selectConfirmedMachineMatches,
  selectNewMachineMatches
} from '@selectors/projectSelectors'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import styled from 'styled-components'

const StyledAccordion = styled(Accordion)`
  // Remove grey line above Accordion
  &:before {
    display: none;
  }
`

const StyledGridItem = styled(Grid)`
  // Empty grid items should not occupy space
  &:empty {
    display: none;
  }
`

const StyledProjectCard = styled(ProjectCards)`
  height: 100%;
  .pC_MoreButton {
    margin: 20px;
  }
`

const EmptyCard = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  border-radius: 10px;
  box-sizing: border-box;
  border: dashed 2px;
`

const NoCards = styled.div`
  color: white;
  margin-left: 15px;
  margin-top: 20px;
`

export const RequestsAccordion = ({
  searchValue,
  showConfirmed
}: {
  searchValue: string
  showConfirmed?: boolean
}) => {
  const { t } = useTranslation()
  const history = useHistory()
  const [expanded, setExpanded] = useState(true)
  const company = useSelector(selectCompany)
  const myCompanyID = useSelector(selectCompanyID)
  const matches = useSelector(
    showConfirmed
      ? selectConfirmedMachineMatches(company, myCompanyID)
      : selectNewMachineMatches(company, myCompanyID)
  )

  const openMatch = (customerCompanyID: string, customerProjectID: string) => {
    history.push(`/dashboard/${customerCompanyID}/${customerProjectID}`)
  }

  const showEmptyCards = matches.length % 3 !== 0
  const showTwoEmptyCards = matches.length % 3 === 1

  const title = showConfirmed
    ? t('partnerOverview.confirmedRequests')
    : t('home.currentRequests')
  const noRequestsTitle = showConfirmed
    ? t('home.noConfirmedRequests')
    : t('partnerOverview.noCurrentRequests')

  return (
    <StyledAccordion
      style={{ boxShadow: 'none', background: 'none' }}
      expanded={expanded}
      onChange={() => setExpanded((prevState) => !prevState)}>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <h3 style={{ fontSize: 25 }}>{title}</h3>
      </AccordionSummary>
      <AccordionDetails>
        <Grid container spacing={3}>
          {matches.map((match) => (
            <StyledGridItem
              item
              xs={12}
              sm={6}
              md={4}
              style={{ height: 300 }}
              key={match.id}>
              <StyledProjectCard
                searchValue={searchValue}
                projectPath={match.path}
                type={showConfirmed ? 'confirmed' : 'new'}
                onClick={() =>
                  openMatch(match.customerCompanyID, match.customerProjectID)
                }
                machineName={match.machineName}
              />
            </StyledGridItem>
          ))}
          {showEmptyCards && (
            <StyledGridItem item xs={12} sm={6} md={4} style={{ height: 300 }}>
              <EmptyCard />
            </StyledGridItem>
          )}
          {showEmptyCards && showTwoEmptyCards && (
            <StyledGridItem item xs={12} sm={6} md={4} style={{ height: 300 }}>
              <EmptyCard />
            </StyledGridItem>
          )}
          {matches.length < 1 && <NoCards>{noRequestsTitle}</NoCards>}
        </Grid>
      </AccordionDetails>
    </StyledAccordion>
  )
}
