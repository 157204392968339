import React from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import { AvailableBranches, Branches } from '@interfaces/branches'
import { Field } from 'formik'
const SelectorContainer = styled.div<{ hasError: boolean }>`
  display: flex;
  flex-direction: column;
  background-color: white;
  border-radius: 5px;
  box-sizing: border-box;
  padding: 10px 15px;
  color: black;
  ${({ hasError }) =>
    hasError &&
    `
    border: 1px solid red;
  `}
`
const Title = styled.p`
  color: black;
  border-bottom: ${(props) => `1px solid ${props.theme.surface}`};
  padding-bottom: 5px;
  margin-bottom: 10px;
  box-sizing: border-box;
`
const StyledLabel = styled.label`
  color: black;
  display: flex;
  flex: 1 1 50%;
  margin: 10px 0;
  align-items: center;
`
const StyledField = styled(Field)`
  margin-right: 10px;
  cursor: pointer;
  height: 24px;
`
const CheckboxContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  padding-bottom: 10px;
`

const CheckboxWithLabel = ({ branch }: { branch: Branches }) => {
  const { t } = useTranslation()

  return (
    <StyledLabel>
      <StyledField type="checkbox" name="branches" value={branch} />
      {t(`branches.${branch}`)}
    </StyledLabel>
  )
}

const BranchesSelector = ({
  onChange,
  value,
  isTouched,
  errorMessage
}: {
  onChange: (value: any) => void
  value: string[]
  isTouched: boolean
  errorMessage: string
}) => {
  const { t } = useTranslation()

  return (
    <SelectorContainer hasError={isTouched && errorMessage !== undefined}>
      <Title>{t('register.form.placeholders.branches')}</Title>

      <CheckboxContainer role="group" aria-labelledby="checkbox-group">
        {AvailableBranches.map((branch: Branches) => {
          return <CheckboxWithLabel branch={branch} key={branch} />
        })}
      </CheckboxContainer>
    </SelectorContainer>
  )
}

export default BranchesSelector
