import styled from 'styled-components'

const BaseInput = styled.input`
  color: black;
  border-width: 0ch;
  height: 25px;
  border-radius: 12.5px;
  padding-left: 10px;
  margin: 5px 0;
  outline: none;
`

export default BaseInput
