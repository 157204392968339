import { createSelector } from 'reselect'

export const selectRegister = (s: any) => s.register

export const selectRegisterType = createSelector(
  selectRegister,
  (register) => register.type
)
export const selectCurrentRegisterStep = createSelector(
  selectRegister,
  (register) => register.currentStep
)
export const selectRegisterForm = createSelector(
  selectRegister,
  (register) => register.form
)
