/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react'
import { useFirebase } from 'react-redux-firebase'
import { useParams, useLocation } from 'react-router-dom'
import styled, { ThemeProvider } from 'styled-components'
import CompanySpectrum from './CompanySpectrum'
import { BigSizeContext } from '@services/contexts'
import i18next from 'i18next'
import { PriceAndWarranty } from '@screens/addProject/usedMachinerySupplier/PriceAndWarranty'
import { CustomGalleryItem } from 'packpart-interfaces/lib'

const Container = styled.div`
  padding: 30px 30px 30px 30px;
  background-color: 'white';
`

const MachineInfoContainer = styled.div`
  padding: 10px 20px;
`
const MachineTitle = styled.div`
  color: black;
  font-weight: bold;
  font-size: 25px;
`
const MachinePriceAndWarranty = styled(PriceAndWarranty)`
  padding: 10px;
  div {
    color: black;
  }
`
const MachineImageContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
`
const MachineImage = styled.img`
  padding: 10px;
  height: 400px;
  object-fit: contain;
`

const useQuery = () => {
  return new URLSearchParams(useLocation().search)
}

const RenderCompanySpectrum = () => {
  const query = useQuery()
  const currentLanguage = query.get('language')
  const firebase = useFirebase()
  const { projectID, companyID } = useParams<{
    projectID: string
    companyID: string
  }>()
  const [spectrum, setSpectrum] = useState<any>()
  const getSpectrum = async () => {
    const doc = await firebase
      .firestore()
      .doc(`companies/${companyID}/projects/${projectID}`)
    const data = (await doc.get()).data()
    setSpectrum(data)
  }

  useEffect(() => {
    getSpectrum()
  }, [])

  useEffect(() => {
    if (currentLanguage) i18next.changeLanguage(currentLanguage)
  }, [currentLanguage])

  if (!spectrum) return null
  return (
    <ThemeProvider
      theme={{
        primary: '#62FCFC',
        primaryDark: 'black',
        error: 'red',
        text: 'black',
        secondary: 'black',
        background: 'white',
        border: 'rgba(73, 73, 73, 0.5)',
        surface: 'white',
        placeholder: 'white',
        disabled: 'white',
        sidebarWidth: '70px',
        headerHeight: '70px',
        card: 'white',
        subcard: 'white',
        popoverBackground: 'white',
        textDark: 'black',
        lightGray: 'white'
      }}>
      <Container>
        <BigSizeContext.Provider value={true}>
          {spectrum.type === 'usedMachinerySupplier' && (
            <MachineInfoContainer>
              <MachineTitle>{spectrum.name}</MachineTitle>
              <MachinePriceAndWarranty
                price={spectrum?.price}
                setPrice={() => null}
                warranty={spectrum?.warranty}
                setWarranty={() => null}
                disabled={true}
              />
              <MachineImageContainer>
                {spectrum?.descriptionImages?.map(
                  (imageItem: CustomGalleryItem) => (
                    <MachineImage src={imageItem.original} alt="" />
                  )
                )}
              </MachineImageContainer>
            </MachineInfoContainer>
          )}
          <CompanySpectrum spectrum={spectrum} expandAll />
        </BigSizeContext.Provider>
      </Container>
    </ThemeProvider>
  )
}

export default RenderCompanySpectrum
