/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react'
import { useFirebase } from 'react-redux-firebase'
import styled from 'styled-components'
import { useLocation } from 'react-router-dom'
import i18next from 'i18next'
import { Project } from '../../interfaces/project'
import { MachineCard } from '../cards/MachineCard'
import { CircularProgress, Grid } from '@material-ui/core'

const Container = styled.div`
  padding: 30px 30px 30px 30px;
  background-color: 'white';
`
const StyledGridItem = styled(Grid)`
  // Empty grid items should not occupy space
  &:empty {
    display: none;
  }
`
const Spinner = styled(CircularProgress)`
  align-self: center;
  color: ${(props) => props.theme.primary};
`
const useQuery = () => {
  return new URLSearchParams(useLocation().search)
}

const UsedMachineryIframe = () => {
  const query = useQuery()
  const currentLanguage = query.get('language')
  const firebase = useFirebase()
  const [usedMachines, setUsedMachines] = useState<Project[] | undefined>()

  const getUsedMachinesCloudFunction = (firebase as any)
    .app()
    .functions('europe-west3')
    .httpsCallable('getUsedMachines')

  const getUsedMachines = async () => {
    const usedMachines = await getUsedMachinesCloudFunction()
    setUsedMachines(usedMachines?.data || [])
  }

  useEffect(() => {
    getUsedMachines()
  }, [])

  useEffect(() => {
    if (currentLanguage) i18next.changeLanguage(currentLanguage)
  }, [currentLanguage])

  if (!usedMachines) return <Spinner />
  return (
    <Container>
      <Grid container spacing={3}>
        {usedMachines.map((usedMachine, index) => {
          const openMachine = () => {
            const currentURL = window.location.origin
            window
              ?.open(
                `${currentURL}/showUsedMachineryPartnerDetails/${usedMachine.companyID}/${usedMachine.id}`,
                '_blank'
              )
              ?.focus()
          }
          return (
            <StyledGridItem
              item
              xs={12}
              sm={6}
              md={6}
              lg={4}
              style={{ height: 300 }}
              key={usedMachine.id}>
              <MachineCard
                project={usedMachine}
                optionsIconName="fullscreen"
                options={[]}
                hideFullscreenButton
                setMachineCardOptionsId={openMachine}
                onImageClick={openMachine}
              />
            </StyledGridItem>
          )
        })}
      </Grid>
    </Container>
  )
}

export default UsedMachineryIframe
