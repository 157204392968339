import Button from '@components/elements/Button'
import ContentCenterer from '@components/elements/ContentCenterer'
import CustomHelmet from '@components/elements/CustomHelmet'
import ErrorMessage from '@components/elements/ErrorMessage'
import Modal from '@components/elements/Modal'
import { Icon } from '@material-ui/core'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useFirebase } from 'react-redux-firebase'
import styled from 'styled-components'
import RegisterBox from './RegisterBox'

const HorizontalLine = styled.div`
  width: 100%;
  height: 1px;
  background-color: black;
`

const Form = styled.div`
  display: flex;
  flex: 1 1 50%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  @media (max-width: 850px) {
    flex-direction: column-reverse;
  }
`
const Container = styled.div`
  width: 80%;
  padding: 70px 60px;
  box-sizing: border-box;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: ${(props) => props.theme.surface};
  @media (max-width: 850px) {
    width: 100%;
    height: 100%;
  }
`
const StyledInput = styled.input`
  background: #fff;
  width: auto;
  line-height: 40px;
  border-radius: 10px;
  margin-bottom: 15px;
  box-sizing: border-box;
  padding: 0 0 0 15px;
  color: black;
  font-weight: 600;
  font-size: 18px;
  outline: none;
  border-width: 0;
`
const ForgotPassword = styled.button`
  background-color: transparent;
  text-decoration: underline;
  font-size: 14px;
  margin: 20px 0 40px;
`
const ForgotPasswordBox = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  background-color: ${(props) => props.theme.surface};
  border-radius: 10px;
  box-sizing: border-box;
  padding: 60px 30px;
  position: relative;
`
const CloseIcon = styled(Icon)`
  position: absolute;
  top: 10px;
  right: 12px;
  color: black;
  font-size: 28px !important;
  cursor: pointer;
`

const RegisterMessage = styled.p`
  font-size: 22px;
  line-height: 25px;
  font-weight: 700;
  text-align: center;
  margin: 50px 0 30px;

  white-space: pre-wrap;
`
const ResponsiveMessage = styled.p`
  color: ${(props) => props.theme.primary};
  margin: 20px 50px;
  font-weight: 600;
  font-size: 14px;
  text-align: center;
`
export const LoginBox = () => {
  const { t } = useTranslation()
  const [email, setEmail] = useState('')
  const [forgotPwEmail, setForgotPwEmail] = useState('')
  const [password, setPassword] = useState('')
  const [error, setError] = useState('')
  const [forgotPwError, setForgotPwError] = useState('')
  const [forgotPasswordActive, setForgotPasswordActive] = useState(false)
  const firebase = useFirebase()

  const handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setError('')
    setEmail(event.target.value)
  }
  const handleForgotPwEmailChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setForgotPwError('')
    setForgotPwEmail(event.target.value)
  }
  const handlePasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setError('')
    setPassword(event.target.value)
  }
  const logMeIn = async () => {
    try {
      await firebase.login({ email, password })
    } catch (e) {
      let errorMessage = 'Error in LoginBox, logMeIn'
      if (e instanceof Error) {
        errorMessage = e.message
      }
      setError(errorMessage)
      console.log('e', errorMessage)
    }
  }
  const resetPassword = async () => {
    try {
      await firebase.resetPassword(forgotPwEmail)
      setForgotPasswordActive(false)
    } catch (e) {
      let errorMessage = 'Error in LoginBox, resetPassword'
      if (e instanceof Error) {
        errorMessage = e.message
      }
      setForgotPwError(errorMessage)
      console.log('e', errorMessage)
    }
  }
  const handleEnter = (e: React.KeyboardEvent) => {
    if (e.key === 'Enter') logMeIn()
  }
  return (
    <Form>
      <Container>
        <StyledInput
          onChange={handleEmailChange}
          placeholder={t('placeholder.email')}
        />
        <StyledInput
          onChange={handlePasswordChange}
          onKeyDown={handleEnter}
          placeholder={t('placeholder.password')}
          type="password"
          style={{ marginBottom: 30 }}
        />
        <ErrorMessage margin={'0 0 15px'}>{error}</ErrorMessage>
        <ContentCenterer>
          <Button
            variant="contained"
            condensed
            primary={email.length > 0 && password.length > 0}
            onClick={logMeIn}>
            {t('buttons.login')}
          </Button>
          <ForgotPassword onClick={() => setForgotPasswordActive(true)}>
            {t('buttons.forgotPassword')}
          </ForgotPassword>
          <HorizontalLine />
          <RegisterMessage>{t('login.registerMessage')}</RegisterMessage>
          <RegisterBox />
        </ContentCenterer>
      </Container>
      <ResponsiveMessage>{t('messages.loginBoxNote')}</ResponsiveMessage>
      <Modal
        size="50%"
        height="auto"
        open={forgotPasswordActive}
        onOpen={() => setForgotPwError('')}
        onClose={() => {
          setForgotPasswordActive(false)
          setForgotPwEmail('')
        }}>
        <CustomHelmet title={t('metadata.resetPassword')} />
        <ForgotPasswordBox>
          <CloseIcon onClick={() => setForgotPasswordActive(false)}>
            close
          </CloseIcon>
          <StyledInput
            onChange={handleForgotPwEmailChange}
            placeholder="E-Mail"
          />
          <ErrorMessage margin={'0 0 15px'}>{forgotPwError}</ErrorMessage>
          <ContentCenterer>
            <Button
              variant="contained"
              primary
              condensed
              disabled={forgotPwEmail.length === 0}
              onClick={resetPassword}>
              {t('buttons.resetPassword')}
            </Button>
          </ContentCenterer>
        </ForgotPasswordBox>
      </Modal>
    </Form>
  )
}
