import styled from 'styled-components'
interface ContainerProps {
  direction?: 'column' | 'row'
  justifyContent?:
    | 'center'
    | 'flex-start'
    | 'flex-end'
    | 'space-between'
    | 'space-around'
  alignItems?: 'center' | 'flex-start' | 'flex-end' | 'baseline' | 'normal'
  autoHeight?: boolean
}

const Container = styled.div<ContainerProps>`
  display: flex;
  flex: 1;
  justify-content: ${(props) => props.justifyContent || 'center'};
  align-items: ${(props) => props.alignItems || 'normal'};
  padding: 0;
  margin: 0;
  height: ${(props) => (props.autoHeight ? 'auto' : '100vh')};
  max-height: 100vh;
  max-width: 100vw;
  overflow-y: hidden;
  box-sizing: border-box;
  background-color: ${(props) => props.theme.background};
  flex-direction: ${(props) => props.direction || 'row'};
`

export default Container
