import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { StyledErrorMessage } from '@components/forms/elements'
import { Field } from 'formik'

export const FieldWithErrorContainer = styled.div<{ hasError?: boolean }>`
  margin-bottom: ${({ hasError }) => (hasError ? '20px' : '25px')};
  box-sizing: border-box;
  width: 100%;
`
const StyledField = styled(Field).withConfig({
  shouldForwardProp: (prop: any) =>
    !['hasError', 'isIncomplete', 'errorMessage', 'isTouched'].includes(prop)
})<{ hasError: boolean }>`
  background-color: white;
  border-radius: 5px;
  height: 40px;
  border-width: 0;
  padding: 10px 0 10px 10px;
  width: 100%;
  color: black;
  box-sizing: border-box;
  outline: none !important;
  ${({ hasError, isIncomplete }) => {
    if (hasError) return 'border: 1px solid red;'
    if (isIncomplete) return 'border: 1px solid orange;'
  }}
`

export const FieldWithError = (props: any) => {
  const { t } = useTranslation()
  const hasError = props.isTouched && props.errorMessage !== undefined

  return (
    <FieldWithErrorContainer hasError={hasError}>
      <StyledField
        {...props}
        placeholder={t(`register.form.placeholders.${props.name}`)}
        hasError={hasError}
        isIncomplete={props.isIncomplete}
      />
      <StyledErrorMessage component="div" name={props.name} />
    </FieldWithErrorContainer>
  )
}
