import { AvailableCategories, Categories } from '@interfaces/project'
import { setCurrentStep, setOpenCategory } from '@redux/project'
import Fill from '@screens/addProject/configurators/Fill'
import Label from '@screens/addProject/configurators/Label'
import Packaging from '@screens/addProject/configurators/Packaging'
import Seal from '@screens/addProject/configurators/Seal'
import Sort from '@screens/addProject/configurators/Sort'
import CategorySwitcher from '@screens/addProject/elements//CategorySwitcher'
import {
  selectActiveCategories,
  selectOpenCategory
} from '@selectors/projectSelectors'
import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useIsPartner, useIsUsedMachinerySupplier } from '@services/customHooks'
import styled from 'styled-components'

const Description = styled.p`
  color: #333;
  font-size: 20px;
  margin: 20px 0 30px 26px;
  line-height: 24px;
  @media (max-width: 1200px) {
    margin: 20px 0 20px 15px;
  }
`

const Content = styled.div`
  display: flex;
`

interface ConfiguratorContainerInterface {
  disableCorner?: boolean
}
const ConfiguratorContainer = styled.div<ConfiguratorContainerInterface>`
  width: 90%;
  min-height: 360px;
  max-height: calc(100vh - 400px);
  border-radius: 18px;
  border-top-left-radius: ${(props) => (props.disableCorner ? 0 : '18px')};
  background-color: ${(props) => props.theme.card};
  box-sizing: border-box;
  padding: 20px 40px;
  overflow-y: auto;
  @media (max-width: 1200px) {
    max-height: calc(100vh - 400px);
  }
  @media (max-width: 1200px) {
    max-height: calc(100vh - 380px);
  }
`
export const Details = () => {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const activeCategories = useSelector(selectActiveCategories)
  const openCategory = useSelector(selectOpenCategory)
  const isPartner = useIsPartner()
  const isUsedMachinerySupplier = useIsUsedMachinerySupplier()

  useEffect(() => {
    if (!openCategory || !activeCategories.includes(openCategory)) {
      if (activeCategories.length > 0)
        dispatch(setOpenCategory(activeCategories[0]))
      else dispatch(setCurrentStep(0))
    }
  }, [activeCategories, dispatch, openCategory])

  return (
    <>
      <Description>
        {isUsedMachinerySupplier
          ? t('project.details.usedMachinerySupplierTitle')
          : t(`project.details.${isPartner ? 'producerTitle' : 'title'}`)}
      </Description>

      <Content>
        <CategorySwitcher />
        <ConfiguratorContainer
          disableCorner={openCategory === AvailableCategories[0]}>
          {openCategory === Categories.Sort && <Sort />}
          {openCategory === Categories.Fill && <Fill />}
          {openCategory === Categories.Seal && <Seal />}
          {openCategory === Categories.Label && <Label />}
          {openCategory === Categories.Packaging && <Packaging />}
        </ConfiguratorContainer>
      </Content>
    </>
  )
}
