import React from 'react'
import MaterialButton from '@material-ui/core/Button'
import styled from 'styled-components'
import Icon from '@material-ui/core/Icon'
import { motion } from 'framer-motion'

interface ButtonProps {
  primary?: boolean
  disabled?: boolean
  condensed?: boolean
  small?: boolean
  children?: any
  onClick?: () => void
  type?: string
  variant?: string
  error?: boolean
  className?: string
}
const Button = styled(MaterialButton).withConfig({
  shouldForwardProp: (prop) => !['primary', 'condensed', 'error'].includes(prop)
})<ButtonProps>`
  background-color: ${(props) => {
    if (props.disabled) return props.theme.disabled
    if (props.primary) return props.theme.primary
    if (props.error) return 'red'
    return '#FFF'
  }} !important;
  color: black;
  height: ${(props) => {
    if (props.small) return '30px'
    if (props.condensed) return '37px'
    return '46px'
  }};
  padding: ${(props) => {
    if (props.small) return '5px 2px 5px 10px'
    if (props.condensed) return '7px 7px 7px 30px'
    return '10px 10px 10px 20px'
  }};
  border-radius: 10px;
  border: ${(props) => (props.disabled ? '1px solid black' : null)};
  box-shadow: 0px 3px 7px 0px rgba(0, 0, 0, 0.25);
  .MuiButton-label {
    color: black;
    font-weight: ${(props) => {
      if (props.small) return 500
      if (props.condensed) return 600

      return 700
    }};
    font-size: ${(props) => {
      if (props.small) return '15px'
      if (props.condensed) return '18px'
      return '20px'
    }};
    text-transform: none;
  }
  :hover {
    background-color: ${({ theme, error }) =>
      error ? 'red' : theme.primary} !important;
  }
`
const StyledIcon = styled(Icon).withConfig({
  shouldForwardProp: (prop) => !['primary', 'condensed', 'error'].includes(prop)
})<ButtonProps>`
  margin-left: ${(props) => {
    if (props.small) return '0px'
    if (props.condensed) return '10px'
    return '0px'
  }};
  color: black;
  font-size: ${(props) => {
    if (props.small) return '20px'
    if (props.condensed) return '25px'
    return '35px'
  }} !important;
`
const ButtonWithIcon = (props: ButtonProps) => {
  return (
    <motion.div
      whileHover={{ scale: props.disabled ? 1 : 1.02 }}
      whileTap={{ scale: props.disabled ? 1 : 0.98 }}
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}>
      <Button {...(props as any)}>
        {props.children}
        <StyledIcon {...props}>chevron_right</StyledIcon>
      </Button>
    </motion.div>
  )
}
export default ButtonWithIcon
