import {
  defaultProjectValues,
  offeringValues,
  FieldWithInputs,
  QuestionType,
  RequiredType
} from '@interfaces/project'
import cloneDeep from 'lodash/cloneDeep'
import unset from 'lodash/unset'
import set from 'lodash/set'

/*
 **********
 * Common *
 **********
 */
const outputRateWageProducer: FieldWithInputs = {
  inputs: [
    {
      type: QuestionType.Float,
      required: true,
      unit: 'pieces',
      name: 'pieces'
    },
    {
      type: QuestionType.SingleChoiceSelect,
      name: 'unitType',
      options: ['oneTime', 'monthly', 'annually']
    }
  ],
  requiredType: RequiredType.All,
  multiplePriorities: true,
  initialNotOptional: true,
  prefill: true,
  glossarLink: 'https://packpart.eu/verpackungsmaschinen-glossar#a004'
}

/*
 *************
 * Searching *
 *************
 */
const wageProducerSearching = cloneDeep(defaultProjectValues)
// sortAndFeed
// -> not optional
unset(wageProducerSearching, 'sort.sortAndFeed.fields.outputRate')
set(
  wageProducerSearching,
  'sort.sortAndFeed.fields.outputRateWageProducer',
  outputRateWageProducer
)

// fill
// -> not optional
unset(wageProducerSearching, 'fill.fields.fullOrHalfAutomatic')
unset(wageProducerSearching, 'fill.fields.outputRate')
set(
  wageProducerSearching,
  'fill.fields.outputRateWageProducer',
  outputRateWageProducer
)

// seal
// -> not optional
unset(wageProducerSearching, 'seal.fields.fullOrHalfAutomatic')
unset(wageProducerSearching, 'seal.fields.outputRate')
set(
  wageProducerSearching,
  'seal.fields.outputRateWageProducer',
  outputRateWageProducer
)

// label
// -> not optional
unset(wageProducerSearching, 'label.fields.fullOrHalfAutomatic')
unset(wageProducerSearching, 'label.fields.outputRate')
set(
  wageProducerSearching,
  'label.fields.outputRateWageProducer',
  outputRateWageProducer
)

// packaging
// -> not optional
unset(wageProducerSearching, 'packaging.fields.fullOrHalfAutomatic')
unset(wageProducerSearching, 'packaging.fields.outputRate')
set(
  wageProducerSearching,
  'packaging.fields.outputRateWageProducer',
  outputRateWageProducer
)

/*
 ************
 * Offering *
 ************
 */
const wageProducerOffering = cloneDeep(offeringValues)
// sortAndFeed
// -> not optional
unset(wageProducerOffering, 'sort.sortAndFeed.fields.outputRate')
set(
  wageProducerOffering,
  'sort.sortAndFeed.fields.outputRateWageProducer',
  outputRateWageProducer
)

// fill
// -> not optional
unset(wageProducerOffering, 'fill.fields.fullOrHalfAutomatic')
unset(wageProducerOffering, 'fill.fields.outputRate')
set(
  wageProducerOffering,
  'fill.fields.outputRateWageProducer',
  outputRateWageProducer
)

// seal
// -> not optional
unset(wageProducerOffering, 'seal.fields.fullOrHalfAutomatic')
unset(wageProducerOffering, 'seal.fields.outputRate')
set(
  wageProducerOffering,
  'seal.fields.outputRateWageProducer',
  outputRateWageProducer
)

// label
// -> not optional
unset(wageProducerOffering, 'label.fields.fullOrHalfAutomatic')
unset(wageProducerOffering, 'label.fields.outputRate')
set(
  wageProducerOffering,
  'label.fields.outputRateWageProducer',
  outputRateWageProducer
)

// packaging
// -> not optional
unset(wageProducerOffering, 'packaging.fields.fullOrHalfAutomatic')
unset(wageProducerOffering, 'packaging.fields.outputRate')
set(
  wageProducerOffering,
  'packaging.fields.outputRateWageProducer',
  outputRateWageProducer
)

export { wageProducerOffering, wageProducerSearching }
