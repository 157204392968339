import { createSelector } from 'reselect'
import { selectFirebaseData } from './generalSelectors'
import { ProfileTypes } from '@interfaces/user'
import { Company } from '@interfaces/company'

const selectProfile = (s: any) => s?.firebase?.profile

export const selectCompanies = createSelector(
  selectFirebaseData,
  (firebaseData) => firebaseData?.companies || null
)
export const selectCompaniesArray = createSelector(
  selectCompanies,
  (companies) => {
    const companiesWithIds: Company[] = Object.entries(companies)?.map(
      ([key, value]: [string, any]) => ({
        ...value,
        id: key
      })
    )
    return companiesWithIds
  }
)
export const selectCompany = createSelector(
  selectCompanies,
  selectProfile,
  (companies, { companyID }) => companies?.[companyID] || null
)

export const selectCompanyType = createSelector(selectCompany, (company) =>
  company?.type ? (company.type as ProfileTypes) : null
)
export const selectCompanyBranches = createSelector(
  selectCompany,
  (company) => company?.branches || []
)
export const selectCompanyByID = (companyID: string) =>
  createSelector(
    selectFirebaseData,
    (firebaseData) =>
      firebaseData?.companies?.[companyID] as undefined | Company
  )

export const selectIsPremium = createSelector(
  selectCompany,
  selectCompanyType,
  (company, type) => {
    if (type === 'searching') return true
    return company?.premiumUser
  }
)
export const selectIsProjectUnlocked = (companyID: string, projectID: string) =>
  createSelector(selectCompany, selectCompanyType, (company, type) => {
    if (type === 'searching') return true
    if (!company.unlocked) return false
    return company.unlocked.some(
      (u: { company: string; project: string }) =>
        u.company === companyID && u.project === projectID
    )
  })
