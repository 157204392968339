import BaseInput from '@components/elements/BaseInput'
import React from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import Boolean from '../elements/questions/Boolean'

const OuterInputContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  padding-bottom: 10px;
`

const CurrencyInputContainer = styled.div`
  display: flex;
  align-items: center;
  font-size: 22px;
  font-weight: 600;
  color: #3d3d3d;
  position: relative;
`
const CurrencyInput = styled(BaseInput)`
  margin-left: 20px;
  margin-right: 20px;
  font-size: 18px;
  max-width: 190px;
  box-sizing: border-box;
  padding-right: 70px;
`
const CurrencySign = styled.div`
  position: absolute;
  right: 40px;
  color: black;
  font-size: 18px;
`

const WarrantyInputContainer = styled.div`
  display: flex;
  align-items: center;
  font-size: 22px;
  font-weight: 600;
  color: #3d3d3d;
`

const WarrantyInput = styled(Boolean)`
  input[type='checkbox']::after {
    font-size: 25px;
    top: 1px;
  }
`

export const PriceAndWarranty = ({
  price,
  setPrice,
  warranty,
  setWarranty,
  disabled,
  className
}: {
  price: string
  setPrice: (val: string) => void
  warranty: boolean
  setWarranty: (val: boolean) => void
  disabled?: boolean
  className?: string
}) => {
  const { t } = useTranslation()

  return (
    <OuterInputContainer className={className}>
      <CurrencyInputContainer>
        {t('project.done.price')}:
        <CurrencyInput
          disabled={disabled}
          value={price}
          onChange={(e) => setPrice(e.target.value)}
        />
        <CurrencySign>EUR</CurrencySign>
      </CurrencyInputContainer>
      <WarrantyInputContainer>
        {t('project.done.warranty')}:
        <WarrantyInput
          disabled={disabled}
          value={warranty}
          onChange={setWarranty}
        />
      </WarrantyInputContainer>
    </OuterInputContainer>
  )
}
