import { Branches } from '@interfaces/branches'
import { action, empty, on, payload, reducer } from 'ts-action'
import { ProfileTypes } from '@interfaces/user'
/* Interfaces */

type Type = null | ProfileTypes
export interface RegisterForm {
  firstName: string
  lastName: string
  position: string
  email: string
  phone: string
  password: string
  company: string
  address: string
  description: string
  branches: Branches[]
  country: string
  profileImage: File | null
  logo: File | null
  imagePicture: File | null
}
export interface RegisterState {
  type: Type
  currentStep: Number
  form: RegisterForm
}

/* Redux */
export const setRegisterType = action('setRegisterType', payload<Type>())
export const setCurrentRegisterStep = action(
  'setCurrentRegisterStep',
  payload<number>()
)
export const setRegisterForm = action(
  'setRegisterForm',
  payload<RegisterForm>()
)
export const resetRegisterForm = action('resetRegisterForm', empty())

const initialState: RegisterState = {
  type: null,
  currentStep: 0,
  form: {
    firstName: '',
    lastName: '',
    position: '',
    email: '',
    phone: '',
    password: '',
    company: '',
    address: '',
    branches: [],
    country: '',
    description: '',
    profileImage: null,
    logo: null,
    imagePicture: null
  }
}

export const registerReducer = reducer(
  initialState,
  on(setRegisterType, (state, { payload }) => ({
    ...state,
    type: payload
  })),
  on(setCurrentRegisterStep, (state, { payload }) => ({
    ...state,
    currentStep: payload
  })),
  on(setRegisterForm, (state, { payload }) => ({
    ...state,
    form: payload
  })),
  on(resetRegisterForm, () => initialState)
)
