import Button from '@components/elements/Button'
import { Dashboard } from '@components/elements/Dashboard'
import { Icon } from '@material-ui/core'
import { resetProject } from '@redux/project'
import { selectCurrentProject } from '@selectors/projectSelectors'
import { selectSidebarIsOpen } from '@selectors/sidebarSelectors'
import { useIsPartner } from '@services/customHooks'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { Redirect, useHistory, useParams } from 'react-router-dom'
import styled from 'styled-components'
const ProjectContainer = styled.div`
  width: 80%;
  margin-top: 50px;
  background-color: ${(props) => props.theme.surface};
  border-radius: 18px;
  align-self: center;
  box-sizing: border-box;
  padding: 20px 25px;
  @media (max-width: 1200px) {
    width: calc(100% - 40px);
    margin-right: 40px;
    margin-top: 25px;
  }
  @media (max-width: 800px) {
    width: calc(100% - 20px);
    margin-right: 20px;
  }
`
const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 80px 0 30px;
`
const StyledIcon = styled(Icon)`
  color: ${({ theme }) => theme.primary};
  font-size: 70px !important;
`

const Title = styled.p`
  color: ${({ theme }) => theme.textDark};
  font-size: 40px;
  margin: 20px 0 50px;
  font-weight: bold;
  text-align: center;
`
const Description = styled.p`
  color: ${({ theme }) => theme.textDark};
  font-size: 25px;
  font-weight: bold;
  text-align: center;
  margin-bottom: 60px;
`
const Name = styled.p`
  color: ${({ theme }) => theme.textDark};
  font-size: 25px;
  font-weight: bold;
  margin: 0 0 120px;
  padding: 10px 20px;
  background-color: ${({ theme }) => theme.subcard};
  border-radius: 25px;
`
export const Done = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const history = useHistory()
  const inset = useSelector(selectSidebarIsOpen)

  const { name, displayedType } = useSelector(selectCurrentProject)
  const isPartner = useIsPartner()
  const { projectID } = useParams<{ projectID: string }>()

  const endConfigurator = () => {
    if (isPartner) {
      dispatch(resetProject())
      history.push('/')
      return
    }
    if (displayedType === 'searchingUsedMachinerySupplier') {
      history.push(`/usedMachineryOverview/${projectID}`)
    } else {
      history.push(`/partnerOverview/${projectID}`)
    }
    dispatch(resetProject())
  }
  if (!projectID) return <Redirect to="/" />
  return (
    <Dashboard inset={inset}>
      <ProjectContainer>
        <Container>
          <StyledIcon>check_box</StyledIcon>
          <Title>{t('project.progress.finish')}</Title>
          <Description>
            {isPartner
              ? t('project.basics.rangeOfServicesCreated')
              : t('project.basics.projectCreated')}
          </Description>
          {!isPartner && <Name>{name}</Name>}
          <Button onClick={endConfigurator}>
            {t('project.basics.findPartner')}
          </Button>
        </Container>
      </ProjectContainer>
    </Dashboard>
  )
}
