import { MachineCard } from '@components/cards/MachineCard'
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Grid
} from '@material-ui/core'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import { Project } from 'packpart-interfaces/lib'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { DashedLinkCard } from '../DashedLinkCard'
import Dialog from '@components/elements/Dialog'
import { selectCompanyID } from '../../../../selectors/profileSelectors'
import { useSelector } from 'react-redux'
import { useFirestore } from 'react-redux-firebase'

const StyledAccordion = styled(Accordion)`
  // Remove grey line above Accordion
  &:before {
    display: none;
  }
`

const StyledGridItem = styled(Grid)`
  // Empty grid items should not occupy space
  &:empty {
    display: none;
  }
`

export const MyMachinesAccordion = ({
  machineProjects,
  idsOfHiddenProjects,
  setDeleteDialog,
  goToServiceSpectrum,
  goToDescriptionImages,
  machineCardOptionsId,
  setMachineCardOptionsId
}: {
  machineProjects: Project[]
  idsOfHiddenProjects: string[]
  setDeleteDialog: (pId?: string | null) => void
  goToServiceSpectrum: (project: Project) => void
  goToDescriptionImages: (project: Project) => void
  machineCardOptionsId?: string
  setMachineCardOptionsId: (id: string) => void
}) => {
  const { t } = useTranslation()
  const [myMachinesExpanded, setMyMachinesExpanded] = useState(true)
  const [showSoldDialog, setShowSoldDialog] = useState<string | null>(null)
  const companyID = useSelector(selectCompanyID)
  const firestore = useFirestore()

  const markAsSold = () => {
    if (!showSoldDialog) return
    const projectID = showSoldDialog
    firestore.doc(`companies/${companyID}/projects/${projectID}`).update({
      isSold: true
    })
    setMachineCardOptionsId('')
    setShowSoldDialog(null)
  }

  return (
    <StyledAccordion
      style={{ boxShadow: 'none', background: 'none' }}
      expanded={myMachinesExpanded}
      onChange={() => setMyMachinesExpanded((prevState) => !prevState)}>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <h3 style={{ fontSize: 25 }}>{t('home.myUsedMachinery')}</h3>
      </AccordionSummary>
      <AccordionDetails>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6} md={4} style={{ height: 300 }}>
            <DashedLinkCard link="/new">
              <p
                style={{
                  fontWeight: 500,
                  fontSize: 18,
                  textAlign: 'center'
                }}>
                {t('home.addNewMachine')}
              </p>
            </DashedLinkCard>
          </Grid>
          {machineProjects.map(
            (mProject) =>
              !idsOfHiddenProjects.includes(mProject.id!) && (
                <StyledGridItem
                  item
                  xs={12}
                  sm={6}
                  md={4}
                  style={{ height: 300 }}
                  key={mProject.id}>
                  <MachineCard
                    project={mProject}
                    onDelete={() => setDeleteDialog(mProject.id)}
                    options={[
                      {
                        title: t('home.editServiceSpectrum'),
                        onClick: () => goToServiceSpectrum(mProject)
                      },
                      {
                        title: t('home.editDescriptionImages'),
                        onClick: () => goToDescriptionImages(mProject)
                      },
                      {
                        title: t('home.markAsSold'),
                        onClick: () => setShowSoldDialog(mProject.id as string)
                      }
                    ]}
                    machineCardOptionsId={machineCardOptionsId}
                    setMachineCardOptionsId={setMachineCardOptionsId}
                  />
                </StyledGridItem>
              )
          )}
        </Grid>
      </AccordionDetails>
      <Dialog
        onClose={() => setShowSoldDialog(null)}
        open={!!showSoldDialog}
        title={t('home.markAsSold')}
        description={t('messages.markAsSoldDescription')}
        buttons={[
          {
            title: t('buttons.cancel'),
            onClick: () => setShowSoldDialog(null),
            primary: false
          },
          {
            title: t('buttons.confirm'),
            onClick: markAsSold,
            primary: true
          }
        ]}
      />
    </StyledAccordion>
  )
}
