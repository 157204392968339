import ProfilePicture from '@components/elements/ProfilePicture'
import Icon from '@material-ui/core/Icon'
import { useIsPartner } from '@services/customHooks'
import { motion } from 'framer-motion'
import i18next from 'i18next'
import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useFirebase } from 'react-redux-firebase'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import { useSelector } from 'react-redux'
import { selectLanguage } from '../../selectors/profileSelectors'
import Select from '@material-ui/core/Select'
import { FormControl, MenuItem } from '@material-ui/core'

const HeaderContainer = styled.div`
  display: flex;
  height: ${(props) => props.theme.headerHeight};
  width: 100%;
  background-color: ${(props) => props.theme.secondary};
  align-items: center;
  justify-content: space-between;
`

const ItemContainer = styled.div`
  margin-right: 10px;
  align-items: center;
  display: flex;
`

const Logo = styled.img`
  height: 38px;
  margin-left: 20px;
`
const StyledIcon = styled(Icon)`
  margin-right: 20px;
  color: ${(props) => props.theme.surface};
  cursor: pointer;
  :hover {
    color: ${({ theme }) => theme.primary};
  }
`

const LanguageSelect = styled(Select)`
  margin-right: 10px;
`

const Header = ({ hideItems }: { hideItems?: boolean }) => {
  const { t } = useTranslation()
  const firebase = useFirebase()
  const isPartner = useIsPartner()
  const currentLanguage = useSelector(selectLanguage)
  const changeLanguage = (language: string) => {
    firebase.updateProfile({ language })
  }
  useEffect(() => {
    i18next.changeLanguage(currentLanguage)
  }, [currentLanguage])

  return (
    <HeaderContainer>
      <Link to="/">
        <Logo src={require('../../assets/logo.png').default} />
      </Link>
      {!hideItems && (
        <ItemContainer>
          {!isPartner && process.env.REACT_APP_ENVIRONMENT !== 'production' && (
            <Link to="/new">
              <motion.div whileHover={{ scale: 1.02 }}>
                <StyledIcon>add_circle</StyledIcon>
              </motion.div>
            </Link>
          )}
          <FormControl>
            <LanguageSelect
              value={currentLanguage}
              onChange={(event: any) =>
                changeLanguage(event.target.value as string)
              }>
              <MenuItem value={'de'}>{t('languages.de')}</MenuItem>
              <MenuItem value={'en'}>{t('languages.en')}</MenuItem>
            </LanguageSelect>
          </FormControl>
          <ProfilePicture />
        </ItemContainer>
      )}
    </HeaderContainer>
  )
}

export default Header
