/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react'
import styled from 'styled-components'

import { motion, AnimatePresence } from 'framer-motion'
import { ClickAwayListener } from '@material-ui/core'

interface ModalContainerInterface {
  size: string
  height?: string
}
const ModalContainer = styled.div<ModalContainerInterface>`
  width: ${(props) => props.size};
  height: ${(props) => props.height || props.size};
  max-height: 95%;
  align-self: center;
  display: flex;
  @media (max-width: 768px) {
    max-height: 100%;
  }
`
const Backdrop = styled(motion.div)`
  position: fixed;
  z-index: 1000;
  width: 100vw;
  height: 100vh;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.25);
`
const Modal = ({
  open,
  onOpen,
  onClose,
  children,
  size = '80%',
  height
}: {
  open: boolean
  onOpen?: () => void
  onClose: () => void
  children: any
  size?: string
  height?: string
}) => {
  useEffect(() => {
    if (open) {
      document.body.classList.add('modal-open')
      if (onOpen) onOpen()
    } else document.body.classList.remove('modal-open')
    return function cleanup() {
      document.body.classList.remove('modal-open')
    }
  }, [open])

  return (
    <AnimatePresence>
      {open && (
        <Backdrop
          transition={{ duration: 0.7 }}
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}>
          <ClickAwayListener onClickAway={onClose}>
            <ModalContainer size={size} height={height}>
              {children}
            </ModalContainer>
          </ClickAwayListener>
        </Backdrop>
      )}
    </AnimatePresence>
  )
}

export default Modal
