import DashedContainer from '@components/elements/DashedContainer'
import Icon from '@material-ui/core/Icon'
import React from 'react'
import { useHistory } from 'react-router-dom'
import styled from 'styled-components'

const Container = styled(DashedContainer)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  border-radius: 10px;
  box-sizing: border-box;
  /* Prevents cutted borders on the edges */
  border: dashed 2px;
  background-image: none;
  cursor: pointer;
  :hover {
    background-image: var(--gradient1), var(--gradient1), var(--gradient2),
      var(--gradient2);
    border: none;
  }
`

const AddCircleIcon = styled(Icon)`
  margin-right: 1px;
  margin-top: 5px;
  margin-bottom: 15px;
  font-size: 50px !important;
  background: white;
  border-radius: 25px;
  color: ${(props) => props.theme.surface};
  ${Container}:hover & {
    color: ${({ theme }) => theme.secondary};
    background: ${({ theme }) => theme.primary};
  }
`

export const DashedLinkCard: React.FC<{ link: string }> = ({
  children,
  link
}) => {
  const history = useHistory()

  return (
    <Container onClick={() => history.push(link)}>
      <AddCircleIcon>addCircle</AddCircleIcon>
      {children}
    </Container>
  )
}
