import React, { useState } from 'react'
import styled from 'styled-components'
import { useDropzone } from 'react-dropzone'
import { useSelector } from 'react-redux'
import { useFirebase } from 'react-redux-firebase'
import { selectUID } from '@selectors/firebaseAuth'
import { Icon, CircularProgress } from '@material-ui/core'

interface FileCallbackInterface {
  id: string
  downloadURL: string
  File: any
}

interface DragProps {
  isDragActive: boolean
}

const Container = styled.div<DragProps>`
  display: flex;
  align-items: center;
  flex-direction: row;
  box-sizing: border-box;
  max-width: 100%;
  height: 28px;
  padding: 0 10px 0 10px;
  align-self: center;
  border-radius: 5px;
  cursor: pointer;
  outline: none;
  background: ${({ theme, isDragActive }) =>
    isDragActive ? theme.primary : theme.card};
  :hover {
    box-shadow: 0 0 3px 1px ${({ theme }) => theme.primary};
  }
  margin-top: 5px;
  margin-bottom: 10px;
`

const UploadIcon = styled(Icon)<DragProps>`
  color: ${({ theme, isDragActive }) =>
    isDragActive ? theme.secondary : theme.disabled};
  ${Container}:hover & {
    color: ${({ theme }) => theme.secondary};
  }
`

const UploadTitle = styled.p<DragProps>`
  margin-left: 10px;
  box-sizing: border-box;
  font-weight: 600;
  color: ${({ theme, isDragActive }) =>
    isDragActive ? theme.secondary : theme.disabled};
  border: none !important;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  ${Container}:hover & {
    color: ${({ theme }) => theme.secondary};
  }
`

const LoadingIcon = styled(CircularProgress)`
  color: ${({ theme }) => theme.primary};
  width: 18px !important;
  height: 18px !important;
  margin-left: 20px;
`

const CloseIcon = styled(Icon)`
  color: ${({ theme }) => theme.disabled};
  :hover {
    color: ${({ theme }) => theme.secondary};
    transform: scale(1.07);
  }
  margin-left: 10px;
`
interface FileValueInterface {
  databaseID: string
  fullPath: string
  downloadPath: string
  name: string
}
const File = ({
  value,
  onChange,
  placeholder
}: {
  value: FileValueInterface | undefined
  onChange: (f: FileValueInterface | null) => void
  placeholder?: string
}) => {
  const firebase = useFirebase()
  const [loading, setLoading] = useState(false)
  const uid = useSelector(selectUID)
  const filesPath = `uploads/${uid}/temporary`

  const onDrop = async (files: File[]) => {
    const [file] = files
    setLoading(true)
    const callback = (await firebase.uploadFile(filesPath, file, filesPath, {
      name: `${Math.random().toString(36).slice(2)}${file.name}`
    })) as unknown as FileCallbackInterface

    const fileObject = {
      databaseID: callback.id,
      fullPath: callback.File.fullPath,
      downloadPath: callback.downloadURL,
      name: file.name
    }
    onChange(fileObject)
    setLoading(false)
  }

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    multiple: false
  })

  const onFileDelete = async (e: any) => {
    e.stopPropagation()
    if (value?.fullPath) firebase.deleteFile(value.fullPath)
    onChange(null)
  }

  return (
    <>
      {/* TODO */}
      {/* @ts-ignore */}
      <Container {...getRootProps()}>
        <UploadIcon isDragActive={isDragActive}>backup</UploadIcon>
        {loading ? (
          <LoadingIcon />
        ) : (
          <>
            <UploadTitle isDragActive={isDragActive}>
              {value ? value.name : placeholder}
            </UploadTitle>
            {value && <CloseIcon onClick={onFileDelete}>clear</CloseIcon>}
          </>
        )}
        {!value && <input {...getInputProps()} />}
      </Container>
    </>
  )
}

export default File
