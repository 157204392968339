/* eslint-disable react-hooks/exhaustive-deps */
import Breadcrumbs from '@components/elements/Breadcrumbs'
import CompanySpectrum from '@components/elements/CompanySpectrum'
import CustomHelmet from '@components/elements/CustomHelmet'
import { Dashboard } from '@components/elements/Dashboard'
import { ProfileTypes } from '@interfaces/user'
import { Icon } from '@material-ui/core'
import CircularProgress from '@material-ui/core/CircularProgress'
import { selectCompanyByID } from '@selectors/companySelectors'
import { selectCompanyID } from '@selectors/profileSelectors'
import {
  selectMatchForMachineProject,
  selectMatchForPartnerProject
} from '@selectors/projectSelectors'
import { selectSidebarIsOpen } from '@selectors/sidebarSelectors'
import { getUrlFirebase } from '@services/helpers'
import { useIsPartner } from '@services/customHooks'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import {
  isEmpty,
  isLoaded,
  useFirebase,
  useFirestoreConnect
} from 'react-redux-firebase'
import { Redirect, useLocation, useParams } from 'react-router-dom'
import styled from 'styled-components'
import CategoryPreview from './elements/CategoriesPreview'
import { ImageGallery } from '@screens/addProject/usedMachinerySupplier/ImageGallery'
import { PriceAndWarranty } from '@screens/addProject/usedMachinerySupplier/PriceAndWarranty'

const RootContainer = styled.div`
  display: flex;
  flex: 1;
  height: 200px;
  padding-right: 40px;
  overflow: auto;
`
const Spinner = styled(CircularProgress)`
  align-self: center;
  color: ${(props) => props.theme.primary};
`

const CompanyTitle = styled.h1`
  font-weight: 800;
`
const TitleLineContainer = styled.div`
  display: flex;
  justify-content: space-between;
  padding-right: 40px;
  align-items: center;
  margin-bottom: 30px;
`
const LeftContainer = styled.div`
  display: flex;
  height: 100%;
  min-width: 270px;
  flex-direction: column;
  flex: 2.25;
  border-radius: 10px;
  box-sizing: border-box;
  background: ${(props) => props.theme.surface};
  overflow-y: auto;
`
const LeftImage = styled.img`
  display: flex;
  box-sizing: border-box;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  object-fit: contain;
  width: 100%;
  max-height: 65%;
`
const LeftContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  padding: 30px;
`
const LeftTitle = styled.div`
  display: flex;
  font-size: 21px;
  font-weight: bold;
  color: #2d2d2d;
`

const SmallCategoryContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(190px, 1fr));
  grid-gap: 5px;
  margin-top: 20px;
  margin-bottom: 20px;
`

const LeftDescription = styled.div`
  display: block;
  font-size: 16px;
  line-height: 21px;
  font-weight: 600;
  white-space: pre-line;
  color: #3d3d3d;
`

const StyledPriceAndWarranty = styled(PriceAndWarranty)`
  padding-bottom: 20px;
`

const MachineDescription = styled(LeftDescription)`
  display: flex;
  height: 115px;
  overflow-y: auto;
`

const MiddleContainer = styled.div`
  display: flex;
  flex: 2.25;
  min-width: 270px;
  box-sizing: border-box;
  margin-right: 15px;
  margin-left: 15px;
  padding-right: 5px;
  overflow-y: scroll;
  ::-webkit-scrollbar-track {
    border-radius: 10px;
  }
  ::-webkit-scrollbar-thumb {
    border-radius: 10px;
  }
`

const RightContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  padding-top: 20px;
  border-radius: 10px;
  box-sizing: border-box;
  background: ${(props) => props.theme.surface};
  overflow-y: auto;
  min-width: 230px;
`

const ContactTitle = styled.div`
  display: flex;
  font-size: 20px;
  font-weight: bold;
  color: #2d2d2d;
  margin: 0 15px;
  white-space: nowrap;
  align-self: center;
`

const ContactImage = styled.img`
  height: 180px;
  width: 180px;
  object-fit: cover;
  border-radius: 50%;
  margin-top: 25px;
  align-self: center;
`

const ContactRowContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 20px 15px 10px 15px;
  justify-content: space-between;
`
const ContactName = styled.h1`
  display: flex;
  flex: 1;
  font-size: 20px;
  font-weight: bold;
  color: #2d2d2d;
`

const ContactDescription = styled.div`
  display: block;
  font-size: 16px;
  line-height: 21px;
  font-weight: 600;
  color: #3d3d3d;
  white-space: pre-line;
  margin: 0 25px 15px 15px;
`

const MailIcon = styled(Icon)`
  color: ${(props) => props.theme.secondary};
  font-size: 30px !important;
  cursor: pointer;
  margin: 0 10px;
  :hover {
    transform: scale(1.04);
    color: ${({ theme }) => theme.primary};
  }
`
const ContactContainer = styled.div`
  display: flex;
  padding: 5px 15px 5px 5px;
  cursor: pointer;
  align-items: center;
  color: black;
  font-weight: 600;
  background-color: ${({ theme }) => theme.subcard};
  border-radius: 10px;
  :hover {
    color: ${({ theme }) => theme.primary};
    span {
      color: ${({ theme }) => theme.primary};
    }
  }
`

const PartnerDetails = ({
  fallbackShowMachineDetails
}: {
  fallbackShowMachineDetails?: boolean
}) => {
  const { partnerID, projectID: partnerProjectId } = useParams<{
    partnerID: string
    projectID: string
  }>()
  const location = useLocation<{
    showMachineDetails?: boolean
    clientProjectId?: string
  }>()
  const showMachineDetails =
    location.state?.showMachineDetails || fallbackShowMachineDetails
  const clientProjectId = location.state?.clientProjectId
  const partner = useSelector(selectCompanyByID(partnerID))
  const { imagePicture, description } = partner || {}

  const inset = useSelector(selectSidebarIsOpen)
  const partnerOwner = useSelector((s: any) =>
    partner ? s.firestore?.data?.users?.[partner.owner] : undefined
  )
  const isHiddenProfile = partner?.hiddenProfile
  const { profileImage } = partnerOwner || {}
  const { t } = useTranslation()
  const firebase = useFirebase()
  const [profilePicUrl, setProfilePicUrl] = useState<string | null>(null)
  const [corpPicUrl, setCorpPicUrl] = useState<string | boolean | null>(null)
  const [spectrum, setSpectrum] = useState<any>()
  const isPartner = useIsPartner()
  const companyID = useSelector(selectCompanyID)
  const match = useSelector(
    showMachineDetails
      ? selectMatchForMachineProject(
          clientProjectId as string,
          partnerProjectId,
          partnerID
        )
      : selectMatchForPartnerProject(partnerProjectId, partnerID)
  )
  useFirestoreConnect([
    {
      collection: 'companies',
      doc: partnerID
    },
    {
      collection: 'users',
      doc: partner?.owner
    },
    {
      collection: 'companies',
      doc: companyID,
      storeAs: `project/${
        showMachineDetails ? (clientProjectId as string) : partnerProjectId
      }/matches`,
      subcollections: [
        {
          collection: 'projects',
          doc: showMachineDetails
            ? (clientProjectId as string)
            : partnerProjectId,

          subcollections: [{ collection: 'matches' }]
        }
      ]
    }
  ])

  useEffect(() => {
    const fetch = async () => {
      if (!corpPicUrl && imagePicture && partner) {
        const urlOrFalse = await getUrlFirebase(firebase, partner.imagePicture)
        setCorpPicUrl(urlOrFalse)
      }
      if (!profilePicUrl && profileImage) {
        const urlOrFalse = await getUrlFirebase(
          firebase,
          partnerOwner.profileImage
        )
        setProfilePicUrl(urlOrFalse)
      }
    }
    fetch()
  }, [partner, partnerOwner])

  useEffect(() => {
    const fetch = async () => {
      if (showMachineDetails) {
        // load machine project
        const doc = await firebase
          .firestore()
          .doc(`companies/${partnerID}/projects/${partnerProjectId}`)
        const machineProject = (await doc.get()).data()
        setSpectrum(machineProject || null)
      } else {
        // load company spectrum
        const projectsCollection = await firebase
          .firestore()
          .collection(`companies/${partnerID}/projects`)
          .get()
        const [companySpectrum] = projectsCollection.docs
        setSpectrum(companySpectrum ? companySpectrum.data() : null)
      }
    }
    if (partnerID) fetch()
  }, [partnerID])
  const requestAsPartner = async () => {
    if (isPartner) {
      try {
        const requestPartnerForPartner = (firebase as any)
          .app()
          .functions('europe-west3')
          .httpsCallable('requestPartnerForPartner')
        const { data: email } = await requestPartnerForPartner({
          ownID: companyID,
          requestedID: partnerID
        })
        window.open(`mailto:${email}`, '_self')
      } catch (e) {
        console.log(e)
      }
      return
    }

    if (match?.requested) return
    try {
      if (match) {
        const doc = firebase
          .firestore()
          .doc(
            `companies/${companyID}/projects/${
              showMachineDetails
                ? (clientProjectId as string)
                : partnerProjectId
            }/matches/${match?.id}`
          )
        await doc.update({ requested: true })
      }
    } catch (e) {
      console.log(e)
    }
  }

  const renderImage = (
    urlData: string | null,
    urlFirebase: string | boolean | null,
    isContactImage?: boolean
  ) => {
    if (urlData && urlFirebase !== false)
      return urlFirebase ? (
        isContactImage ? (
          <ContactImage src={urlFirebase as string} />
        ) : (
          <LeftImage src={urlFirebase as string} />
        )
      ) : (
        <Spinner style={{ marginTop: 20 }} />
      )
    return null
  }

  const title = (() => {
    if (showMachineDetails) return spectrum?.name
    if (!partner?.hiddenProfile) return partner?.name?.toUpperCase()
    return 'PackPart GmbH'
  })()

  const requestTitle = showMachineDetails
    ? t('project.overview.requestMachine')
    : t('partnerOverview.partnerRequest')

  if (isLoaded(partner) && isEmpty(partner)) return <Redirect to="/" />
  if (!isLoaded(partner) || !isLoaded(partnerOwner) || !spectrum)
    return <Spinner />
  if (partner?.type === ProfileTypes.Searching) return <Redirect to="/" />
  return (
    <Dashboard inset={inset}>
      <CustomHelmet
        title={t('metadata.partnerDetails', {
          partnerName: title
        })}
      />
      <Breadcrumbs
        crumbs={[
          { link: '/', text: 'Dashboard' },
          {
            link: showMachineDetails
              ? `/usedMachineryOverview/${clientProjectId}`
              : `/partnerOverview/${partnerProjectId}`,
            text: showMachineDetails
              ? t('project.overview.yourUsedMachinery')
              : t('project.overview.yourProjectPartners')
          },
          {
            active: true,
            text: title
          }
        ]}
      />
      <TitleLineContainer>
        <CompanyTitle>{title} </CompanyTitle>
        {partnerProjectId && companyID !== partnerID && (
          <ContactContainer onClick={requestAsPartner}>
            <MailIcon>{!match?.requested ? 'mail' : 'check_mark'}</MailIcon>
            {!match?.requested
              ? requestTitle
              : t('partnerOverview.partnerRequested')}
          </ContactContainer>
        )}
      </TitleLineContainer>
      <RootContainer>
        <LeftContainer>
          {showMachineDetails ? (
            <>
              <ImageGallery
                fixedHeight="250px"
                imageItems={spectrum.descriptionImages}
                setImageItems={() => null}
                showButtons={false}
                showImageName={false}
              />
              <LeftContentContainer>
                <LeftTitle>
                  {t('project.companyPresentation.rangeOfServices')}
                </LeftTitle>
                <SmallCategoryContainer>
                  {spectrum?.categories && (
                    <CategoryPreview categories={spectrum?.categories} />
                  )}
                </SmallCategoryContainer>
                <StyledPriceAndWarranty
                  price={spectrum.price}
                  setPrice={() => null}
                  warranty={spectrum.warranty}
                  setWarranty={() => null}
                  disabled={true}
                />
                <MachineDescription>{spectrum.description}</MachineDescription>
              </LeftContentContainer>
            </>
          ) : (
            <>
              {imagePicture && renderImage(imagePicture, corpPicUrl)}
              <LeftContentContainer>
                <LeftTitle>
                  {t('project.companyPresentation.rangeOfServices')}
                </LeftTitle>
                <SmallCategoryContainer>
                  {spectrum?.categories && (
                    <CategoryPreview categories={spectrum?.categories} />
                  )}
                </SmallCategoryContainer>
                <LeftDescription>
                  {isHiddenProfile ? '' : description}
                </LeftDescription>
              </LeftContentContainer>
            </>
          )}
        </LeftContainer>

        <MiddleContainer>
          {spectrum ? (
            <CompanySpectrum spectrum={spectrum} />
          ) : (
            <>{t('partnerOverview.noRangeOfServices')}</>
          )}
        </MiddleContainer>

        {!isHiddenProfile && (
          <RightContainer>
            <ContactTitle>
              {t('project.companyPresentation.contactTitle')}
            </ContactTitle>
            {renderImage(profileImage, profilePicUrl, true)}
            <ContactRowContainer>
              <ContactName>{`${partnerOwner.firstName} ${partnerOwner.lastName}`}</ContactName>
            </ContactRowContainer>
            <ContactDescription>{partner?.address}</ContactDescription>
          </RightContainer>
        )}
      </RootContainer>
    </Dashboard>
  )
}

export default PartnerDetails
