/* eslint-disable react-hooks/exhaustive-deps */
import React, { useRef, useMemo } from 'react'
import styled from 'styled-components'
import Popover from '@material-ui/core/Popover'
import Icon from '@material-ui/core/Icon'

interface PopoverProps {
  children: React.ReactNode
  content: React.ReactNode
  isVisible: boolean
  position: string
  width?: number
  height?: number
  awayFromAnchorMargin?: number
  handlePopoverClose: () => void
}

interface StyleProps {
  backgroundColor?: string
}

const StyledDiv = styled.div`
  display: flex;
  flex: 1;
`

const TriangleForTop = styled.div<StyleProps>`
  position: absolute;
  width: 0;
  height: 0;
  bottom: -20px;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  border-style: solid;
  border-width: 20px 20px 0 20px;
  border-color: ${({ backgroundColor }) => backgroundColor || '#eaeaea'}
    transparent transparent transparent;
`

const TriangleForBottom = styled.div<StyleProps>`
  position: absolute;
  width: 0;
  height: 0;
  top: -20px;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  border-style: solid;
  border-width: 0 20px 20px 20px;
  border-color: transparent transparent
    ${({ backgroundColor }) => backgroundColor || '#eaeaea'} transparent;
`

const TriangleForLeft = styled.div<StyleProps>`
  position: absolute;
  width: 0;
  height: 0;
  top: 0;
  bottom: 0;
  right: -20px;
  margin-bottom: auto;
  margin-top: auto;
  border-style: solid;
  border-width: 20px 0 20px 20px;
  border-color: transparent transparent transparent
    ${({ backgroundColor }) => backgroundColor || '#eaeaea'};
`
const TriangleForRight = styled.div<StyleProps>`
  position: absolute;
  width: 0;
  height: 0;
  top: 0;
  bottom: 0;
  left: -20px;
  margin-bottom: auto;
  margin-top: auto;
  border-style: solid;
  border-width: 20px 20px 20px 0;
  border-color: transparent
    ${({ backgroundColor }) => backgroundColor || '#eaeaea'} transparent
    transparent;
`

const CloseButtonContainer = styled.div<StyleProps>`
  display: flex;
  justify-content: flex-end;
  position: relative;
  border-radius: 15px 15px 0 0;
  padding: 15px 20px 5px;
  background-color: ${({ backgroundColor }) => backgroundColor || '#eaeaea'};
`
const CloseButton = styled(Icon)`
  color: black;
  cursor: pointer;
  :hover {
    transform: scale(1.04);
    color: ${({ theme }) => theme.primary};
  }
`

const CustomPopover = ({
  handlePopoverClose,
  isVisible,
  children,
  content,
  position,
  backgroundColor,
  width,
  height,
  awayFromAnchorMargin
}: PopoverProps & StyleProps) => {
  const containerRef = useRef<HTMLDivElement | null>(null)

  const posConfig = useMemo(() => {
    const config: { [k: string]: any } = {
      anchorOrigin: {
        vertical: 'bottom',
        horizontal: 'center'
      },
      transformOrigin: {
        vertical: 'top',
        horizontal: 'center'
      },
      paperPropsStyle: {
        overflow: 'visible',
        background: '#eaeaea',
        width,
        height,
        borderRadius: '15px',
        transform: `translate(0px, ${awayFromAnchorMargin}px)`
      }
    }
    switch (position) {
      case 'top':
        config.anchorOrigin.vertical = 'top'
        config.transformOrigin.vertical = 'bottom'
        config.paperPropsStyle.transform = `translate(0px, -${awayFromAnchorMargin}px)`
        break
      case 'left':
        config.anchorOrigin.vertical = 'center'
        config.anchorOrigin.horizontal = 'left'
        config.transformOrigin.vertical = 'center'
        config.transformOrigin.horizontal = 'right'
        config.paperPropsStyle.transform = `translate(-${awayFromAnchorMargin}px, 0px)`
        break
      case 'right':
        config.anchorOrigin.vertical = 'center'
        config.anchorOrigin.horizontal = 'right'
        config.transformOrigin.vertical = 'center'
        config.transformOrigin.horizontal = 'left'
        config.paperPropsStyle.transform = `translate(${awayFromAnchorMargin}px, 0px)`
        break
    }
    return config
  }, [position])

  const Triangle = useMemo(() => {
    switch (position) {
      case 'top':
        return <TriangleForTop backgroundColor={backgroundColor} />
      case 'left':
        return <TriangleForLeft backgroundColor={backgroundColor} />
      case 'right':
        return <TriangleForRight backgroundColor={backgroundColor} />
      // 'bottom'
      default:
        return <TriangleForBottom backgroundColor={backgroundColor} />
    }
  }, [position])

  return (
    <StyledDiv ref={containerRef}>
      {children}
      <Popover
        open={isVisible}
        anchorEl={containerRef.current}
        onClose={handlePopoverClose}
        PaperProps={{
          style: {
            ...posConfig?.paperPropsStyle,
            width,
            height,
            display: 'flex',
            flexDirection: 'column'
          }
        }}
        anchorOrigin={posConfig?.anchorOrigin}
        transformOrigin={posConfig?.transformOrigin}>
        {Triangle}
        <CloseButtonContainer backgroundColor={backgroundColor}>
          <CloseButton onClick={handlePopoverClose}>close</CloseButton>
        </CloseButtonContainer>
        {content}
      </Popover>
    </StyledDiv>
  )
}

CustomPopover.defaultProps = {
  awayFromAnchorMargin: 0
}

export default CustomPopover
