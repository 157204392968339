import { selectProfileType } from '@selectors/profileSelectors'
import { useSelector } from 'react-redux'
import { ProfileTypes } from '@interfaces/user'
import { selectRegisterType } from '@selectors/registerSelectors'

export const isPartner = (profileType: ProfileTypes) => {
  return (
    profileType === ProfileTypes.Offering ||
    profileType === ProfileTypes.WageProducer ||
    profileType === ProfileTypes.UsedMachinerySupplier
  )
}

export function useIsPartner() {
  const profileType = useSelector(selectProfileType)
  return isPartner(profileType)
}

export function useIsPartnerInRegister() {
  const profileType = useSelector(selectRegisterType)
  return isPartner(profileType)
}

export function useIsUsedMachinerySupplier() {
  const profileType = useSelector(selectProfileType)
  return profileType === ProfileTypes.UsedMachinerySupplier
}
