/* eslint-disable react-hooks/exhaustive-deps */
import Button from '@components/elements/Button'
import CompanySpectrum from '@components/elements/CompanySpectrum'
import Dialog from '@components/elements/Dialog'
import { Company } from '@interfaces/company'
import { Project } from '@interfaces/project'
import { User } from '@interfaces/user'
import { Icon } from '@material-ui/core'
import {
  selectIsPremium,
  selectIsProjectUnlocked
} from '@selectors/companySelectors'
import { selectCompanyID } from '@selectors/profileSelectors'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { useFirebase } from 'react-redux-firebase'
import styled from 'styled-components'

const ProjectInfoContainer = styled.div`
  border-radius: 10px;
  width: 80vw;
  padding: 30px 0 50px;
  max-height: 80vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow-y: scroll;
  background-color: ${(props) => props.theme.surface};
`
const CloseIcon = styled(Icon)`
  color: black;
  margin: 15px 10px 10px auto;
  display: flex;
  font-size: 28px !important;
  cursor: pointer;
  :hover {
    transform: scale(1.08);
    color: ${({ theme }) => theme.primary};
  }
`
const ContactContainer = styled.div`
  align-self: flex-start;
  margin-left: 30px;
  width: calc(100% - 60px);
  h3 {
    font-size: 16px;
    font-weight: 800;
    color: #494949;
  }
`
const SpectrumContainer = styled.div`
  padding: 0 10px;
  box-sizing: border-box;
  width: 100%;
`
const Row = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 20px 0 0;
  padding-bottom: 3px;
  border-bottom: 1px solid #494949;
`
const ContactProperty = styled.div`
  font-size: 15px;
  font-weight: 600;
  color: #494949;
`
const ContactValue = styled.div`
  font-size: 15px;
  font-weight: 700;
  color: #494949;
  a {
    color: #494949;
  }
`
const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  div {
    margin: 0 10px;
  }
`
const NotUnlockedMessage = styled.div`
  font-weight: 500;
  color: ${(props) => props.theme.error};
`
const ProjectInfo = ({
  path,
  onClose
}: {
  path: string | null
  onClose: () => void
}) => {
  const { t } = useTranslation()
  const firebase = useFirebase()
  const [project, setProject] = useState<null | Project>(null)
  const [contactData, setContactData] = useState<null | any>()
  const [unlockPopupVisible, setUnlockPopupVisible] = useState(false)
  const [, companyID, , projectID] = path?.split('/') || []
  const hasPremium = useSelector(selectIsPremium)
  const userCompanyID = useSelector(selectCompanyID)
  const isUnlocked = useSelector(selectIsProjectUnlocked(companyID, projectID))
  const getProjectDetails = async () => {
    const data = await (
      await firebase
        .firestore()
        .doc(path as string)
        .get()
    ).data()
    if (data) setProject(data as Project)
  }
  const getContactData = async () => {
    const company = (await (
      await firebase.firestore().doc(`companies/${companyID}`).get()
    ).data()) as Company
    const owner = (await (
      await firebase.firestore().doc(`users/${company.owner}`).get()
    ).data()) as User

    const { address, name } = company
    const { email, firstName, lastName, phone } = owner

    setContactData({ address, name, email, firstName, lastName, phone })
  }
  const unlockProject = async () => {
    const yourCompanyRef = firebase
      .firestore()
      .doc(`companies/${userCompanyID}`)
    await yourCompanyRef.update({
      // @ts-ignore
      unlocked: firebase.firestore.FieldValue.arrayUnion({
        company: companyID,
        project: projectID
      })
    })
    setUnlockPopupVisible(false)
  }
  const rejectProject = async () => {
    const yourCompanyRef = firebase
      .firestore()
      .doc(`companies/${userCompanyID}`)
    await yourCompanyRef.update({
      // @ts-ignore
      rejected: firebase.firestore.FieldValue.arrayUnion({
        company: companyID,
        project: projectID
      })
    })
    onClose()
  }
  useEffect(() => {
    if (path) getProjectDetails()
  }, [path])

  useEffect(() => {
    if (isUnlocked) getContactData()
  }, [isUnlocked])
  if (!path) return null
  if (!project) return <>Loading</>
  return (
    <ProjectInfoContainer>
      <CloseIcon onClick={onClose}>close</CloseIcon>
      {isUnlocked && contactData && (
        <ContactContainer>
          <h3>{contactData.name}</h3>
          <Row>
            <ContactProperty>{t('partnerOverview.address')}:</ContactProperty>
            <ContactValue>{contactData.address}</ContactValue>
          </Row>
          <Row>
            <ContactProperty>
              {t('partnerOverview.contactPerson')}:
            </ContactProperty>
            <ContactValue>
              {contactData.firstName} {contactData.lastName}
            </ContactValue>
          </Row>
          <Row>
            <ContactProperty>{t('partnerOverview.email')}:</ContactProperty>
            <ContactValue>
              <a href={`mailto:${contactData.email}`}>{contactData.email}</a>
            </ContactValue>
          </Row>
          <Row>
            <ContactProperty>{t('partnerOverview.phone')}:</ContactProperty>
            <ContactValue>
              <a href={`tel:${contactData.phone}`}>{contactData.phone}</a>
            </ContactValue>
          </Row>
        </ContactContainer>
      )}
      <SpectrumContainer>
        <CompanySpectrum spectrum={project} expandAll />
      </SpectrumContainer>
      {!isUnlocked && hasPremium && (
        <ButtonContainer>
          <Button onClick={() => rejectProject()}>
            {t('buttons.rejectProjectRequest')}
          </Button>

          <Button onClick={() => hasPremium && setUnlockPopupVisible(true)}>
            {t('buttons.unlockProjectNow')}
          </Button>
        </ButtonContainer>
      )}
      {!hasPremium && (
        <NotUnlockedMessage>
          {t('partnerOverview.unlockedMessage')}
        </NotUnlockedMessage>
      )}
      <Dialog
        onClose={() => setUnlockPopupVisible(false)}
        open={unlockPopupVisible}
        title={t('partnerOverview.unlockNow')}
        description={t('partnerOverview.unlockNowNote')}
        buttons={[
          {
            title: t('buttons.cancel'),
            onClick: () => setUnlockPopupVisible(false),
            primary: false
          },
          {
            title: t('buttons.unlockNow'),
            onClick: unlockProject,
            primary: true
          }
        ]}
      />
    </ProjectInfoContainer>
  )
}

export default ProjectInfo
