import firebase from 'firebase/app'
import 'firebase/auth'
import 'firebase/firestore'
import 'firebase/functions'
import 'firebase/storage'
import React from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import {
  firebaseReducer,
  ReactReduxFirebaseProvider
} from 'react-redux-firebase'
import { createFirestoreInstance, firestoreReducer } from 'redux-firestore'

import { combineReducers, createStore } from 'redux'
import App from './App'
import { persistStore, persistReducer } from 'redux-persist'
import hardSet from 'redux-persist/es/stateReconciler/hardSet'
import { PersistGate } from 'redux-persist/integration/react'
import { ThemeProvider } from 'styled-components'
import theme from './theme/variables'
import { StylesProvider } from '@material-ui/core/styles'
import '@custom-css/reset.css'
import '@custom-css/global.css'
import { sidebarReducer } from '@redux/sidebar'
import { projectReducer } from '@redux/project'
import { profileReducer } from '@redux/profile'
import { I18nextProvider } from 'react-i18next'
import i18n from './translations/i18n'
import { registerReducer } from '@redux/register'
import * as serviceWorkerRegistration from './serviceWorkerRegistration'
import { feedbackReducer } from '@redux/feedback'
import localForage from 'localforage'
import Loader from '@components/elements/Loader'

const persistConfig = {
  key: 'root',
  storage: localForage,
  blacklist: ['register']
}
const fbConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SERNDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID
}

// react-redux-firebase config
const rrfConfig = {
  userProfile: 'users',
  useFirestoreForProfile: true,
  logErrors: true,
  useFirestoreForStorageMeta: true
}

// Initialize firebase instance
firebase.initializeApp(fbConfig)
firebase.firestore()
firebase.firestore().settings({
  ignoreUndefinedProperties: true,
  experimentalAutoDetectLongPolling: true
})
// Add firebase to reducers
const rootReducer = combineReducers({
  firebase: persistReducer(
    { key: 'firebaseState', storage: localForage, stateReconciler: hardSet },
    firebaseReducer
  ),
  firestore: persistReducer(
    { key: 'firestoreState', storage: localForage, stateReconciler: hardSet },
    // @ts-ignore
    firestoreReducer
  ),
  sidebar: sidebarReducer,
  project: projectReducer,
  register: registerReducer,
  feedback: feedbackReducer,
  profile: profileReducer
})

// Create store with reducers and initial state
const persistedReducer = persistReducer(persistConfig, rootReducer)
const initialState = {}
export const store = createStore(
  persistedReducer,
  initialState,
  (window as any).__REDUX_DEVTOOLS_EXTENSION__ &&
    (window as any).__REDUX_DEVTOOLS_EXTENSION__()
)
export const persistor = persistStore(store)

const rrfProps = {
  firebase,
  config: rrfConfig,
  dispatch: store.dispatch,
  createFirestoreInstance
}
serviceWorkerRegistration.unregister()

ReactDOM.render(
  <Provider store={store}>
    <PersistGate loading={<Loader />} persistor={persistor}>
      <ReactReduxFirebaseProvider {...rrfProps}>
        <StylesProvider injectFirst>
          <ThemeProvider theme={theme}>
            <I18nextProvider i18n={i18n}>
              <App />
            </I18nextProvider>
          </ThemeProvider>
        </StylesProvider>
      </ReactReduxFirebaseProvider>
    </PersistGate>
  </Provider>,
  document.getElementById('root')
)
