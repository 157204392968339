import React from 'react'
import styled from 'styled-components'

interface ButtonProps {
  borderColor?: string
}
const Button = styled.div<ButtonProps>`
  --border-color: ${({ borderColor }) => borderColor ?? 'white'};
  --gradient1: linear-gradient(90deg, var(--border-color) 70%, transparent 30%);
  --gradient2: linear-gradient(0deg, var(--border-color) 70%, transparent 30%);
  background-image: var(--gradient1), var(--gradient1), var(--gradient2),
    var(--gradient2);
  background-repeat: repeat-x, repeat-x, repeat-y, repeat-y;
  background-size: 15px 2px, 15px 2px, 2px 15px, 2px 15px;
  background-position: left top, right bottom, left bottom, right top;
  :hover {
    --border-color: ${({ theme }) => theme.primary};
    animation: border-dance 1s infinite linear;
  }
  @keyframes border-dance {
    0% {
      background-position: left top, right bottom, left bottom, right top;
    }
    100% {
      background-position: left 15px top, right 15px bottom, left bottom 15px,
        right top 15px;
    }
  }
`

const DashedContainer = (props: any) => {
  return <Button {...props}>{props.children}</Button>
}
export default DashedContainer
