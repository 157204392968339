import BellJar from '@components/elements/BellJar'
import Icon from '@material-ui/core/Icon'
import { selectCompanyID } from '@selectors/profileSelectors'
import { selectMatchesByID } from '@selectors/projectSelectors'
import React from 'react'
import { useSelector } from 'react-redux'
import { useFirestore, useFirestoreConnect } from 'react-redux-firebase'
import { useHistory } from 'react-router-dom'
import styled from 'styled-components'

interface ProjectCardProps {
  title: string
  description: string
  onEdit: () => void
  onDelete: () => void
  id: string
  isUsedMachineryCard?: boolean
}

const Container = styled.div`
  display: flex;
  width: 100%;
  min-height: 120px;
  border-radius: 10px;
  box-sizing: border-box;
  margin-bottom: 20px;
  background: ${(props) => props.theme.secondary};
  padding: 0 10px 0 10px;
`

const IconContainer = styled.div`
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  justify-content: space-between;
  align-items: center;
  width: 40px;
  background: ${(props) => props.theme.secondary};
  padding: 12px 0;
`
const BellJarContainer = styled.div`
  margin-left: 5px;
`
const EditIcon = styled(Icon)`
  margin-left: 5px;
  font-size: 22px !important;
  color: ${(props) => props.theme.surface};
  cursor: pointer;
  :hover {
    transform: scale(1.04);
    color: ${({ theme }) => theme.primary};
  }
`

const Seperator = styled.div`
  width: 2px;
  height: 100%;
  margin-left: 10px;
  background-color: ${({ theme }) => theme.surface};
`

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  padding: 12px 0 12px 10px;
  overflow: hidden;
  cursor: pointer;
`
const Title = styled.h1`
  font-size: 22px;
  font-weight: 600;
  margin-bottom: 10px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`
const Description = styled.h1`
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  overflow: hidden;
  padding-right: 30px;
  color: ${(props) => props.theme.surface};
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
`

const ProjectLongCard = (props: ProjectCardProps) => {
  const { title, description, onEdit, onDelete, id } = props
  const companyID = useSelector(selectCompanyID)
  const matches = useSelector(selectMatchesByID(id))
  const firestore = useFirestore()
  const history = useHistory()

  useFirestoreConnect([
    {
      collection: 'companies',
      doc: companyID,
      storeAs: `project/${id}/matches`,
      subcollections: [
        {
          collection: 'projects',
          doc: id,

          subcollections: [{ collection: 'matches' }]
        }
      ]
    }
  ])
  const unreadCount = matches
    .map((v) => (v?.unread ? 1 : 0) as number)
    .reduce((a: number, b: number) => a + b, 0)

  const openProject = async () => {
    const collection = firestore
      .collection(`companies/${companyID}/projects/${id}/matches`)
      .where('unread', '==', true)
    const data = await collection.get()
    data.forEach((d) => d.ref.update({ unread: false }))

    if (props.isUsedMachineryCard) history.push(`usedMachineryOverview/${id}`)
    else history.push(`partnerOverview/${id}`)
  }
  return (
    <Container>
      <IconContainer>
        <BellJarContainer>
          <BellJar
            notificationCount={unreadCount}
            iconSize={26}
            onNotificationPress={openProject}
          />
        </BellJarContainer>
        <EditIcon onClick={onEdit}>edit</EditIcon>
        <EditIcon onClick={onDelete}>delete</EditIcon>
      </IconContainer>
      <Seperator />

      <ContentContainer onClick={openProject}>
        <Title>{title}</Title>
        <Description>{description}</Description>
      </ContentContainer>
    </Container>
  )
}

export default ProjectLongCard
