import { selectUID } from '@selectors/firebaseAuth'
import imageCompression from 'browser-image-compression'
import cloneDeep from 'lodash/cloneDeep'
import { useSelector } from 'react-redux'
import { useFirebase } from 'react-redux-firebase'
import { getUrlFirebase } from './helpers'
import differenceBy from 'lodash/differenceBy'
import { CustomGalleryItem } from 'packpart-interfaces/lib'

export const resizeImage = async (file: File, maxSize: number) => {
  const resizeOptions = {
    maxWidthOrHeight: maxSize || 1000,
    useWebWorker: true
  }
  const resizedFile = (await imageCompression(file, resizeOptions)) as File
  return resizedFile
}

export const resizeAndUploadImage = async ({
  firebase,
  path,
  file,
  maxSize
}: {
  path: string
  file: File
  firebase: any
  maxSize?: number
}) => {
  let fileToUpload = file
  if (maxSize) fileToUpload = await resizeImage(file, maxSize)

  const uploadedFile = await firebase.uploadFile(path, fileToUpload)
  const uploadedFileMetadata = uploadedFile.uploadTaskSnapshot.metadata

  return `gs://${uploadedFileMetadata.bucket}/${uploadedFileMetadata.fullPath}`
}

export const useImageUpload = () => {
  const firebase = useFirebase()
  const uid = useSelector(selectUID)

  const uploadFromImageGallery = async ({
    uploadedImages = [],
    imageItems,
    projectId,
    maxSize
  }: {
    uploadedImages: CustomGalleryItem[] | undefined
    imageItems: CustomGalleryItem[]
    projectId: string
    maxSize?: number
  }) => {
    try {
      const newImageItems = cloneDeep(imageItems)

      // if uploaded images exist -> delete removed images from the
      // imageGallery also from fb
      const removedImages = differenceBy(
        uploadedImages,
        imageItems,
        'description'
      )
      for (const rImage of removedImages) {
        if (rImage.storageUrl) {
          const imageRef = firebase.storage().refFromURL(rImage.storageUrl)
          await imageRef.delete()
        }
      }

      // Upload to fb & set download url
      for (const item of newImageItems) {
        // image is already uploaded
        if (!item.file) {
          continue
        }
        const src = await resizeAndUploadImage({
          firebase,
          path: `files/${uid}/descriptionImages/${projectId}`,
          file: item.file,
          maxSize
        })
        item.storageUrl = src
        item.original = await getUrlFirebase(firebase, src)
      }

      // remove file property on all objects
      const newImageItemsWithoutFile: CustomGalleryItem[] = []
      for (const item of newImageItems) {
        const { file, ...otherProps } = item
        newImageItemsWithoutFile.push({ ...otherProps })
      }
      return newImageItemsWithoutFile
    } catch (e) {
      let errorMessage = 'Error in storage, uploadFromImageGallery'
      if (e instanceof Error) {
        errorMessage = e.message
      }
      console.log('e', errorMessage)
    }
  }

  return { uploadFromImageGallery }
}
