import Button from '@components/elements/Button'
import { ProfileTypes } from '@interfaces/user'
import BranchesSelector from '@screens/login/BranchesSelector'
import { getRegisterSchema } from '@services/helpers'
import { Field, Formik } from 'formik'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import {
  RegisterDisclaimer,
  StyledForm,
  LeftForm,
  RightForm,
  StyledErrorMessage,
  FieldWithErrorContainer,
  FieldWithError,
  ButtonNote,
  ButtonContainer,
  Progress
} from '@components/forms/elements'

interface IRegisterForm {
  initialValues: any
  onSubmit: (values: any) => void
  error: string
  profileType: ProfileTypes | null
  loading?: boolean
}

const RegisterForm = ({
  initialValues,
  onSubmit,
  error,
  loading = false,
  profileType
}: IRegisterForm) => {
  const { t } = useTranslation()
  const SignupSchema = getRegisterSchema(t, profileType, false)
  const [isSubmitEnabled, setIsSubmitEnabled] = useState(false)

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={SignupSchema}
      onSubmit={onSubmit}>
      {({ values, touched, errors, isValid }) => (
        <StyledForm>
          {/* left side */}
          <LeftForm>
            <FieldWithError
              name="firstName"
              isTouched={touched.firstName}
              errorMessage={errors.firstName}
            />
            <FieldWithError
              name="lastName"
              isTouched={touched.lastName}
              errorMessage={errors.lastName}
            />
            <FieldWithError
              name="email"
              type="email"
              isTouched={touched.email}
              errorMessage={errors.email}
            />
            <FieldWithError
              name="password"
              type="password"
              isTouched={touched.password}
              errorMessage={errors.password}
            />
          </LeftForm>

          {/* right side */}
          <RightForm>
            <FieldWithErrorContainer
              hasError={touched.branches && errors?.branches !== undefined}>
              <Field
                name="branches"
                as={BranchesSelector}
                isTouched={touched.branches}
                errorMessage={errors.branches}
              />
              <StyledErrorMessage component="div" name={'branches'} />
            </FieldWithErrorContainer>
          </RightForm>

          <RegisterDisclaimer
            profileType={profileType}
            setIsSubmitEnabled={setIsSubmitEnabled}
          />

          {/* button */}
          <ButtonContainer>
            <Button type="submit" primary condensed disabled={!isSubmitEnabled}>
              {loading && <Progress />}
              {profileType === ProfileTypes.Searching
                ? t('buttons.registerSearching')
                : t('buttons.registerOffering')}
            </Button>
            {error}
            {profileType !== ProfileTypes.Searching && (
              <ButtonNote>{t('messages.registerBoxNote')}</ButtonNote>
            )}
          </ButtonContainer>
        </StyledForm>
      )}
    </Formik>
  )
}

export default RegisterForm
