import { AvailableCategories } from '@interfaces/project'
import { setCurrentStep, setOpenCategory, toggleCategory } from '@redux/project'
import {
  selectActiveCategories,
  selectOpenCategory,
  selectShowUnfinishedFields,
  selectUndoneCategories
} from '@selectors/projectSelectors'
import { indexToLetter } from '@services/helpers'
import { motion } from 'framer-motion'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components'
import { OverhangBottom, OverhangTop } from '../microElements/Overhang'

const CategoriesQuickSwitcher = styled.div`
  height: 100%;
  width: 120px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-start;
`
interface CategoryBubbleInterface {
  isActive?: boolean
  isEnabled?: boolean
  hasRedBorder?: boolean
}

const CategoriesQuickSwitchContainer = styled.div<CategoryBubbleInterface>`
  height: 50px;
  width: 70px;
  margin: 0 0 20px;
  background-color: ${(props) =>
    props.isActive ? props.theme.card : 'transparent'};
  display: flex;
  border-top-left-radius: 24px;
  border-bottom-left-radius: 24px;
  border-bottom-right-radius: -24px;
  justify-content: flex-start;
  align-items: center;
  position: relative;
`

const CategoryBubble = styled(motion.button)<CategoryBubbleInterface>`
  width: 48px;
  height: 48px;
  border-radius: 24px;
  background-color: ${(props) =>
    props.isEnabled ? props.theme.primary : props.theme.card};
  display: flex;
  justify-content: center;
  align-items: center;
  color: black;
  font-weight: 600;
  font-size: 20px;
  opacity: ${(props) => (props.isEnabled ? 1 : 0.5)};
  cursor: ${(props) => (props.isEnabled ? 'pointer' : 'not-allowed')};
  border: ${({ hasRedBorder }) => (hasRedBorder ? '1px solid red' : 'none')};
`
const CloseIconContainer = styled.div`
  width: 20px;
  height: 20px;
  background-color: ${(props) => props.theme.card};
  border-radius: 10px;
  position: absolute;
  left: -30px;
  color: black;
  display: flex;
  font-size: 14px;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  :hover {
    background-color: ${({ theme }) => theme.primary};
  }
`

const CategorySwitcher = () => {
  const dispatch = useDispatch()
  const activeCategories = useSelector(selectActiveCategories)
  const openCategory = useSelector(selectOpenCategory)
  const undoneCategories = useSelector(selectUndoneCategories)
  const showUnfinishedFields = useSelector(selectShowUnfinishedFields)
  const [hasRedBorder, setHasRedBorder] = useState(false)

  useEffect(() => {
    setHasRedBorder(showUnfinishedFields)
  }, [showUnfinishedFields])

  useEffect(() => {
    if (!openCategory || !activeCategories.includes(openCategory)) {
      if (activeCategories.length > 0)
        dispatch(setOpenCategory(activeCategories[0]))
      else dispatch(setCurrentStep(0))
    }
  }, [activeCategories, dispatch, openCategory])

  return (
    <CategoriesQuickSwitcher>
      {AvailableCategories.map((category, index) => {
        const isEnabled = activeCategories.includes(category)
        const isActive = openCategory === category
        const isUndone = undoneCategories.includes(category)
        return (
          <CategoriesQuickSwitchContainer isActive={isActive} key={category}>
            {isActive && index !== 0 && <OverhangTop />}
            {isEnabled && (
              <CloseIconContainer
                onClick={() => dispatch(toggleCategory(category))}>
                x
              </CloseIconContainer>
            )}
            <CategoryBubble
              onClick={() => isEnabled && dispatch(setOpenCategory(category))}
              isEnabled={isEnabled}
              isActive={isActive}
              hasRedBorder={isUndone && hasRedBorder}
              whileHover={{ scale: 1.1 }}
              whileTap={{ scale: 0.9 }}>
              {indexToLetter(index)}
            </CategoryBubble>
            {isActive && <OverhangBottom />}
          </CategoriesQuickSwitchContainer>
        )
      })}
    </CategoriesQuickSwitcher>
  )
}

export default CategorySwitcher
