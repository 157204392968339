import {
  selectCurrentStep,
  selectProgress,
  selectRelevantCategories
} from '@selectors/projectSelectors'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import styled from 'styled-components'

interface ProgressInterface {
  fill: number
}
const ProgressBarContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  width: 60%;
  margin: 0 auto 3px;
  box-sizing: border-box;
`
interface TitleInterface {
  $offset?: boolean
}
const Title = styled.p<TitleInterface>`
  font-size: 25px;
  color: white;
  font-weight: 600;
  margin-right: ${({ $offset }) => ($offset ? '75px' : '10px')};
  @media (max-width: 1200px) {
    font-size: 20px;
  }
`
const Subtitle = styled.p`
  font-size: 15px;
  color: ${(props) => props.theme.primary};
  margin-left: 10px;
  @media (max-width: 1200px) {
    font-size: 12px;
  }
`
const TitleContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
  @media (max-width: 1200px) {
    margin-bottom: 5px;
  }
`
const ProgressBarInner = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
`
const Progress = styled.div`
  display: flex;
  flex: 1;
  background-color: #494949;
  height: 11px;
  border-radius: 6px;
  margin-right: 20px;
`

const ProgressFill = styled.div<ProgressInterface>`
  display: flex;
  flex: ${(props) => props.fill};
  background-color: ${(props) => props.theme.primary};
  height: 11px;
  border-radius: 6px;
  transition: all linear 500ms;
`

const ProgressUnfilled = styled.div<ProgressInterface>`
  display: flex;
  flex: ${(props) => props.fill};
  background-color: transparent;
  transition: all linear 500ms;
`

const ProgressBar = () => {
  const currentStep = useSelector(selectCurrentStep)
  const progress = useSelector(selectProgress)
  const relevantCategories = useSelector(selectRelevantCategories)
  const { t } = useTranslation()
  const maxSteps = 2
  const title = () => {
    if (progress === 100) return t('project.progress.step6')
    if (progress > 90) return t('project.progress.step5')
    if (progress > 66) return t('project.progress.step4')
    if (progress > 50) return t('project.progress.step3')
    if (progress > 25) return t('project.progress.step2')
    if (progress > 10) return t('project.progress.step1')

    return t('project.progress.start')
  }
  const time = () => {
    return Math.ceil(relevantCategories.length * 5 * ((100 - progress) / 100))
  }
  const isLastStep = currentStep === maxSteps
  return (
    <ProgressBarContainer>
      <TitleContainer>
        <Title $offset={isLastStep}>{title()}</Title>
        {!isLastStep && (
          <Subtitle>{t('project.progress.still', { time: time() })}</Subtitle>
        )}
      </TitleContainer>
      <ProgressBarInner>
        <Progress>
          <ProgressFill fill={progress} />
          <ProgressUnfilled fill={100 - progress} />
        </Progress>
        {progress} % / 100 %
      </ProgressBarInner>
    </ProgressBarContainer>
  )
}
export default ProgressBar
