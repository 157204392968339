import React from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import styled from 'styled-components'

const FooterContainer = styled.div`
  display: flex;
  height: 25px;
  width: 100%;
  background-color: ${(props) => props.theme.secondary};
  align-items: center;
  justify-content: flex-end;
`
const LinkItem = styled.div`
  color: white;
  margin-right: 20px;
  font-size: 12px;
  cursor: pointer;
  :hover {
    color: ${(props) => props.theme.primary};
  }
`
const Footer = () => {
  const { t } = useTranslation()
  return (
    <FooterContainer>
      <Link to="/impressum">
        <LinkItem>{t('imprint')}</LinkItem>
      </Link>
      <Link to="/datenschutz">
        <LinkItem>{t('privacy')}</LinkItem>
      </Link>
    </FooterContainer>
  )
}

export default Footer
