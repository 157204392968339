import { createSelector } from 'reselect'
import { selectCompany } from './companySelectors'

export const selectProfile = (s: any) => {
  if (s?.firebase?.profile && s?.profile)
    return { ...s.firebase.profile, ...s.profile }
  return null
}

export const selectProfileType = createSelector(
  selectProfile,
  (profile) => profile?.type
)
export const selectCompanyID = createSelector(
  selectProfile,
  (profile) => profile?.companyID || 'undefined'
)
export const selectLanguage = createSelector(
  selectProfile,
  (profile) => profile?.language || 'de'
)
export const selectProfileImage = createSelector(
  selectProfile,
  (profile) => profile?.profileImage
)
export const selectProfileFormValues = createSelector(
  selectProfile,
  selectCompany,
  (profile, companyData) => {
    if (!profile || !companyData) return null
    const { phone, company, firstName, lastName, position, profileImage } =
      profile
    const { address, country, branches, logo, imagePicture, description } =
      companyData
    return {
      company,
      firstName,
      lastName,
      position,
      profileImage,
      address,
      country,
      branches,
      phone,
      logo,
      description,
      imagePicture
    }
  }
)

export const selectProfileFormValuesLoaded = createSelector(
  selectProfileFormValues,
  (formValues) => !!formValues?.firstName
)

export const selectAreProfileValuesComplete = createSelector(
  selectProfileFormValues,
  (formValues) => {
    if (!formValues) return null
    const {
      phone = '',
      company = '',
      address = '',
      country = ''
    }: any = formValues
    return (
      phone.length > 0 &&
      company.length > 0 &&
      address.length > 0 &&
      country.length > 0
    )
  }
)

export const selectShowCompleteProfileModal = createSelector(
  selectProfile,
  (profile) => !!profile?.showCompleteProfileModal
)
