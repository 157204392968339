import Button from '@components/elements/Button'
import CustomHelmet from '@components/elements/CustomHelmet'
import Modal from '@components/elements/Modal'
import Icon from '@material-ui/core/Icon'
import { setFeedback } from '@redux/feedback'
import { setCurrentRegisterStep } from '@redux/register'
import {
  selectCurrentRegisterStep,
  selectRegisterForm,
  selectRegisterType
} from '@selectors/registerSelectors'
import { resizeAndUploadImage } from '@services/storage'
import i18next from 'i18next'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useFirebase } from 'react-redux-firebase'
import styled from 'styled-components'
import useMedia from 'use-media'
import RegisterForm from '@components/forms/RegisterForm'
import Toggles from './Toggles'

const Container = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  border-radius: 10px;
  background-color: ${(props) => props.theme.surface};
  box-sizing: border-box;
  padding: 40px 50px;
  position: relative;
  @media (max-width: 850px) {
    padding: 50px 0 0 0;
  }
`
const Title = styled.p`
  color: black;
  font-size: 25px;
  font-weight: 800;
`
const CloseIcon = styled(Icon)`
  color: black;
  position: absolute;
  top: 20px;
  right: 20px;
  font-size: 28px !important;
  cursor: pointer;
`
const BackIcon = styled(Icon)`
  color: black;
  position: absolute;
  top: 20px;
  left: 20px;
  font-size: 28px !important;
  cursor: pointer;
`
const RegisterBox = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const [registerOpen, setRegisterOpen] = useState(false)
  const currentStep = useSelector(selectCurrentRegisterStep)
  const close = () => setRegisterOpen(false)
  const goBack = () => dispatch(setCurrentRegisterStep(0))
  const formValues = useSelector(selectRegisterForm)
  const firebase = useFirebase()
  const [registerError, setRegisterError] = useState('')
  const type = useSelector(selectRegisterType)
  const [loading, setLoading] = useState(false)
  const isSmall = useMedia({ maxWidth: '850px' })

  return (
    <>
      <Button
        variant="contained"
        condensed
        onClick={() => setRegisterOpen(true)}>
        {t('buttons.register')}
      </Button>
      <Modal
        size={isSmall ? '100%' : '70%'}
        height="auto"
        open={registerOpen}
        onClose={() => null /* onClose */}>
        <Container>
          <CustomHelmet title={t('metadata.register')} />
          <Title>{t('register.title')}</Title>
          <CloseIcon onClick={close}>close</CloseIcon>
          {currentStep === 1 && (
            <BackIcon onClick={goBack}>arrow_back_ios</BackIcon>
          )}

          {currentStep === 0 && <Toggles />}
          {currentStep === 1 && (
            <RegisterForm
              profileType={type}
              loading={loading}
              error={registerError}
              initialValues={formValues}
              onSubmit={async (values) => {
                try {
                  setLoading(true)
                  const {
                    address,
                    email,
                    company,
                    branches,
                    country,
                    firstName,
                    lastName,
                    password,
                    phone,
                    position,
                    description
                  } = values
                  await firebase.createUser(
                    { email, password },
                    {
                      email,
                      phone,
                      company,
                      firstName,
                      lastName,
                      position,
                      roles: { admin: true, editor: true, viewer: true },
                      type,
                      created_at: Date.now()
                    }
                  )
                  const uid = await firebase.auth().currentUser?.uid
                  const { id } = await firebase
                    .firestore()
                    .collection('companies')
                    .add({
                      address,
                      name: company,
                      country,
                      branches,
                      owner: uid,
                      type,
                      description,
                      premiumUser: false,
                      created_at: Date.now()
                    })

                  const profileImage = values.profileImage
                    ? await resizeAndUploadImage({
                        path: `profile-pictures/${uid}`,
                        file: values.profileImage,
                        firebase,
                        maxSize: 1000
                      })
                    : null
                  const logo = values.logo
                    ? await resizeAndUploadImage({
                        path: `logos/${uid}`,
                        file: values.logo,
                        firebase,
                        maxSize: 600
                      })
                    : null
                  const imagePicture = values.imagePicture
                    ? await resizeAndUploadImage({
                        path: `image-pictures/${uid}`,
                        file: values.imagePicture,
                        firebase
                      })
                    : null
                  await firebase.updateProfile({
                    companyID: id,
                    profileImage,
                    email,
                    language: i18next.language
                  })
                  if (imagePicture || logo)
                    await firebase
                      .firestore()
                      .collection('companies')
                      .doc(id)
                      .update({ imagePicture, logo })
                } catch (e) {
                  let errorMessage = 'Error in RegisterBox'
                  if (e instanceof Error) {
                    errorMessage = e.message
                  }
                  console.log(e, errorMessage)
                  if (errorMessage) setRegisterError(errorMessage)
                  dispatch(
                    setFeedback({ message: errorMessage, type: 'error' })
                  )
                } finally {
                  setLoading(false)
                }
              }}
            />
          )}
        </Container>
      </Modal>
    </>
  )
}

export default RegisterBox
