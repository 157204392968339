import CustomHelmet from '@components/elements/CustomHelmet'
import { ProjectCategory } from '@interfaces/project'
import { selectProjectSort } from '@selectors/projectSelectors'
import { useIsPartner } from '@services/customHooks'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import ConfigurationCategory from '../elements/ConfigurationCategory'
import ConfiguratorTitle from '../microElements/ConfiguratorTitle'

const Sort = () => {
  const data = useSelector(selectProjectSort)
  const { t } = useTranslation()
  const isPartner = useIsPartner()
  return (
    <>
      <CustomHelmet title={t('metadata.newProjectSort')} />
      <ConfiguratorTitle>
        {t(
          `project.categories.${
            isPartner ? 'depositRangeOfServiceSort' : 'sort'
          }`
        )}
      </ConfiguratorTitle>
      {Object.keys(data).map((category, index) => (
        <ConfigurationCategory
          key={index}
          title={t(`project.configurator.categories.${category}`)}
          category={data[category] as ProjectCategory}
          position={`sort.${category}`}
          defaultOpen={index === 0}
        />
      ))}
    </>
  )
}

export default Sort
