/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import {
  Categories,
  Project,
  ProjectCategory,
  ProjectOvercategory,
  ProjectTypes
} from '@interfaces/project'
import { Icon } from '@material-ui/core'
import CategoryPreview from '@screens/MatchedOverview/elements/CategoriesPreview'
import { getCategoryIndex } from '@services/helpers'
import { BigSizeContext } from '@services/contexts'
import isArray from 'lodash/isArray'
import isEmpty from 'lodash/isEmpty'
import orderBy from 'lodash/orderBy'
import React, { useState, useContext, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useFirebase } from 'react-redux-firebase'
import styled from 'styled-components'
import { CustomImage } from '@components/elements/CustomImage'

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`
const Category = styled.div`
  width: 100%;
  background: ${(props) => props.theme.surface};
  border-radius: 10px;
  margin-bottom: 20px;
  box-sizing: border-box;
  padding: 10px 20px;
`
const CategoryTitle = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin: 5px 0;
  cursor: pointer;
  :hover {
    span {
      color: ${({ theme }) => theme.primary};
    }
  }
`
interface ArrowIconInterface {
  open?: boolean
}
const ArrowIcon = styled(Icon)<ArrowIconInterface>`
  color: black;
  transform: ${({ open }) => (open ? 'rotate(-180deg)' : 'rotate(0deg)')};
  transition: all ease-in-out 300ms;
`
const OvercategoryTitle = styled.div`
  font-size: 14px;
  font-weight: 700;
  color: ${(props) => props.theme.secondary};
  overflow: hidden;
  text-overflow: ellipsis;
  display: flex;
  justify-content: space-between;
  width: calc(100% - 15px);
  margin: 20px 0 20px 5px;
  padding-bottom: 4px;
  box-sizing: border-box;
  align-items: center;
  border-bottom: 1px solid ${(props) => props.theme.secondary};
  cursor: pointer;
  span {
    font-size: 17px !important;
  }
  :hover {
    span {
      color: ${({ theme }) => theme.primary};
    }
  }
`
const FieldsContainer = styled.div`
  display: flex;
  flex-direction: column;
`
interface FieldTitleInterface {
  open?: boolean
}
const FieldTitle = styled.div<FieldTitleInterface>`
  display: flex;
  flex: 1;
  height: 46px;
  background-color: ${(props) => props.theme.subcard};
  color: black;
  font-size: 18px;
  font-weight: 600;
  box-sizing: border-box;
  justify-content: space-between;
  align-items: center;
  padding: 0 0 0 10px;
  word-break: break-all;
  cursor: pointer;
`
const FieldDataContainer = styled.div`
  background-color: ${({ theme }) => theme.lightGray};
  border-radius: 7px;
  overflow: hidden;
  margin: 10px 0;
`

const ToggleIcon = styled(Icon)<FieldTitleInterface>`
  color: ${(props) => props.theme.border};
  transform: rotate(${(props) => (props.open ? '-180deg' : '0deg')});
  transition: transform ease-in-out 300ms;
  font-size: 30px !important;
`
const ToggleIconContainer = styled.div<FieldTitleInterface>`
  height: 100%;
  width: 46px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${({ theme }) => theme.lightGray};
  border-bottom-right-radius: ${(props) => (props.open ? '0' : '7px')};
  border-top-right-radius: 7px;
  cursor: pointer;
  :hover {
    span {
      color: ${({ theme }) => theme.primary};
    }
  }
`
const DataField = styled.div`
  display: flex;
  flex-wrap: wrap;
  padding: 10px 0;
  background-color: ${(props) => props.theme.subcard};
  margin: 10px;
  border-radius: 7px;
  color: black;
  div {
    color: black;
  }
`
const InnerDataField = styled(DataField)`
  padding: 0;
  width: 100%;
`
const InnerDataFieldSimpleTitle = styled.div`
  color: black;
  width: 100%;
  margin: 14px 0 0 10px;
`

const InnerDataFieldTitle = styled.div`
  font-weight: 600;
  color: #333 !important;
  flex: 1 1 100%;
  display: flex;
  box-sizing: border-box;
  margin: 15px 12px 5px 12px;
  padding-bottom: 3px;
  border-bottom: 1px solid #666;
  justify-content: space-between;
  align-items: center;
  span {
    font-size: 16px !important;
    color: #333 !important;
  }
  cursor: pointer;
  :hover {
    span {
      color: ${({ theme }) => theme.primary};
    }
  }
`
const CheckIcon = styled(Icon)`
  color: black;
  font-size: 20px !important;
  margin: 0 5px 0 0;
  color: ${({ theme }) => theme.primary};
`
const StringValue = styled.div`
  margin: 0 5px;
  font-size: 14px;
`
const HyperLink = styled.a`
  margin: 0 5px;
  font-size: 14px;
  color: ${({ theme }) => theme.primary};
  cursor: pointer;
  display: flex;
`
interface ValueItemInterface {
  fullWidth?: boolean
}
const ValueItem = styled.div<ValueItemInterface>`
  display: flex;
  flex: ${(props) => (props.fullWidth ? '1 1 100%' : '1 1 50%')};
  margin: 5px 0;
  padding: 0 10px;
  box-sizing: border-box;
  align-items: center;
  line-height: 19px;
  div {
    line-height: 19px;
  }
`
const ValueItemColumn = styled(ValueItem)`
  flex-direction: column;
  align-items: flex-start;
  line-height: 30px;
`

const Bold = styled.div`
  font-weight: 700;
  color: #333 !important;
  margin-left: 5px;
`
const MultiPrioTitle = styled.div`
  color: black;
  font-weight: 700;
  margin: 14px 0 0 10px;
`

interface ImageInterface {
  big?: boolean
}
const PreviewImage = styled(CustomImage)<ImageInterface>`
  display: flex;
  box-sizing: border-box;
  border-radius: 5px;
  object-fit: contain;
  max-width: 175px;
  max-height: 175px;
  margin: 0 10px;
  cursor: pointer;
  ${({ big }) =>
    big &&
    `
    max-width: 600px;
    max-height: 600px;
  `}
`

const OtherFile = ({ path }: { path: string }) => {
  const firebase = useFirebase()
  const [url, setUrl] = useState<string | null>(null)
  const getUrl = async () => {
    const firebaseUrl = await firebase
      .storage()
      .refFromURL(path)
      .getDownloadURL()
    setUrl(firebaseUrl)
  }
  useEffect(() => {
    getUrl()
  }, [])
  if (!url) return null
  return (
    <ValueItem fullWidth>
      <HyperLink onClick={() => window.open(url, '_blank')}>{url}</HyperLink>
    </ValueItem>
  )
}

const Value = ({
  identifier,
  child,
  showIdentifier
}: {
  identifier: string
  child: { value: any; identifier: any }
  showIdentifier?: boolean
}) => {
  const { t, i18n } = useTranslation()
  const firebase = useFirebase()
  const showBig = useContext(BigSizeContext)
  const renderValue = (v: any): any => {
    switch (typeof v) {
      case 'boolean':
        return (
          <CheckIcon>{v ? 'check_box' : 'indeterminate_check_box'}</CheckIcon>
        )
      case 'string':
        if (v === 'no') return renderValue(false)
        if (v === 'yes') return renderValue(true)
        return <StringValue>{v}</StringValue>
      case 'number':
        return <StringValue>{v}</StringValue>
      default:
        return <></>
    }
  }
  if (
    i18n.exists(
      `project.configurator.fields.${identifier}.${child.identifier}.${child.value}`
    )
  )
    return (
      <ValueItem>
        {/* Einheit Workaround */}
        {child.identifier === 'unitType' && (
          <>{t('project.configurator.general.unit')}:</>
        )}
        {showIdentifier && (
          <>
            {t(`project.configurator.fieldsWithSubfields.${child.identifier}`)}:
          </>
        )}
        <Bold>
          {t(
            `project.configurator.fields.${identifier}.${child.identifier}.${child.value}`
          )}
        </Bold>
      </ValueItem>
    )

  if (typeof child.value === 'string' && child.value.startsWith('gs://')) {
    if (
      ['jpg', 'jpeg', 'png', 'gif'].some((i) =>
        child.value.toLowerCase().endsWith(i)
      )
    )
      return (
        <PreviewImage
          big={showBig}
          url={child.value}
          onClick={async () => {
            const url = await firebase
              .storage()
              .refFromURL(child.value)
              .getDownloadURL()
            window.open(url, '_blank')
          }}
        />
      )

    return (
      <ValueItemColumn>
        {showIdentifier && (
          <>
            {t(`project.configurator.fields.${identifier}.${child.identifier}`)}
          </>
        )}

        <OtherFile path={child.value} />
      </ValueItemColumn>
    )
  }
  if (child.identifier.toLowerCase().endsWith('other'))
    return (
      <ValueItem>
        {showIdentifier && (
          <Bold style={{ margin: 0 }}>{t('project.basics.other')}:</Bold>
        )}
        {renderValue(child.value)}
      </ValueItem>
    )
  // i am not sure if i should show false - values?
  if (child.value === false) return null
  if (child.value === null) return null
  if (typeof child.value === 'number' || typeof child.value === 'boolean')
    return (
      <ValueItem>
        {renderValue(child.value)}
        {t(`project.configurator.fields.${identifier}.${child.identifier}`)}
      </ValueItem>
    )
  return (
    <ValueItem>
      {showIdentifier && (
        <>
          {t(`project.configurator.fields.${identifier}.${child.identifier}`)}
        </>
      )}
      {renderValue(child.value)}
    </ValueItem>
  )
}

const InnerFields = ({
  field,
  identifier,
  onClick,
  open,
  expandAll,
  type
}: {
  field: any
  identifier: string
  onClick: () => void
  open?: boolean
  expandAll?: boolean
  type: ProjectTypes | undefined
}) => {
  const { t } = useTranslation()
  const arrayData = isArray(field) ? field : [field]
  const [openInnerField, setOpenInnerField] = useState<string | null>()
  const childData = arrayData.map((subField) =>
    Object.entries(subField).map(([key, value]: [any, any]) => ({
      value,
      identifier: key
    }))
  )

  return (
    <FieldDataContainer>
      <FieldTitle onClick={onClick} open={open}>
        {t(`project.configurator.subcategories.${identifier}`)}

        <ToggleIconContainer open={open}>
          <ToggleIcon open={open}>expand_more</ToggleIcon>
        </ToggleIconContainer>
      </FieldTitle>
      {open &&
        childData.map((parent, index) => (
          <>
            {childData.length > 1 && (
              <MultiPrioTitle>
                {index + 1}.{' '}
                {type === 'offering' ||
                type === 'wageProducer' ||
                type === 'usedMachinerySupplier'
                  ? t('project.basics.rangeOfServices')
                  : t('project.configurator.general.priority')}
              </MultiPrioTitle>
            )}
            <DataField>
              {orderBy(parent, [
                function (o) {
                  return typeof o.value
                }
              ]).map((child) => {
                if (child.identifier.startsWith('_to_')) return null
                if (child.identifier.startsWith('_from_')) {
                  const cleanedIdentifier = child.identifier.replace(
                    '_from_',
                    ''
                  )
                  return (
                    <ValueItem fullWidth>
                      <>
                        {/* TODO MAKE THESE UNDER EACH OTHER??? */}
                        {`${t(
                          `project.configurator.fields.${identifier}.${cleanedIdentifier}`
                        )}:`}
                      </>
                      <Bold>
                        {`${t('project.configurator.general.between')} ${
                          child.value
                        } ${t('project.configurator.general.and')} ${
                          parent.find(
                            (p) => p.identifier === `_to_${cleanedIdentifier}`
                          )?.value
                        }`}
                      </Bold>
                    </ValueItem>
                  )
                }
                if (
                  typeof child.value === 'object' &&
                  child.value !== null &&
                  parent.some((c) => Object.keys(c.value).length > 1)
                )
                  return (
                    <>
                      <InnerDataFieldTitle
                        onClick={() =>
                          setOpenInnerField((c) =>
                            c === child.identifier ? null : child.identifier
                          )
                        }>
                        {t(
                          `project.configurator.fieldsWithSubfields.${child.identifier}`
                        )}
                        <ArrowIcon
                          open={
                            openInnerField === child.identifier || expandAll
                          }>
                          expand_more
                        </ArrowIcon>
                      </InnerDataFieldTitle>
                      {(openInnerField === child.identifier || expandAll) && (
                        <InnerDataField>
                          {orderBy(
                            Object.entries(child.value).map(([key, value]) => ({
                              key,
                              value
                            })),
                            [
                              function (o) {
                                return typeof o.value
                              }
                            ]
                          ).map((obj) => (
                            <Value
                              identifier={identifier}
                              child={{ identifier: obj.key, value: obj.value }}
                              // Condition 2: Do not show description if is equal to title
                              showIdentifier={
                                parent.length > 1 &&
                                child.identifier !== obj.key
                              }
                            />
                          ))}
                        </InnerDataField>
                      )}
                    </>
                  )
                if (typeof child.value === 'object' && child.value !== null)
                  return (
                    <>
                      <InnerDataFieldSimpleTitle>
                        {t(
                          `project.configurator.fieldsWithSubfields.${child.identifier}`
                        )}
                      </InnerDataFieldSimpleTitle>
                      <InnerDataField>
                        {Object.entries(child.value)
                          .map(([key, value]) => ({
                            key,
                            value
                          }))
                          .map((obj) => (
                            <Value
                              identifier={identifier}
                              child={{ identifier: obj.key, value: obj.value }}
                              // Condition 2: Do not show description if is equal to title
                              showIdentifier={
                                parent.length > 1 &&
                                child.identifier !== obj.key
                              }
                            />
                          ))}
                      </InnerDataField>
                    </>
                  )
                return (
                  <Value
                    identifier={identifier}
                    child={child}
                    // Condition 2: Do not show description if is equal to title
                    showIdentifier={
                      child.identifier !== 'unitType' &&
                      identifier !== child.identifier
                    }
                  />
                )
              })}
            </DataField>
          </>
        ))}
    </FieldDataContainer>
  )
}
const CategoryDetails = ({
  details,
  expandAll,
  type
}: {
  details: ProjectCategory | ProjectOvercategory | undefined
  expandAll?: boolean
  type: ProjectTypes | undefined
}) => {
  const { t } = useTranslation()
  const overCategories = details ? Object.keys(details)?.sort() : []
  const [overCategoryOpen, setOverCategoryOpen] = useState<string | null>(
    !details?.data ? overCategories[0] : null
  )
  const [openField, setOpenField] = useState<string | null>()
  if (!details) return null
  if (details?.data) {
    return (
      <FieldsContainer>
        {Object.entries(details.data).map(([identifier, field]) => (
          <InnerFields
            field={field}
            identifier={identifier}
            onClick={() =>
              setOpenField((c) => (c === identifier ? null : identifier))
            }
            open={openField === identifier || expandAll}
            expandAll={expandAll}
            type={type}
          />
        ))}
      </FieldsContainer>
    )
  }

  return (
    <>
      {overCategories.map(
        (overCategory, index) =>
          // @ts-ignore
          !isEmpty(details[overCategory]?.data) && (
            <>
              {/* <OvercategoryTitle
                onClick={() =>
                  setOverCategoryOpen((c) =>
                    c === overCategory ? null : overCategory
                  )
                }>

                {`${t(`project.configurator.categories.${overCategory}`)}`}
                <ArrowIcon open={overCategory === overCategoryOpen}>
                  expand_more
                </ArrowIcon>
              </OvercategoryTitle> */}

              <CategoryDetails
                expandAll={expandAll}
                details={
                  // @ts-ignore
                  details[overCategory]
                }
                type={type}
              />
            </>
          )
      )}
    </>
  )
}

interface CompanySpectrumInterface {
  spectrum: Project
  expandAll?: boolean
  className?: string
}

const CompanySpectrum = ({
  spectrum,
  expandAll,
  className
}: CompanySpectrumInterface) => {
  const [openCategory, setOpenCategory] = useState<null | string>(
    spectrum?.categories?.[0] || null
  )
  return (
    <Container className={className}>
      {[...spectrum?.categories]
        ?.sort((first: Categories, second: Categories) =>
          getCategoryIndex(first) < getCategoryIndex(second) ? -1 : 1
        )
        ?.map(
          (category) =>
            category && (
              <Category>
                <CategoryTitle
                  onClick={() =>
                    setOpenCategory((c) => (c === category ? null : category))
                  }>
                  <CategoryPreview categories={[category]} />
                  <ArrowIcon open={openCategory === category || expandAll}>
                    expand_more
                  </ArrowIcon>
                </CategoryTitle>
                {(openCategory === category || expandAll) && (
                  <CategoryDetails
                    details={spectrum[category]}
                    expandAll={expandAll}
                    type={spectrum.type}
                  />
                )}
              </Category>
            )
        )}
    </Container>
  )
}

export default CompanySpectrum
