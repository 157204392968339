import React from 'react'
import styled from 'styled-components'
import { Link } from 'react-router-dom'

const Container = styled.div`
  position: relative;
  width: 0;
  height: 0;
`
const CrumbContainer = styled.div`
  position: absolute;
  display: flex;
  bottom: 20px;
  @media (max-width: 1200px) {
    bottom: 5px;
  }
  p {
    font-size: 18px;
    color: ${({ theme }) => theme.surface};
    @media (max-width: 1200px) {
      font-size: 15px;
    }
  }
`
interface CrumbInterface {
  active?: boolean
}
const Crumb = styled.p<CrumbInterface>`
  white-space: nowrap;
  color: ${({ active, theme }) => active && theme.primary} !important;
  cursor: pointer;
  :hover {
    color: ${({ theme }) => theme.primary};
  }
`
const Seperator = styled.p`
  margin: 0 5px;
`
export interface BreadcrumbInterface {
  link?: string
  text: string
  active?: boolean
  onClick?: () => void
}

const Breadcrumbs = ({ crumbs }: { crumbs: BreadcrumbInterface[] }) => {
  return (
    <Container>
      <CrumbContainer>
        {crumbs.map(({ link, text, active, onClick }, index) => (
          <React.Fragment key={index}>
            {index !== 0 && <Seperator>/</Seperator>}
            {active ? (
              <Crumb active={active}>{text}</Crumb>
            ) : link ? (
              <Link to={link}>
                <Crumb>{text}</Crumb>
              </Link>
            ) : (
              <Crumb onClick={onClick}>{text}</Crumb>
            )}
          </React.Fragment>
        ))}
      </CrumbContainer>
    </Container>
  )
}
export default Breadcrumbs
