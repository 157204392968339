import React from 'react'
import BaseInput from '@components/elements/BaseInput'
import styled from 'styled-components'
const Label = styled.p`
  color: black;
  margin-left: 5px;
  display: flex;
  flex: 1;
  width: max-content;
`
const Container = styled.div`
  flex: 1 1 50%;
  cursor: pointer;
  display: flex;
  align-items: center;
  padding-left: 5px;
  margin-left: -5px;
`
const BooleanInput = styled(BaseInput)``
const Boolean = ({
  value,
  onChange,
  placeholder,
  disabled,
  className
}: {
  value: boolean | undefined
  onChange: (v: boolean) => void
  placeholder?: string
  disabled?: boolean
  className?: string
}) => {
  return (
    <Container className={className} onClick={() => onChange(!value)}>
      <BooleanInput
        disabled={disabled}
        type="checkbox"
        checked={value}
        onChange={(e) => {
          onChange(e.target.checked)
        }}
        placeholder={placeholder}
      />
      <Label>{placeholder}</Label>
    </Container>
  )
}

export default Boolean
