import * as Yup from 'yup'
import {
  AvailableCategories,
  Categories,
  defaultProjectValues,
  offeringValues,
  QuestionType,
  FieldWithInputs,
  RequiredType,
  Input,
  SubcategoryData,
  ProjectCategory,
  ProjectTypes
} from '@interfaces/project'
import {
  wageProducerOffering,
  wageProducerSearching
} from '@interfaces/wageProducerProject'
import { Branches } from '@interfaces/branches'
import { cloneDeep } from 'lodash'
import unset from 'lodash/unset'
import set from 'lodash/set'
import pull from 'lodash/pull'
import get from 'lodash/get'
import { ProfileTypes } from '@interfaces/user'
import {
  usedMachineryOffering,
  usedMachinerySearching
} from '../interfaces/usedMachineryProject'

export const getCategoryIndex = (category: Categories) => {
  return AvailableCategories.indexOf(category)
}

export const indexToLetter = (index: number) => String.fromCharCode(index + 65)

export const getRegisterSchema = (
  t: any,
  signupType: ProfileTypes | null,
  hidePasswordAndMail?: boolean
) => {
  Yup.setLocale({
    mixed: {
      required: t('validations.required'),
      notOneOf: t('validations.pleaseChoose')
    },
    string: {
      min: t('validations.tooShort'),
      max: t('validations.tooLong'),
      email: t('validations.invalidEmail')
    },
    array: {
      min: t('validations.arrayNotEmpty')
    }
  })
  const SignupSchema = Yup.object().shape({
    firstName: Yup.string().required(),
    lastName: Yup.string().required(),
    email: hidePasswordAndMail ? Yup.mixed() : Yup.string().email().required(),
    country: Yup.string(),
    phone: Yup.string(),
    password: hidePasswordAndMail
      ? Yup.mixed()
      : Yup.string().min(8).required(),
    company: Yup.string(),
    address: Yup.string(),
    description:
      signupType === ProfileTypes.Offering ||
      signupType === ProfileTypes.WageProducer ||
      signupType === ProfileTypes.UsedMachinerySupplier
        ? Yup.string()
        : Yup.mixed(),
    branches: Yup.array().of(Yup.string()).min(1),
    profileImage: Yup.mixed(),
    logo: Yup.mixed(),
    imagePicture: Yup.mixed()
  })
  return SignupSchema
}

// prevents text-overflow
export const truncateText = (text: string, allowedLength: number = 30) => {
  if (text?.length > allowedLength) {
    return `${text.substring(0, allowedLength)}...`
  }
  return text
}

export const getUrlFirebase = async (firebase: any, source: string) => {
  try {
    const ref = firebase.storage().refFromURL(source)
    return await ref.getDownloadURL()
  } catch (error) {
    let errorMessage = 'Error in helpers, getUrlFirebase'
    if (error instanceof Error) {
      errorMessage = error.message
    }
    console.log(error, errorMessage)
    return false
  }
}

export const isSubcategoryDone = (
  subcategory: FieldWithInputs,
  subcategoryData: SubcategoryData[]
) => {
  const requiredChecker = (
    prioData: SubcategoryData,
    inputs: Input[],
    nestedName?: string
  ) => {
    // requiredType from parent inputs get applied to subinputs if they have no requiredType
    switch (requiredTypes[requiredTypes.length - 1]) {
      case RequiredType.MinOne:
        return inputs.some((i: Input) => {
          return isInputDone(prioData, i, nestedName)
        })
      case RequiredType.MaxOne:
        return (
          inputs.filter((i: Input) => isInputDone(prioData, i, nestedName))
            .length <= 1
        )
      case RequiredType.OnlyOne:
        return (
          inputs.filter((i: Input) => isInputDone(prioData, i, nestedName))
            .length === 1
        )
      case RequiredType.All:
        return inputs.every((i: Input) => {
          return isInputDone(prioData, i, nestedName)
        })
      default:
        // Specific
        return inputs.every((i: Input) => {
          return i.required ? isInputDone(prioData, i, nestedName) : true
        })
    }
  }

  const isInputDone = (
    prioData: SubcategoryData,
    input: Input,
    nestedName?: string
  ): boolean => {
    // nested inputs? -> start recursion
    if (input?.inputs) {
      if (input.requiredType) requiredTypes.push(input.requiredType)
      const recursiveInputResult = requiredChecker(
        prioData,
        input.inputs,
        input.name
      )
      if (input.requiredType) requiredTypes.pop()
      return recursiveInputResult
    }

    // recursive input? -> get nested prioData for this input
    if (prioData && nestedName) prioData = prioData[nestedName]
    if (prioData) {
      const inputValue = prioData[input.name]
      switch (input.type) {
        case QuestionType.MultiChoice:
        case QuestionType.SingleChoice:
        case QuestionType.File:
        case QuestionType.YesNo:
        case QuestionType.Boolean:
          return !!inputValue
        case QuestionType.Float:
          if (
            !inputValue &&
            Object.keys(prioData).some((key) => key.includes('_from_'))
          ) {
            // handle offering side from to values
            const from = prioData[`_from_${input.name}`]
            const to = prioData[`_to_${input.name}`]
            return (
              ((!!from && !Number.isNaN(from)) || from === 0) &&
              ((!!to && !Number.isNaN(to)) || to === 0)
            )
          }
          return (!!inputValue && !Number.isNaN(inputValue)) || inputValue === 0
        case QuestionType.String:
        case QuestionType.LongString:
          return typeof inputValue === 'string' && inputValue.length >= 1
        case QuestionType.Date:
          return (
            typeof inputValue === 'string' &&
            !isNaN(new Date(inputValue).getDate())
          )
        default:
          // SingleChoiceSelect
          return true
      }
    }
    return false
  }

  let data: SubcategoryData[] = []
  // is subcategoryData an array? -> if no: no prios (object needs to be wrapped in array)
  if (!Array.isArray(subcategoryData)) {
    data.push(subcategoryData)
  } else data = subcategoryData

  const requiredTypes: RequiredType[] = [
    subcategory.requiredType ?? RequiredType.Specific
  ]

  return data.every((prioData) => {
    return requiredChecker(prioData, subcategory.inputs, undefined)
  })
}

// Remove unrelevant subcategories before redux-init (onlyForBranches, onlyForCustomer)
export const getDefaultProjectValues = (
  type: ProjectTypes = 'searching',
  branches: Branches[] = []
) => {
  const cleanCategory = (category: ProjectCategory, path: string) => {
    const deepTraverse = (
      fieldOrInput: FieldWithInputs | Input,
      path: string
    ) => {
      const { onlyForBranches, onlyForCustomer } = fieldOrInput

      // Delete field or input if branch in Profile is not in onlyForBranches-Array
      // or onlyForCustomer is set, but type is partner (offering, , usedMachinerySupplier)
      if (
        (onlyForBranches &&
          !branches.some((branchInProfile) => {
            return onlyForBranches.includes(branchInProfile)
          })) ||
        (onlyForCustomer &&
          type !== 'searching' &&
          type !== 'searchingWageProducer' &&
          type !== 'searchingUsedMachinerySupplier')
      ) {
        // delete specific input
        if (path.includes('inputs')) {
          let inputs = cloneDeep(get(projectValues, path))
          const itemToDelete = inputs.find((found: Input) => {
            const input = fieldOrInput as Input
            return found?.name === input?.name
          })
          inputs = pull(inputs, itemToDelete)
          set(projectValues, path, inputs)
        } else unset(projectValues, path)
      } else if (fieldOrInput?.inputs) {
        // get own index if fieldOrInput is input (needed because index can change if inputs before got deleted)
        if (path.includes('inputs')) {
          const index = get(projectValues, path).findIndex((found: Input) => {
            const input = fieldOrInput as Input
            return found?.name === input?.name
          })
          path += `[${index}]`
        }
        fieldOrInput.inputs.forEach((input) => {
          deepTraverse(input, path + '.inputs')
        })
      }
    }

    // fields
    Object.entries(category.fields).forEach(([fieldName, fieldValue]) => {
      deepTraverse(fieldValue, path + `.fields.${fieldName}`)
    })
    // optionalFields
    Object.entries(category.optionalFields).forEach(
      ([fieldName, fieldValue]) => {
        deepTraverse(fieldValue, path + `.optionalFields.${fieldName}`)
      }
    )
  }

  let projectValues: any = null
  switch (type) {
    case 'offering':
      projectValues = cloneDeep(offeringValues)
      break
    case 'usedMachinerySupplier':
      projectValues = cloneDeep(usedMachineryOffering)
      break
    case 'searchingUsedMachinerySupplier':
      projectValues = cloneDeep(usedMachinerySearching)
      break
    case 'wageProducer':
      projectValues = cloneDeep(wageProducerOffering)
      break
    case 'searchingWageProducer':
      projectValues = cloneDeep(wageProducerSearching)
      break
    default:
      // searching
      projectValues = cloneDeep(defaultProjectValues)
  }
  // clean each category (max depth 2)
  AvailableCategories.forEach((category: Categories) => {
    if (!projectValues[category]?.fields)
      Object.entries(projectValues[category] as ProjectCategory).forEach(
        ([name, value]) => cleanCategory(value, `${category}.${name}`)
      )
    else
      cleanCategory(projectValues[category] as ProjectCategory, `${category}`)
  })

  return projectValues
}
